import styled from 'styled-components'
import { BaseTable, Text } from 'components'

export const OrderSummaryRow = styled(Text)`
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top-width: 0;

  @media (max-width: 420px) {
    font-size: 14px;
    padding: 10px;

    span:first-child {
      margin-right: 10px;
    }
  }
`

export const OrderSummaryContainer = styled(Text)`
  margin-bottom: 36px;

  ${BaseTable} {
    margin-top: 21px;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      font-size: 16px;
    }

    .ant-table-thead tr th {
      span {
        font-weight: bold;
        text-transform: uppercase;
        color: #524d6a;
      }
    }
  }
`

export const PlansTableContainer = styled(Text)`
  margin-bottom: 30px;

  ${BaseTable} {
    .ant-table-thead tr th {
      background: #ffffff !important;

      span {
        font-weight: bold;
        text-transform: uppercase;
        color: #524d6a;
      }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      font-size: 16px;
    }

    .ant-table-tbody > tr > td:first-child {
      background: #f0f2f5;
      font-weight: 600;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td:not(:first-child) {
      background: white !important;
    }
  }

  @media (max-width: 375px) {
    ${BaseTable} {
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        font-size: 14px;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 8px;
      }
    }
  }

  @media (max-width: 320px) {
    ${BaseTable} {
      .ant-table-thead > tr > th span,
      .ant-table-tbody > tr > td {
        font-size: 12px !important;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 7px;
      }
    }
  }
`

export const PlansTableHint = styled(Text)`
  border-radius: 4px;
  border: solid 1px #e4e9f0;
  background-color: #f0f2f5;
  padding: 15px;
  margin-top: 15px;

  p:last-child {
    margin-bottom: 0;
  }
`

export const Container = styled(Text)`
  max-width: 1120px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px #e4e9f0;
  padding: 36px;
  margin-top: 30px;

  a {
    color: #1890ff;
  }

  @media (max-width: 420px) {
    padding: 15px;
  }
`

export const Title = styled(Text)`
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 12px;
`

export const TermsText = styled(Text)`
  font-size: 16px;
  display: inline;
  line-height: 0 !important;

  a {
    text-decoration: underline;
    color: #524d6a;
    margin-left: 5px;
    margin-right: 5px;
  }
`
