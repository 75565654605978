import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as constants from './identity.constants'
import * as api from './identity.api'
import * as notificationsConstants from '../Notifications/notifications.constants'

export function* getPersons({ payload }) {
  try {
    const response = yield call(api.getPersons, payload)
    yield put({ type: constants.GET_PERSONS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PERSONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.persons.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.persons.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getPerson({ id }) {
  try {
    const response = yield call(api.getPerson, id)
    yield put({ type: constants.GET_PERSON_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PERSON_ERROR, error: err.response.data.error.type })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getPersonGovernmentIDs({ id }) {
  try {
    const response = yield call(api.getPersonGovernmentIDs, id)
    yield put({ type: constants.GET_PERSON_GOVERNMENT_IDS_SUCCESS, payload: response })
  } catch (err) {
    yield put({
      type: constants.GET_PERSON_GOVERNMENT_IDS_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.getGovernmentIDs.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.getGovernmentIDs.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* setPersonDecision({ payload }) {
  try {
    const response = yield call(api.setPersonDecision, payload)
    yield put({ type: constants.SET_PERSON_DECISION_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.SET_PERSON_DECISION_ERROR, error: err.response.data.error.type })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.set.decision.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.set.decision.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getOrganizations({ payload }) {
  try {
    const response = yield call(api.getOrganizations, payload)
    yield put({ type: constants.GET_ORGANIZATIONS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_ORGANIZATIONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.organizations.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.organizations.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getOrganization({ id }) {
  try {
    const response = yield call(api.getOrganization, id)
    yield put({ type: constants.GET_ORGANIZATION_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_ORGANIZATION_ERROR, error: err.response.data.error.type })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.organization.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.organization.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* setOrganizationDecision({ payload }) {
  try {
    const response = yield call(api.setOrganizationDecision, payload)
    yield put({ type: constants.SET_ORGANIZATION_DECISION_SUCCESS, payload: response })
  } catch (err) {
    yield put({
      type: constants.SET_ORGANIZATION_DECISION_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.organization.set.decision.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.organization.set.decision.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getOrganizationNotes({ payload }) {
  try {
    const response = yield call(api.getOrganizationNotes, payload)
    yield put({ type: constants.GET_ORGANIZATION_NOTES_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_ORGANIZATION_NOTES_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.notes.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.notes.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* postOrganizationNote({ payload }) {
  try {
    const { id, note } = payload
    const response = yield call(api.postOrganizationNote, id, note)
    yield put({ type: constants.POST_ORGANIZATION_NOTE_SUCCESS, payload: response })
  } catch (err) {
    yield put({
      type: constants.POST_ORGANIZATION_NOTE_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.organization.post.note.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.organization.post.note.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getPersonsNotes({ payload }) {
  try {
    const response = yield call(api.getPersonsNotes, payload)
    yield put({ type: constants.GET_PERSON_NOTES_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PERSON_NOTES_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.notes.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.notes.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* postPersonsNote({ payload }) {
  try {
    const { id, note } = payload
    const response = yield call(api.postPersonsNote, id, note)
    yield put({ type: constants.POST_PERSON_NOTE_SUCCESS, payload: response })
  } catch (err) {
    yield put({
      type: constants.POST_PERSON_NOTE_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.post.note.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.post.note.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* postPersonPassport({ payload }) {
  try {
    const { id, data } = payload
    yield call(api.postPersonPassport, id, data)
    yield put({ type: constants.POST_PERSON_PASSPORT_SUCCESS, payload: data })
  } catch (err) {
    yield put({
      type: constants.POST_PERSON_PASSPORT_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.post.passport.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.post.passport.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* postPersonDriverLicense({ payload }) {
  try {
    const { id, data } = payload
    yield call(api.postPersonDriverLicense, id, data)
    yield put({ type: constants.POST_PERSON_DRIVER_LICENSE_SUCCESS, payload: data })
  } catch (err) {
    yield put({
      type: constants.POST_PERSON_DRIVER_LICENSE_ERROR,
      error: err.response.data.error.type,
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.person.post.driver-license.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.person.post.driver-license.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* searchPersons({ payload }) {
  try {
    const response = yield call(api.searchPersons, payload)
    yield put({ type: constants.SEARCH_PERSONS_SUCCESS, payload: { data: response } })
  } catch (err) {
    yield put({ type: constants.SEARCH_PERSONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.persons.search.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.persons.search.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* searchOrganizations({ payload }) {
  try {
    const response = yield call(api.searchOrganizations, payload)
    yield put({ type: constants.SEARCH_ORGANIZATIONS_SUCCESS, payload: { data: response } })
  } catch (err) {
    yield put({ type: constants.SEARCH_ORGANIZATIONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.organization.search.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.organization.search.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getPersonsByOrganizationId({ id }) {
  try {
    const response = yield call(api.getPersonsByOrganizationId, { id })
    yield put({ type: constants.GET_PERSONS_BY_ORGANIZATION_ID_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PERSONS_BY_ORGANIZATION_ID_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.personsByOrganizationId.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.persons.personsByOrganizationId.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getSupportedCountries({ payload }) {
  try {
    const response = yield call(api.getSupportedCountries, payload)
    yield put({ type: constants.GET_SUPPORTED_COUNTRIES_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_SUPPORTED_COUNTRIES_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.countries.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.countries.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getSupportedStates({ payload }) {
  try {
    const response = yield call(api.getSupportedStates, payload)
    yield put({ type: constants.GET_SUPPORTED_STATES_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_SUPPORTED_STATES_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.states.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.states.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getKycDocuments({ payload }) {
  try {
    const response = yield call(api.getKycDocuments, payload)
    yield put({ type: constants.GET_KYC_DOCUMENTS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_KYC_DOCUMENTS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.documents.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.documents.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getKybDocument({ payload }) {
  try {
    const response = yield call(api.getKybDocument, payload)
    yield put({ type: constants.GET_KYB_DOCUMENT_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_KYB_DOCUMENT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.document.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.document.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getKycDocument({ payload }) {
  try {
    const response = yield call(api.getKycDocument, payload)
    yield put({ type: constants.GET_KYC_DOCUMENT_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_KYC_DOCUMENT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.document.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.document.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getKybDocuments({ payload }) {
  try {
    const response = yield call(api.getKybDocuments, payload)
    yield put({ type: constants.GET_KYB_DOCUMENTS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_KYB_DOCUMENTS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.documents.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.documents.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* uploadKycDocument({ payload }) {
  try {
    const response = yield call(api.uploadKycDocument, payload)
    yield put({ type: constants.UPLOAD_KYC_DOCUMENT_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.UPLOAD_KYC_DOCUMENT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.documents.upload.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.documents.upload.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* uploadKybDocument({ payload }) {
  try {
    const response = yield call(api.uploadKybDocument, payload)
    yield put({ type: constants.UPLOAD_KYB_DOCUMENT_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.UPLOAD_KYB_DOCUMENT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.identity.documents.upload.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.identity.documents.upload.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.GET_PERSONS, getPersons)])
  yield all([takeEvery(constants.GET_PERSON, getPerson)])
  yield all([takeEvery(constants.GET_PERSON_GOVERNMENT_IDS, getPersonGovernmentIDs)])
  yield all([takeEvery(constants.SET_PERSON_DECISION, setPersonDecision)])
  yield all([takeEvery(constants.GET_ORGANIZATIONS, getOrganizations)])
  yield all([takeEvery(constants.GET_ORGANIZATION, getOrganization)])
  yield all([takeEvery(constants.SET_ORGANIZATION_DECISION, setOrganizationDecision)])
  yield all([takeEvery(constants.GET_PERSON_NOTES, getPersonsNotes)])
  yield all([takeEvery(constants.POST_PERSON_NOTE, postPersonsNote)])
  yield all([takeEvery(constants.GET_ORGANIZATION_NOTES, getOrganizationNotes)])
  yield all([takeEvery(constants.POST_ORGANIZATION_NOTE, postOrganizationNote)])
  yield all([takeEvery(constants.POST_PERSON_PASSPORT, postPersonPassport)])
  yield all([takeEvery(constants.POST_PERSON_DRIVER_LICENSE, postPersonDriverLicense)])
  yield all([takeEvery(constants.SEARCH_PERSONS, searchPersons)])
  yield all([takeEvery(constants.SEARCH_ORGANIZATIONS, searchOrganizations)])
  yield all([takeEvery(constants.GET_PERSONS_BY_ORGANIZATION_ID, getPersonsByOrganizationId)])
  yield all([takeEvery(constants.GET_SUPPORTED_COUNTRIES, getSupportedCountries)])
  yield all([takeEvery(constants.GET_SUPPORTED_STATES, getSupportedStates)])
  yield all([takeEvery(constants.GET_KYC_DOCUMENTS, getKycDocuments)])
  yield all([takeEvery(constants.GET_KYB_DOCUMENTS, getKybDocuments)])
  yield all([takeEvery(constants.GET_KYB_DOCUMENT, getKybDocument)])
  yield all([takeEvery(constants.GET_KYC_DOCUMENT, getKycDocument)])
  yield all([takeEvery(constants.UPLOAD_KYC_DOCUMENT, uploadKycDocument)])
  yield all([takeEvery(constants.UPLOAD_KYB_DOCUMENT, uploadKybDocument)])
}
