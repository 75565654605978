import React from 'react'
import { Skeleton } from 'antd'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { PageTitle } from '../../ApiKeys/apiKeys.styled'
import { SubContent, ContentFooter, ContentHeader } from '../../Settings/settings.styled'

const OrganizationUpdateSkeleton = () => {
  return (
    <div>
      <Helmet title="Settings - Organization Settings" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage
            id="settings.organization.title"
            defaultMessage="Organization Settings"
          />
        </PageTitle>
      </ContentHeader>
      <SubContent>
        <Skeleton.Input className="mt-2 mb-4 with-label" size="large" active />
        <div className="row mb-5">
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
          <div className="col-6">
            <Skeleton.Input className="with-label" active size="large" />
          </div>
        </div>
      </SubContent>
      <ContentFooter>
        <Skeleton.Button active />
      </ContentFooter>
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.organization.teamMembers" defaultMessage="Team Members" />
        </PageTitle>
      </ContentHeader>
      <SubContent>
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
      </SubContent>
    </div>
  )
}

export default OrganizationUpdateSkeleton
