import axios from 'axios'

export async function getApplicationsHistory({ pageSize, skipPersons, skipOrganizations }) {
  const res = await axios.get(
    `/applications?skipPersons=${skipPersons}&skipOrganizations=${skipOrganizations}&pageSize=${pageSize}`,
  )
  return res.data
}

export async function getStatistics({ timeFrame }) {
  const res = await axios.get(`/statistics?timeFrame=${timeFrame}`)
  return res.data
}
