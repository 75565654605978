import styled, { createGlobalStyle } from 'styled-components'
import { Layout, List, Menu } from 'antd'
import { Text } from 'components'
import { Title, StyledAuthFooter } from '../Auth/auth.styled'

const { Content, Sider, Header } = Layout

export const StyledLayout = styled(Layout)`
  ${StyledAuthFooter} {
    text-align: center;

    ul {
      margin-top: 30px;
      display: inline-flex;
      list-style: none;
      a {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`

export const StyledNotificationsList = styled(List)`
  width: 430px;
  background-color: white;
  padding: 0 15px;

  .ant-list-item {
    height: 50px;
  }

  @media (max-width: 640px) {
    width: 320px;
    max-width: 100%;
  }
`

export const StyledNotifications = styled(Text)`
  margin-left: 18px;
  padding-left: 12px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;

  > img {
    margin-right: 15px;
    width: 16px;
    height: 16px;
    opacity: 0.65;
  }

  &:hover {
    cursor: pointer;

    > img {
      opacity: 1;
    }
  }

  .ant-list-items {
    max-width: 440px;
  }
`

export const StyledUserName = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
  width: 100%;
  transition: width 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
  white-space: nowrap;
  transform: translateZ(0);
`

export const StyledUserEmail = styled.div`
  color: #6889fd;
  font-weight: 600;
  width: 155px;
  text-overflow: ellipsis;
  transition: width 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
  white-space: nowrap;
  transform: translateZ(0);
`

export const StyledUserInfo = styled.div`
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;

  .short-username {
    display: flex;
    width: 52px;
    min-width: 52px;
    height: 52px;
    background: white;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 21px;
    margin-right: 10px;
    color: #524d6a !important;
    font-family: 'Assistant', sans-serif;
  }
`

export const StyledContent = styled(Content)`
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;

  max-width: 1120px;
  width: 100%;

  margin: 30px auto;

  @media (max-width: 1024px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`

export const StyledSider = styled(Sider)`
  &.ant-layout-sider {
    background: #001433;
  }

  .ant-layout-sider-trigger {
    background-color: #33435c;
  }

  &.ant-layout-sider-collapsed {
    .short-username {
      display: flex;
    }
    ${StyledUserName} {
      width: 0;
    }
    ${StyledUserEmail} {
      width: 0;
    }
    ${StyledNotifications} {
      flex-direction: column;

      > img {
        margin-bottom: 10px;
        margin-left: 16px;
      }
    }
    .ant-layout-sider-children {
      .logo-container {
        display: block;
        width: 52px;
        height: 64px;
      }
    }
  }

  .ant-layout-sider-children {
    .logo-container {
      width: 201px;
      height: 64px;
      display: block;
      margin-left: 30px;
      margin-bottom: 15px;
      margin-top: 30px;
      overflow: hidden;
      transform: translateZ(0);
      transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  @media (max-width: 960px) {
    display: none;
  }
`

export const StyledSideMenu = styled(Menu)`
  &.ant-menu.ant-menu-dark {
    font-family: 'Assistant', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
    font-weight: 600;
    background: #001433;

    padding-top: 25px !important;
    border-top: 1px solid #4d5a70;
  }

  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-submenu-title > span {
    display: flex !important;
    align-items: center;
    padding: 0 !important;
    font-size: 15px;

    img {
      opacity: 0.65;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu {
    padding: 0 !important;
    padding-left: 6px !important;
    margin-left: 24px !important;
  }

  &.ant-menu-inline-collapsed {
    .ant-menu-submenu-title {
      padding: 0 !important;
    }
    .ant-menu-item,
    .ant-menu-submenu {
      padding-left: 15px !important;
      margin-left: 30px !important;
    }
  }

  .anticon {
    font-size: 14px !important;
    line-height: 1 !important;
    min-width: 14px;

    + span {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  span,
  img,
  div,
  li {
    transition: none !important;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    transform: translateZ(0);
    transition: margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  .ant-menu-item.ant-menu-item-selected {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2) !important;
  }

  &.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #001433 !important;
    box-shadow: none !important;
  }

  &.ant-menu-inline-collapsed {
    width: 112px;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-active {
    .anticon {
      img {
        opacity: 1;
      }
      + span {
        color: rgba(255, 255, 255, 1) !important;
      }
    }
  }

  @media (max-width: 640px) {
    padding-top: 0;
  }
`

export const StyledHeader = styled(Header)`
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  font-weight: 600;
  padding-right: 0 !important;
  padding-left: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > img {
    margin-left: 15px;
  }

  .notification-trigger {
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;
    align-self: center;
    height: 57px;
    align-items: center;

    img {
      margin-right: 0;
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  > div > img {
    padding-right: 15px;
  }

  @media (min-width: 961px) {
    display: none;
  }

  .ant-menu-horizontal {
    display: flex;

    > li {
      border: none !important;
    }
    .ant-menu-submenu-title {
      height: 57px;
      display: flex;

      img {
        padding-top: 10px;
      }
    }
  }
`

export const ErrorTitle = styled(Text)`
  font-size: 128px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
`

export const ErrorSubTitle = styled(Title)`
  width: 300px;
  padding: 20px 15px;
  margin: 0 auto;
  line-height: 1.4;
`

export const GlobalStyles = createGlobalStyle`
  body #root {
    min-height: 100%;
    width: 100%;
    height: 100%;
    
    > section {
      min-height: 100vh;
    }
  }
  
  .ant-skeleton {
    width: 100% !important;
    
    &.with-label > span {
      &:before {
          content: "";
          height: 14px;
          width: 30%;
          background: red;
          position: relative;
          top: -20px;
          display: block;
          
          background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
          background-size: 400% 100%;
          animation: ant-skeleton-loading 1.4s ease infinite;
        }
    }
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    height: 42px;
    width: 100%;
  }

  @media (max-width: 960px) {
    ${StyledUserInfo} {
      padding: 15px !important;
      line-height: 1;
      
      ${StyledUserEmail} {
        overflow: visible;
      }
    }
    
    .ant-menu-submenu {
        ${StyledSideMenu} {
          &.ant-menu.ant-menu-dark {
            padding-top: 0 !important;
          }
          width: 320px;
         
          .ant-menu-item {
            padding: 15px !important;
            margin: 0 !important;
            height: 50px;
            border-bottom: solid 1px #4d5a70;
          }
          .ant-menu-submenu {
            margin: 0 !important;
            padding: 0 !important;
            border-bottom: solid 1px #4d5a70;
            
            .ant-menu-item {
              padding: 15px !important;
              padding-left: 35px !important;
              height: 50px;
            }
          }
          .ant-menu-submenu-title {
            padding: 15px !important;
            height: 50px;
          }
        }
    }
    
    .ant-menu-submenu > .ant-menu-sub {
      font-family: 'Assistant', sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      min-width: 200px !important;
      
      .ant-menu-item {
        padding: 15px !important;
        margin: 0 !important;
        height: 50px;
        border-bottom: solid 1px #4d5a70;
        line-height: 1.3 !important;
        font-weight: 600;
        
        &.ant-menu-item-selected {
          background-color: rgba(255,255,255,0.2) !important;
        }
      }
      .ant-menu-submenu-title {
        padding: 15px !important;
        height: 50px;
      }
    }
    
    .ant-menu-submenu.ant-menu-light {
      .ant-menu-item {
        border-bottom-color: #e4e9f0;
      }
    }
    
    .ant-popover-arrow {
      display: none !important;
    }
 
  }
  
  .ant-select-item-option {
    min-height: 42px !important;
   align-items: center;
  } 
`
