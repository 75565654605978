import * as constants from './billing.constants'

export function getPaymentPlans() {
  return { type: constants.GET_PAYMENT_PLANS }
}

export function getPaymentMethods() {
  return { type: constants.GET_PAYMENT_METHODS }
}

export function updatePaymentMethod(payload) {
  return { type: constants.UPDATE_PAYMENT_METHOD, payload }
}

export function getInvoices() {
  return { type: constants.GET_INVOICES }
}

export function getInvoice(id) {
  return { type: constants.GET_INVOICE, id }
}
