export const SIDER_COLLAPSED_STORAGE_KEY = 'siderCollapsed'

// MOMENT
export const MOMENT_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm a'
export const MOMENT_DATE_FORMAT = 'MM/DD/YYYY'

// ACTIONS TYPES
export const GET_NOTIFICATIONS = 'app/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'app/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'app/GET_NOTIFICATIONS_ERROR'
