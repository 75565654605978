import React from 'react'
import { FormattedMessage } from 'react-intl'

// eslint-disable-next-line import/prefer-default-export
export const getRules = (required, type, intlPrefix) => {
  const rules = []

  if (required) {
    rules.push({
      required: true,
      message: (
        <span data-testid={`input.${type}.error.required`}>
          <FormattedMessage
            id={`form.item.${intlPrefix}.error.required`}
            defaultMessage={`Please enter your ${intlPrefix}`}
          />
        </span>
      ),
    })
  }

  switch (type) {
    case 'firstName':
      rules.push(() => ({
        validator(rule, value) {
          if (!value || !value.length) return Promise.resolve()
          // eslint-disable-next-line prefer-promise-reject-errors
          if (/[\s]/g.test(value)) return Promise.reject('Please enter valid First Name')
          if (!/[!@#$%^&*(),?":{}|<>\d]/g.test(value)) return Promise.resolve()

          // TODO: use intl here, after update react-intl
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Please enter valid First Name')
        },
      }))
      break
    case 'lastName':
      rules.push(() => ({
        validator(rule, value) {
          if (!value || !value.length) return Promise.resolve()
          // eslint-disable-next-line prefer-promise-reject-errors
          if (/[\s]/g.test(value)) return Promise.reject('Please enter valid Last Name')
          if (!/[!@#$%^&*(),?":{}|<>\d]/g.test(value)) return Promise.resolve()

          // TODO: use intl here, after update react-intl
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Please enter valid Last Name')
        },
      }))
      break
    case 'phone':
    case 'billingPhoneNumber':
      rules.push({
        pattern: /^\d+$/,
        message: (
          <FormattedMessage
            id={`form.item.${intlPrefix}.error.notValid`}
            defaultMessage={`Please enter valid ${intlPrefix}`}
          />
        ),
      })
      break
    case 'zipCode':
    case 'billingZip':
      rules.push({
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: (
          <FormattedMessage
            id={`form.item.${intlPrefix}.error.notValid`}
            defaultMessage={`Please enter valid ${intlPrefix}`}
          />
        ),
      })
      break
    case 'password':
      rules.push({
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\-@#\$%\^&\*])(?=.{8,})/,
        message: (
          <FormattedMessage
            id={`form.item.${intlPrefix}.error.notValid`}
            defaultMessage="Passwords must be at least 8 characters, have one capital letter, one number, and one special character."
          />
        ),
      })
      break
    case 'confirmPassword':
      rules.push(({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve()
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('The two passwords that you entered do not match!')
        },
      }))
      break
    case 'oldPassword':
      // no rules for oldPassword for migrated passwords
      break
    default:
      rules.push({
        type,
        message: (
          <span data-testid={`input.${type}.error.notValid`}>
            <FormattedMessage
              id={`form.item.${intlPrefix}.error.notValid`}
              defaultMessage={`Please enter valid ${intlPrefix}`}
            />
          </span>
        ),
      })
  }

  return rules
}
