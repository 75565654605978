import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { StyledTable } from '../identity.styled'
import { IDENTITY_BUSINESS_ROUTE, IDENTITY_CUSTOMER_ROUTE } from '../../App/app.router.constants'
import { IDENTITY_ENUM, IDENTITY_LIST_DEFAULT_PAGE_SIZE } from '../identity.constants'
import { USER_ROLES_ENUM } from '../../Auth/auth.constants'
import * as notificationsConstants from '../../Notifications/notifications.constants'

const IdentityList = ({ type, data, loading, handlePageChange, columns }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  const handleRowClick = record => {
    if (USER_ROLES_ENUM.DEVELOPER === user.role) {
      dispatch({
        type: notificationsConstants.NOTIFICATION_SHOW,
        payload: {
          messageId: `notification.identity.permission.error.message`,
          descriptionId: `notification.identity.permission.error.description`,
          type: notificationsConstants.NOTIFICATION_TYPES.INFO,
        },
      })
    } else {
      history.push(
        `${type === IDENTITY_ENUM.KYC ? IDENTITY_CUSTOMER_ROUTE : IDENTITY_BUSINESS_ROUTE}/${
          record.id
        }`,
      )
    }
  }

  const handleTableChange = pagination => {
    const { current } = pagination
    handlePageChange(current, IDENTITY_LIST_DEFAULT_PAGE_SIZE)
  }

  return (
    <Fragment>
      <StyledTable
        rowKey="id"
        loading={loading}
        pagination={
          data.meta
            ? {
                total: data.meta.count,
                defaultPageSize: IDENTITY_LIST_DEFAULT_PAGE_SIZE,
                hideOnSinglePage: true,
              }
            : false
        }
        dataSource={data.data}
        columns={columns}
        onChange={handleTableChange}
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
      />
    </Fragment>
  )
}

export default IdentityList
