import React from 'react'
import { WhiteVerticalTable } from 'components'

const AdditionalDataKeyValue = ({ data }) => {
  const dataSource = Object.keys(data)
    .filter(key => Object(data[key]) !== data[key])
    .map(key => ({
      key,
      label: key,
      value: data[key],
    }))

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: value => {
        return <span className="font-weight-bold">{value}</span>
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  return (
    <WhiteVerticalTable
      className=""
      rowKey="key"
      columns={columns}
      dataSource={dataSource}
      bordered
      size="default"
      pagination={false}
      showHeader={false}
    />
  )
}

export default AdditionalDataKeyValue
