import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as constants from './app.constants'
import * as api from './app.api'
import * as notificationsConstants from '../Notifications/notifications.constants'

// eslint-disable-next-line import/prefer-default-export
export function* getNotifications() {
  try {
    const response = yield call(api.getNotifications)
    yield put({ type: constants.GET_NOTIFICATIONS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_NOTIFICATIONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.app.notifications.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.app.notifications.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.GET_NOTIFICATIONS, getNotifications)])
}
