import styled from 'styled-components'
import { Form } from 'antd'

export const WidgetsListContainer = styled.div`
  .ant-table tr th {
    font-weight: 600;
    color: #524d6a;
  }
  .ant-table tr th,
  .ant-table tr td {
    text-align: center;
  }
`

export const WidgetForm = styled(Form)`
  .checkbox-description {
    font-size: 12px;
    padding-left: 25px;
    margin-bottom: 10px;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
`
