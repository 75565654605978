import * as constants from './preSaleCheckout.constants'

const initialState = {
  loading: false,
  response: {
    plan: {},
    paymentMethods: [],
  },
}

const preSaleCheckout = (state = initialState, action) => {
  switch (action.type) {
    case constants.PRE_SALE_CHECKOUT:
      return {
        ...state,
        loading: true,
      }

    case constants.PRE_SALE_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      }

    case constants.PRE_SALE_CHECKOUT_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default preSaleCheckout
