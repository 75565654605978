import React from 'react'
import { FormattedMessage } from 'react-intl'
import InfoIcon from 'assets/images/icons/ic-info.svg'
import { NoRecordsFoundStyled } from 'components/common.styled'

const NoRecordsFound = () => (
  <NoRecordsFoundStyled>
    <img src={InfoIcon} alt="Api Keys list no records Info icon" />
    <FormattedMessage id="settings.apiKey.noRecords" defaultMessage="You don’t have any API Keys" />
  </NoRecordsFoundStyled>
)

export default NoRecordsFound
