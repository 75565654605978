// eslint-disable-next-line import/prefer-default-export
export const TABLE_PLANS_DATA = [
  {
    key: '1',
    feature: 'dashboard',
    selfService: true,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '2',
    feature: 'api',
    selfService: true,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '3',
    feature: 'kycKybWidget',
    selfService: true,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '4',
    feature: 'reporting',
    selfService: true,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '5',
    feature: 'teamMembers',
    selfService: '3',
    smallBusiness: 'unlimited',
    enterprise: 'unlimited',
  },
  {
    key: '6',
    feature: 'recurringSanctions',
    selfService: 'manual',
    smallBusiness: 'quarterly',
    enterprise: 'monthly',
  },
  {
    key: '7',
    feature: 'machineLearning',
    selfService: false,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '8',
    feature: 'customizableWidget',
    selfService: false,
    smallBusiness: true,
    enterprise: true,
  },
  {
    key: '9',
    feature: 'customizableReporting',
    selfService: false,
    smallBusiness: 'limited',
    enterprise: 'full',
  },
  {
    key: '9',
    feature: 'kycKybChecks',
    selfService: 'kycKybChecks.selfService',
    smallBusiness: 'kycKybChecks.smallBusiness',
    enterprise: 'kycKybChecks.enterprise',
  },
  {
    key: '10',
    feature: 'platformFee',
    selfService: 'platformFee.selfService',
    smallBusiness: 'platformFee.smallBusiness',
    enterprise: 'platformFee.enterprise',
  },
]

// ACTIONS TYPES
export const PRE_SALE_CHECKOUT = 'auth/PRE_SALE_CHECKOUT'
export const PRE_SALE_CHECKOUT_SUCCESS = 'auth/PRE_SALE_CHECKOUT_SUCCESS'
export const PRE_SALE_CHECKOUT_ERROR = 'auth/PRE_SALE_CHECKOUT_ERROR'
