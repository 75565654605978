import React, { Fragment, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoadingOutlined } from '@ant-design/icons'
import { Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { StyledUpload, StyledUploadArea } from './image.upload.styled'
import { StyledButton } from '../antd.styled'
import ImagePreview from './ImagePreview'
import { Button } from '../index'
import {
  IDS_TYPES_ENUM,
  DEFAULT_PASSPORT_COUNTRY,
} from '../../features/Identity/identity.constants'
import * as notificationsConstants from '../../features/Notifications/notifications.constants'

const { Option } = Select

const ImageUpload = ({
  label,
  image,
  upload,
  loading,
  type,
  handleSelectChange,
  countries,
  states,
  state,
}) => {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState(null)
  const [previewImageUrl, setPreviewImageUrl] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleFileSelect, setVisibleFileSelect] = useState(false)

  useEffect(() => {
    if (!loading) {
      setVisibleFileSelect(false)
      setPreviewImageUrl(null)
    }
  }, [loading])

  useEffect(() => {
    if (image !== null) {
      setImageUrl(image)
    }
  }, [image])

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    if (!isJpgOrPng) {
      dispatch({
        type: notificationsConstants.NOTIFICATION_SHOW,
        payload: {
          messageId: `notification.identity.error.imageUpload.type.message`,
          descriptionId: `notification.identity.error.imageUpload.type.description`,
          type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
        },
      })
      return false
    }

    const isLt400kb = file.size / 1024 / 1024 > 0.4
    if (!isLt400kb) {
      dispatch({
        type: notificationsConstants.NOTIFICATION_SHOW,
        payload: {
          messageId: `notification.identity.error.imageUpload.sizeLess.message`,
          descriptionId: `notification.identity.error.imageUpload.sizeLess.description`,
          type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
        },
      })
      return false
    }

    const isLt2M = file.size / 1024 / 1024 < 4
    if (!isLt2M) {
      dispatch({
        type: notificationsConstants.NOTIFICATION_SHOW,
        payload: {
          messageId: `notification.identity.error.imageUpload.sizeMore.message`,
          descriptionId: `notification.identity.error.imageUpload.sizeMore.description`,
          type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
        },
      })
      return false
    }

    getBase64(file, res => {
      setPreviewImageUrl(res)
    })

    return true
  }

  const uploadButton = (
    <div className="ant-upload-label-container">
      <div className="ant-upload-label mb-3">
        {loading ? <LoadingOutlined /> : <span>{label}</span>}
      </div>
      <div className="ant-upload-hint-text mb-3">
        <FormattedMessage
          id="image.upload.description"
          defaultMessage="Drag and drop or select an image file (JPEG or PNG). Minimal size is 400 kilobytes and maximal size is 4 megabytes."
        />
      </div>
      <StyledButton type="primary">
        <FormattedMessage id="image.selectImage.btn" defaultMessage="Select an Image" />
      </StyledButton>
    </div>
  )

  const handleImageClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setVisible(true)
  }

  const handleSubmitSelectedImage = () => {
    upload(previewImageUrl)
  }

  const handleCancelSelectedImage = () => {
    setVisibleFileSelect(false)
  }

  const handleImageUpdate = () => {
    setVisible(false)
    setVisibleFileSelect(true)
  }

  return (
    <Fragment>
      <StyledUpload withImageUrl={!!imageUrl}>
        {imageUrl ? (
          <StyledButton className="h-100" type="link" onClick={handleImageClick}>
            <img src={`data:image/png;base64,${imageUrl}`} alt="ID preview" />
          </StyledButton>
        ) : (
          <Fragment>
            <span className="d-flex flex-grow-1 justify-content-center align-items-center">
              {label}
            </span>
            <StyledButton type="primary" onClick={() => setVisibleFileSelect(true)}>
              <FormattedMessage id="image.upload.btn" defaultMessage="Upload" />
            </StyledButton>
          </Fragment>
        )}
      </StyledUpload>

      <Modal
        centered
        visible={visibleFileSelect}
        title={<FormattedMessage id="image.selectFile.modal.title" defaultMessage="Select File" />}
        onOk={handleSubmitSelectedImage}
        onCancel={handleCancelSelectedImage}
        footer={[
          <Button key="back" onClick={handleCancelSelectedImage}>
            <FormattedMessage id="image.upload.modal.cancel" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            disabled={!previewImageUrl}
            type="primary"
            loading={loading}
            onClick={handleSubmitSelectedImage}
          >
            <FormattedMessage id="image.upload.modal.submit" defaultMessage="Upload" />
          </Button>,
        ]}
      >
        {type === IDS_TYPES_ENUM.PASSPORT ? (
          <Fragment>
            <span className="font-weight-bold">Country</span>
            <div>
              <Select
                showSearch
                className="w-100 mb-3 mt-1"
                defaultValue={DEFAULT_PASSPORT_COUNTRY}
                onChange={handleSelectChange}
              >
                {countries.map(c => (
                  <Option key={c.countryCode} value={c.countryCode}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <span className="font-weight-bold">State</span>
            <div>
              <Select
                showSearch
                className="w-100 mb-3 mt-1"
                defaultValue={state}
                onChange={handleSelectChange}
              >
                {states.map(c => (
                  <Option key={c.code} value={c.code}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Fragment>
        )}

        {previewImageUrl ? (
          <img src={previewImageUrl} alt="ID preview" style={{ width: '100%' }} />
        ) : (
          <div>
            <StyledUploadArea
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={() => false}
            >
              {uploadButton}
            </StyledUploadArea>
          </div>
        )}
      </Modal>

      <ImagePreview
        imageUrl={imageUrl}
        visible={visible}
        setVisible={setVisible}
        handleImageUpdate={handleImageUpdate}
      />
    </Fragment>
  )
}

ImageUpload.defaultProps = {
  countries: [],
}

export default ImageUpload
