import axios from 'axios'

export async function getUserProfile() {
  return axios.get(`/profiles`).then(res => res.data)
}

export async function createUserProfile(payload) {
  return axios.post(`/profiles`, payload).then(res => res.data)
}

export async function updateUserProfile(payload) {
  return axios.patch(`/profiles`, payload).then(res => res.data)
}
