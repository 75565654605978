import axios from 'axios'

export async function getApiKeys() {
  return axios.get('/apiKeys').then(res => res.data)
}

export async function createApiKey(payload) {
  return axios.post('/apiKeys', payload).then(res => res.data)
}

export async function updateApiKey(payload) {
  return axios.patch(`/apiKeys/${payload.id}`, payload).then(res => res.data)
}

export async function deleteApiKey(id) {
  return axios.delete(`/apiKeys/${id}`).then(res => res.data)
}
