import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Container, HorizontalSeparator, PageTitle } from 'components'
import Statistics from './components/Statistics'
import History from './components/History'

const Dashboard = () => {
  return (
    <Container>
      <Helmet title="Dashboard" />
      <PageTitle>
        <FormattedMessage id="dashboard.title" defaultMessage="Dashboard" />
      </PageTitle>
      <HorizontalSeparator />
      <Statistics />
      <HorizontalSeparator />
      <History />
    </Container>
  )
}

export default Dashboard
