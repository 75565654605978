import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Button, Input, Text } from 'components'

const { TextArea } = Input

const DecisionModal = ({ visible, handleSubmit, handleCancel, loading, decisionType }) => {
  const [note, setNote] = useState('')

  useEffect(() => {
    if (!loading) {
      setNote('')
    }
  }, [loading])

  const handleNoteChange = e => {
    setNote(e.target.value)
  }

  const handleDecisionSubmit = () => {
    handleSubmit(note)
  }

  const handleDecisionCancel = () => {
    setNote('')
    handleCancel()
  }

  return (
    <Modal
      centered
      visible={visible}
      title={
        <FormattedMessage
          id={`identity.details.decision.${decisionType}.modal.title`}
          defaultMessage="Update"
        />
      }
      onOk={handleDecisionSubmit}
      onCancel={handleDecisionCancel}
      footer={[
        <Button key="back" onClick={handleDecisionCancel}>
          <FormattedMessage id="identity.details.decision.modal.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!note.length || loading}
          loading={loading}
          onClick={handleDecisionSubmit}
        >
          <FormattedMessage id="identity.details.decision.modal.submit" defaultMessage="Submit" />
        </Button>,
      ]}
    >
      <Text className="font-weight-bold mb-3">
        <FormattedMessage
          id={`identity.details.decision.${decisionType}.modal.description`}
          defaultMessage="Please give detailed reasons why application was updated"
        />
      </Text>

      <TextArea
        onChange={handleNoteChange}
        value={note}
        className="mb-4"
        rows={7}
        placeholder="Reason..."
      />
    </Modal>
  )
}

DecisionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default DecisionModal
