import React from 'react'
import { Skeleton } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { Collapse, CollapsePanel, Tabs } from 'components'
import { StyledHistoryTabPane } from '../../identity.styled'
import Notes from './Notes'
import History from './History'
import Documents from './Documents'

const ApplicationHistory = ({
  loading,
  statusHistory,
  notes,
  postNote,
  documents,
  noteLoading,
  handleFileUpload,
  handleFileDownload,
  documentForDownload,
  documentForDownloadLoading,
  documentUploadLoading,
}) => {
  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      accordion
    >
      <CollapsePanel
        header={
          <span className="font-weight-bold">
            <FormattedMessage id="identity.history" defaultMessage="Application History" />
          </span>
        }
        key="1"
      >
        <Tabs defaultActiveKey="1">
          <StyledHistoryTabPane
            tab={<FormattedMessage id="identity.history.tab.history" defaultMessage="History" />}
            key="1"
          >
            {loading ? (
              <div className="p-3">
                <Skeleton avatar active />
                <Skeleton avatar active />
                <Skeleton avatar active />
              </div>
            ) : (
              <History data={statusHistory} />
            )}
          </StyledHistoryTabPane>
          <StyledHistoryTabPane
            tab={<FormattedMessage id="identity.history.tab.notes" defaultMessage="Notes" />}
            key="2"
          >
            <Notes noteLoading={noteLoading} postNote={postNote} data={notes} />
          </StyledHistoryTabPane>
          <StyledHistoryTabPane
            tab={
              <FormattedMessage id="identity.history.tab.documents" defaultMessage="Documents" />
            }
            key="3"
          >
            <Documents
              handleFileDownload={handleFileDownload}
              handleFileUpload={handleFileUpload}
              data={documents}
              documentForDownload={documentForDownload}
              documentForDownloadLoading={documentForDownloadLoading}
              documentUploadLoading={documentUploadLoading}
            />
          </StyledHistoryTabPane>
        </Tabs>
      </CollapsePanel>
    </Collapse>
  )
}

export default ApplicationHistory
