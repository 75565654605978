import * as constants from './apiKeys.constants'

const initialState = {
  loading: false,
  list: [],
}

const apiKeys = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_API_KEYS:
    case constants.API_KEY_CREATE:
      return {
        ...state,
        loading: true,
      }

    case constants.API_KEY_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case constants.API_KEY_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: state.list.filter(item => item.id !== action.payload),
      }
    }

    case constants.API_KEY_UPDATE_SUCCESS: {
      const list = [...state.list]
      const indexToUpdate = list.findIndex(item => item.id === action.payload.id)
      list[indexToUpdate] = { ...list[indexToUpdate], ...action.payload }

      return {
        ...state,
        loading: false,
        list,
      }
    }

    case constants.GET_API_KEYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [...action.payload],
      }
    }

    case constants.GET_API_KEYS_ERROR:
    case constants.API_KEY_CREATE_ERROR:
    case constants.API_KEY_UPDATE_ERROR:
    case constants.API_KEY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default apiKeys
