import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Button, PaymentMethodLogo } from 'components'
import { Card, CardHintText, CardText, PaymentMethodRow } from '../billing.styled'
import * as routerConstants from '../../App/app.router.constants'
import PaymentMethodSkeleton from '../skeletons/PaymentMethodSkeleton'
import { getPaymentMethods } from '../billing.actions'

const PaymentMethod = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { paymentMethod, loading } = useSelector(state => state.billing)

  useEffect(() => {
    if (!paymentMethod.id) {
      dispatch(getPaymentMethods())
    }
  }, [paymentMethod])

  if (loading) return <PaymentMethodSkeleton />

  return (
    <Card>
      <CardText className="font-weight-bold">
        <FormattedMessage
          id="settings.billing.payment.method.title"
          defaultMessage="Payment Method"
        />
      </CardText>
      <PaymentMethodRow>
        <PaymentMethodLogo type={paymentMethod.label} />

        <div className="ml-4 pt-2">
          <CardHintText>
            <span>{paymentMethod.label}</span>
          </CardHintText>
          <CardHintText className="font-weight-bold">**** {paymentMethod.lastFour}</CardHintText>
        </div>
      </PaymentMethodRow>
      <Button
        onClick={() =>
          history.push(routerConstants.SETTINGS_ADD_PAYMENT_METHOD_ROUTE, {
            paymentMethodId: paymentMethod.id,
          })
        }
        type="link"
      >
        <FormattedMessage
          id="settings.billing.payment.method.update.link"
          defaultMessage="Update Payment Information"
        />
      </Button>
    </Card>
  )
}

export default PaymentMethod
