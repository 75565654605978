import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { HorizontalSeparator } from 'components'
import { Container, Title } from '../preSaleCheckout.styled'
import OrderSummary from '../components/OrderSummary'
import { LOGIN_ROUTE } from '../../App/app.router.constants'

const PaymentSuccess = () => {
  const history = useHistory()
  const { response } = useSelector(state => state.preSaleCheckout)

  useEffect(() => {
    if (!response.id) {
      history.push(LOGIN_ROUTE)
    }
  }, [response.id])

  return (
    <Container>
      <Helmet title="Charge.io PreSale Checkout Success" />

      <Title>
        <FormattedMessage id="preSaleCheckout.success.title" defaultMessage="Thank you!" />
      </Title>
      <FormattedMessage
        id="preSaleCheckout.success.description"
        defaultMessage="Your account has been created. We will email you when the dashboard is launched."
      />
      <HorizontalSeparator />

      <OrderSummary response={response} />

      <div className="text-center">
        <FormattedMessage
          id="preSaleCheckout.success.footer"
          defaultMessage="In the meantime, if you have any questions about your account you can reach out to our support team at"
        />
        &nbsp;
        <a href="mailto:support@charge.io">support@charge.io</a>
      </div>
    </Container>
  )
}

export default PaymentSuccess
