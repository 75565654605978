import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { IDENTITY_STATUSES } from './identity.constants'

// eslint-disable-next-line import/prefer-default-export
export const mapDataFields = (fieldsToPick, object) => {
  const res = _.pick(object, fieldsToPick)
  return Object.keys(res).map(key => {
    return { field: key, value: res[key] }
  })
}

export const getColumnsWithStatusFilter = columns => {
  return columns.map(c => {
    if (c.key === 'status') {
      delete c.filteredValue
      return {
        ...c,
        filters: Object.keys(IDENTITY_STATUSES).map(key => ({
          text: (
            <FormattedMessage
              id={`dashboard.subTitle.history.row.status.${IDENTITY_STATUSES[key]}`}
              defaultMessage={IDENTITY_STATUSES[key]}
            />
          ),
          value: IDENTITY_STATUSES[key],
        })),
        onFilter: (value, record) => (record.result ? record.result.status === value : false),
      }
    }
    return c
  })
}

export const getColumnsAndResetStatusFilter = columns => {
  return columns.map(c => {
    if (c.key === 'status') {
      delete c.filters
      return {
        ...c,
        filteredValue: null,
      }
    }
    return c
  })
}

export const getKYBButtonsState = (linkedKYC, status) => {
  let result = {
    isAcceptAllowed: false,
    isDeclineAllowed: false,
  }

  if (linkedKYC.data) {
    if (linkedKYC.data.length === 0) {
      return {
        isAcceptAllowed:
          status === IDENTITY_STATUSES.DENY || status === IDENTITY_STATUSES.MANUAL_REVIEW,
        isDeclineAllowed:
          status === IDENTITY_STATUSES.ACCEPT || status === IDENTITY_STATUSES.MANUAL_REVIEW,
      }
    }

    const {
      isOneOfLinkedKYCinManualReview,
      isOneOfLinkedKYCDeclined,
      isAllLinkedKYCApproved,
    } = linkedKYC.data.reduce(
      (acc, value) => {
        // Igor is on vacation - I am sorry
        const { result: resultSafe = { status: 'UNDEFINED' } } = value
        if (resultSafe.status === IDENTITY_STATUSES.MANUAL_REVIEW) {
          return {
            ...acc,
            isOneOfLinkedKYCinManualReview: true,
          }
        }
        if (resultSafe.status === IDENTITY_STATUSES.DENY) {
          return {
            ...acc,
            isOneOfLinkedKYCDeclined: true,
          }
        }
        if (resultSafe.status === IDENTITY_STATUSES.ACCEPT) {
          return {
            ...acc,
            approvedCount: acc.approvedCount + 1,
            isAllLinkedKYCApproved: acc.approvedCount + 1 === linkedKYC.data.length,
          }
        }
        return acc
      },
      {
        isOneOfLinkedKYCinManualReview: false,
        isOneOfLinkedKYCDeclined: false,
        isAllLinkedKYCApproved: false,
        approvedCount: 0,
      },
    )

    if (isOneOfLinkedKYCinManualReview) {
      result = {
        isAcceptAllowed: false,
        isDeclineAllowed: false,
      }
    }
    if (isOneOfLinkedKYCDeclined) {
      result = {
        isAcceptAllowed: false,
        isDeclineAllowed: true,
      }
    }
    if (isAllLinkedKYCApproved) {
      result = {
        isAcceptAllowed: true,
        isDeclineAllowed: true,
      }
    }
  }

  if (status === IDENTITY_STATUSES.DENY) {
    result = {
      isAcceptAllowed: result.isAcceptAllowed,
      isDeclineAllowed: false,
    }
  }

  if (status === IDENTITY_STATUSES.ACCEPT) {
    result = {
      isAcceptAllowed: false,
      isDeclineAllowed: result.isDeclineAllowed,
    }
  }

  return result
}

export const getKYCButtonsState = status => {
  return {
    isAcceptAllowed:
      status === IDENTITY_STATUSES.DENY || status === IDENTITY_STATUSES.MANUAL_REVIEW,
    isDeclineAllowed:
      status === IDENTITY_STATUSES.ACCEPT || status === IDENTITY_STATUSES.MANUAL_REVIEW,
  }
}
