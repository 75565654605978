import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { BaseTable } from 'components'
import { OrderSummaryContainer, OrderSummaryRow, Title } from '../preSaleCheckout.styled'

const OrderSummary = ({ response }) => {
  const price = response.plan.price / 100
  const amount = price * 12
  const discountAmount = (amount / 10) * -1
  const total = amount + discountAmount
  const paymentMethod = response.paymentMethods.find(item => item.isDefault)

  const TABLE_SUMMARY_DATA = [
    {
      key: '0',
      details: 'preSaleCheckout.plansTable.column.smallBusiness',
      unit: 1,
      price: `$${price.toFixed(2)}`,
      amount: `$${amount.toFixed(2)}`,
    },
    {
      key: '1',
      details: 'preSaleCheckout.success.orderSummary.oneYearDiscount',
      unit: null,
      price: null,
      amount: `$${discountAmount.toFixed(2)}`,
    },
  ]

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    }
    if (index === 1) {
      obj.props.colSpan = 0
    }
    return obj
  }

  const columns = [
    {
      title: (
        <FormattedMessage
          id="preSaleCheckout.summary.table.column.details"
          defaultMessage="Order Details"
        />
      ),
      dataIndex: 'details',
      key: 'details',
      render: (text, row, index) => {
        if (index === 0) {
          return <FormattedMessage id={text} defaultMessage={text} />
        }
        return {
          children: <FormattedMessage id={text} defaultMessage={text} />,
          props: {
            colSpan: 3,
          },
        }
      },
    },
    {
      title: (
        <FormattedMessage id="preSaleCheckout.summary.table.column.unit" defaultMessage="Unit" />
      ),
      dataIndex: 'unit',
      key: 'unit',
      render: renderContent,
    },
    {
      title: (
        <FormattedMessage id="preSaleCheckout.summary.table.column.price" defaultMessage="Price" />
      ),
      dataIndex: 'price',
      key: 'price',
      render: renderContent,
    },
    {
      title: (
        <FormattedMessage
          id="preSaleCheckout.summary.table.column.amount"
          defaultMessage="Amount"
        />
      ),
      dataIndex: 'amount',
      key: 'amount',
    },
  ]

  return (
    <OrderSummaryContainer>
      <Title>
        <FormattedMessage
          id="preSaleCheckout.success.orderSummary"
          defaultMessage="Order Summary"
        />
      </Title>
      <div>
        <FormattedMessage
          id="preSaleCheckout.success.orderSummary.date"
          defaultMessage="Order placed on"
        />
        &nbsp;
        <span>{moment(response.createdAt).format('MMMM DD, YYYY')}</span>
      </div>

      <BaseTable
        className="utils__scrollTable"
        columns={columns}
        dataSource={TABLE_SUMMARY_DATA}
        bordered
        size="default"
        pagination={false}
      />

      <OrderSummaryRow>
        <FormattedMessage id="preSaleCheckout.success.orderSummary.total" defaultMessage="Total" />
        <span>${total.toFixed(2)}</span>
      </OrderSummaryRow>

      <OrderSummaryRow>
        <FormattedMessage
          id="preSaleCheckout.success.orderSummary.cardTransactions"
          defaultMessage={`Credit Card Transactions (ending in {lastFour})`}
          values={{ lastFour: paymentMethod ? paymentMethod.lastFour : null }}
        />
        <span>-${total.toFixed(2)}</span>
      </OrderSummaryRow>

      <OrderSummaryRow>
        <FormattedMessage
          id="preSaleCheckout.success.orderSummary.balance"
          defaultMessage="Balance"
        />
        <span>$0.00</span>
      </OrderSummaryRow>
    </OrderSummaryContainer>
  )
}

export default OrderSummary
