import styled from 'styled-components'
import { Upload } from 'antd'
import { StyledButton } from '../antd.styled'

export const StyledUploadArea = styled(Upload)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 200px;
  text-align: center;
  justify-content: center;

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
  }

  .ant-upload-hint-text {
    font-size: 12px;
    width: 314px;
    text-align: center;
    margin: 0 auto;
  }
`

export const StyledUpload = styled.div`
  background: #ffffff;
  padding: ${props => (props.withImageUrl ? '0' : '5px')};
  border: ${props => (props.withImageUrl ? '1px solid #e4e9f0' : '1px dashed #524d6a')};
  height: 131px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;

  ${StyledButton} {
    width: 100%;
    min-width: auto !important;
    bottom: 0;
    align-self: ${props => (props.withImageUrl ? 'flex-start' : 'flex-end')};
  }

  img {
    height: 127px;
    width: 100%;
    object-fit: cover;
  }
`
