import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'antd'
import InfoIcon from 'assets/images/icons/ic-info.svg'
import { MOMENT_DATE_TIME_FORMAT } from '../App/app.constants'
import { IdentityStatus } from '../../components'

export const IDENTITY_NOTIFICATION_PREFIX = 'notification.identity'

// ACTIONS TYPES
export const GET_PERSONS = 'identity/GET_PERSONS'
export const GET_PERSONS_SUCCESS = 'identity/GET_PERSONS_SUCCESS'
export const GET_PERSONS_ERROR = 'identity/GET_PERSONS_ERROR'

export const GET_PERSON = 'identity/GET_PERSON'
export const GET_PERSON_SUCCESS = 'identity/GET_PERSON_SUCCESS'
export const GET_PERSON_ERROR = 'identity/GET_PERSON_ERROR'

export const GET_PERSON_GOVERNMENT_IDS = 'identity/GET_PERSON_GOVERNMENT_IDS'
export const GET_PERSON_GOVERNMENT_IDS_SUCCESS = 'identity/GET_PERSON_GOVERNMENT_IDS_SUCCESS'
export const GET_PERSON_GOVERNMENT_IDS_ERROR = 'identity/GET_PERSON_GOVERNMENT_IDS_ERROR'

export const SET_PERSON_DECISION = 'identity/SET_PERSON_DECISION'
export const SET_PERSON_DECISION_SUCCESS = 'identity/SET_PERSON_DECISION_SUCCESS'
export const SET_PERSON_DECISION_ERROR = 'identity/SET_PERSON_DECISION_ERROR'

export const GET_ORGANIZATIONS = 'identity/GET_ORGANIZATIONS'
export const GET_ORGANIZATIONS_SUCCESS = 'identity/GET_ORGANIZATIONS_SUCCESS'
export const GET_ORGANIZATIONS_ERROR = 'identity/GET_ORGANIZATIONS_ERROR'

export const GET_ORGANIZATION = 'identity/GET_ORGANIZATION'
export const GET_ORGANIZATION_SUCCESS = 'identity/GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_ERROR = 'identity/GET_ORGANIZATION_ERROR'

export const SET_ORGANIZATION_DECISION = 'identity/SET_ORGANIZATION_DECISION'
export const SET_ORGANIZATION_DECISION_SUCCESS = 'identity/SET_ORGANIZATION_DECISION_SUCCESS'
export const SET_ORGANIZATION_DECISION_ERROR = 'identity/SET_ORGANIZATION_DECISION_ERROR'

export const GET_ORGANIZATION_NOTES = 'identity/GET_ORGANIZATION_NOTES'
export const GET_ORGANIZATION_NOTES_SUCCESS = 'identity/GET_ORGANIZATION_NOTES_SUCCESS'
export const GET_ORGANIZATION_NOTES_ERROR = 'identity/GET_ORGANIZATION_NOTES_ERROR'

export const POST_ORGANIZATION_NOTE = 'identity/POST_ORGANIZATION_NOTE'
export const POST_ORGANIZATION_NOTE_SUCCESS = 'identity/POST_ORGANIZATION_NOTE_SUCCESS'
export const POST_ORGANIZATION_NOTE_ERROR = 'identity/POST_ORGANIZATION_NOTE_ERROR'

export const GET_PERSON_NOTES = 'identity/GET_PERSON_NOTES'
export const GET_PERSON_NOTES_SUCCESS = 'identity/GET_PERSON_NOTES_SUCCESS'
export const GET_PERSON_NOTES_ERROR = 'identity/GET_PERSON_NOTES_ERROR'

export const POST_PERSON_NOTE = 'identity/POST_PERSON_NOTE'
export const POST_PERSON_NOTE_SUCCESS = 'identity/POST_PERSON_NOTE_SUCCESS'
export const POST_PERSON_NOTE_ERROR = 'identity/POST_PERSON_NOTE_ERROR'

export const POST_PERSON_PASSPORT = 'identity/POST_PERSON_PASSPORT'
export const POST_PERSON_PASSPORT_SUCCESS = 'identity/POST_PERSON_PASSPORT_SUCCESS'
export const POST_PERSON_PASSPORT_ERROR = 'identity/POST_PERSON_PASSPORT_ERROR'

export const POST_PERSON_DRIVER_LICENSE = 'identity/POST_PERSON_DRIVER_LICENSE'
export const POST_PERSON_DRIVER_LICENSE_SUCCESS = 'identity/POST_PERSON_DRIVER_LICENSE_SUCCESS'
export const POST_PERSON_DRIVER_LICENSE_ERROR = 'identity/POST_PERSON_DRIVER_LICENSE_ERROR'

export const SEARCH_PERSONS = 'identity/SEARCH_PERSONS'
export const SEARCH_PERSONS_SUCCESS = 'identity/SEARCH_PERSONS_SUCCESS'
export const SEARCH_PERSONS_ERROR = 'identity/SEARCH_PERSONS_ERROR'

export const SEARCH_ORGANIZATIONS = 'identity/SEARCH_ORGANIZATIONS'
export const SEARCH_ORGANIZATIONS_SUCCESS = 'identity/SEARCH_ORGANIZATIONS_SUCCESS'
export const SEARCH_ORGANIZATIONS_ERROR = 'identity/SEARCH_ORGANIZATIONS_ERROR'

export const GET_PERSONS_BY_ORGANIZATION_ID = 'identity/GET_PERSONS_BY_ORGANIZATION_ID'
export const GET_PERSONS_BY_ORGANIZATION_ID_SUCCESS =
  'identity/GET_PERSONS_BY_ORGANIZATION_ID_SUCCESS'
export const GET_PERSONS_BY_ORGANIZATION_ID_ERROR = 'identity/GET_PERSONS_BY_ORGANIZATION_ID_ERROR'

export const GET_SUPPORTED_COUNTRIES = 'identity/GET_SUPPORTED_COUNTRIES'
export const GET_SUPPORTED_COUNTRIES_SUCCESS = 'identity/GET_SUPPORTED_COUNTRIES_SUCCESS'
export const GET_SUPPORTED_COUNTRIES_ERROR = 'identity/GET_SUPPORTED_COUNTRIES_ERROR'

export const GET_SUPPORTED_STATES = 'identity/GET_SUPPORTED_STATES'
export const GET_SUPPORTED_STATES_SUCCESS = 'identity/GET_SUPPORTED_STATES_SUCCESS'
export const GET_SUPPORTED_STATES_ERROR = 'identity/GET_SUPPORTED_STATES_ERROR'

export const GET_KYC_DOCUMENTS = 'identity/GET_KYC_DOCUMENTS'
export const GET_KYC_DOCUMENTS_SUCCESS = 'identity/GET_KYC_DOCUMENTS_SUCCESS'
export const GET_KYC_DOCUMENTS_ERROR = 'identity/GET_KYC_DOCUMENTS_ERROR'

export const UPLOAD_KYC_DOCUMENT = 'identity/UPLOAD_KYC_DOCUMENT'
export const UPLOAD_KYC_DOCUMENT_SUCCESS = 'identity/UPLOAD_KYC_DOCUMENT_SUCCESS'
export const UPLOAD_KYC_DOCUMENT_ERROR = 'identity/UPLOAD_KYC_DOCUMENT_ERROR'

export const GET_KYB_DOCUMENTS = 'identity/GET_KYB_DOCUMENTS'
export const GET_KYB_DOCUMENTS_SUCCESS = 'identity/GET_KYB_DOCUMENTS_SUCCESS'
export const GET_KYB_DOCUMENTS_ERROR = 'identity/GET_KYB_DOCUMENTS_ERROR'

export const GET_KYB_DOCUMENT = 'identity/GET_KYB_DOCUMENT'
export const GET_KYB_DOCUMENT_SUCCESS = 'identity/GET_KYB_DOCUMENT_SUCCESS'
export const GET_KYB_DOCUMENT_ERROR = 'identity/GET_KYB_DOCUMENT_ERROR'

export const GET_KYC_DOCUMENT = 'identity/GET_KYC_DOCUMENT'
export const GET_KYC_DOCUMENT_SUCCESS = 'identity/GET_KYC_DOCUMENT_SUCCESS'
export const GET_KYC_DOCUMENT_ERROR = 'identity/GET_KYC_DOCUMENT_ERROR'

export const UPLOAD_KYB_DOCUMENT = 'identity/UPLOAD_KYB_DOCUMENT'
export const UPLOAD_KYB_DOCUMENT_SUCCESS = 'identity/UPLOAD_KYB_DOCUMENT_SUCCESS'
export const UPLOAD_KYB_DOCUMENT_ERROR = 'identity/UPLOAD_KYB_DOCUMENT_ERROR'

export const SEARCH_PERSONS_FIELDS = ['email', 'identifier', 'lastName']
export const DEFAULT_SEARCH_PERSONS_FIELD = 'email'

export const SEARCH_ORGANIZATIONS_FIELDS = ['businessName', 'identifier', 'dba']
export const DEFAULT_SEARCH_ORGANIZATIONS_FIELD = 'businessName'

export const IDENTITY_ENUM = {
  KYC: 'kyc',
  KYB: 'kyb',
}

export const IDENTITY_STATUSES = {
  ACCEPT: 'ACCEPT',
  DENY: 'DENY',
  CLOSE: 'CLOSE',
  MANUAL_REVIEW: 'MANUAL_REVIEW',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
}

export const IDENTITY_LIST_DEFAULT_PAGE = 1
export const IDENTITY_LIST_DEFAULT_PAGE_SIZE = 5

export const IDENTITY_BUSINESS_NAME_FIELDS_SET = ['businessName', 'dba', 'ein', 'businessType']

export const IDENTITY_BUSINESS_LOCATION_FIELDS_SET = [
  'businessStreetAddress',
  'businessCity',
  'businessState',
  'businessZip',
  'businessCountry',
]

export const IDENTITY_BUSINESS_CONTACT_FIELDS_SET = [
  'contactFirstName',
  'contactLastName',
  'contactTitle',
  'contactPhone',
  'contactEmail',
  'website',
]

export const IDENTITY_LINKED_APPLICATION_FIELDS_SET = ['firstName', 'lastName', 'email']

export const IDENTITY_BUSINESS_DEVICE_FIELDS_SET = ['ip', 'deviceFingerprint']

export const IDS_TYPES_ENUM = {
  PASSPORT: 'passport',
  DRIVER_LICENSE: 'driver-license',
}

export const DEFAULT_PASSPORT_COUNTRY = 'US'
export const DEFAULT_DRIVER_LICENSE_STATE = 'CA'

export const IDS_IMAGE_TYPES_ENUM = {
  IMAGE: 'image',
  SELFIE: 'selfie',
  FRONT: 'front',
  BACK: 'back',
}

export const TABLE_DATA = [
  {
    key: '1',
    id: '000112',
    name: 'Daniel Romero',
    email: 'romero@gmail.com',
    type: 'KYC',
    form: 'Bridge',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '2',
    id: '000113',
    name: 'Philip Davidson',
    email: 'davidson@gmail.com',
    type: 'KYC',
    form: 'Kalorian',
    status: 'DENY',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '3',
    id: '000114',
    name: 'Tyler Morrison',
    email: 'morrison@gmail.com',
    type: 'KYB',
    form: 'Kalorian',
    status: 'DENY',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '4',
    id: '000115',
    name: 'Matthew Roberts',
    email: 'roberts@gmail.com',
    type: 'KYC',
    form: 'Charge Payments',
    status: 'MANUAL_REVIEW',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '5',
    id: '000116',
    name: 'Daniel Richardson',
    email: 'richardson@gmail.com',
    type: 'KYB',
    form: 'Kalorian',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '6',
    id: '000117',
    name: 'Matthew Jacobs',
    email: 'jacobs@gmail.com',
    type: 'KYB',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '7',
    id: '000118',
    name: 'Matthew Jacobs',
    email: 'jacobs@gmail.com',
    type: 'KYB',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '8',
    id: '000119',
    name: 'Matthew Jacobs',
    email: 'jacobs@gmail.com',
    type: 'KYB',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
  {
    key: '9',
    id: '000120',
    name: 'Matthew Jacobs',
    email: 'jacobs@gmail.com',
    type: 'KYB',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '04/02/2020 2:20PM',
  },
]

export const BUSINESS_DISPLAY_DATA = [
  { field: 'businessType', value: 'Vape' },
  { field: 'typeOfBusinessOwnership', value: 'Government' },
  { field: 'stateOfFormation', value: 'CA' },
  { field: 'businessStartDate', value: '2019-12-20' },
  { field: 'typeOfLocation', value: 'Retail' },
  { field: 'squareFootageOffice', value: '0-250' },
  { field: 'numberOfLocations', value: '2' },
  { field: 'priorBankruptcy', value: 'No' },
]

export const CUSTOMER_DISPLAY_DATA = [
  { field: 'employees', value: '3' },
  { field: 'typeOfCustomer', value: 'Vape' },
  { field: 'typeOfCustomerOwnership', value: 'Private' },

  { field: 'stateOfFormation', value: 'CA' },
  { field: 'businessStartDate', value: '2019-12-20' },
  { field: 'typeOfLocation', value: 'Retail' },
  { field: 'squareFootageOffice', value: '0-250' },
  { field: 'numberOfLocations', value: '2' },
  { field: 'priorBankruptcy', value: 'No' },
]

export const LINKED_APPLICATION_DATA = [
  { field: 'name', value: 'John' },
  { field: 'email', value: 'john-doe@gmail.com' },
  { field: 'date', value: '2019-12-20' },
]

export const APPLICATION_HISTORY_DOCUMENTS_DATA = [{ id: '0', name: '11document-name.pdf' }]

export const ADDITIONAL_CHECKS_DATA = {
  name: 'Bank Verification',
  type: 'Checking',
  account_number: '987654321',
  routing_number: '123456789',
}

export const KYC_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    responsive: ['md'],
  },
  {
    title: 'Person Name',
    dataIndex: 'data',
    key: 'name',
    render: value => {
      return (
        <span>
          {value.firstName} {value.lastName}
        </span>
      )
    },
  },
  {
    title: 'Email',
    dataIndex: ['data', 'email'],
    key: 'email',
    responsive: ['md'],
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'date',
    render: value => {
      return <span>{moment(value).format(MOMENT_DATE_TIME_FORMAT)}</span>
    },
    responsive: ['md'],
  },
  {
    title: 'Status',
    dataIndex: ['result', 'status'],
    key: 'status',
    render: status => {
      if (status) {
        return (
          <IdentityStatus key={status} status={status}>
            <FormattedMessage
              id={`dashboard.subTitle.history.row.status.${status}`}
              defaultMessage={status}
            />
          </IdentityStatus>
        )
      }
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="identity.row.noStatus.message"
              defaultMessage="This KYC is linked to Organization"
            />
          }
        >
          <img src={InfoIcon} alt="info icon" />
        </Tooltip>
      )
    },
  },
]

export const KYB_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    responsive: ['md'],
  },
  {
    title: 'Business Name',
    dataIndex: ['data', 'organization', 'businessName'],
    key: 'name',
  },
  {
    title: 'DBA',
    dataIndex: ['data', 'organization', 'dba'],
    key: 'dba',
    responsive: ['md'],
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'date',
    render: value => {
      return <span>{moment(value).format(MOMENT_DATE_TIME_FORMAT)}</span>
    },
    responsive: ['md'],
  },
  {
    title: 'Status',
    dataIndex: ['result', 'status'],
    key: 'status',
    render: status => {
      if (status) {
        return (
          <IdentityStatus key={status} status={status}>
            <FormattedMessage
              id={`dashboard.subTitle.history.row.status.${status}`}
              defaultMessage={status}
            />
          </IdentityStatus>
        )
      }
      return null
    },
  },
]
