import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import * as routerConstants from 'features/App/app.router.constants'
import { Content, ContentFooter, ContentHeader } from 'features/Settings/settings.styled'
import ApiKeyPermissions from 'features/ApiKeys/components/ApiKeyPermissions'
import { createApiKey } from 'features/ApiKeys/apiKeys.actions'
import { PageTitle, SubTitle } from 'features/ApiKeys/apiKeys.styled'
import { getApiKeyPermissionsTypesByRole } from 'features/ApiKeys/apiKeys.utils'
import { Button, Input } from 'components'

const CreateApiKey = ({ history }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.apiKeys.loading)
  const userRole = useSelector(state => state.auth.user.role)
  const apiKeyPermissionsTypesByRole = getApiKeyPermissionsTypesByRole(userRole)
  const [selectedType, setSelectedType] = useState(
    apiKeyPermissionsTypesByRole.length === 1 ? apiKeyPermissionsTypesByRole[0].key : null,
  )
  const [name, setName] = useState('')

  const handleTypeSelect = key => {
    setSelectedType(key)
  }

  const handleNameChange = e => {
    setName(e.target.value)
  }

  const handleApiKeySave = () => {
    dispatch(
      createApiKey({
        name,
        scope: selectedType,
      }),
    )
  }

  return (
    <div>
      <Helmet title="Create API Key" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.apiKey.title" defaultMessage="API keys" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <SubTitle>
          <FormattedMessage
            id="settings.apiKey.create.subTitle"
            defaultMessage="Create an API Key"
          />
        </SubTitle>
        <Input
          onChange={handleNameChange}
          placeholder="API Key Name"
          size="default"
          type="text"
          className="w-100 mb-4"
          data-testid="settings.apiKey.create.name.input"
        />
        <SubTitle>
          <FormattedMessage
            id="settings.apiKey.create.subTitle.permissions"
            defaultMessage="API Key Permissions"
          />
        </SubTitle>
        {apiKeyPermissionsTypesByRole.map(item => (
          <ApiKeyPermissions
            selected={selectedType === item.key}
            type={item.key}
            handleClick={handleTypeSelect}
            icon={item.icon}
            key={item.key}
          />
        ))}
      </Content>
      <ContentFooter>
        <Button
          loading={loading}
          disabled={!name.length || selectedType === null}
          type="primary"
          onClick={handleApiKeySave}
          data-testid="settings.apiKey.create.button.save"
        >
          <FormattedMessage
            id="settings.apiKey.createAndView.button"
            defaultMessage="Create & View"
          />
        </Button>
        <Button
          className="ml-3"
          type="outline"
          onClick={() => history.push(routerConstants.SETTINGS_API_KEYS_ROUTE)}
        >
          <FormattedMessage id="settings.apiKey.cancel.button" defaultMessage="Cancel" />
        </Button>
      </ContentFooter>
    </div>
  )
}

CreateApiKey.propTypes = {
  history: PropTypes.object.isRequired,
}

export default CreateApiKey
