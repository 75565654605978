export const CHART_DATA = [
  {
    id: 'KYC',
    color: '#6889fd',
    data: [
      {
        x: '01/01',
        y: 248,
      },
      {
        x: '01/02',
        y: 36,
      },
      {
        x: '01/03',
        y: 263,
      },
      {
        x: '01/04',
        y: 76,
      },
      {
        x: '01/05',
        y: 174,
      },
      {
        x: '01/06',
        y: 162,
      },
      {
        x: '01/07',
        y: 191,
      },
      {
        x: '01/08',
        y: 222,
      },
      {
        x: '01/09',
        y: 161,
      },
      {
        x: '01/10',
        y: 456,
      },
      {
        x: '01/11',
        y: 1123,
      },
      {
        x: '01/12',
        y: 890,
      },
      {
        x: '01/13',
        y: 1235,
      },
      {
        x: '01/14',
        y: 1745,
      },
      {
        x: '01/15',
        y: 1856,
      },
    ],
  },
  {
    id: 'KYB',
    color: '#acb7bf',
    data: [
      {
        x: '01/01',
        y: 234,
      },
      {
        x: '01/02',
        y: 45,
      },
      {
        x: '01/03',
        y: 143,
      },
      {
        x: '01/04',
        y: 221,
      },
      {
        x: '01/05',
        y: 226,
      },
      {
        x: '01/06',
        y: 789,
      },
      {
        x: '01/07',
        y: 678,
      },
      {
        x: '01/08',
        y: 825,
      },
      {
        x: '01/09',
        y: 987,
      },
      {
        x: '01/10',
        y: 1202,
      },
      {
        x: '01/11',
        y: 1540,
      },
      {
        x: '01/12',
        y: 1535,
      },
      {
        x: '01/13',
        y: 1678,
      },
      {
        x: '01/14',
        y: 1887,
      },
      {
        x: '01/15',
        y: 1345,
      },
    ],
  },
]

export const TIME_RANGES_ENUM = {
  WEEK: {
    key: 'week',
    days: 7,
  },
  MONTH: {
    key: 'month',
    days: 30,
  },
}

export const TABLE_DATA = [
  {
    key: '1',
    id: '000112',
    type: 'KYC',
    form: 'Bridge',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '2',
    id: '000113',
    type: 'KYC',
    form: 'Kalorian',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '3',
    id: '000114',
    type: 'KYB',
    form: 'Kalorian',
    status: 'DENY',
    date: '01/24',
  },
  {
    key: '4',
    id: '000115',
    type: 'KYC',
    form: 'Charge Payments',
    status: 'MANUAL_REVIEW',
    date: '01/24',
  },
  {
    key: '5',
    id: '000116',
    type: 'KYB',
    form: 'Kalorian',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '6',
    id: '000117',
    type: 'KYB',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '7',
    id: '000118',
    type: 'KYC',
    form: 'Bridge',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '8',
    id: '000119',
    type: 'KYB',
    form: 'Bridge',
    status: 'MANUAL_REVIEW',
    date: '01/24',
  },
  {
    key: '9',
    id: '000120',
    type: 'KYC',
    form: 'Genesis',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '10',
    id: '000121',
    type: 'KYC',
    form: 'Genesis',
    status: 'MANUAL_REVIEW',
    date: '01/24',
  },
  {
    key: '11',
    id: '000122',
    type: 'KYB',
    form: 'Genesis',
    status: 'MANUAL_REVIEW',
    date: '01/24',
  },
  {
    key: '12',
    id: '000123',
    type: 'KYC',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '01/24',
  },
  {
    key: '13',
    id: '000125',
    type: 'KYC',
    form: 'Charge Payments',
    status: 'ACCEPT',
    date: '01/24',
  },
]

export const HISTORY_ITEM_STATUSES_ENUM = {
  ACCEPT: 'ACCEPT',
  CREATED: 'CREATED',
  MANUAL_REVIEW: 'MANUAL_REVIEW',
  DENY: 'DENY',
}

export const HISTORY_ITEM_TYPES_FILTER_ENUM = {
  ALL: 'all_type',
  KYC: 'kyc',
  KYB: 'kyb',
}

export const CHART_DEFAULT_TIME_FRAME = 'month'

// ACTIONS TYPES
export const GET_APPLICATION_HISTORY = 'dashboard/GET_APPLICATION_HISTORY'
export const GET_APPLICATION_HISTORY_SUCCESS = 'dashboard/GET_APPLICATION_HISTORY_SUCCESS'
export const GET_APPLICATION_HISTORY_ERROR = 'dashboard/GET_APPLICATION_HISTORY_ERROR'

export const GET_STATISTICS = 'dashboard/GET_STATISTICS'
export const GET_STATISTICS_SUCCESS = 'dashboard/GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_ERROR = 'dashboard/GET_STATISTICS_ERROR'
