import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { Helmet } from 'react-helmet'
import { Button, FormItemWrapper } from 'components'
import { PageTitle } from '../ApiKeys/apiKeys.styled'
import { Content, ContentFooter, ContentHeader } from '../Settings/settings.styled'
import { StyledUserProfileFormContainer } from './userProfile.styled'
import { getUserProfile, updateUserProfile, createUserProfile } from './userProfile.actions'
import UserProfileSkeleton from './skeletons/UserProfileSkeleton'
import { setUserProfile } from '../Auth/auth.actions'

const UserProfile = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { profile, loading } = useSelector(state => state.userProfile)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!profile.id && user.profile) {
      dispatch(getUserProfile())
    }
  }, [profile.id, user.profile])

  useEffect(() => {
    if (profile.id && !user.profile) {
      dispatch(setUserProfile(profile))
    }
  }, [profile.id, user.profile])

  const onFinish = values => {
    if (profile.id) {
      dispatch(updateUserProfile(values))
    } else {
      dispatch(createUserProfile(values))
    }
  }

  const handleSubmit = () => form.submit()

  if (!profile.id && user.profile && loading) return <UserProfileSkeleton />

  return (
    <div>
      <Helmet title="Settings - User Profile" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.userProfile.title" defaultMessage="User Profile" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <Form
          form={form}
          name="checkout"
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          hideRequiredMark
          initialValues={{
            phoneNumberPrefix: '1',
            ...user,
            ...profile,
          }}
        >
          <StyledUserProfileFormContainer>
            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper disabled name="firstName" type="string" form={form} />
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper disabled name="lastName" type="string" form={form} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper disabled name="email" type="email" form={form} />
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper name="phone" required type="phone" form={form} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper name="address" required type="string" form={form} />
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper name="city" required type="string" form={form} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper name="state" required type="string" form={form} />
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <FormItemWrapper name="country" required type="string" form={form} />
              </div>
            </div>
          </StyledUserProfileFormContainer>
        </Form>
      </Content>

      <ContentFooter>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          <FormattedMessage id="form.item.button.save" defaultMessage="Save" />
        </Button>
      </ContentFooter>
    </div>
  )
}

export default UserProfile
