import React from 'react'
import PropTypes from 'prop-types'
import DataField from './DataField'

const DataFieldList = ({ data, twoCols }) =>
  twoCols ? (
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        {data.slice(0, Math.ceil(data.length / 2)).map(item => (
          <DataField key={item.field} {...item} />
        ))}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        {data.slice(Math.ceil(data.length / 2), data.length).map(item => (
          <DataField key={item.field} {...item} />
        ))}
      </div>
    </div>
  ) : (
    data.map(item => <DataField key={item.field} {...item} />)
  )

DataFieldList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
}

export default DataFieldList
