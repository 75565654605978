import axios from 'axios'

export async function getPersons({ currentPage, pageSize }) {
  const res = await axios.get(
    `/persons?data=simple&currentPage=${currentPage}&pageSize=${pageSize}`,
  )
  return res.data
}

export async function getPerson(id) {
  const res = await axios.get(`/persons/${id}?data=full`)
  return res.data
}

export async function getPersonGovernmentIDs(id) {
  const res = await axios.get(`/persons/${id}/documents/government`)
  return res.data
}

export async function setPersonDecision(payload) {
  const res = await axios.post(`/persons/${payload.id}/decision?data=full`, payload)
  return res.data
}

export async function getOrganizations({ currentPage, pageSize }) {
  const res = await axios.get(
    `/organizations?data=simple&currentPage=${currentPage}&pageSize=${pageSize}`,
  )
  return res.data
}

export async function getOrganization(id) {
  const res = await axios.get(`/organizations/${id}?data=full`)
  return res.data
}

export async function setOrganizationDecision(payload) {
  const res = await axios.post(`/organizations/${payload.id}/decision?data=full`, payload)
  return res.data
}

export async function getOrganizationNotes({ id }) {
  const res = await axios.get(`/organizations/${id}/notes`)
  return res.data
}

export async function postOrganizationNote(id, note) {
  const res = await axios.post(`/organizations/${id}/notes`, {
    body: note,
  })
  return res.data
}

export async function getPersonsNotes({ id }) {
  const res = await axios.get(`/persons/${id}/notes`)
  return res.data
}

export async function postPersonsNote(id, note) {
  const res = await axios.post(`/persons/${id}/notes`, {
    body: note,
  })
  return res.data
}

export async function postPersonPassport(id, data) {
  const res = await axios.post(`/persons/${id}/documents/passport`, data)
  return res.data
}

export async function postPersonDriverLicense(id, data) {
  const res = await axios.post(`/persons/${id}/documents/driver-license`, data)
  return res.data
}

export async function searchPersons({ query }) {
  const res = await axios.get(`/persons/query/simple?field=${query.field}&value=${query.value}`)
  return res.data
}

export async function searchOrganizations({ query }) {
  const res = await axios.get(
    `/organizations/query/simple?field=${query.field}&value=${query.value}`,
  )
  return res.data
}

export async function getPersonsByOrganizationId({ currentPage = 1, pageSize = 100, id }) {
  const res = await axios.get(
    `/persons?data=simple&currentPage=${currentPage}&pageSize=${pageSize}&organizationId=${id}`,
  )
  return res.data
}

export async function getSupportedCountries() {
  const res = await axios.get(`/countries`)
  return res.data.filter(c => c.supported)
}

export async function getSupportedStates() {
  const res = await axios.get(`/states`)
  return res.data
}

export async function getKycDocuments({ id }) {
  const res = await axios.get(`/persons/${id}/documents`)
  return res.data
}

export async function getKybDocuments({ id }) {
  const res = await axios.get(`/organizations/${id}/documents`)
  return res.data
}

export async function getKybDocument({ id, name }) {
  const res = await axios.get(`/organizations/${id}/documents/${name}`)
  return res.data
}

export async function getKycDocument({ id, name }) {
  const res = await axios.get(`/persons/${id}/documents/${name}`)
  return res.data
}

export async function uploadKycDocument({ id, file }) {
  const bodyFormData = new FormData()
  bodyFormData.append('file', file.originFileObj)
  bodyFormData.append('name', file.name)

  const res = await axios.post(`/persons/${id}/documents`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data;' },
  })
  return res.data
}

export async function uploadKybDocument({ id, file }) {
  const bodyFormData = new FormData()
  bodyFormData.append('file', file.originFileObj)
  bodyFormData.append('name', file.name)

  const res = await axios.post(`/organizations/${id}/documents`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data;' },
  })
  return res.data
}
