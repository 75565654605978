import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import {
  Card,
  CardHintText,
  CardText,
  MonthlyBillPrice,
  MonthlyBillPriceContainer,
} from '../billing.styled'

const PlanCard = ({ id, label, price, handleSelect, selectedPlanId, currentPlanId }) => {
  return (
    <Card isSelected={selectedPlanId === id} className="p-0 mb-2">
      <CardText className="font-weight-bold">
        <FormattedMessage id={`settings.billing.plan.${label}`} defaultMessage={label} />
      </CardText>
      <MonthlyBillPriceContainer>
        <MonthlyBillPrice>${price / 100}</MonthlyBillPrice>
      </MonthlyBillPriceContainer>
      <div>
        <ul className="pl-4">
          <li>
            <CardHintText>KYC yourself. Give our product a try.</CardHintText>
          </li>
          <li>
            <CardHintText>KYC yourself. Give our product a try.</CardHintText>
          </li>
          <li>
            <CardHintText>KYC yourself. Give our product a try.</CardHintText>
          </li>
        </ul>

        <Button
          disabled={currentPlanId === id || selectedPlanId === id}
          type="secondary"
          onClick={handleSelect}
          className="w-100 mt-2"
        >
          {currentPlanId === id && (
            <FormattedMessage
              id="settings.billing.plan.current.btn"
              defaultMessage="Current plan"
            />
          )}
          {selectedPlanId === id && (
            <FormattedMessage
              id="settings.billing.plan.selected.btn"
              defaultMessage="Selected plan"
            />
          )}
          {selectedPlanId !== id && currentPlanId !== id && (
            <FormattedMessage id="settings.billing.plan.select.btn" defaultMessage="Select" />
          )}
        </Button>
      </div>
    </Card>
  )
}

PlanCard.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export default PlanCard
