import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { CaretRightOutlined } from '@ant-design/icons'
import InfoIcon from 'assets/images/icons/ic-info.svg'
import { Button, Collapse, CollapsePanel, IdentityStatus } from 'components'
import { Empty, Tooltip } from 'antd'
import {
  StyledLinkedApplication,
  StyledLinkedApplicationBody,
  StyledLinkedApplicationHeader,
  StyledLinkedApplications,
  StyledLinkedApplicationsContainer,
} from '../identity.styled'
import DataFieldList from './DataFieldList'
import { IDENTITY_LINKED_APPLICATION_FIELDS_SET } from '../identity.constants'
import { mapDataFields } from '../identity.utils'
import LinkedApplicationsSkeleton from '../skeletons/LinkedApplicationsSkeleton'
import { IDENTITY_CUSTOMER_ROUTE } from '../../App/app.router.constants'

const LinkedApplications = ({ list, loading }) => {
  const history = useHistory()

  return (
    <StyledLinkedApplications>
      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        accordion
      >
        <CollapsePanel
          header={
            <span className="font-weight-bold">
              <FormattedMessage
                id="identity.business.details.linked.applications"
                defaultMessage="KYC Linked Applications"
              />
            </span>
          }
          key="1"
        >
          <StyledLinkedApplicationsContainer>
            <div className="row">
              {list.data && list.data.length ? (
                <Fragment>
                  {list.data.map(({ id, result, data, source }) => {
                    const dataFields = [
                      ...mapDataFields(IDENTITY_LINKED_APPLICATION_FIELDS_SET, data),
                    ]
                    if (source) {
                      dataFields.push({
                        field: 'identifier',
                        value: source.user.identifier,
                      })
                    }

                    return (
                      <div key={id} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <StyledLinkedApplication>
                          <StyledLinkedApplicationHeader>
                            <div>
                              <Button
                                onClick={() => history.push(`${IDENTITY_CUSTOMER_ROUTE}/${id}`)}
                                type="outline"
                              >
                                <FormattedMessage
                                  id="identity.business.details.linked.applications.view.btn"
                                  defaultMessage="View"
                                />
                              </Button>
                            </div>

                            {result ? (
                              <IdentityStatus status={result.status}>
                                <FormattedMessage
                                  id={`dashboard.subTitle.history.row.status.${result.status}`}
                                  defaultMessage={result.status}
                                />
                              </IdentityStatus>
                            ) : (
                              <Tooltip
                                title={
                                  <FormattedMessage
                                    id="identity.row.noStatus.message"
                                    defaultMessage="This KYC is linked to Organization"
                                  />
                                }
                              >
                                <img src={InfoIcon} alt="info icon" />
                              </Tooltip>
                            )}
                          </StyledLinkedApplicationHeader>
                          <StyledLinkedApplicationBody>
                            <DataFieldList data={dataFields} />
                          </StyledLinkedApplicationBody>
                        </StyledLinkedApplication>
                      </div>
                    )
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  {loading ? (
                    <LinkedApplicationsSkeleton />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100 pt-5 pb-5">
                      <Empty />
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </StyledLinkedApplicationsContainer>
        </CollapsePanel>
      </Collapse>
    </StyledLinkedApplications>
  )
}

export default LinkedApplications
