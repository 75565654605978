import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Cards from 'react-credit-cards'
import Payment from 'payment'
import { FormItem, Input } from 'components'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../../PreSaleCheckout/preSaleCheckout.utils'

const AddPaymentMethodFrom = ({ form }) => {
  const [card, setCard] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    label: '',
    number: '',
    issuer: null,
  })

  const handleInputFocus = e => {
    setCard({ ...card, focus: e.target.name })
  }

  const handleInputChange = e => {
    const { value, name } = e.target
    setCard({ ...card, [name]: value })
  }

  const callback = res => {
    setCard({ ...card, issuer: res.issuer })
    form.setFieldsValue({
      issuer: res.issuer,
    })
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-5 d-flex align-items-center">
        <Cards
          cvc={card.cvc}
          expiry={card.expiry}
          focused={card.focus}
          name={card.label}
          number={card.number}
          callback={callback}
        />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <FormItem
          label={
            <FormattedMessage
              id="preSaleCheckout.payment.info.field.cardNumber"
              defaultMessage="Credit Card Number"
            />
          }
          name="number"
          hasFeedback
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="preSaleCheckout.payment.info.field.cardNumber.error.required"
                  defaultMessage="Please enter your Credit Card Number"
                />
              ),
            },
          ]}
          normalize={value => formatCreditCardNumber(value)}
        >
          <Input
            name="number"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            placeholder="Card Number"
          />
        </FormItem>

        <FormItem
          label={
            <FormattedMessage
              id="preSaleCheckout.payment.info.field.billingName"
              defaultMessage="Billing Name"
            />
          }
          name="label"
          hasFeedback
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="preSaleCheckout.payment.info.field.billingName.error.required"
                  defaultMessage="Please enter your Billing Name"
                />
              ),
            },
          ]}
        >
          <Input
            name="label"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            placeholder="Billing Name"
          />
        </FormItem>

        <div className="row">
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
            <FormItem
              label={
                <FormattedMessage
                  id="preSaleCheckout.payment.info.field.expirationDate"
                  defaultMessage="Expiration Date"
                />
              }
              name="expiry"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.expirationDate.error.required"
                      defaultMessage="Please enter your Expiration Date"
                    />
                  ),
                },
                () => ({
                  validator(rule, value) {
                    if (!value || Payment.fns.validateCardExpiry(value)) {
                      return Promise.resolve()
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Please enter valid Card Expiry')
                  },
                }),
              ]}
              normalize={value => formatExpirationDate(value)}
            >
              <Input
                name="expiry"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder="Expiration Date"
              />
            </FormItem>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <FormItem
              label={
                <FormattedMessage
                  id="preSaleCheckout.payment.info.field.cvc"
                  defaultMessage="CVC"
                />
              }
              name="cvc"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.cvv.error.required"
                      defaultMessage="Please enter your CVC"
                    />
                  ),
                },
              ]}
              normalize={value => formatCVC(value)}
            >
              <Input
                name="cvc"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder="CVC"
                maxLength="3"
              />
            </FormItem>

            <FormItem label={null} name="issuer" className="d-none">
              <Input name="issuer" value={card.issuer} />
            </FormItem>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPaymentMethodFrom
