import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, HorizontalSeparator } from 'components'
import { IDENTITY_ENUM, IDENTITY_STATUSES } from '../identity.constants'
import { ERROR_404_ROUTE } from '../../App/app.router.constants'
import {
  StyledDetailsBlock,
  StyledDetailsContainer,
  StyledDetailsLeftColumn,
  StyledDetailsRow,
} from '../identity.styled'
import {
  AdditionalData,
  ApplicationHistory,
  BusinessInformation,
  DataProvidersResults,
  DetailsFooter,
  DetailsHeader,
  LinkedApplications,
} from '../components'
import {
  getOrganization,
  setOrganizationDecision,
  postOrganizationNote,
  getPersonByOrganizationId,
  getKybDocuments,
  uploadKybDocument,
  getKybDocument,
  getOrganizationNotes,
} from '../identity.actions'
import { getKYBButtonsState } from '../identity.utils'

const IdentityBusinessDetails = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id } = match.params
  const {
    loading,
    selected,
    error,
    acceptLoading,
    denyLoading,
    noteLoading,
    list,
    organizationApplicationsLoading,
    documentForDownload,
    documentForDownloadLoading,
    documentUploadLoading,
  } = useSelector(state => state.identity)
  const { data = {}, result, notes, documents } = selected
  const { isAcceptAllowed, isDeclineAllowed } = getKYBButtonsState(list, result.status)

  useEffect(() => {
    dispatch(getOrganization(id))
  }, [id])

  useEffect(() => {
    dispatch(getKybDocuments(id))
  }, [id])

  useEffect(() => {
    if (result.status) {
      dispatch(getOrganizationNotes(id))
    }
  }, [id, result.status])

  useEffect(() => {
    dispatch(getPersonByOrganizationId(id))
  }, [id])

  useEffect(() => {
    if (error) {
      history.push(ERROR_404_ROUTE)
    }
  }, [error])

  const handleFileUpload = file => {
    dispatch(uploadKybDocument({ file, id }))
  }

  const handleFileDownload = name => {
    dispatch(getKybDocument({ id, name }))
  }

  const handleAccept = note =>
    dispatch(setOrganizationDecision({ id, decision: IDENTITY_STATUSES.ACCEPT, note }))
  const handleDeny = note =>
    dispatch(setOrganizationDecision({ id, decision: IDENTITY_STATUSES.DENY, note }))

  return (
    <StyledDetailsContainer>
      <Container>
        <DetailsHeader
          loading={loading}
          history={history}
          name={
            data.organization ? `${data.organization.businessName} / ${data.organization.id}` : null
          }
          status={result.status}
        />
        <HorizontalSeparator />
        <StyledDetailsRow>
          <StyledDetailsLeftColumn>
            <StyledDetailsBlock>
              <BusinessInformation data={data} loading={loading} />
            </StyledDetailsBlock>
            <StyledDetailsBlock>
              <ApplicationHistory
                statusHistory={result.actionHistory}
                notes={notes}
                documents={documents}
                loading={loading}
                noteLoading={noteLoading}
                postNote={note => dispatch(postOrganizationNote(id, note))}
                handleFileUpload={handleFileUpload}
                handleFileDownload={handleFileDownload}
                documentForDownload={documentForDownload}
                documentForDownloadLoading={documentForDownloadLoading}
                documentUploadLoading={documentUploadLoading}
              />
            </StyledDetailsBlock>
          </StyledDetailsLeftColumn>
          <LinkedApplications list={list} loading={organizationApplicationsLoading} />
        </StyledDetailsRow>
        <StyledDetailsBlock>
          <DataProvidersResults data={result.checks} loading={loading} />
        </StyledDetailsBlock>
        {data.organization &&
        data.organization.meta &&
        Object.keys(data.organization.meta).length !== 0 ? (
          <AdditionalData data={data.organization.meta} />
        ) : null}
      </Container>
      {!loading ? (
        <DetailsFooter
          isAcceptAllowed={isAcceptAllowed}
          isDeclineAllowed={isDeclineAllowed}
          handleAccept={handleAccept}
          handleDeny={handleDeny}
          acceptLoading={acceptLoading}
          denyLoading={denyLoading}
          type={IDENTITY_ENUM.KYB}
        />
      ) : null}
    </StyledDetailsContainer>
  )
}

IdentityBusinessDetails.propTypes = {
  match: PropTypes.object.isRequired,
}

export default IdentityBusinessDetails
