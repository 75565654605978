import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Form } from 'antd'
import { Button, FormItemWrapper, Link, Text } from 'components'
import { LoginContainer, Title } from '../auth.styled'
import * as routerConstants from '../../App/app.router.constants'

const RegisterForm = ({ onFinish, loading, email, token }) => {
  const [form] = Form.useForm()

  if (email) {
    form.setFieldsValue({ email })
  }

  return (
    <Fragment>
      <Title>
        <FormattedMessage id="register.subheading" defaultMessage="Register Your Account" />
      </Title>
      <LoginContainer>
        <Helmet title="Charge Identity - Register" />
        <Form
          form={form}
          onFinish={onFinish}
          name="login"
          layout="vertical"
          scrollToFirstError
          hideRequiredMark
        >
          <FormItemWrapper
            dataTestId="auth.register.input.firstName"
            name="firstName"
            form={form}
            required
            hideLabel
            maxLength={50}
          />

          <FormItemWrapper
            dataTestId="auth.register.input.lastName"
            name="lastName"
            form={form}
            required
            hideLabel
            maxLength={50}
          />

          <FormItemWrapper
            dataTestId="auth.register.input.email"
            name="email"
            required
            type="email"
            form={form}
            defaultValue={email}
            disabled={token}
            hideLabel
            maxLength={319}
          />

          <div className="mb-2">
            <FormattedMessage
              id="form.item.password.error.notValid"
              defaultMessage="Passwords must be at least 8 characters, have one capital letter, one number, and one special character."
            />
          </div>

          <FormItemWrapper
            dataTestId="auth.register.input.password"
            name="password"
            type="password"
            required
            form={form}
            hideLabel
          />

          <FormItemWrapper
            dataTestId="auth.register.input.confirmPassword"
            name="confirmPassword"
            type="confirmPassword"
            required
            dependencies={['password']}
            form={form}
            hideLabel
          />

          <Button
            htmlType="submit"
            data-testid="auth.register.button.submit"
            loading={loading}
            className="w-100"
            type="primary"
          >
            <FormattedMessage id="form.register" defaultMessage="Register" />
          </Button>
        </Form>
      </LoginContainer>
      <Text className="text-center text-white">
        <FormattedMessage
          id="register.login.subtext"
          defaultMessage="Already have a Charge account?"
        />
        <Link className="text-white font-weight-bold ml-1" to={routerConstants.LOGIN_ROUTE}>
          <FormattedMessage id="register.loginLink" defaultMessage="Login" />
        </Link>
      </Text>
    </Fragment>
  )
}

export default RegisterForm
