import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const preparePaymentMethod = values => {
  return {
    type: 'card',
    label: values.issuer,
    token: 'encryptedTEXT',
    lastFour: values.number.replace(/ /g, '').substr(-4),
    isDefault: true,
    expirationDate: moment(values.expiry, 'MM/YY').format('YYYY-MM-DD'),
  }
}
