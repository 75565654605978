import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Checkbox, Form } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormItem, HorizontalSeparator } from 'components'
import moment from 'moment'
import { Container, TermsText, Title } from './preSaleCheckout.styled'
import AccountDetails from './components/AccountDetails'

import StripeCheckoutForm from '../Billing/components/StripeCheckoutForm'

import {
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  PRE_SALE_CHECKOUT_SUCCESS_ROUTE,
} from '../App/app.router.constants'
import SelectPaymentPlanForm from '../Billing/components/SelectPaymentPlanForm'

import { getPrepaidPaymentPlans } from '../Billing/billing.selectors'
import { getPaymentPlans } from '../Billing/billing.actions'
import { DEFAULT_SELECTED_PAYMENT_PLAN_INDEX } from '../Billing/billing.constants'
import { preSaleCheckout } from './preSaleCheckout.actions'

const PreSaleCheckout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [planId, setPlanId] = useState('')
  const [formData, setFormData] = useState({
    agreement: false,
  })
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [token, setToken] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [contactData, setContactData] = useState(null)
  const { paymentPlans, paymentPlansLoading } = useSelector(getPrepaidPaymentPlans)
  const { response, loading } = useSelector(state => state.preSaleCheckout)

  useEffect(() => {
    dispatch(getPaymentPlans())
  }, [dispatch])

  useEffect(() => {
    if (paymentPlans.length) {
      setPlanId(paymentPlans[DEFAULT_SELECTED_PAYMENT_PLAN_INDEX].id)
    }
  }, [paymentPlans])

  useEffect(() => {
    if (response.id) {
      history.push(PRE_SALE_CHECKOUT_SUCCESS_ROUTE)
    }
  }, [response.id])

  useEffect(() => {
    if (token) {
      const payment = {
        isDefault: true,
        type: token.type,
        label: token.card.brand,
        token: token.id,
        lastFour: token.card.last4,
        expirationDate: moment(`${token.card.exp_month}/${token.card.exp_year}`, 'M/YYYY').format(
          'YYYY-MM-DD',
        ),
      }
      const contact = {
        // TODO: hardcoded role
        role: 'admin',
        ...contactData,
      }
      const plan = {
        id: planId,
      }
      dispatch(
        preSaleCheckout({
          contact,
          payment,
          plan,
        }),
      )
    }
  }, [token])

  const handleTokenCreateSuccess = res => {
    setToken(res)
  }

  const onFinish = values => {
    setFormSubmitted(true)
    setContactData(values)
  }

  const handleCardError = error => {
    setFormSubmitted(false)
    setCardError(error)
  }

  const handlePlanSelect = selectedPlan => {
    setPlanId(selectedPlan.id)
  }

  const onFieldsChange = (data, fields) => {
    setFormData(
      fields.reduce((acc, cur) => {
        acc[cur.name[0]] = cur.value
        return acc
      }),
    )
  }

  return (
    <Container>
      <Helmet title="Charge.io PreSale Checkout" />

      <Title>
        <FormattedMessage
          id="preSaleCheckout.title"
          defaultMessage="Thank you for your interest in Charge"
        />
      </Title>
      <FormattedMessage
        id="preSaleCheckout.description"
        defaultMessage="During the Charge pre-sale customers are able to get the best pricing of our product and lock their rates in for a year. Plans will activate when you process your first KYC or KYB check."
      />
      <HorizontalSeparator />

      <SelectPaymentPlanForm
        paymentPlans={paymentPlans}
        loading={paymentPlansLoading}
        selectedPlanId={planId}
        handlePlanSelect={handlePlanSelect}
      />

      <Fragment>
        <Form
          form={form}
          name="checkout"
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          hideRequiredMark
          initialValues={{
            phoneNumberPrefix: '1',
          }}
        >
          <AccountDetails form={form} />

          <Title>
            <FormattedMessage
              id="preSaleCheckout.payment.info"
              defaultMessage="Payment Information"
            />
          </Title>
          <HorizontalSeparator />

          <StripeCheckoutForm
            formSubmitted={formSubmitted}
            handleTokenCreateSuccess={handleTokenCreateSuccess}
            setCardError={handleCardError}
          />

          <HorizontalSeparator />

          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
              <FormItem
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      // eslint-disable-next-line prefer-promise-reject-errors
                      value ? Promise.resolve() : Promise.reject('Should accept agreement'),
                  },
                ]}
              >
                <Checkbox>
                  <TermsText>
                    <FormattedMessage id="preSaleCheckout.agree.text" defaultMessage="I agree to" />
                    <Link target="_blank" to={TERMS_AND_CONDITIONS_ROUTE}>
                      <FormattedMessage
                        id="preSaleCheckout.agree.link.terms"
                        defaultMessage="Terms & Condition"
                      />
                    </Link>
                    <FormattedMessage id="preSaleCheckout.agree.text.and" defaultMessage="and" />
                    <Link target="_blank" to={PRIVACY_POLICY_ROUTE}>
                      <FormattedMessage
                        id="preSaleCheckout.agree.link.privacy"
                        defaultMessage="Privacy Policy"
                      />
                    </Link>
                  </TermsText>
                </Checkbox>
              </FormItem>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 text-right">
              <Button
                disabled={!formData.agreement || (cardError && cardError.code)}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                <FormattedMessage id="form.register" defaultMessage="Sign Up" />
              </Button>
            </div>
          </div>
        </Form>
      </Fragment>
    </Container>
  )
}

export default PreSaleCheckout
