import styled from 'styled-components'
import { Menu } from 'antd'
import { Text } from 'components'

const { Item } = Menu

export const StyledMenu = styled(Menu)``

export const StyledMenuItem = styled(Item)``

export const StyledSettingsContainer = styled.div`
  background: #ffffff;
  padding: 0;

  @media (max-width: 640px) {
    padding: 15px;
  }
`

export const StyledSettingsContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    flex: 1;
  }

  @media (max-width: 640px) {
    > div {
      padding: 0;
    }
    ${StyledMenu} {
      margin: 0 !important;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .ant-menu {
      width: 100%;
    }
  }

  ${StyledMenu} {
    background: white;
    border-bottom: 1px solid #f8a51a !important;
    margin-left: 36px;
    margin-right: 36px;
    margin-top: 36px;
    position: relative;

    .ant-menu-overflowed-submenu {
      border-bottom: none !important;
      position: absolute;
      right: 0;

      > div {
        font-size: 32px;
        padding: 5px;
        color: #524d6a;
        margin-bottom: 5px;
        width: 42px;
        text-align: center;
      }

      &.ant-menu-submenu-active {
        border-bottom: none;
        color: #698aff;
      }
    }
  }

  ${StyledMenuItem} {
    height: 41px;
    border-radius: 2px;
    border: solid 1px #e4e9f0 !important;
    border-bottom: 1px solid #f8a51a !important;
    margin-right: 10px !important;
    text-align: center;
    padding: 9px 20px 0 !important;
    line-height: 1.5;

    > span {
      font-family: 'Assistant', sans-serif;
      color: #524d6a;
      font-size: 16px;
    }

    &:hover,
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background-color: #f2f4f8 !important;

      > span {
        font-weight: bold;
        color: #698aff;
      }
    }

    &:hover:not(.ant-menu-item-selected) {
      > span {
        font-weight: normal;
        color: #698aff;
      }
    }
  }
`

export const Content = styled.div`
  background: white;
  border: none;
  min-height: 468px;
  padding-top: 16px;
  padding-bottom: 16px;

  .ant-table-content {
    font-family: 'Assistant', sans-serif;

    tr {
      &:hover {
        td {
          background: white !important;
        }
      }
    }
    th {
      padding: 16px;

      .ant-table-column-title {
        font-weight: bold;
        color: #524d6a;
      }
    }
    td {
      padding: 16px;

      .hidden-api-key {
        letter-spacing: 2px;
      }

      .ant-input {
        height: 32px;
      }
    }
    .column-actions {
      button {
        border: none;
        padding: 0;
        margin-right: 16px;

        span {
          text-decoration: underline !important;
          color: #1890ff;
        }
      }
    }
  }
`

export const ContentHeader = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e9f0;
`

export const ContentFooter = styled.div`
  padding-top: 16px;
  border-top: 1px solid #e4e9f0;
  text-align: right;
`

export const SubContent = styled.div`
  background: white;
  border: none;
  padding-top: 16px;

  &:last-child {
    padding-bottom: 16px;
  }
`

export const PageTitle = styled(Text)`
  font-weight: bold;
  font-size: 22px;
  color: #524d6a;
`

export const SubTitle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: #524d6a;
  margin-bottom: 12px;
`

export const BreadcrumbsTriangle = styled.span`
  font-size: 8px;
  margin-left: 20px;
  margin-right: 20px;
`
