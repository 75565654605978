import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { StyledTable } from '../../Identity/identity.styled'
import { WIDGETS_TABLE_DATA } from '../widgets.constants'
import { WidgetsListContainer } from '../widgets.styled'

const WidgetsList = ({ editWidget, deleteWidget }) => {
  const columns = [
    {
      title: 'Widget Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Widget Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Total Views',
      dataIndex: 'totalViews',
      key: 'totalViews',
    },
    {
      title: '# Started',
      dataIndex: 'started',
      key: 'started',
    },
    {
      title: '# Completed',
      dataIndex: 'completed',
      key: 'completed',
    },
    {
      title: 'Acceptance',
      dataIndex: 'acceptance_percent',
      key: 'acceptance_percent',
    },
    {
      title: 'Actions',
      dataIndex: '',
      className: 'column-actions',
      render: (_, item) => (
        <div>
          <Button type="link" onClick={() => false}>
            <FormattedMessage id="widget.list.item.settings.preview" defaultMessage="Preview" />
          </Button>
          <Button type="link" onClick={() => editWidget(item)}>
            <FormattedMessage id="widget.list.item.settings.button" defaultMessage="Settings" />
          </Button>
          <Button type="link" onClick={() => deleteWidget(item)}>
            <FormattedMessage id="widget.list.item.delete.button" defaultMessage="Delete" />
          </Button>
        </div>
      ),
    },
  ]

  return (
    <WidgetsListContainer>
      <StyledTable
        rowKey="id"
        loading={false}
        pagination={false}
        dataSource={WIDGETS_TABLE_DATA}
        columns={columns}
      />
    </WidgetsListContainer>
  )
}

export default WidgetsList
