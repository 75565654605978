import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Select } from 'antd'
import { FormItem, Input, Button } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import {
  StyledMemberInviteContainer,
  StyledMemberList,
  StyledMemberListItem,
  StyledMemberListItemRole,
  StyledOrganizationInputAddonAfter,
} from '../organization.styled'
import { USER_ROLES_ENUM } from '../../Auth/auth.constants'
import {
  postInvitation,
  getInvitations,
  updateMemberRole,
  removeMember,
} from '../organization.actions'

const { Option } = Select

const TeamMembers = ({ isAdmin, members, userId }) => {
  const dispatch = useDispatch()
  const [selectedMember, setSelectedMember] = useState(null)
  const [visible, setVisible] = useState(false)
  const [changeRoleVisible, setChangeRoleVisible] = useState(false)
  const [memberEmail, setMemberEmail] = useState('')
  const [memberRole, setMemberRole] = useState(USER_ROLES_ENUM.ADMIN)
  const [newMemberRole, setNewMemberRole] = useState(USER_ROLES_ENUM.ADMIN)
  const { invitationLoading, updateRoleLoading, removeMemberLoading } = useSelector(
    state => state.organization,
  )

  useEffect(() => {
    if (!updateRoleLoading) {
      setChangeRoleVisible(false)
      setSelectedMember(null)
    }
  }, [updateRoleLoading])

  useEffect(() => {
    if (!removeMemberLoading) {
      setVisible(false)
      setSelectedMember(null)
    }
  }, [removeMemberLoading])

  useEffect(() => {
    if (!invitationLoading && isAdmin) {
      setMemberEmail('')
      setMemberRole(USER_ROLES_ENUM.ADMIN)
      dispatch(getInvitations())
    }
  }, [invitationLoading])

  const handleChange = e => {
    setMemberEmail(e.target.value)
  }

  const handleChangeRole = member => {
    setSelectedMember(member)
    setChangeRoleVisible(true)
  }

  const handleRemoveMember = member => {
    setSelectedMember(member)
    setVisible(true)
  }

  const handleUpdateRoleSelect = value => {
    setNewMemberRole(value)
  }

  const handleChangeRoleSubmit = () => {
    dispatch(
      updateMemberRole({
        userId: selectedMember.id,
        role: newMemberRole,
      }),
    )
  }

  const handleChangeRoleCancel = () => {
    setChangeRoleVisible(false)
  }

  const handleSubmit = () => {
    dispatch(
      removeMember({
        userId: selectedMember.id,
      }),
    )
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleMemberInviteRoleChange = value => {
    setMemberRole(value)
  }

  const handleMemberInvite = () => {
    dispatch(
      postInvitation({
        email: memberEmail,
        role: memberRole,
      }),
    )
  }

  return (
    <StyledMemberInviteContainer>
      {isAdmin ? (
        <FormItem label={null} name="inviteEmail" hasFeedback={false}>
          <FormattedMessage id="form.item.inviteMember" defaultMessage="Invite with email">
            {placeholder => (
              <Input
                addonAfter={
                  <StyledOrganizationInputAddonAfter>
                    <Select
                      defaultValue={USER_ROLES_ENUM.ADMIN}
                      onChange={handleMemberInviteRoleChange}
                    >
                      <Option value={USER_ROLES_ENUM.ADMIN}>
                        <FormattedMessage
                          id={`settings.organization.member.role.${USER_ROLES_ENUM.ADMIN}`}
                          defaultMessage="Administrator"
                        />
                      </Option>
                      <Option value={USER_ROLES_ENUM.DEVELOPER}>
                        <FormattedMessage
                          id={`settings.organization.member.role.${USER_ROLES_ENUM.DEVELOPER}`}
                          defaultMessage="Developer"
                        />
                      </Option>
                      <Option value={USER_ROLES_ENUM.COMPLIANCE}>
                        <FormattedMessage
                          id={`settings.organization.member.role.${USER_ROLES_ENUM.COMPLIANCE}`}
                          defaultMessage="Compliance"
                        />
                      </Option>
                    </Select>
                    <Button
                      disabled={!memberEmail.length}
                      type="primary"
                      onClick={handleMemberInvite}
                      loading={invitationLoading}
                    >
                      <FormattedMessage id="form.item.button.invite" defaultMessage="Invite" />
                    </Button>
                  </StyledOrganizationInputAddonAfter>
                }
                placeholder={placeholder}
                onChange={handleChange}
                value={memberEmail}
              />
            )}
          </FormattedMessage>
        </FormItem>
      ) : null}

      <StyledMemberList noTopBorder={isAdmin}>
        {members.map(item => (
          <StyledMemberListItem key={item.email}>
            <div className="d-flex flex-row">
              <div>
                {item.firstName} {item.lastName}
              </div>
              <div>・</div>
              <div>{item.email}</div>
            </div>
            <div className="d-flex flex-row align-items-center">
              {isAdmin && item.id !== userId ? (
                <Button danger type="link" onClick={() => handleChangeRole(item)}>
                  <FormattedMessage
                    id="settings.organization.member.changeRole.button"
                    defaultMessage="Change Role"
                  />
                </Button>
              ) : null}
              {isAdmin && item.id !== userId ? (
                <Button type="link" onClick={() => handleRemoveMember(item)}>
                  <FormattedMessage
                    id="settings.organization.member.remove.button"
                    defaultMessage="Remove Member"
                  />
                </Button>
              ) : null}
              <StyledMemberListItemRole>
                <FormattedMessage
                  id={`settings.organization.member.role.${item.role}`}
                  defaultMessage={item.role}
                />
              </StyledMemberListItemRole>
            </div>
          </StyledMemberListItem>
        ))}
      </StyledMemberList>

      <Modal
        centered
        visible={visible}
        title={
          <FormattedMessage
            id="settings.organization.delete.member.modal.title"
            defaultMessage="Remove team member"
          />
        }
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            <FormattedMessage
              id="settings.security.notifications.modal.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button key="submit" type="primary" loading={removeMemberLoading} onClick={handleSubmit}>
            <FormattedMessage id="settings.security.notifications.modal.ok" defaultMessage="Okay" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="settings.organization.delete.member.modal.description"
          defaultMessage="Are you sure you want to remove team member?"
        />
      </Modal>

      <Modal
        centered
        visible={changeRoleVisible}
        title={
          <FormattedMessage
            id="settings.organization.changeRole.member.modal.title"
            defaultMessage="Remove team member"
          />
        }
        onOk={handleChangeRoleSubmit}
        onCancel={handleChangeRoleCancel}
        footer={[
          <Button key="back" onClick={handleChangeRoleCancel}>
            <FormattedMessage
              id="settings.security.notifications.modal.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updateRoleLoading}
            onClick={handleChangeRoleSubmit}
          >
            <FormattedMessage id="settings.security.notifications.modal.ok" defaultMessage="Okay" />
          </Button>,
        ]}
      >
        <div>
          <FormattedMessage
            id="settings.organization.changeRole.member.modal.description"
            defaultMessage="Please select new user role"
          />
        </div>

        <Select
          className="w-100 mt-2"
          defaultValue={USER_ROLES_ENUM.ADMIN}
          onChange={handleUpdateRoleSelect}
        >
          <Option value={USER_ROLES_ENUM.ADMIN}>
            <FormattedMessage
              id={`settings.organization.member.role.${USER_ROLES_ENUM.ADMIN}`}
              defaultMessage="Administrator"
            />
          </Option>
          <Option value={USER_ROLES_ENUM.DEVELOPER}>
            <FormattedMessage
              id={`settings.organization.member.role.${USER_ROLES_ENUM.DEVELOPER}`}
              defaultMessage="Developer"
            />
          </Option>
          <Option value={USER_ROLES_ENUM.COMPLIANCE}>
            <FormattedMessage
              id={`settings.organization.member.role.${USER_ROLES_ENUM.COMPLIANCE}`}
              defaultMessage="Compliance"
            />
          </Option>
        </Select>
      </Modal>
    </StyledMemberInviteContainer>
  )
}

TeamMembers.defaultProps = {
  members: [],
}

export default TeamMembers
