import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { Title, LoginContainerTransparent } from '../auth.styled'
import * as routerConstants from '../../App/app.router.constants'

const RegisterSuccess = () => {
  const history = useHistory()

  return (
    <LoginContainerTransparent>
      <Helmet title="Verify your email" />

      <Title>
        <FormattedMessage id="verify.title" defaultMessage="Your registration is almost done" />
      </Title>

      <Title>
        <FormattedMessage
          id="verify.subTitle"
          defaultMessage="Please, check your email for a verification message"
        />
      </Title>

      <Button
        type="primary"
        htmlType="button"
        className="w-100 mt-4"
        onClick={() => history.push(routerConstants.LOGIN_ROUTE)}
      >
        <FormattedMessage id="verify.loginLink" defaultMessage="Login" />
      </Button>
      <Button
        type="link"
        block
        className="w-100 text-white mt-2"
        onClick={() => history.push(routerConstants.LOGIN_ROUTE)}
      >
        <FormattedMessage id="verify.backLink" defaultMessage="Back" />
      </Button>
    </LoginContainerTransparent>
  )
}

export default RegisterSuccess
