import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loader from 'components/Loader'
import { Inner, Title, LoginContainerTransparent } from '../auth.styled'
import { logout, validateEmail } from '../auth.actions'
import * as routerConstants from '../../App/app.router.constants'

const VerifyEmail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    if (
      Object.keys(parsed).length === 0 ||
      parsed.email === undefined ||
      parsed.token === undefined
    ) {
      history.push(routerConstants.LOGIN_ROUTE)
    } else {
      dispatch(logout(true))
      dispatch(validateEmail(parsed))
    }
  }, [location, dispatch, history])

  return (
    <LoginContainerTransparent>
      <Helmet title="Verify your email" />
      <Title>
        <FormattedMessage id="register.validatingEmail" defaultMessage="Verifying your Email" />
      </Title>
      <Title>
        <FormattedMessage
          id="register.validatingEmailSubTitle"
          defaultMessage="This will only take a few seconds..."
        />
      </Title>
      <Inner>
        <div className="loader">
          <Loader />
        </div>
      </Inner>
    </LoginContainerTransparent>
  )
}

export default VerifyEmail
