import * as constants from './userProfile.constants'

const initialState = {
  loading: false,
  profile: {},
}

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USER_PROFILE:
    case constants.CREATE_USER_PROFILE:
    case constants.UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: true,
      }
    case constants.GET_USER_PROFILE_SUCCESS:
    case constants.CREATE_USER_PROFILE_SUCCESS:
    case constants.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      }
    case constants.CLEAR_USER_PROFILE:
      return initialState
    case constants.GET_USER_PROFILE_ERROR:
    case constants.CREATE_USER_PROFILE_ERROR:
    case constants.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default userProfile
