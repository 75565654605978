import React from 'react'
import { Skeleton } from 'antd'
import { Card, CardText, MonthlyBillPriceContainer } from '../billing.styled'

const PlanCardSkeleton = () => {
  return (
    <Card className="p-0 mb-2">
      <CardText className="font-weight-bold">
        <Skeleton.Input active />
      </CardText>
      <MonthlyBillPriceContainer />
      <div className="text-center">
        <ul className="pl-4">
          <li>
            <Skeleton.Input active size="small" />
          </li>
          <li>
            <Skeleton.Input active size="small" />
          </li>
          <li>
            <Skeleton.Input active size="small" />
          </li>
        </ul>
        <Skeleton.Button className="m-auto w-100" active />
      </div>
    </Card>
  )
}

export default PlanCardSkeleton
