import React from 'react'
import { BaseTable, PageSubTitle, PageTitle, Text } from 'components'
import { TermsContainer } from '../auth.styled'
import { TERMS_COOKIES_COLUMNS, TERMS_COOKIES_DATA } from '../auth.constants'

const PrivacyPolicy = () => (
  <TermsContainer>
    <PageTitle>Charge’s Privacy Policy</PageTitle>

    <Text>
      Charge Technology Group, Inc. (“Charge”) respects the privacy of our users. This Privacy
      Policy (“Policy”) describes our privacy practices concerning information collected in
      connection with Charge’s payment, verification, and related online services, including but not
      limited to Authentication, Checkout, Document Verification, ID Verification and Identity
      Verification (the “Services”). A separate policy, available here, describes our privacy
      practices in connection with our online website, located at www.charge.io. Charge makes the
      Services available to third parties for integration into those third parties’ websites,
      applications, and online services. Charge collects, uses, and discloses individual users’
      information only as directed by these third parties and, accordingly, under applicable data
      protection laws, Charge is a processor or service provider (“data processor”) of user
      information with respect to the Services and not a controller or business (“data controller”).
      Further, some features of the Services may be disabled or altered by the data controller, or
      the data controller may require Charge to collect, use, disclose, or otherwise process data in
      ways that differ from those described below. Thus, to fully understand how your information
      will be handled when you use the Services, you must review not just this Policy, but also the
      privacy policy of the third party with whom you are dealing directly (the “Customer Data
      Controller”). As an exception to the above, Charge processes personal information in the
      capacity of a data controller and a business to comply with its regulatory obligations. For
      further information please review the section “Charge acting as a data controller” below.
    </Text>

    <Text>
      Notwithstanding the above, Charge may process certain individual users’ information in
      anonymized and/or aggregated form for its own purposes.
    </Text>

    <Text>
      Charge is headquartered in the United States at 1100 Highland Ave, Manhattan Beach, CA 90266.
    </Text>

    <PageTitle>What personal and other information Charge collects about you</PageTitle>

    <Text>
      Charge collects “personal information” about users of the Services. “Personal information” is
      information such as a name, email address, or identification card image, which refers to an
      identified or identifiable person. Charge processes personal information on behalf of the
      Customer Data Controller. For its own purposes, Charge only processes the personal information
      described in section “Charge acting as a data controller” and anonymized and/or aggregated
      information. “Anonymized information” is information which does not relate to an identified or
      identifiable person or is rendered anonymous in such a manner that the person is no longer
      identifiable.
    </Text>

    <Text>
      <span className="font-weight-bold">Personal information.</span> Charge collects a wide range
      of personal information through the Services. This information varies depending on the Charge
      application and the Customer Data Controller in question, but may include such information as
      name, physical address, email address, telephone number, social security number, driver’s
      license number, state or national ID card number, passport number, other ID card number,
      credit or debit card number, CVV, expiration date, and/or date of birth. In some cases, Charge
      may collect a visually scanned or photographed image of your face and/or your identification
      card, driver’s license, passport, utility bill, bank account statement, insurance card, or
      credit/debit card. This image may include your photograph and other information from the
      imaged document, such as your eye color, weight, height, and organ donor status.
    </Text>

    <Text>
      <span className="font-weight-bold">Information for Illinois residents:</span> Charge’s
      collection of personal information may include biometric identifiers and/or biometric
      information (collectively “biometric data”), and Charge may share such biometric data with the
      Customer Data Controller. Charge may collect, process and store your biometric data for the
      purpose of verification services and long-term proof of inspection of your provided form of
      identification, on behalf of and as instructed by the Customer Data Controller. Charge will
      store your biometric data for as long as the Customer Data Controller requests (e.g., the
      duration of your use of its services), which shall be no longer than the earlier of the date
      when (i) the Customer Data Controller ceases to have a relationship with Charge or (ii) within
      three (3) years after the Customer Data Controller informs Charge that its last interaction
      with you has occurred.
    </Text>

    <Text>
      <span className="font-weight-bold">Facial recognition.</span> If you agree to use our
      Biometric Facial Recognition, or other facial recognition service that we offer to our
      business customers, Charge will collect an image of your face that you provide through a
      mobile app (i.e. a selfie) and a photo or scan of your face as it appears on an identification
      document. Charge will use facial recognition technology only for the purpose of verifying your
      identity as the person who appears on the identification document. Charge may share the facial
      scans with the Charge customer through which you used Charge’s identity verification service.
      Charge will retain your facial recognition information, including the photo of your face and
      photo or scan of your identification document, for the amount of time requested by the Charge
      customer through which you used Charge’s identity verification service. In no event will
      Charge store your facial recognition information after Charge ceases to have a customer
      relationship with the customer through which you used Charge’s identity verification service.
    </Text>

    <Text>
      <span className="font-weight-bold">Data provided by third parties.</span> We may receive
      personal or anonymized and/or aggregated information about you from the Customer Data
      Controller that integrates the Services into its website, application, or other online
      service. This information includes a customer ID, selected by the Customer Data Controller,
      that uniquely identifies you in the third party’s database. For additional information, review
      the privacy policy of the Customer Data Controller.
    </Text>

    <Text>
      At the direction of the Customer Data Controller, Charge also might obtain information about
      you from other third parties, such as consumer reporting agencies and fraud-prevention
      services.
    </Text>

    <Text>
      <span className="font-weight-bold">Cookies and other tracking data.</span> When you use the
      Services, we automatically receive and record certain information from your computer (or other
      device) and/or your web browser. This may include such information as the third-party website
      or application into which the Services are integrated, the date and time that you use the
      Services, your IP address and domain name, your software and hardware attributes (including
      operating system, device model, and hashed device fingerprint information), and your general
      geographic location (e.g., your city, state, or metropolitan region; or your geolocation (GPS
      coordinates) if available). If you use the Authentication service, we may install little
      pieces of software (called: service workers) on your device to increase the speed of the next
      and following verifications. The service worker does not collect any data about you and does
      not track you. We will process such data only as instructed by the Customer Data Controller,
      or as required for Charge to meet its obligations relating to its own regulatory compliance.
      We also use cookies in connection with the Services. For further information please refer to
      the section entitled “Cookies” below.
    </Text>

    <PageTitle>Cookies</PageTitle>

    <Text>
      <span className="font-weight-bold">What are cookies?</span> Cookies are small files that are
      stored on your computer or other device by your web browser. A cookie allows Charge to
      recognize whether you have used the Services before and may store user preferences and other
      information.
    </Text>

    <Text>
      <span className="font-weight-bold">How are cookies used?</span> For example, cookies can be
      used to collect information about your use of the Services during your current session and
      over time, your computer or other device’s operating system and browser type, your Internet
      service provider, your domain name and IP address, and your general geographic location or
      geolocation. We process personal information generated by cookies only as instructed by the
      Customer Data Controller.
    </Text>

    <Text>
      <span className="font-weight-bold">What kind of cookies are used on the Website?</span> Our
      website primarily uses the following types of cookies:
    </Text>

    <BaseTable
      className="utils__scrollTable"
      columns={TERMS_COOKIES_COLUMNS}
      dataSource={TERMS_COOKIES_DATA}
      bordered
      size="default"
      pagination={false}
    />

    <Text>
      __gatSession cookieThis cookie is used to throttle the request rate and expires after 10
      minutes.“Session cookies” are temporary bits of information which are deleted when you exit
      your web browser. Session cookies are typically used to improve navigation and to collect web
      statistics.
    </Text>

    <Text>
      “Persistent cookies” are more permanent bits of information that are stored and remain on your
      computer until they are deleted by you. This type of cookie stores information on your
      computer for a number of purposes; such as saving your passwords. Persistent cookies delete
      themselves after a certain period of time but are renewed each time you visit the website.
    </Text>

    <Text>
      <span className="font-weight-bold">How do you avoid cookies?</span>
      If you are concerned about having cookies on your computer or device, you can set your browser
      to refuse all cookies or to indicate when a cookie is being set, allowing you to decide
      whether to accept it. You can also delete cookies from your computer. However, if you choose
      to block or delete cookies, certain features of the Services may not operate correctly.
    </Text>

    <PageTitle>
      How Charge uses the personal and anonymized/aggregated information that we collect
    </PageTitle>

    <Text>
      In general, Charge uses the personal and anonymized and/or aggregated information that we
      collect in connection with the Services as discussed in this section of the Policy. Other than
      as described under the section “Charge acting as a data controller” personal information is
      used by Charge only as directed by the Customer Data Controller that integrates the Services
      into its website, application, or other online service. Subject to the privacy policy of the
      Customer Data Controller, we use your personal information as follows on behalf of the
      Customer Data Controller.
    </Text>

    <Text>
      Charge may use your personal information to provide the Services. For example, we might use
      your credit card information or ID card information to populate an online form, or to verify
      your identity in connection with your use of another online service. We also may use your
      personal information to fulfill the terms of any agreement between us and the Customer Data
      Controller; to complete a transaction that you initiate; to deliver confirmations, account
      information, notifications, and similar operational communications; and to comply with legal
      and/or regulatory requirements.
    </Text>

    <Text>
      Anonymized and/or aggregated information that we collect in connection with the Services is
      used by Charge for its own purposes to perform analytics and research concerning the Services.
    </Text>

    <Text>
      We process the provided personal information manually, by our specially trained verification
      agents or automatically with the use of our software and machine learning capabilities. When
      we process the personal information automatically, we apply especially the following criteria:
      <div>
        <ul>
          <li> Checks on the integrity and quality of the photographs;</li>
          <li> Checks on the integrity and recognition of the document;</li>
          <li>
            {' '}
            Extracting and analysis of text, graphical layout, and any other available information
            on the document, face photographs and face maps, background;
          </li>
          <li>
            {' '}
            Analysis of results of all the steps combined, considering multiple variables,
            predictions and confidence values for a final score;
          </li>
          <li> Lookups against known images as well as known cases;</li>
          <li>
            {' '}
            In case of identity verification, your selfie is compared to the photo on the document
            and is used to ensure your liveness in the selfie.
          </li>
        </ul>
      </div>
    </Text>

    <Text>
      Please reach out to the Customer Data Controller responsible for your personal information
      with any inquiries regarding the rights you may have in case your personal information is
      subject to automated decision-making.
    </Text>

    <PageTitle>
      How Charge shares personal and anonymized/aggregated information with other entities
    </PageTitle>

    <Text>
      In general, Charge shares the personal and anonymized and/or aggregated information that we
      collect in connection with the Services as discussed below.
    </Text>

    <Text>
      Other than as described under the section “Charge acting as a data controller” below, Charge
      shares personal information only as directed by the Customer Data Controller, and thus the
      following language is subject to the privacy policy of the Customer Data Controller.
    </Text>

    <Text>
      <span className="font-weight-bold">Charge service providers.</span>
      We share the personal and pseudonymized information that we collect on behalf of a particular
      Customer Data Controller with that Customer Data Controller.
    </Text>

    <Text>
      <span className="font-weight-bold">Customer Data Controller.</span>
      Charge also uses third-party service providers to help us deliver, manage, and improve the
      Services. These service providers may collect and/or use your personal information or
      anonymized and/or aggregated information to assist us in achieving the purposes discussed
      above in the section entitled “How Charge uses the personal and anonymized /aggregated
      information that we collect.”
    </Text>

    <Text>
      We also may share your personal information with other third parties when necessary to fulfill
      your requests for services; to complete a transaction that you initiate; or to meet the terms
      of any agreement that you have with us or our partners.
    </Text>

    <Text>
      <span className="font-weight-bold">Analytics providers.</span>
      We partner with certain other third parties to collect anonymized and/or aggregated
      information and engage in analysis, auditing, research, and reporting.
    </Text>

    <Text>
      <span className="font-weight-bold">Legal purposes.</span>
      We also may use or share your personal information with third parties when we have reason to
      believe that doing so is necessary:
      <div>
        <ul>
          <li>
            {' '}
            to comply with applicable law or a court order, subpoena, or other legal process;
          </li>
          <li>
            {' '}
            to investigate, prevent, or take action regarding illegal activities, suspected fraud,
            violations of our terms and conditions, or situations involving threats to our property
            or the property or physical safety of any person or third party;
          </li>
          <li>
            {' '}
            to establish, protect, or exercise our legal rights or defend against legal claims; or
          </li>
          <li>
            {' '}
            to facilitate the financing, securitization, insuring, sale, assignment, bankruptcy, or
            other disposal of all or part of our business or assets.
          </li>
        </ul>
      </div>
    </Text>

    <Text>
      <span className="font-weight-bold">Aggregated information.</span>
      From time to time, Charge may also share anonymized and/or aggregated information about users
      of the Services, such as by publishing a report on trends in the usage of the Services.
    </Text>

    <Text>
      <span className="font-weight-bold">Information for California residents.</span>
      Charge processes your personal information on behalf of the Customer Data Controller pursuant
      to a written agreement for verification services. As such, Charge acts as a service provider
      to the Customer Data Controller. Moreover, Charge does not sell your personal information as
      the terms “sell” and “personal information” are defined by the California Consumer Privacy Act
      (the “CCPA”) and, in providing its services to the Customer Data Controller, Charge will not
      retain, use, or disclose your personal information to any other third parties that would
      constitute “selling” as the term is defined by the CCPA. Any questions or requests regarding
      Charge’s processing of your personal information in respect of your rights under the CCPA
      should be directed to the Customer Data Controller that is responsible for your personal
      information.
    </Text>

    <PageTitle>Security</PageTitle>

    <Text>
      Charge uses commercially reasonable physical, electronic, and procedural safeguards designed
      to protect your personal information against loss or unauthorized access, use, modification,
      or deletion. Among other things, Charge encrypts sensitive information both in transit and at
      rest.{' '}
      <span>
        Charge regularly conducts security audits, vulnerability scans, and penetration tests
      </span>{' '}
      to ensure compliance with industry security practices and standards. However, no security
      program is foolproof, and thus we cannot guarantee the absolute security of your personal or
      other information. Moreover, we cannot guarantee the safety of your information when in the
      possession of other parties, such as the Customer Data Controller.
    </Text>

    <PageTitle>Reviewing and updating your information</PageTitle>

    <Text>
      With the exception of Charge acting as a data controller (please see below), Charge will grant
      you access to your personal information only as directed by the Customer Data Controller that
      integrates the Services into its website, application, or online service. Charge also will
      retain your personal information as directed by the Customer Data Controller and, accordingly,
      we may retain your personal information for as short as a few minutes or longer as directed by
      the Customer Data Controller.
    </Text>

    <Text>
      Thus, if you want to learn more about the personal information that Charge has about you, or
      you would like to submit a request to update or change that information, please contact the
      Customer Data Controller. You also may reach us by email at{' '}
      <a href="mailto:privacy@charge.io">privacy@charge.io</a>.
    </Text>

    <PageTitle>Charge acting as a data controller</PageTitle>

    <PageSubTitle>Processing of GPS coordinates and IP addresses</PageSubTitle>

    <Text>
      Charge acts as a data controller when processing GPS coordinates and IP addresses collected
      when rendering its services to Customers Data Controllers.
    </Text>

    <Text>
      The data subjects whose personal information is processed are end-users using the Services.
    </Text>

    <PageSubTitle>Purpose and legal basis for processing</PageSubTitle>

    <Text>
      The purpose of processing is to provide legal notices to data subjects and allow them to grant
      their informed consent relating to the use of biometric information by Charge in connection
      with the Services, as required under the laws applicable to Charge concerning the respective
      user (e.g. the Illinois Biometric Information Privacy Act or “BIPA”).
    </Text>

    <Text>
      Charge processes the personal information – GPS coordinates and IP addresses – on the basis of
      Article 6(1)(f) GDPR, specifically:
    </Text>

    <Text>
      (i) the prevailing legitimate interest of Charge to comply with its legal obligations in
      jurisdictions outside of the European Union;
    </Text>

    <Text>
      (ii) the prevailing legitimate interest of data subjects using Charge’s services to be
      notified of biometric information processing by Charge and express their consent, under the
      laws applicable to Charge concerning the respective user.
    </Text>

    <PageSubTitle>Recipients of the personal information</PageSubTitle>

    <Text>
      <ol>
        <li>
          The personal information is entrusted to data processors acting on Charge’s behalf,
          providing hosting services, technical staff and relevant technical infrastructure. In such
          case the processors shall process personal information on the basis of an agreement with
          Charge and exclusively in accordance with Charge’s instructions.
        </li>
        <li>
          The personal information may be received by competent state authorities operating on the
          basis of generally applicable legal provisions.
        </li>
        <li>
          Charge’s authorized employees and contractors may have access to the personal information.
        </li>
      </ol>
    </Text>

    <PageSubTitle>The period for which the personal information will be stored</PageSubTitle>

    <Text>
      GPS coordinates and IP addresses will only be stored for the period necessary to establish the
      location of the data subject and deleted immediately afterwards.
    </Text>

    <PageSubTitle>
      Data subjects’ rights in regard to personal information – GPS coordinates and IP addresses
    </PageSubTitle>

    <Text>
      In accordance with applicable law, you may have the right to: (i) request confirmation of
      whether we are processing your personal information; (ii) obtain access to or a copy of your
      personal information; (iii) receive an electronic copy of personal information that you have
      provided to us, or ask us to send that information to another company (the “right of data
      portability”); (iv) object to or restrict our uses of your personal information; (v) seek
      correction or amendment of inaccurate, untrue, incomplete, or improperly processed personal
      information; and (vi) request erasure of personal information held about you by us, subject to
      certain exceptions prescribed by law.
    </Text>

    <Text>
      We will process such requests in accordance with applicable laws. To protect your privacy, we
      will take steps to verify your identity before fulfilling your request. If we are unable to
      verify your identity, we will not be able to fulfill your request.
    </Text>

    <PageSubTitle>California Residents</PageSubTitle>

    <Text>
      For purposes of the California Consumer Privacy Act, Charge does not “sell” personal
      information.
    </Text>

    <Text>
      California residents have the right not to receive discriminatory treatment by Charge for the
      exercise of their rights conferred by the California Consumer Privacy Act.
    </Text>

    <PageSubTitle>
      Data Subjects Located in the European Economic Area – Supervisory Authority
    </PageSubTitle>

    <Text>
      If you are located in the European Economic Area, you have the right to file a complaint with
      the supervisory authority – Austrian Data Protection Authority (Österreichische
      Datenschutzbehörde). To exercise your rights please contact us at the address provided below.
    </Text>

    <PageTitle>
      Additional information for users of the Services from outside the United States
    </PageTitle>

    <Text>
      The personal information that Charge collects through or in connection with the Services is
      transferred to and processed in the United States for the purposes described above. Charge
      also may subcontract the processing of your data to, or otherwise share your data with, its
      affiliates or third parties in the United States or countries other than your country of
      residence. The data protection laws in these countries may be different from, and less
      stringent than, those in your country of residence.
    </Text>

    <Text>
      However,{' '}
      <span>
        we only transfer your personal information to countries where the EU Commission has decided
        that they have an adequate level of data protection or we take measures to ensure that all
        recipients provide an adequate level of data protection. We do this for example by entering
        into appropriate data transfer agreements based on Standard Contractual Clauses (2010/87/EC
        and/or 2004/915/EC).
      </span>
    </Text>

    <PageTitle>Children’s Privacy</PageTitle>

    <Text>
      The Services are not directed to children under the age of 13, and Charge will never knowingly
      collect personal or other information from anyone it knows is under the age of 13. We
      recommend that persons over 13 but under 18 years of age ask their parents for permission
      before using the Services or sending any information about themselves to anyone over the
      Internet.
    </Text>

    <PageTitle>Changes to this Policy</PageTitle>

    <Text>
      Technology and the Internet are rapidly changing. Charge therefore is likely to make changes
      to the Services in the future and as a consequence will need to revise this Policy to reflect
      those changes. When we revise the Policy, Charge will post the new Policy on the Charge
      website’s home page (www.charge.io), so you should review that page periodically. If we make a
      material change to the Policy, you will be provided with appropriate notice. If we maintain
      your email address, we also may email you a copy of the revised Policy at your most recently
      provided email address. It is therefore important that you update your email address if it
      changes.
    </Text>

    <PageTitle>Questions or comments</PageTitle>

    <Text>
      If you have any questions or comments regarding our Policy, please mail or email us at:
    </Text>

    <Text>
      Charge Technology Group, Inc. <br />
      1100 Highland Ave <br />
      Manhattan Beach, CA 90266
    </Text>

    <Text>
      Email: <a href="mailto:privacy@charge.io">privacy@charge.io</a>
    </Text>

    <Text>
      The Data Protection Officer can be reached by email at:{' '}
      <a href="mailto:privacy@charge.io">privacy@charge.io</a>
    </Text>

    <Text>
      We may request that you confirm your identity in order to continue with your request.
    </Text>

    <Text>
      <span className="font-weight-bold">Effective date:</span> April 1, 2020
    </Text>
  </TermsContainer>
)

export default PrivacyPolicy
