import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Content, ContentHeader, PageTitle } from 'features/Settings/settings.styled'
import { useSelector } from 'react-redux'
import { BillingContainer } from './billing.styled'
import CurrentMonthlyBill from './components/CurrentMonthlyBill'
import PaymentMethod from './components/PaymentMethod'
import InvoiceHistory from './components/InvoiceHistory'
import NoOrganizationWrapper from '../Organization/components/NoOrganizationWrapper'

const Billing = () => {
  const { organization, loading } = useSelector(state => state.organization)

  return (
    <div>
      <Helmet title="Settings - Billing" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.billing.title" defaultMessage="Billing" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <NoOrganizationWrapper>
          <BillingContainer>
            <div>
              <CurrentMonthlyBill loading={loading} paymentPlan={organization.plan} />
              <PaymentMethod />
            </div>
            <div className="w-100">
              <InvoiceHistory />
            </div>
          </BillingContainer>
        </NoOrganizationWrapper>
      </Content>
    </div>
  )
}

export default Billing
