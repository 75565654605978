import { FormattedMessage } from 'react-intl'
import React from 'react'

export const USER_ROLES_ENUM = {
  ADMIN: 'admin',
  DEVELOPER: 'dev',
  COMPLIANCE: 'compliance',
}

export const USER_STORE_FIELD_NAME = 'user'

// ACTIONS TYPES
export const USER_LOGIN = 'auth/USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'auth/USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'auth/USER_LOGIN_ERROR'

export const USER_LOGOUT = 'auth/USER_LOGOUT'

export const USER_REGISTER = 'auth/USER_REGISTER'
export const USER_REGISTER_SUCCESS = 'auth/USER_REGISTER_SUCCESS'
export const USER_REGISTER_ERROR = 'auth/USER_REGISTER_ERROR'

export const USER_VALIDATE_EMAIL = 'auth/USER_VALIDATE_EMAIL'
export const USER_VALIDATE_EMAIL_SUCCESS = 'auth/USER_VALIDATE_EMAIL_SUCCESS'
export const USER_VALIDATE_EMAIL_ERROR = 'auth/USER_VALIDATE_EMAIL_ERROR'

export const USER_FORGOT_PASSWORD = 'auth/USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_SUCCESS = 'auth/USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_ERROR = 'auth/USER_FORGOT_PASSWORD_ERROR'

export const USER_RESET_PASSWORD = 'auth/USER_RESET_PASSWORD'
export const USER_RESET_PASSWORD_SUCCESS = 'auth/USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_ERROR = 'auth/USER_RESET_PASSWORD_ERROR'

export const GET_LOGGED_IN_USER = 'auth/GET_LOGGED_IN_USER'
export const GET_LOGGED_IN_USER_SUCCESS = 'auth/GET_LOGGED_IN_USER_SUCCESS'
export const GET_LOGGED_IN_USER_ERROR = 'auth/GET_LOGGED_IN_USER_ERROR'

export const SET_USER_ACCOUNT_ID = 'auth/SET_USER_ACCOUNT_ID'
export const SET_USER_ACCOUNT_ID_SUCCESS = 'auth/SET_USER_ACCOUNT_ID_SUCCESS'
export const SET_USER_ACCOUNT_ID_ERROR = 'auth/SET_USER_ACCOUNT_ID_ERROR'

export const SET_USER_PROFILE = 'auth/SET_USER_PROFILE'
export const SET_USER_PROFILE_SUCCESS = 'auth/SET_USER_PROFILE_SUCCESS'
export const SET_USER_PROFILE_ERROR = 'auth/SET_USER_PROFILE_ERROR'

export const USER_REGISTER_BY_INVITATION = 'auth/USER_REGISTER_BY_INVITATION'
export const USER_REGISTER_BY_INVITATION_SUCCESS = 'auth/USER_REGISTER_BY_INVITATION_SUCCESS'
export const USER_REGISTER_BY_INVITATION_ERROR = 'auth/USER_REGISTER_BY_INVITATION_ERROR'

export const VALIDATE_INVITATION_TOKEN = 'auth/VALIDATE_INVITATION_TOKEN'
export const VALIDATE_INVITATION_TOKEN_SUCCESS = 'auth/VALIDATE_INVITATION_TOKEN_SUCCESS'
export const VALIDATE_INVITATION_TOKEN_ERROR = 'auth/VALIDATE_INVITATION_TOKEN_ERROR'

export const UPDATE_PASSWORD = 'auth/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'auth/UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'auth/UPDATE_PASSWORD_ERROR'

export const TOGGLE_USER_NOTIFICATIONS = 'auth/TOGGLE_USER_NOTIFICATIONS'
export const TOGGLE_USER_NOTIFICATIONS_SUCCESS = 'security/TTOGGLE_USER_NOTIFICATIONS_SUCCESS'
export const TOGGLE_USER_NOTIFICATIONS_ERROR = 'security/TOGGLE_USER_NOTIFICATIONS_ERROR'

export const TERMS_COOKIES_DATA = [
  {
    name: '__qa',
    type: 'Persistent cookie',
    purpose: 'This  cookie  is  used  to distinguish  users  and expires after 2 years.',
  },
  {
    name: '__gat',
    type: 'Session cookie',
    purpose:
      'This  cookie  is  used  to throttle the request rate and   expires   after   10 minutes.',
  },
]

export const TERMS_COOKIES_COLUMNS = [
  {
    title: <FormattedMessage id="privacyPolicy.cookieName.column" defaultMessage="Cookie Name" />,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <FormattedMessage id="privacyPolicy.cookieType.column" defaultMessage="Cookie Type" />,
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: <FormattedMessage id="privacyPolicy.purpose.column" defaultMessage="Purpose" />,
    dataIndex: 'purpose',
    key: 'purpose',
  },
]
