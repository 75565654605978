import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from 'features/App/app.reducer'
import auth from 'features/Auth/auth.reducer'
import notifications from 'features/Notifications/notifications.reducer'
import apiKeys from 'features/ApiKeys/apiKeys.reducer'
import identity from 'features/Identity/identity.reducer'
import organization from 'features/Organization/organization.reducer'
import billing from 'features/Billing/billing.reducer'
import userProfile from 'features/UserProfile/userProfile.reducer'
import preSaleCheckout from 'features/PreSaleCheckout/preSaleCheckout.reducer'
import dashboard from 'features/Dashboard/dashboard.reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    apiKeys,
    identity,
    notifications,
    organization,
    billing,
    userProfile,
    preSaleCheckout,
    dashboard,
  })
