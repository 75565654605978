import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HISTORY_ITEM_STATUSES_ENUM } from '../features/Dashboard/dashboard.constants'

export const StyledContainer = styled.div`
  background: #ffffff;
  padding: 35px;

  @media (max-width: 640px) {
    padding: 15px;
  }
`

export const StyledText = styled.div`
  font-family: 'Assistant', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  color: #524d6a;
`

export const StyledPageTitle = styled(StyledText)`
  font-size: 22px;
  font-weight: 800;
`

export const StyledPageSubTitle = styled(StyledText)`
  font-size: 16px;
  font-weight: 600;
`

export const StyledLink = styled(Link)`
  color: #524d6a;
`

export const NoRecordsFoundStyled = styled(StyledText)`
  padding: 24px;
  border-radius: 2px;
  border: solid 1px #91d5ff;
  background-color: #e6f7ff;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;

  > img {
    align-self: flex-start;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  span {
    padding-left: 16px;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  span.hint {
    padding: 0;
    font-size: 14px;
    font-weight: normal;
  }
`

export const StyledHorizontalSeparator = styled.div`
  height: 1px;
  background-color: #e4e9f0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const StyledBaseTableFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .ant-input-search {
    margin-left: 15px;
  }
`

/**
 * Get history item colors set (primary & secondary) by status
 * @param status
 * @returns {{secondary: string, primary: string}}
 */
export const getHistoryItemStatusColors = status => {
  switch (status) {
    case HISTORY_ITEM_STATUSES_ENUM.ACCEPT:
      return {
        primary: '#72cf5e',
        secondary: '#e5ffdd',
      }
    case HISTORY_ITEM_STATUSES_ENUM.DENY:
      return {
        primary: '#f45050',
        secondary: '#ffebeb',
      }
    case HISTORY_ITEM_STATUSES_ENUM.CREATED:
      return {
        primary: '#1890ff',
      }
    case HISTORY_ITEM_STATUSES_ENUM.MANUAL_REVIEW:
      return {
        primary: '#ff9936',
        secondary: '#fff7ef',
      }
    default:
      return {
        primary: '#524d6a',
        secondary: '#ffffff',
      }
  }
}

export const StyledIdentityStatus = styled(StyledText)`
  background-color: ${props => getHistoryItemStatusColors(props.status).primary};
  color: #ffffff;
  padding: 5px 16px;
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  width: auto;
  text-align: center;

  box-shadow: 0 2px 4px 0 #e4e9f0;

  span {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  @media (max-width: 320px) {
    padding: 5px 9px;
    font-size: 11px;
  }
`
