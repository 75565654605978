import React from 'react'
import IncognitoCard from 'assets/images/creditCards/logo-card-incognito-card.svg'
import VisaCard from 'assets/images/creditCards/logo-card-visa.svg'
import MasterCard from 'assets/images/creditCards/logo-card-mastercard.svg'
import DiscoverCard from 'assets/images/creditCards/logo-card-discover.svg'
import AmexCard from 'assets/images/creditCards/logo-card-amex.svg'

const PaymentMethodLogo = ({ type }) => {
  let logo = null

  switch (type) {
    case 'Visa':
      logo = VisaCard
      break
    case 'MasterCard':
      logo = MasterCard
      break
    case 'Discover':
      logo = DiscoverCard
      break
    case 'American Express':
      logo = AmexCard
      break
    default:
      logo = IncognitoCard
  }

  return <img src={logo} alt="Credit card logo" />
}

export default PaymentMethodLogo
