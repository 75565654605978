import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as routerConstants from 'features/App/app.router.constants'
import {
  BreadcrumbsTriangle,
  Content,
  ContentHeader,
  PageTitle,
} from '../../Settings/settings.styled'
import { SelectPlanContainer } from '../billing.styled'

import UpgradePlanModal from '../components/UpgradePlanModal'
import SelectPaymentPlanForm from '../components/SelectPaymentPlanForm'
import { getPaymentPlans } from '../billing.actions'
import { updatePaymentPlan } from '../../Organization/organization.actions'
import { getInvoicePaymentPlans } from '../billing.selectors'

const SelectPaymentPlan = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [selectedPlan, setSelectedPlan] = useState({})
  const [upgradePlanModalVisible, setUpgradePlanModalVisible] = useState(false)

  const { organization, loading } = useSelector(state => state.organization)
  const { paymentPlans, paymentPlansLoading } = useSelector(getInvoicePaymentPlans)

  useEffect(() => {
    dispatch(getPaymentPlans())
  }, [dispatch])

  useEffect(() => {
    if (upgradePlanModalVisible) {
      setUpgradePlanModalVisible(false)
      history.push(routerConstants.SETTINGS_BILLING_ROUTE)
    }
  }, [organization.plan.id])

  const handlePlanSelect = plan => {
    setSelectedPlan(plan)
    setUpgradePlanModalVisible(true)
  }

  const handlePlanUpgrade = selectedPaymentPlanId => {
    dispatch(
      updatePaymentPlan({
        organizationId: organization.id,
        paymentPlanId: selectedPaymentPlanId,
      }),
    )
  }

  return (
    <SelectPlanContainer>
      <Helmet title="Billing Select Plan" />
      <ContentHeader className="d-flex flex-row align-items-center">
        <PageTitle className="font-weight-normal">
          <FormattedMessage id="settings.billing.title" defaultMessage="Billing" />
        </PageTitle>
        <BreadcrumbsTriangle>&#9664;</BreadcrumbsTriangle>
        <PageTitle>
          <FormattedMessage id="settings.billing.selectPlan.title" defaultMessage="Select A Plan" />
        </PageTitle>
      </ContentHeader>

      <Content>
        <SelectPaymentPlanForm
          paymentPlans={paymentPlans}
          loading={paymentPlansLoading}
          selectedPlanId={selectedPlan.id}
          currentPlanId={organization.plan.id}
          handlePlanSelect={handlePlanSelect}
        />
      </Content>

      <UpgradePlanModal
        handleCancel={() => setUpgradePlanModalVisible(false)}
        handlePlanUpgrade={handlePlanUpgrade}
        visible={upgradePlanModalVisible}
        selectedPlan={selectedPlan}
        updatePlanLoading={loading}
      />
    </SelectPlanContainer>
  )
}

export default SelectPaymentPlan
