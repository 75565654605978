import React from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { StyledUserEmail, StyledUserInfo, StyledUserName } from '../app.styled'

const MAX_VISIBLE_EMAIL_LENGTH = 18

const UserInfo = () => {
  const { email, firstName, lastName } = useSelector(state => state.auth.user)
  return (
    <StyledUserInfo>
      <div className="short-username">{firstName ? firstName[0] : null}</div>
      <div className="d-flex flex-row justify-content-center flex-column">
        <StyledUserName>
          {firstName} {lastName}
        </StyledUserName>
        {email && email.length > MAX_VISIBLE_EMAIL_LENGTH ? (
          <Tooltip placement="right" title={email}>
            <StyledUserEmail>{email}</StyledUserEmail>
          </Tooltip>
        ) : (
          <StyledUserEmail>{email}</StyledUserEmail>
        )}
      </div>
    </StyledUserInfo>
  )
}

export default UserInfo
