import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { Helmet } from 'react-helmet'
import { Button } from 'components'
import { PageTitle } from '../../ApiKeys/apiKeys.styled'
import { SubContent, ContentFooter, ContentHeader } from '../../Settings/settings.styled'

import TeamMembers from '../components/TeamMembers'
import PendingInvitations from '../components/PendingInvitations'
import OrganizationForm from '../components/OrganizationForm'
import OrganizationUpdateSkeleton from '../skeletons/OrganizationUpdateSkeleton'

import { updateOrganization } from '../organization.actions'
import { USER_ROLES_ENUM } from '../../Auth/auth.constants'

const OrganizationUpdate = ({ organization, loading }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const isAdmin = user.role === USER_ROLES_ENUM.ADMIN

  const onFinish = values => {
    dispatch(
      updateOrganization({
        id: organization.id,
        ...values,
      }),
    )
  }

  const handleSubmit = () => form.submit()

  return !organization.id && loading ? (
    <OrganizationUpdateSkeleton />
  ) : (
    <div>
      <Helmet title="Settings - Organization Settings" />
      <Form
        form={form}
        name="checkout"
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        hideRequiredMark
        initialValues={{
          phoneNumberPrefix: '1',
          ...organization,
        }}
      >
        <ContentHeader>
          <PageTitle>
            <FormattedMessage
              id="settings.organization.title"
              defaultMessage="Organization Settings"
            />
          </PageTitle>
        </ContentHeader>
        <SubContent>
          <OrganizationForm isAdmin={isAdmin} form={form} />
        </SubContent>

        {isAdmin ? (
          <ContentFooter>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              <FormattedMessage id="form.item.button.save" defaultMessage="Save" />
            </Button>
          </ContentFooter>
        ) : null}

        <ContentHeader>
          <PageTitle>
            <FormattedMessage
              id="settings.organization.teamMembers"
              defaultMessage="Team Members"
            />
          </PageTitle>
        </ContentHeader>

        <SubContent>
          <TeamMembers userId={user.id} isAdmin={isAdmin} members={organization.users} />
        </SubContent>

        {isAdmin ? (
          <Fragment>
            <ContentHeader>
              <PageTitle>
                <FormattedMessage
                  id="settings.organization.pendingInvitations"
                  defaultMessage="Pending Invitations"
                />
              </PageTitle>
            </ContentHeader>

            <SubContent>
              <PendingInvitations />
            </SubContent>
          </Fragment>
        ) : null}
      </Form>
    </div>
  )
}

export default OrganizationUpdate
