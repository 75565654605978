import { USER_ROLES_ENUM } from 'features/Auth/auth.constants'
import adminIcon from 'assets/images/icons/ic-admin.svg'
import developerIcon from 'assets/images/icons/ic-developer.svg'
import complianceIcon from 'assets/images/icons/ic-compliance.svg'

export const MAX_API_KEYS_COUNT = 5

export const API_KEY_PERMISSIONS_TYPES = [
  {
    key: USER_ROLES_ENUM.ADMIN,
    icon: adminIcon,
  },
  {
    key: USER_ROLES_ENUM.DEVELOPER,
    icon: developerIcon,
  },
  {
    key: USER_ROLES_ENUM.COMPLIANCE,
    icon: complianceIcon,
  },
]

// ACTIONS TYPES
export const API_KEY_CREATE = 'settings/API_KEY_CREATE'
export const API_KEY_CREATE_SUCCESS = 'settings/API_KEY_CREATE_SUCCESS'
export const API_KEY_CREATE_ERROR = 'settings/API_KEY_CREATE_ERROR'

export const API_KEY_DELETE = 'settings/API_KEY_DELETE'
export const API_KEY_DELETE_SUCCESS = 'settings/API_KEY_DELETE_SUCCESS'
export const API_KEY_DELETE_ERROR = 'settings/API_KEY_DELETE_ERROR'

export const API_KEY_UPDATE = 'settings/API_KEY_UPDATE'
export const API_KEY_UPDATE_SUCCESS = 'settings/API_KEY_UPDATE_SUCCESS'
export const API_KEY_UPDATE_ERROR = 'settings/API_KEY_UPDATE_ERROR'

export const GET_API_KEYS = 'settings/GET_API_KEYS'
export const GET_API_KEYS_SUCCESS = 'settings/GET_API_KEYS_SUCCESS'
export const GET_API_KEYS_ERROR = 'settings/GET_API_KEYS_ERROR'
