import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Button } from 'components'
import { SelectedPlanProp } from '../billing.styled'
import Footer from '../../Auth/components/AuthFooter'

const UpgradePlanModal = ({
  visible,
  selectedPlan,
  handlePlanUpgrade,
  handleCancel,
  updatePlanLoading,
}) => {
  const { id, price, label } = selectedPlan

  const handleSubmit = () => {
    handlePlanUpgrade(id)
  }

  return (
    <Modal
      centered
      visible={visible}
      title={
        <FormattedMessage
          id="settings.billing.selectPlan.modal.title"
          defaultMessage="Are you sure you want to upgrade your plan?"
        />
      }
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          <FormattedMessage id="settings.billing.selectPlan.modal.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={updatePlanLoading} onClick={handleSubmit}>
          <FormattedMessage
            id="settings.billing.selectPlan.modal.upgrade"
            defaultMessage="Yes, upgrade my plan"
          />
        </Button>,
      ]}
    >
      <SelectedPlanProp>
        <FormattedMessage
          id="settings.billing.selectPlan.modal.selected.label"
          defaultMessage="Plan Selected"
        />
        <span className="font-weight-bold">
          <FormattedMessage id={`settings.billing.plan.${label}`} defaultMessage={label} />
        </span>
      </SelectedPlanProp>
      <SelectedPlanProp>
        <FormattedMessage
          id="settings.billing.selectPlan.modal.subtotal.label"
          defaultMessage="Subtotal"
        />
        <span className="font-weight-bold">${price / 100}</span>
      </SelectedPlanProp>
      <SelectedPlanProp>
        <FormattedMessage
          id="settings.billing.selectPlan.modal.frequency.label"
          defaultMessage="Plan selected"
        />
        <span className="font-weight-bold">
          <FormattedMessage id="settings.billing.plan.period.monthly" defaultMessage="Monthly" />
        </span>
      </SelectedPlanProp>
      <SelectedPlanProp>
        <FormattedMessage
          id="settings.billing.selectPlan.modal.total.label"
          defaultMessage="Total"
        />
        <span className="font-weight-bold">${price / 100}</span>
      </SelectedPlanProp>
      <Footer />
    </Modal>
  )
}

UpgradePlanModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handlePlanUpgrade: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object.isRequired,
}

export default UpgradePlanModal
