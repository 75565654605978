import React, { Fragment, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ChangePasswordIcon from 'assets/images/icons/ic-change-password.svg'
import { Button } from 'components'
import { Form } from 'antd'
import { SecurityRow } from '../security.styled'
import ChangePasswordModal from './ChangePasswordModal'
import { updatePassword } from '../../Auth/auth.actions'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const { loading } = useSelector(state => state.auth)

  useEffect(() => {
    if (!loading) {
      setVisible(false)
    }
  }, [loading])

  const handleSubmit = () => {
    form.submit()
  }

  const onFinish = values => {
    dispatch(
      updatePassword({
        oldPassword: values.oldPassword,
        newPassword: values.password,
      }),
    )
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Fragment>
      <SecurityRow>
        <div className="d-flex">
          <img src={ChangePasswordIcon} alt="Change password icon" />

          <div className="d-flex flex-column ml-4">
            <span className="font-weight-bold">
              <FormattedMessage
                id="settings.security.changePassword.title"
                defaultMessage="Change Password"
              />
            </span>
            <FormattedMessage
              id="settings.security.changePassword.subTitle"
              defaultMessage="Here you can change your password"
            />
          </div>
        </div>

        <Button type="primary" onClick={() => setVisible(true)}>
          <FormattedMessage
            id="settings.security.changePassword.btn"
            defaultMessage="Change Password"
          />
        </Button>
      </SecurityRow>

      <ChangePasswordModal
        loading={loading}
        form={form}
        visible={visible}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        onFinish={onFinish}
      />
    </Fragment>
  )
}

export default ChangePassword
