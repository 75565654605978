import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { register, registerByInvitation, validateInvitationToken, logout } from '../auth.actions'
import * as routerConstants from '../../App/app.router.constants'

import RegisterForm from '../components/RegisterForm'
import RegisterSuccess from '../components/RegisterSuccess'

const Register = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, registerSuccess, invitationData } = useSelector(state => state.auth)
  const { token } = queryString.parse(location.search)

  useEffect(() => {
    if (token) {
      dispatch(logout(true))
      dispatch(validateInvitationToken({ token }))
    }
  }, [token])

  const onFinish = values => {
    if (!token) {
      dispatch(register(values))
    } else {
      dispatch(
        registerByInvitation({
          role: invitationData.role,
          invitationId: invitationData.id,
          accountId: invitationData.accountId,
          email: invitationData.email,
          ...values,
        }),
      )
    }
  }

  useEffect(() => {
    if (token && registerSuccess) {
      history.push(routerConstants.LOGIN_ROUTE)
    }
  }, [registerSuccess])

  return !registerSuccess ? (
    <RegisterForm
      token={token}
      email={invitationData.email}
      loading={loading}
      onFinish={onFinish}
    />
  ) : (
    <RegisterSuccess />
  )
}

export default Register
