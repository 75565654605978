import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Button, Container, HorizontalSeparator, PageTitle } from '../../components'
import WidgetsList from './components/WidgetsList'
import WidgetFormModal from './components/WidgetFormModal'
import WidgetDeleteConfirmModal from './components/WidgetDeleteConfirmModal'

const Widgets = () => {
  const [visible, setVisible] = useState(false)
  const [visibleDeleteWidget, setVisibleDeleteWidget] = useState(false)
  const [widgetToEdit, setWidgetToEdit] = useState(null)

  const handleSubmit = () => false

  const handleEditWidget = item => {
    setWidgetToEdit(item)
    setVisible(true)
  }

  const handleCreateWidget = () => {
    setWidgetToEdit(null)
    setVisible(true)
  }

  const handleDeleteWidgetModal = () => {
    setVisibleDeleteWidget(true)
  }

  const handleDeleteWidget = () => {
    setVisibleDeleteWidget(false)
  }

  return (
    <Container>
      <Helmet title="Widgets list" />
      <PageTitle>
        <FormattedMessage id="widgets.list.title" defaultMessage="Widgets" />
      </PageTitle>
      <HorizontalSeparator />
      <Button type="primary" onClick={handleCreateWidget}>
        <FormattedMessage id="widgets.list.button.create" defaultMessage="Create widget" />
      </Button>
      <WidgetFormModal
        widget={widgetToEdit}
        handleSubmit={handleSubmit}
        visible={visible}
        setVisible={setVisible}
      />
      <WidgetDeleteConfirmModal
        visible={visibleDeleteWidget}
        setVisible={setVisibleDeleteWidget}
        handleDelete={handleDeleteWidget}
      />
      <WidgetsList editWidget={handleEditWidget} deleteWidget={handleDeleteWidgetModal} />
    </Container>
  )
}

export default Widgets
