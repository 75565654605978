import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormItemWrapper, HorizontalSeparator } from 'components'
import { Title } from '../preSaleCheckout.styled'

const AccountDetails = ({ form }) => {
  return (
    <Fragment>
      <Title>
        <FormattedMessage id="preSaleCheckout.account.details" defaultMessage="Account Details" />
      </Title>
      <HorizontalSeparator />
      <div className="row">
        <div className="col-12">
          <FormItemWrapper name="companyName" required type="string" form={form} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper name="firstName" required type="string" form={form} />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper name="lastName" required type="string" form={form} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper name="email" required type="email" form={form} />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper name="phone" required type="phone" form={form} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper name="password" type="password" required form={form} />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <FormItemWrapper
            name="confirmPassword"
            type="confirmPassword"
            required
            dependencies={['password']}
            form={form}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default AccountDetails
