import { all, call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import * as constants from './billing.constants'
import * as api from './billing.api'
import * as notificationsConstants from '../Notifications/notifications.constants'
import * as routerConstants from '../App/app.router.constants'

export function* getPaymentPlans() {
  try {
    const response = yield call(api.getPaymentPlans)
    yield put({ type: constants.GET_PAYMENT_PLANS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PAYMENT_PLANS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.paymentPlans.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.paymentPlans.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updatePaymentMethod({ payload }) {
  try {
    const response = yield call(api.updatePaymentMethod, payload)
    yield put({ type: constants.UPDATE_PAYMENT_METHOD_SUCCESS, payload: response })
    yield put(push(routerConstants.SETTINGS_BILLING_ROUTE))
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.paymentMethod.plan.success.message`,
        descriptionId: `notification.settings.billing.paymentMethod.plan.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.UPDATE_PAYMENT_METHOD_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.paymentMethod.update.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.paymentMethod.update.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getPaymentMethods() {
  try {
    const response = yield call(api.getPaymentMethods)
    yield put({ type: constants.GET_PAYMENT_METHODS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_PAYMENT_METHODS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.paymentMethod.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.paymentMethod.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getInvoices() {
  try {
    const response = yield call(api.getInvoices)
    yield put({ type: constants.GET_INVOICES_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_INVOICES_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.invoices.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.invoices.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getInvoice({ id }) {
  try {
    const response = yield call(api.getInvoice, id)
    yield put({ type: constants.GET_INVOICE_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_INVOICE_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.invoice.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.invoice.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.GET_PAYMENT_PLANS, getPaymentPlans)])
  yield all([takeEvery(constants.UPDATE_PAYMENT_METHOD, updatePaymentMethod)])
  yield all([takeEvery(constants.GET_PAYMENT_METHODS, getPaymentMethods)])
  yield all([takeEvery(constants.GET_INVOICES, getInvoices)])
  yield all([takeEvery(constants.GET_INVOICE, getInvoice)])
}
