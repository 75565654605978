import * as constants from './apiKeys.constants'

export function createApiKey(payload) {
  return { type: constants.API_KEY_CREATE, payload }
}

export function updateApiKey(payload) {
  return { type: constants.API_KEY_UPDATE, payload }
}

export function deleteApiKey(payload) {
  return { type: constants.API_KEY_DELETE, payload }
}

export function getApiKeys() {
  return { type: constants.GET_API_KEYS }
}
