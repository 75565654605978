import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'
import { Helmet } from 'react-helmet'
import { Button } from 'components'
import { PageTitle } from '../../ApiKeys/apiKeys.styled'
import { SubContent, ContentFooter, ContentHeader } from '../../Settings/settings.styled'

import OrganizationForm from '../components/OrganizationForm'
import AddPaymentMethodFrom from '../../Billing/components/AddPaymentMethodFrom'
import SelectPaymentPlanForm from '../../Billing/components/SelectPaymentPlanForm'

import { createOrganization } from '../organization.actions'
import { getPaymentPlans } from '../../Billing/billing.actions'
import { getInvoicePaymentPlans } from '../../Billing/billing.selectors'
import { DEFAULT_SELECTED_PAYMENT_PLAN_INDEX } from '../../Billing/billing.constants'
import { preparePaymentMethod } from '../../Billing/billing.utils'

const OrganizationCreate = ({ loading }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [planId, setPlanId] = useState('')
  const { paymentPlans, paymentPlansLoading } = useSelector(getInvoicePaymentPlans)

  useEffect(() => {
    dispatch(getPaymentPlans())
  }, [dispatch])

  useEffect(() => {
    if (paymentPlans.length) {
      setPlanId(paymentPlans[DEFAULT_SELECTED_PAYMENT_PLAN_INDEX].id)
    }
  }, [paymentPlans])

  const onFinish = values => {
    const payload = {
      account: values,
      paymentMethod: preparePaymentMethod(values),
      planId,
    }
    dispatch(createOrganization(payload))
  }

  const handlePlanSelect = selectedPlan => {
    setPlanId(selectedPlan.id)
  }

  const handleSubmit = () => form.submit()

  return (
    <div>
      <Helmet title="Settings - Organization Settings" />

      <Form
        form={form}
        name="checkout"
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        hideRequiredMark
        initialValues={{
          phoneNumberPrefix: '1',
        }}
      >
        <ContentHeader>
          <PageTitle>
            <FormattedMessage
              id="settings.organization.title"
              defaultMessage="Organization Settings"
            />
          </PageTitle>
        </ContentHeader>

        <SubContent>
          <OrganizationForm form={form} />
        </SubContent>

        <ContentHeader>
          <PageTitle>
            <FormattedMessage
              id="settings.organization.paymentInfo"
              defaultMessage="Payment Information"
            />
          </PageTitle>
        </ContentHeader>
        <SubContent>
          <AddPaymentMethodFrom form={form} />
        </SubContent>
        <ContentHeader>
          <PageTitle>
            <FormattedMessage id="settings.organization.selectPlan" defaultMessage="Select Plan" />
          </PageTitle>
        </ContentHeader>
        <SubContent>
          <SelectPaymentPlanForm
            paymentPlans={paymentPlans}
            loading={paymentPlansLoading}
            selectedPlanId={planId}
            handlePlanSelect={handlePlanSelect}
          />
        </SubContent>
      </Form>
      <ContentFooter>
        <Button type="primary" loading={loading} onClick={handleSubmit}>
          <FormattedMessage id="form.item.button.save" defaultMessage="Save" />
        </Button>
      </ContentFooter>
    </div>
  )
}

export default OrganizationCreate
