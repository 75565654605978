import React from 'react'
import { Form, Select } from 'antd'
import { StyledInput } from '../../antd.styled'

const { Item } = Form
const { Option } = Select

const PhoneInput = ({ onChange, placeholder, name, value, disabled }) => {
  const prefixSelector = (
    <Item name="phoneNumberPrefix" noStyle>
      <Select disabled={disabled} onChange={prefix => onChange('phoneNumberPrefix', prefix)}>
        <Option value="1">+1</Option>
        <Option value="380">+380</Option>
      </Select>
    </Item>
  )

  return (
    <StyledInput
      value={value}
      addonBefore={prefixSelector}
      onChange={e => onChange(name, e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default PhoneInput
