import React, { useMemo, useEffect, useState } from 'react'
import { useElements, CardElement, Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StyledStripeCheckoutCardElement } from '../billing.styled'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, { locale: 'en' })

const useOptions = () => {
  return useMemo(
    () => ({
      style: {
        base: {
          color: '#424770',
          fontFamily: 'Assistant, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  )
}

const StripeCheckoutForm = ({ handleTokenCreateSuccess, formSubmitted, setCardError }) => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <StripeCheckoutCardElement
          formSubmitted={formSubmitted}
          handleTokenCreateSuccess={handleTokenCreateSuccess}
          setCardError={setCardError}
        />
      </Elements>
    </div>
  )
}

const StripeCheckoutCardElement = ({ handleTokenCreateSuccess, formSubmitted, setCardError }) => {
  const elements = useElements()
  const options = useOptions()
  const stripe = useStripe()
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    if (formSubmitted) {
      const fetchData = async () => {
        const payload = await stripe.createToken(elements.getElement(CardElement))
        handleTokenCreateSuccess(payload.token)
      }
      fetchData()
    }
  }, [formSubmitted])

  const handleCardElementChange = e => {
    setErrorMsg(e.error ? e.error.message : null)
    setCardError(e.error)
  }

  return (
    <StyledStripeCheckoutCardElement>
      <CardElement options={options} onChange={handleCardElementChange} />
      {errorMsg ? (
        <span className="ant-form-item-explain ant-form-item-explain-error">{errorMsg}</span>
      ) : null}
    </StyledStripeCheckoutCardElement>
  )
}

export default StripeCheckoutForm
