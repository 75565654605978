import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from 'styled-components'
import Router from 'router'
import Localization from 'features/App/app.localization'
import { GlobalStyles } from 'features/App/app.styled'
import * as serviceWorker from './serviceWorker'
import { SENTRY_DSN } from './config/sentry'
import configureStore from './configureStore'

import 'bootstrap-css-only/css/bootstrap.min.css'
import 'antd/dist/antd.css'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
})

const history = createBrowserHistory()
const store = configureStore(history)

// Set up for future theming
const theme = {}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Localization>
        <Router history={history} />
      </Localization>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.register()
export { store, history }
