import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as constants from './preSaleCheckout.constants'
import * as api from './preSaleCheckout.api'
import * as notificationsConstants from '../Notifications/notifications.constants'

export function* postPreSaleCheckout({ payload }) {
  try {
    const response = yield call(api.preSaleCheckout, payload)
    yield put({ type: constants.PRE_SALE_CHECKOUT_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.PRE_SALE_CHECKOUT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `auth.preSaleCheckout.error.${err.response.data.error.type}.message`,
        descriptionId: `auth.preSaleCheckout.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.PRE_SALE_CHECKOUT, postPreSaleCheckout)])
}
