import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { StyledFooter } from '../identity.styled'
import DecisionModal from './DecisionModal'
import { IDENTITY_ENUM, IDENTITY_STATUSES } from '../identity.constants'

const IdentityDetailsFooter = ({
  acceptLoading,
  denyLoading,
  handleAccept,
  handleDeny,
  isAcceptAllowed,
  isDeclineAllowed,
  type,
}) => {
  const [decisionType, setDecisionType] = useState(null)
  const [decisionModalVisible, setDecisionModalVisible] = useState(false)

  useEffect(() => {
    if (!acceptLoading || !denyLoading) {
      setDecisionModalVisible(false)
    }
  }, [acceptLoading, denyLoading])

  const handleAcceptClick = () => {
    setDecisionType(IDENTITY_STATUSES.ACCEPT)
    setDecisionModalVisible(true)
  }
  const handleDeclineClick = () => {
    setDecisionType(IDENTITY_STATUSES.DENY)
    setDecisionModalVisible(true)
  }

  const handleModalCancel = () => setDecisionModalVisible(false)

  const handleModalSubmit = note => {
    if (decisionType === IDENTITY_STATUSES.ACCEPT) {
      handleAccept(note)
    }
    if (decisionType === IDENTITY_STATUSES.DENY) {
      handleDeny(note)
    }
  }

  return (
    <StyledFooter>
      {!isAcceptAllowed && !isDeclineAllowed && type === IDENTITY_ENUM.KYB ? (
        <FormattedMessage
          id="identity.details.footer.kyb.disabled.note"
          defaultMessage="Please approve or decline all associated KYCs before continuing"
        />
      ) : null}

      <Button
        disabled={acceptLoading || !isAcceptAllowed}
        loading={acceptLoading}
        onClick={handleAcceptClick}
        type="success"
      >
        <FormattedMessage id="identity.details.footer.approve.btn" defaultMessage="Approve" />
      </Button>
      <Button
        disabled={denyLoading || !isDeclineAllowed}
        loading={denyLoading}
        type="danger"
        onClick={handleDeclineClick}
      >
        <FormattedMessage id="identity.details.footer.decline.btn" defaultMessage="Decline" />
      </Button>

      <DecisionModal
        decisionType={decisionType}
        visible={decisionModalVisible}
        handleSubmit={handleModalSubmit}
        handleCancel={handleModalCancel}
        loading={acceptLoading || denyLoading}
      />
    </StyledFooter>
  )
}

IdentityDetailsFooter.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  handleDeny: PropTypes.func.isRequired,
}

export default IdentityDetailsFooter
