import styled from 'styled-components'
import { Collapse } from 'antd'
import { BaseTable, Button, TabPane, Text, Input } from 'components'
import { getHistoryItemStatusColors } from 'components/common.styled'

export const StyledTable = styled(BaseTable)`
  margin-top: 20px;

  .ant-table-tbody tr:hover {
    cursor: pointer;
  }
`

export const StyledSearchInputsGroup = styled.div`
  width: 100%;

  .ant-input-group-wrapper {
    padding-right: 0 !important;
  }
  .ant-select {
    align-items: center;
    min-width: 120px;
    display: flex;

    margin: 0 !important;

    font-family: 'Assistant', sans-sesrif, serif !important;
    font-size: 14px;
    height: 42px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px rgb(217, 217, 217);
    background-color: #ffffff;
    border-right: 0;
  }

  @media (max-width: 640px) {
    width: 100%;

    .ant-input-group-addon {
      width: 100%;
      display: block;
    }
    .ant-input-group-wrapper {
      height: auto;
    }
    .ant-select {
      border-right: solid 1px rgb(217, 217, 217);
    }
    .ant-input-affix-wrapper {
      margin-top: 5px;
    }
  }
`

export const StyledSearchInput = styled(Input)`
  width: 100%;
  height: 42px;
  padding-right: 4px !important;

  input.ant-input {
    height: 32px !important;
    padding-left: 0 !important;
    border: none !important;
  }

  .ant-input-group-addon {
    padding: 0;
    border: none;
  }
`

export const StyledHeader = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${Button} {
    min-width: auto;
    width: 24px;
    height: 24px;
    margin-right: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
  .ant-skeleton {
    max-width: 120px;

    span {
      width: 100%;
    }
  }
`

export const StyledFooter = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 75px;
  padding: 0 36px;

  position: sticky;
  bottom: 0;

  width: 100%;

  box-shadow: 0 0 16px 0 #e4e9f0;
  border: solid 1px #e4e9f0;
  background-color: #ffffff;

  button {
    margin-left: 15px;
  }

  @media (max-width: 640px) {
    padding: 0;
    justify-content: center;
  }
`

export const StyledDetailsContainer = styled.div`
  height: 93vh;
`

export const StyledSubCollapse = styled(Collapse)`
  .ant-collapse-header {
    background-color: rgb(243, 245, 248, 0.4);
    font-size: 14px;
    height: 52px;
    padding: 15px 40px 15px 16px !important;
  }
  .ant-collapse-content-box {
    padding: 16px !important;
    min-height: 200px;
  }
  .ant-collapse-content-active {
    .ant-collapse-content-box {
      border-bottom: solid 1px #e4e9f0;
    }
  }
  .ant-collapse-item {
    border: none !important;

    &:last-child {
      &:not(.ant-collapse-item-active) {
        .ant-collapse-header {
          border-bottom: none;
        }
      }
      .ant-collapse-content-active {
        .ant-collapse-content-box {
          border-bottom: none;
        }
      }
    }
  }
`

export const StyledDataFieldListContainer = styled.div`
  padding: 20px;

  .ant-btn.ant-btn-link {
    width: auto;
    min-width: auto;
    height: auto;
  }

  @media (max-width: 540px) {
    .row > div:first-child {
      margin-bottom: 10px;
    }
  }
`

export const StyledDataField = styled.div`
  font-size: 12px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledLinkedApplication = styled.div`
  margin: 0 10px 10px;
  border: solid 1px #e4e9f0;
`

export const StyledLinkedApplicationsContainer = styled.div`
  padding-top: 10px;
  max-height: 853px;
  overflow-y: auto;

  .row {
    margin: 0;

    > div {
      padding: 0;
    }

    > div:nth-child(2n + 1) ${StyledLinkedApplication} {
      margin-right: 0;
    }
  }

  @media (max-width: 991px) {
    .row > div ${StyledLinkedApplication} {
      margin-right: 10px !important;
    }
  }
`

export const StyledLinkedApplicationHeader = styled.div`
  height: 52px;
  border-radius: 3px;
  background-color: #f3f5f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
`

export const StyledLinkedApplicationBody = styled.div`
  padding: 15px;
`

export const StyledHistoryRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 71px;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #e4e9f0;

  > div {
    margin-left: 16px;
  }
`

export const StyledHistoryDocumentRow = styled(StyledHistoryRow)`
  height: 54px;
  justify-content: space-between;

  ${Button} {
    margin-left: 16px;

    span {
      font-size: 16px;
      color: #524d6a;
      width: 276px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  button {
    background: transparent;
    border: none;

    &:active,
    &:focus {
      outline: none !important;
    }
  }
  .ant-spin {
    margin-top: 10px;
    height: 27px;
  }
`

export const StyledHistoryRowStatus = styled.span`
  color: ${props => getHistoryItemStatusColors(props.status).primary};
  text-transform: uppercase;
  font-weight: bold;
`

export const StyledNotesScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledNotesRow = styled.div`
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #e4e9f0;
  font-size: 16px;
  width: 100%;

  .note-email {
    color: #1890ff;
  }
  > span {
    font-size: 12px;
  }
`

export const StyledNotesInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
  background: white;
  border-bottom: 1px solid #e4e9f0;

  button {
    height: 32px;
  }
`

export const StyledHistoryTabPane = styled(TabPane)`
  max-height: 319px;
  height: 457px;
  overflow-y: scroll;
`

export const StyledUploadDocumentButton = styled.div`
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #e4e9f0;

  > button {
    width: 100%;
  }
`

export const StyledAdditionalDataPanel = styled.div`
  width: 100%;
  height: auto;
  flex: 1 1 auto;
`

export const StyledAdditionalDataPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 12px 0;

  &:last-child {
    padding-bottom: 12px;
  }
`

export const StyledApiDataContainer = styled.div`
  .row {
    margin: 0;

    > div {
      padding: 0;

      > div {
        margin: 0 10px 10px;
      }
    }

    > div:nth-child(1) > div,
    > div:nth-child(2) > div {
      margin-right: 0;
    }
    > div:last-child > div {
      margin-right: 10px !important;
    }
  }
  border-bottom: ${({ bordered }) => (bordered ? '1px solid #e4e9f0' : 'none')};

  &:first-child {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    .row {
      > div > div {
        margin-right: 10px !important;
      }
    }
  }
`

export const StyledApiDataCheck = styled.div`
  height: 54px;
  border-radius: 4px;
  border: solid 1px #e4e9f0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 10px 0 10px 10px !important;
  justify-content: space-between;

  img {
    height: 13px;
    width: 13px;
    margin-right: 10px;
  }
`

export const StyledLinkedApplications = styled.div`
  max-width: 616px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 540px) {
    max-width: 100%;
  }
`

export const StyledDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`

export const StyledDetailsLeftColumn = styled.div`
  max-width: 412px;
  width: 100%;
  margin-right: 20px;

  @media (max-width: 540px) {
    max-width: 100%;
  }
`

export const StyledCustomerDetailsLeftColumn = styled.div`
  //max-width: 512px;
  max-width: ${props => (props.fullWidth ? 'auto' : '512px')};
  width: 100%;
  //margin-right: 20px;
  margin-right: ${props => (props.fullWidth ? '0' : '20px')};

  @media (max-width: 540px) {
    max-width: 100%;
  }
`

export const StyledDetailsBlock = styled.div`
  margin-bottom: 20px;
`

export const StyledApplicationHistoryContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 540px) {
    max-width: 100%;
  }
`

export const StyledGovernmentIssuedID = styled.div`
  max-width: 512px;

  ${StyledSubCollapse} {
    .ant-collapse-content-box {
      min-height: auto;
    }
  }
  .row {
    margin: 0;

    > div {
      padding: 0 10px 0 0;
    }

    > div:last-child {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .row {
      > div {
        padding: 0;
      }
    }
  }
`
