import { all } from 'redux-saga/effects'

import app from 'features/App/app.sagas'
import auth from 'features/Auth/auth.sagas'
import apiKeys from 'features/ApiKeys/apiKeys.sagas'
import identity from 'features/Identity/identity.sagas'
import organization from 'features/Organization/organization.sagas'
import billing from 'features/Billing/billing.sagas'
import userProfile from 'features/UserProfile/userProfile.sagas'
import preSaleCheckout from 'features/PreSaleCheckout/preSaleCheckout.sagas'
import dashboard from 'features/Dashboard/dashboard.sagas'

export default function* rootSaga() {
  yield all([
    app(),
    apiKeys(),
    identity(),
    auth(),
    organization(),
    billing(),
    userProfile(),
    preSaleCheckout(),
    dashboard(),
  ])
}
