import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { forgotPassword } from '../auth.actions'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import ForgotPasswordSuccess from '../components/ForgotPasswordSuccess'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, forgotPasswordSuccess } = useSelector(state => state.auth)

  const onFinish = values => {
    dispatch(forgotPassword(values))
  }

  return !forgotPasswordSuccess ? (
    <ForgotPasswordForm
      retry={location.state ? location.state.retry : false}
      onFinish={onFinish}
      loading={loading}
    />
  ) : (
    <ForgotPasswordSuccess />
  )
}

export default ForgotPassword
