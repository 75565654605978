import DashboardIcon from 'assets/images/icons/ic-dashboard.svg'
import IdentityIcon from 'assets/images/icons/ic-identity.svg'
import AccountSettingsIcon from 'assets/images/icons/ic-account-settings.svg'
// import HelpIcon from 'assets/images/icons/ic-help.svg'
import LogoutIcon from 'assets/images/icons/ic-logout.svg'
// import WidgetsIcon from 'assets/images/icons/ic-widgets.svg'

import {
  DASHBOARD_ROUTE,
  IDENTITY_BUSINESS_ROUTE,
  IDENTITY_CUSTOMER_ROUTE,
  SETTINGS_PROFILE_ROUTE,
} from './app.router.constants'

export const APP_MENU_ITEMS_ENUM = {
  DASHBOARD: 'dashboard',
  IDENTITY: 'identity',
  IDENTITY_CUSTOMER: 'identity_customer',
  IDENTITY_BUSINESS: 'identity_business',
  WIDGETS: 'widgets',
  KYC: 'kyc',
  SETTINGS: 'settings',
  HELP: 'help',
  FAQS: 'faqs',
  DOC: 'doc',
  LOGOUT: 'logout',
}

export const APP_MENU_DEFAULT_SELECTED_KEY = 'profile'

export const APP_MENU_ENUM = [
  {
    route: DASHBOARD_ROUTE,
    key: APP_MENU_ITEMS_ENUM.DASHBOARD,
    defaultMessage: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    key: APP_MENU_ITEMS_ENUM.IDENTITY,
    defaultMessage: 'Identity',
    icon: IdentityIcon,
    subRoutes: [
      {
        route: IDENTITY_CUSTOMER_ROUTE,
        key: APP_MENU_ITEMS_ENUM.IDENTITY_CUSTOMER,
        defaultMessage: 'Customer',
      },
      {
        route: IDENTITY_BUSINESS_ROUTE,
        key: APP_MENU_ITEMS_ENUM.IDENTITY_BUSINESS,
        defaultMessage: 'Business',
      },
    ],
  },
  // {
  //   route: WIDGETS_ROUTE,
  //   key: APP_MENU_ITEMS_ENUM.WIDGETS,
  //   defaultMessage: 'Widgets',
  //   icon: WidgetsIcon,
  // },
  {
    route: SETTINGS_PROFILE_ROUTE,
    key: APP_MENU_ITEMS_ENUM.SETTINGS,
    defaultMessage: 'Settings',
    icon: AccountSettingsIcon,
  },
  // {
  //   key: APP_MENU_ITEMS_ENUM.HELP,
  //   defaultMessage: 'Help',
  //   icon: HelpIcon,
  //   subRoutes: [
  //     {
  //       route: DASHBOARD_FAQ_ROUTE,
  //       key: APP_MENU_ITEMS_ENUM.FAQS,
  //       defaultMessage: 'FAQs',
  //     },
  //     {
  //       route: DASHBOARD_DOCUMENTATION_ROUTE,
  //       key: APP_MENU_ITEMS_ENUM.DOC,
  //       defaultMessage: 'Doc',
  //     },
  //   ],
  // },
  {
    key: APP_MENU_ITEMS_ENUM.LOGOUT,
    defaultMessage: 'Logout',
    icon: LogoutIcon,
  },
]
