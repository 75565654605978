import React from 'react'

const COST_DIVIDER = 100
const COST_CURRENCY = '$'

const CostText = ({ value }) => {
  if (!value) return null
  const result = (Number(value) / COST_DIVIDER).toFixed(2)
  return result >= 0 ? (
    <span>
      {COST_CURRENCY}
      {result}
    </span>
  ) : (
    <span>
      -{COST_CURRENCY}
      {Math.abs(result).toFixed(2)}
    </span>
  )
}

export default CostText
