import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  ApiKeyPermissionDescription,
  ApiKeyPermissionRow,
  SubTitle,
} from 'features/ApiKeys/apiKeys.styled'
import { FormattedMessage } from 'react-intl'

const ApiKeyPermissions = ({ type, icon, handleClick, selected }) => {
  return (
    <ApiKeyPermissionRow
      data-testid={`settings.apiKey.permission.row.${type}`}
      className={classNames({ selected })}
      onClick={() => handleClick(type)}
    >
      <SubTitle>
        <img className="mr-3" src={icon} alt="Api key permission icon" />
        <FormattedMessage
          id={`settings.apiKey.permission.type.${type}.title`}
          defaultMessage={type}
        />
      </SubTitle>
      <ApiKeyPermissionDescription>
        <FormattedMessage
          id={`settings.apiKey.permission.type.${type}.description`}
          defaultMessage={type}
        />
      </ApiKeyPermissionDescription>
    </ApiKeyPermissionRow>
  )
}

ApiKeyPermissions.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default ApiKeyPermissions
