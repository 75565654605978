import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { PageTitle } from 'features/Settings/settings.styled'
import { TransactionHistoryContainer } from '../billing.styled'
import { StyledTable } from '../../Identity/identity.styled'
import { MOMENT_DATE_TIME_FORMAT } from '../../App/app.constants'
import { CostText } from '../../../components'

const columns = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => {
      return <span>{moment(value).format(MOMENT_DATE_TIME_FORMAT)}</span>
    },
  },
  {
    title: 'Amount',
    dataIndex: 'price',
    key: 'price',
    render: value => {
      return <CostText value={value} />
    },
  },
]

const InvoiceDetailsPaymentsTable = ({ paymentsList, loading }) => {
  const subTotal = paymentsList.reduce((acc, val) => {
    return acc + val.price
  }, 0)

  const Footer = (
    <div className="d-flex flex-row justify-content-between">
      <div className="font-weight-bold">
        <FormattedMessage
          id="settings.billing.invoice.details.subtotal"
          defaultMessage="Subtotal"
        />
      </div>
      <div className="font-weight-bold">
        <CostText value={subTotal} />
      </div>
    </div>
  )

  return (
    <TransactionHistoryContainer className="ml-0">
      <PageTitle>
        <FormattedMessage
          id="settings.billing.invoice.details.payments"
          defaultMessage="Payments"
        />
      </PageTitle>
      <StyledTable
        rowKey="id"
        className="utils__scrollTable"
        size="default"
        pagination={false}
        dataSource={paymentsList}
        columns={columns}
        footer={() => Footer}
        bordered
        loading={loading}
      />
    </TransactionHistoryContainer>
  )
}

export default InvoiceDetailsPaymentsTable
