import React from 'react'
import { Modal } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Button } from '../index'

const ImagePreview = ({ visible, imageUrl, setVisible, handleImageUpdate }) => (
  <Modal
    centered
    visible={visible}
    onCancel={() => setVisible(false)}
    title={<FormattedMessage id="image.preview.modal.title" defaultMessage="ID Preview" />}
    footer={
      false && [
        <Button key="submit" type="primary" loading={false} onClick={handleImageUpdate}>
          <FormattedMessage
            id="image.preview.modal.changeImage"
            defaultMessage="Upload new Image"
          />
        </Button>,
      ]
    }
  >
    <img src={`data:image/jpg;base64,${imageUrl}`} alt="ID preview" style={{ width: '100%' }} />
  </Modal>
)

export default ImagePreview
