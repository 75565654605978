// eslint-disable-next-line import/prefer-default-export
export const WIDGETS_TABLE_DATA = [
  {
    key: '1',
    id: '000112',
    name: 'Test widget 1',
    type: 'KYC',
    totalViews: 550,
    started: 4,
    completed: 250,
    acceptance_percent: 80,
  },
  {
    key: '2',
    id: '000113',
    name: 'Test widget 2',
    type: 'KYB',
    totalViews: 550,
    started: 4,
    completed: 250,
    acceptance_percent: 80,
  },
  {
    key: '4',
    id: '000114',
    name: 'Test widget 3',
    type: 'KYB',
    totalViews: 350,
    started: 4,
    completed: 250,
    acceptance_percent: 80,
  },
]
