import * as constants from './auth.constants'

const initialState = {
  user: {},
  loggedIn: false,
  loading: false,
  invitationData: {},
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER_LOGIN:
    case constants.USER_REGISTER:
    case constants.USER_RESET_PASSWORD:
    case constants.USER_VALIDATE_EMAIL:
    case constants.USER_REGISTER_BY_INVITATION:
    case constants.UPDATE_PASSWORD:
    case constants.TOGGLE_USER_NOTIFICATIONS:
    case constants.USER_FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
      }
    }
    case constants.TOGGLE_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loading: false,
      }
    }
    case constants.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case constants.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
        loading: false,
      }
    }
    case constants.USER_REGISTER_BY_INVITATION_SUCCESS:
    case constants.USER_REGISTER_SUCCESS: {
      return {
        ...state,
        registerSuccess: true,
        loading: false,
      }
    }
    case constants.VALIDATE_INVITATION_TOKEN_SUCCESS: {
      return {
        ...state,
        invitationData: action.payload,
      }
    }
    case constants.USER_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordSuccess: true,
        loading: false,
      }
    }
    case constants.USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        user: {},
        loggedIn: false,
        loading: false,
      }
    }
    case constants.USER_VALIDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case constants.USER_LOGOUT: {
      return {
        ...state,
        user: {},
        loggedIn: false,
      }
    }
    case constants.GET_LOGGED_IN_USER_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      }
    }
    case constants.SET_USER_ACCOUNT_ID_SUCCESS:
    case constants.SET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      }
    }

    case constants.USER_LOGIN_ERROR:
    case constants.USER_REGISTER_ERROR:
    case constants.USER_VALIDATE_EMAIL_ERROR:
    case constants.USER_FORGOT_PASSWORD_ERROR:
    case constants.GET_LOGGED_IN_USER_ERROR:
    case constants.USER_RESET_PASSWORD_ERROR:
    case constants.SET_USER_ACCOUNT_ID_ERROR:
    case constants.SET_USER_PROFILE_ERROR:
    case constants.VALIDATE_INVITATION_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default auth
