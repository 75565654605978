import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const StyledUserProfileFormContainer = styled.div`
  display: block;
  padding-right: 15px;

  @media (min-width: 767px) {
    .row {
      > div:last-child {
        padding-right: 0;
      }
    }
  }

  @media (max-width: 667px) {
    flex-direction: column;
    align-items: center;
  }
`
