import * as constants from './app.constants'

const initialState = {
  notifications: {},
}

const billing = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      }
    case constants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      }
    case constants.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default billing
