import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components'

const ApiKeysTableEditableColumn = ({ value, item, editingItemId, handleNameChange }) => {
  return (
    <Fragment>
      {editingItemId !== item.id ? (
        <div className="font-weight-bold">{value}</div>
      ) : (
        <Input
          onChange={handleNameChange}
          placeholder="API Key Name"
          size="default"
          type="text"
          defaultValue={value}
          className="w-100"
        />
      )}
    </Fragment>
  )
}

ApiKeysTableEditableColumn.propTypes = {
  value: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  editingItemId: PropTypes.string.isRequired,
  handleNameChange: PropTypes.func.isRequired,
}

export default ApiKeysTableEditableColumn
