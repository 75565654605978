import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { Title, LoginContainerTransparent } from '../auth.styled'
import * as routerConstants from '../../App/app.router.constants'

const ForgotPasswordSuccess = () => {
  const history = useHistory()

  return (
    <LoginContainerTransparent>
      <Helmet title="Verify your email" />

      <Title>
        <FormattedMessage
          id="auth.forgotPassword.success.title"
          defaultMessage="We have sent a reset link to your email address. "
        />
      </Title>

      <Title>
        <FormattedMessage
          id="auth.forgotPassword.success.description"
          defaultMessage="Please check your email to continue the password reset process"
        />
      </Title>
      <Button
        type="link"
        block
        className="w-100 text-white mt-2"
        onClick={() => history.push(routerConstants.LOGIN_ROUTE)}
      >
        <FormattedMessage id="verify.loginLink" defaultMessage="Login" />
      </Button>
    </LoginContainerTransparent>
  )
}

export default ForgotPasswordSuccess
