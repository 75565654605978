import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Content, ContentHeader, PageTitle, SubTitle } from 'features/Settings/settings.styled'
import ChangePassword from './components/ChangePassword'
import Notifications from './components/Notifications'
import DeactivateAccount from './components/DeactivateAccount'
import { ORGANIZATIONS_STATUSES_ENUM } from '../Organization/organization.constants'
import { deactivateAccount } from '../Organization/organization.actions'
import { toggleUserNotification } from '../Auth/auth.actions'

const Security = () => {
  const dispatch = useDispatch()
  const { organization, loadingStatusUpdate } = useSelector(state => state.organization)
  const { user, loading } = useSelector(state => state.auth)

  const handleConfirmDeactivate = () => {
    dispatch(
      deactivateAccount({
        status: ORGANIZATIONS_STATUSES_ENUM.DEACTIVATED,
      }),
    )
  }

  const handleNotificationsChange = val => {
    dispatch(toggleUserNotification({ receivesUpdates: val }))
  }

  return (
    <div>
      <Helmet title="Settings - Security" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.security.title" defaultMessage="Security Settings" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <SubTitle>
          <FormattedMessage id="settings.security.subTitle" defaultMessage="Account management" />
        </SubTitle>
        <ChangePassword />
        <Notifications
          loading={loading}
          handleNotificationsChange={handleNotificationsChange}
          receivesUpdates={user.receivesUpdates}
        />
        {false ? (
          <DeactivateAccount
            status={organization.status}
            loadingStatusUpdate={loadingStatusUpdate}
            handleConfirmDeactivate={handleConfirmDeactivate}
          />
        ) : null}
      </Content>
    </div>
  )
}

export default Security
