import styled from 'styled-components'
import { Button, Text } from 'components'
import { Content, PageTitle } from '../Settings/settings.styled'

export const Card = styled.div`
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${props => (props.isSelected ? '#698aff' : '#e4e9f0')};

  &.p-0 > div {
    padding: 16px;
  }
`

export const CardText = styled(Text)`
  font-size: 16px;
`

export const CardHintText = styled(CardText)`
  font-size: 12px;
  margin-bottom: 5px;
`

export const MonthlyBillPriceContainer = styled(CardText)`
  height: 84px;
  background-image: linear-gradient(to left, #ffffff, #f2f4f8);
  font-weight: 600;
  color: #698aff;
`

export const MonthlyBillPrice = styled(Text)`
  font-size: 24px;
  color: #698aff;
  margin-bottom: 10px;
  font-weight: 800;
`

export const PaymentMethodRow = styled(Text)`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e9f0;
  margin-top: 15px;

  img {
    width: 60px;
    height: 40px;
  }
`

export const TransactionHistoryContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 37px;
  margin-left: 17px;

  .ant-table-row.ant-table-row-level-1 > td.ant-table-cell,
  .ant-table-footer {
    background-color: #f0f2f5 !important;
  }

  .total {
    border-radius: 11px;
    background-color: #1890ff;
    color: #ffffff;
    font-size: 12px;
    display: inline;
    padding: 2px 7px;
  }

  .ant-radio-group.ant-radio-group-solid {
    label.ant-radio-button-wrapper:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
    }
    label.ant-radio-button-wrapper:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }
    > .ant-radio-button-wrapper-checked {
      outline: none;
      background: #e4e9f0 !important;
      border-color: #e4e9f0 !important;
      font-weight: 600;
      color: #524d6a !important;

      &:before {
        background: #e4e9f0 !important;
      }
    }
  }
`

export const BillingContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;

    ${TransactionHistoryContainer} {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`

export const SelectPlanContainer = styled.div`
  ${Content} {
    min-height: auto;
  }
`

export const StyledSelectPaymentPlanForm = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-bottom: 15px;

  ${Card} {
    width: 100%;
    margin-right: 8px;
    transition: box-shadow 0.2s ease-in-out;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

      ${Button} {
        background: #1890ff;
        color: white;
      }
    }

    ${MonthlyBillPriceContainer} {
      color: #524d6a;
      font-weight: normal;
      font-size: 16px;
    }

    @media (max-width: 991px) {
      max-width: none;
      width: 100%;
    }
  }
`

export const SelectedPlanProp = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e9f0;

  &:last-child {
    border: none;
  }
`

export const InvoiceDetailsHeader = styled(Text)`
  padding-top: 36px;
  padding-bottom: 36px;
  border-bottom: 1px solid #e4e9f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${PageTitle} {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 16px;
  }
`

export const InvoiceDetailsFooter = styled(Text)`
  padding-top: 36px;
  border-top: 1px solid #e4e9f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InvoiceDetailsHeaderInfo = styled(Text)`
  display: flex;
  flex-direction: row;
  height: 70px;

  > div:first-child {
    padding-right: 36px;
    border-right: 1px solid #e4e9f0;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  > div:last-child {
    padding-left: 36px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .invoice-details-name {
      font-size: 22px;
      font-weight: 800;
    }
  }
`

export const StyledStripeCheckoutCardElement = styled.div`
  .ant-form-item-explain-error {
    color: #ff4d4f;
    margin-top: 5px;
    display: block;
  }
`
