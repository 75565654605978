import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Radio } from 'antd'
import _ from 'lodash'
import { PageTitle } from 'features/Settings/settings.styled'
import { CostText } from 'components'
import moment from 'moment'
import { TransactionHistoryContainer } from '../billing.styled'
import { StyledTable } from '../../Identity/identity.styled'
import { MOMENT_DATE_TIME_FORMAT } from '../../App/app.constants'

const INVOICE_HISTORY_VIEW_TYPES = {
  SUMMARY: 'summary',
  DETAILS: 'details',
}

const InvoiceHistory = ({ kycList, kybList, loading }) => {
  const [viewType, setViewType] = useState(INVOICE_HISTORY_VIEW_TYPES.SUMMARY)
  const flatList = [...kycList, ...kybList]
  const groupedList = _.chain(flatList)
    .groupBy('label')
    .map((value, key) => {
      const { price } = value.reduce(
        (acc, val) => {
          return {
            price: acc.price + val.price,
          }
        },
        {
          price: 0,
        },
      )

      return {
        id: key,
        description: key,
        quantity: value.length,
        children: value,
        price,
      }
    })
    .value()

  const subTotal = flatList.reduce((acc, val) => {
    return acc + val.price
  }, 0)

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Unit Price',
      dataIndex: 'price',
      key: 'price',
      render: (value, record) => {
        return viewType === INVOICE_HISTORY_VIEW_TYPES.SUMMARY ? (
          <CostText
            value={record.children ? Math.abs(value) / record.children.length : Math.abs(value)}
          />
        ) : (
          <CostText value={Math.abs(value)} />
        )
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: value => {
        return <span>{value || '1'}</span>
      },
    },
    {
      title: 'Total',
      dataIndex: 'price',
      key: 'price',
      render: value => {
        return <CostText value={value} />
      },
    },
  ]

  if (viewType === INVOICE_HISTORY_VIEW_TYPES.DETAILS) {
    columns.splice(1, 0, {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => {
        return <span>{moment(value).format(MOMENT_DATE_TIME_FORMAT)}</span>
      },
    })
  }

  const Footer = (
    <div className="d-flex flex-row justify-content-between">
      <div className="font-weight-bold">
        <FormattedMessage
          id="settings.billing.invoice.details.subtotal"
          defaultMessage="Subtotal"
        />
      </div>
      <div className="font-weight-bold">
        <CostText value={subTotal} />
      </div>
    </div>
  )

  const handleViewTypeChange = e => {
    setViewType(e.target.value)
  }

  return (
    <TransactionHistoryContainer className="ml-0">
      <div className="d-flex flex-row justify-content-between 123">
        <PageTitle>
          <FormattedMessage
            id="settings.billing.invoice.details.charges"
            defaultMessage="Charges"
          />
        </PageTitle>
        <div>
          <Radio.Group
            defaultValue={INVOICE_HISTORY_VIEW_TYPES.SUMMARY}
            buttonStyle="solid"
            onChange={handleViewTypeChange}
          >
            <Radio.Button value={INVOICE_HISTORY_VIEW_TYPES.SUMMARY}>
              <FormattedMessage
                id="settings.billing.invoice.details.radio.summary"
                defaultMessage="Summary"
              />
            </Radio.Button>
            <Radio.Button value={INVOICE_HISTORY_VIEW_TYPES.DETAILS}>
              <FormattedMessage
                id="settings.billing.invoice.details.radio.detail"
                defaultMessage="Detail"
              />
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <StyledTable
        rowKey="id"
        className="utils__scrollTable"
        size="default"
        pagination={false}
        dataSource={viewType === INVOICE_HISTORY_VIEW_TYPES.SUMMARY ? groupedList : flatList}
        columns={columns}
        footer={() => Footer}
        bordered
        loading={loading}
      />
    </TransactionHistoryContainer>
  )
}

export default InvoiceHistory
