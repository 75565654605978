import React, { Fragment, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Helmet } from 'react-helmet'
import { Skeleton } from 'antd'
import { Content, ContentFooter, ContentHeader } from 'features/Settings/settings.styled'
import * as routerConstants from 'features/App/app.router.constants'
import { PageTitle } from 'features/ApiKeys/apiKeys.styled'
import { deleteApiKey, getApiKeys, updateApiKey } from 'features/ApiKeys/apiKeys.actions'
import { Button } from 'components'
import NoRecordsFound from './components/NoRecordsFound'
import ApiKeysTable from './components/ApiKeysTable'
import NoOrganizationWrapper from '../Organization/components/NoOrganizationWrapper'
import NoPermissionWrapper from '../Organization/components/NoPermissionWrapper'
import { MAX_API_KEYS_COUNT } from './apiKeys.constants'
import { USER_ROLES_ENUM } from '../Auth/auth.constants'

const roles = [USER_ROLES_ENUM.ADMIN, USER_ROLES_ENUM.DEVELOPER]

const ApiKeys = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { loading, list } = useSelector(state => state.apiKeys)
  const isUserHasPermissions = roles.indexOf(user.role) !== -1

  useEffect(() => {
    if (user.accountId && isUserHasPermissions) {
      dispatch(getApiKeys())
    }
  }, [dispatch, user.accountId, isUserHasPermissions])

  const handleDelete = id => {
    dispatch(deleteApiKey(id))
  }

  const handleSave = data => {
    dispatch(updateApiKey(data))
  }

  return (
    <div>
      <Helmet title="Settings - API Keys" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.apiKey.title" defaultMessage="API keys" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <NoPermissionWrapper roles={roles}>
          <NoOrganizationWrapper>
            {loading ? (
              <Skeleton active />
            ) : (
              <Fragment>
                {list.length ? (
                  <ApiKeysTable
                    data={list}
                    handleDeleteItem={handleDelete}
                    handleSaveItem={handleSave}
                  />
                ) : (
                  <NoRecordsFound />
                )}
              </Fragment>
            )}
          </NoOrganizationWrapper>
        </NoPermissionWrapper>
      </Content>
      {user.accountId && isUserHasPermissions ? (
        <ContentFooter>
          <Button
            disabled={list.length >= MAX_API_KEYS_COUNT}
            type="primary"
            onClick={() => history.push(routerConstants.SETTINGS_API_KEYS_CREATE_ROUTE)}
          >
            {list.length >= MAX_API_KEYS_COUNT ? (
              <FormattedMessage
                id="settings.apiKey.create.button.maxLimit"
                defaultMessage="5 API keys is maximum"
              />
            ) : (
              <FormattedMessage
                id="settings.apiKey.create.button"
                defaultMessage="Create an API Key"
              />
            )}
          </Button>
        </ContentFooter>
      ) : null}
    </div>
  )
}

export default ApiKeys
