import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { StyledDataField } from '../identity.styled'

const DataField = ({ field, value }) => (
  <StyledDataField>
    <span className="font-weight-bold">
      <FormattedMessage id={`identity.data.field.${field}`} defaultMessage={field} />:
    </span>
    &nbsp;
    <span>{value}</span>
  </StyledDataField>
)

DataField.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default DataField
