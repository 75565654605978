import React from 'react'
import LogoImage from 'assets/images/logo-white-large.svg'
import { Layout, Logo } from './auth.styled'
import AuthFooter from './components/AuthFooter'

const Auth = ({ children }) => {
  return (
    <Layout>
      <Logo src={LogoImage} alt="Charge Identity" />
      {children}
      <AuthFooter />
    </Layout>
  )
}

export default Auth
