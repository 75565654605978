import axios from 'axios'

export function login(payload) {
  return axios.post('/auth/login', payload).then(res => res.data)
}

export function register(payload) {
  const data = {
    ...payload,
    // TODO: hardcoded role
    role: 'admin',
  }
  return axios.post('/auth/register', data).then(res => res.data)
}

export function validateEmail(payload) {
  return axios
    .get(`/auth/validate?token=${payload.token}&email=${payload.email}`)
    .then(res => res.data)
}

export function forgotPassword(payload) {
  return axios.post('/auth/forgot', payload).then(res => res.data)
}

export function resetPassword(payload) {
  return axios.post('/auth/reset', payload).then(res => res.data)
}

export function validateInvitationToken(payload) {
  return axios.post(`/invitations/validate`, payload).then(res => res.data)
}

export function registerByInvitation(payload) {
  return axios.post('/invitations/register', payload).then(res => res.data)
}

export async function updatePassword(payload) {
  const res = await axios.post(`settings/password`, payload)
  return res.data
}

export async function toggleUserNotification(payload) {
  const res = await axios.post(`settings/notifications`, payload)
  return res.data
}
