import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Switch } from 'antd'
import { FormattedMessage } from 'react-intl'
import NotificationsIcon from 'assets/images/icons/ic-notifications.svg'
import { Button } from 'components'
import { SecurityRow, SecurityRowToggleContainer } from '../security.styled'

const Notifications = ({ receivesUpdates, handleNotificationsChange, loading }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!loading) {
      setVisible(false)
    }
  }, [loading])

  const handleChange = val => {
    if (!val) {
      setVisible(true)
    } else {
      handleNotificationsChange(true)
    }
  }

  const handleSubmit = () => {
    handleNotificationsChange(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Fragment>
      <SecurityRow>
        <div className="d-flex">
          <img src={NotificationsIcon} alt="Freeze icon" />

          <div className="d-flex flex-column ml-4">
            <span className="font-weight-bold">
              <FormattedMessage
                id="settings.security.notifications.title"
                defaultMessage="Charge Notifications"
              />
            </span>
            <FormattedMessage
              id="settings.security.notifications.subTitle"
              defaultMessage="Enable or Disable  news, promotions, and update emails from Charge."
            />
          </div>
        </div>

        <SecurityRowToggleContainer>
          <Switch checked={receivesUpdates} onChange={handleChange} />
        </SecurityRowToggleContainer>
      </SecurityRow>

      <Modal
        centered
        visible={visible}
        title={
          <FormattedMessage
            id="settings.security.notifications.modal.title"
            defaultMessage="Are you sure you want to turn off notifications?"
          />
        }
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            <FormattedMessage
              id="settings.security.notifications.modal.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            <FormattedMessage id="settings.security.notifications.modal.ok" defaultMessage="Okay" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="settings.security.notifications.modal.description"
          defaultMessage="You will no longer receive promotional emails from Charge Identity. We will always email regarding important information about your account and security."
        />
      </Modal>
    </Fragment>
  )
}

export default Notifications
