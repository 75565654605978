import axios from 'axios'
import store from 'store'
import * as routerConstants from 'features/App/app.router.constants'
import * as authConstants from 'features/Auth/auth.constants'
import { logout } from '../features/Auth/auth.actions'

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

const initInterceptors = (history, dispatch) => {
  axios.interceptors.request.use(config => {
    const user = store.get(authConstants.USER_STORE_FIELD_NAME)
    if (user && user.jwt) {
      config.headers.authorization = `JWT ${user.jwt}`
    }
    return config
  }, undefined)

  axios.interceptors.response.use(undefined, error => {
    const { status } = error.response
    const user = store.get(authConstants.USER_STORE_FIELD_NAME)

    if (status === 401 && user && user.jwt) {
      dispatch(logout())
      window.location.reload()
    }

    if (status === 403) {
      dispatch(logout())
    }

    if (status === 500) {
      history.push(routerConstants.ERROR_500_ROUTE)
    }

    return Promise.reject(error)
  })
}

export default initInterceptors
