import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import store from 'store'
import * as authConstants from '../../Auth/auth.constants'
import * as routerConstants from '../app.router.constants'

/**
 * Wrapper for public routes
 * @param Component - route component
 * @param Layout - layout component
 * @param restrict - to determine if we need to redirect to dashboard if user logged in
 * @param options - rest route props
 * @returns {*}
 * @constructor
 */
const PublicRoute = ({ component: Component, layout: Layout, restrict = true, ...options }) => {
  if (store.get(authConstants.USER_STORE_FIELD_NAME) && restrict) {
    return <Redirect to={routerConstants.DASHBOARD_ROUTE} />
  }
  return (
    <Route
      {...options}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default PublicRoute
