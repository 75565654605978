import React, { Fragment, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { Button, FormItemWrapper } from 'components'
import { LoginContainer, Title } from '../auth.styled'
import { resetPassword } from '../auth.actions'
import * as routerConstants from '../../App/app.router.constants'

const ResetPassword = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, error } = useSelector(state => state.auth)
  const [form] = Form.useForm()

  useEffect(() => {
    if (error) {
      history.push({ pathname: routerConstants.FORGOT_PASSWORD_ROUTE, state: { retry: true } })
    }
  }, [error])

  const onFinish = values => {
    const { email, token } = queryString.parse(location.search)

    dispatch(
      resetPassword({
        ...values,
        email,
        token,
      }),
    )
  }

  return (
    <Fragment>
      <Title>
        <FormattedMessage
          id="auth.resetPassword.title"
          defaultMessage="A customizable KYC widget for customers, merchants, and business leads."
        />
      </Title>
      <LoginContainer>
        <Helmet title="Charge Identity - Reset Password" />
        <Form
          form={form}
          onFinish={onFinish}
          name="login"
          layout="vertical"
          scrollToFirstError
          hideRequiredMark
        >
          <FormItemWrapper
            dataTestId="auth.resetPassword.input.password"
            name="password"
            type="password"
            required
            form={form}
            hideLabel
          />

          <FormItemWrapper
            dataTestId="auth.resetPassword.input.confirmPassword"
            name="confirmPassword"
            type="confirmPassword"
            required
            dependencies={['password']}
            form={form}
            hideLabel
          />

          <Button
            htmlType="submit"
            data-testid="auth.resetPassword.button.submit"
            loading={loading}
            className="w-100"
            type="primary"
          >
            <FormattedMessage id="auth.resetPassword.btn" defaultMessage="Reset" />
          </Button>
        </Form>
      </LoginContainer>
    </Fragment>
  )
}

export default ResetPassword
