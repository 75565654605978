import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import LogoLarge from 'assets/images/logo-white-large.svg'
import LogoSmall from 'assets/images/logo-white-small.svg'
import { StyledSider } from '../app.styled'
import Menu from './Menu'
import UserInfo from './UserInfo'
import { SIDER_COLLAPSED_STORAGE_KEY } from '../app.constants'

const SideBar = () => {
  const history = useHistory()
  const location = useLocation()

  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage.getItem(SIDER_COLLAPSED_STORAGE_KEY)),
  )
  const Logo = collapsed ? LogoSmall : LogoLarge

  const onCollapse = () => {
    setCollapsed(!collapsed)
    localStorage.setItem(SIDER_COLLAPSED_STORAGE_KEY, JSON.stringify(!collapsed))
  }

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth={112}
      width={260}
    >
      <div className="logo-container">
        <img src={Logo} alt="Logo" />
      </div>

      <UserInfo />
      <Menu history={history} location={location} />
    </StyledSider>
  )
}

export default SideBar
