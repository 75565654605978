import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import { FormattedMessage } from 'react-intl'
import ArrowLeftIcon from 'assets/images/icons/ic-arrow-left.svg'
import { Button, IdentityStatus, PageTitle } from 'components'
import { StyledHeader } from '../identity.styled'

const IdentityDetailsHeader = ({ loading, name, status, history }) => (
  <StyledHeader>
    <div className="d-flex flex-row align-items-center">
      <Button type="link" onClick={() => history.goBack()}>
        <img src={ArrowLeftIcon} alt="Arrow left icon" />
      </Button>
      {name ? <PageTitle>{name}</PageTitle> : null}
    </div>

    {loading && !status ? (
      <Skeleton.Button active shape="round" className="d-block" />
    ) : (
      <Fragment>
        {status ? (
          <IdentityStatus status={status}>
            <FormattedMessage
              id={`dashboard.subTitle.history.row.status.${status}`}
              defaultMessage={status}
            />
          </IdentityStatus>
        ) : null}
      </Fragment>
    )}
  </StyledHeader>
)

IdentityDetailsHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

export default IdentityDetailsHeader
