import * as constants from './identity.constants'

const initialState = {
  loading: true,
  list: {
    data: [],
    meta: {},
  },
  selected: {
    data: {
      organization: {},
    },
    result: {},
    documents: [],
    notes: [],
    governmentIDs: [],
  },
  listFiltered: false,
  countries: [],
  states: [],
  error: null,
}

const identity = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PERSON:
    case constants.GET_ORGANIZATION:
    case constants.GET_ORGANIZATIONS:
    case constants.GET_PERSONS: {
      return {
        ...state,
        error: null,
        list: {
          data: [],
          meta: {},
        },
        selected: {
          data: {},
          result: {},
          documents: [],
        },
        loading: true,
      }
    }
    case constants.POST_PERSON_PASSPORT:
    case constants.POST_PERSON_DRIVER_LICENSE: {
      return {
        ...state,
        idsLoading: true,
      }
    }
    case constants.SEARCH_PERSONS:
    case constants.SEARCH_ORGANIZATIONS: {
      return {
        ...state,
        loading: true,
      }
    }
    case constants.UPLOAD_KYC_DOCUMENT:
    case constants.UPLOAD_KYB_DOCUMENT: {
      return {
        ...state,
        documentUploadLoading: true,
      }
    }
    case constants.GET_PERSON_NOTES_SUCCESS:
    case constants.GET_ORGANIZATION_NOTES_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          notes: action.payload.data,
        },
      }
    }
    case constants.GET_KYC_DOCUMENTS:
    case constants.GET_KYB_DOCUMENTS: {
      return {
        ...state,
        documentsLoading: true,
      }
    }
    case constants.GET_KYC_DOCUMENTS_SUCCESS:
    case constants.GET_KYB_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          documents: action.payload.documents,
        },
        documentsLoading: false,
      }
    }
    case constants.UPLOAD_KYB_DOCUMENT_SUCCESS:
    case constants.UPLOAD_KYC_DOCUMENT_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          documents: action.payload,
        },
        documentsLoading: false,
        documentUploadLoading: false,
      }
    }
    case constants.GET_KYB_DOCUMENT:
    case constants.GET_KYC_DOCUMENT: {
      return {
        ...state,
        documentForDownloadLoading: true,
      }
    }
    case constants.GET_KYB_DOCUMENT_SUCCESS:
    case constants.GET_KYC_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documentsLoading: false,
        documentForDownloadLoading: false,
        documentForDownload: action.payload,
      }
    }
    case constants.POST_PERSON_PASSPORT_SUCCESS: {
      const updatedGovernmentIssuedIds = [...state.selected.data.governmentIssuedIds]
      const updatedTypeIndex = updatedGovernmentIssuedIds.findIndex(
        item => item.type === 'passport',
      )

      if (updatedTypeIndex === -1) {
        updatedGovernmentIssuedIds.push({
          type: 'passport',
          state: action.payload.country,
          [action.payload.imageType]: action.payload.image,
        })
      } else {
        updatedGovernmentIssuedIds[updatedTypeIndex] = {
          ...updatedGovernmentIssuedIds[updatedTypeIndex],
          [action.payload.imageType]: action.payload.image,
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            governmentIssuedIds: updatedGovernmentIssuedIds,
          },
        },
        idsLoading: false,
      }
    }
    case constants.POST_PERSON_DRIVER_LICENSE_SUCCESS: {
      const updatedGovernmentIssuedIds = [...state.selected.data.governmentIssuedIds]
      const updatedTypeIndex = updatedGovernmentIssuedIds.findIndex(
        item => item.type === 'driver-license',
      )
      if (updatedTypeIndex === -1) {
        updatedGovernmentIssuedIds.push({
          type: 'driver-license',
          state: action.payload.state,
          [action.payload.imageType]: action.payload.image,
        })
      } else {
        updatedGovernmentIssuedIds[updatedTypeIndex] = {
          ...updatedGovernmentIssuedIds[updatedTypeIndex],
          state: action.payload.state,
          [action.payload.imageType]: action.payload.image,
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            governmentIssuedIds: updatedGovernmentIssuedIds,
          },
        },
        idsLoading: false,
      }
    }
    case constants.SET_ORGANIZATION_DECISION:
    case constants.SET_PERSON_DECISION: {
      return {
        ...state,
        [`${action.payload.decision.toLowerCase()}Loading`]: true,
      }
    }
    case constants.POST_ORGANIZATION_NOTE:
    case constants.POST_PERSON_NOTE: {
      return {
        ...state,
        noteLoading: true,
      }
    }
    case constants.POST_ORGANIZATION_NOTE_SUCCESS:
    case constants.POST_PERSON_NOTE_SUCCESS: {
      const notes = [...state.selected.notes]
      notes.push(action.payload)
      return {
        ...state,
        noteLoading: false,
        selected: {
          ...state.selected,
          notes,
        },
      }
    }
    case constants.GET_ORGANIZATIONS_SUCCESS:
    case constants.GET_PERSONS_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        loading: false,
        listFiltered: false,
      }
    }
    case constants.SEARCH_PERSONS_SUCCESS:
    case constants.SEARCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        loading: false,
        listFiltered: true,
      }
    }
    case constants.GET_PERSONS_BY_ORGANIZATION_ID: {
      return {
        ...state,
        organizationApplicationsLoading: true,
      }
    }
    case constants.GET_PERSONS_BY_ORGANIZATION_ID_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        organizationApplicationsLoading: false,
      }
    }
    case constants.GET_ORGANIZATION_SUCCESS:
    case constants.GET_PERSON_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          result: {},
          ...action.payload,
        },
        loading: false,
      }
    }
    case constants.GET_SUPPORTED_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      }
    }
    case constants.GET_SUPPORTED_STATES_SUCCESS: {
      return {
        ...state,
        states: action.payload,
      }
    }
    case constants.SET_ORGANIZATION_DECISION_SUCCESS:
    case constants.SET_PERSON_DECISION_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
          notes: state.selected.notes,
        },
        [`${action.payload.result.status.toLowerCase()}Loading`]: false,
      }
    }
    case constants.GET_PERSON_GOVERNMENT_IDS: {
      return {
        ...state,
        governmentIDsLoading: true,
      }
    }
    case constants.GET_PERSON_GOVERNMENT_IDS_SUCCESS: {
      return {
        ...state,
        governmentIDsLoading: false,
        selected: {
          ...state.selected,
          governmentIDs: action.payload.governmentIssuedIds,
        },
      }
    }

    case constants.GET_ORGANIZATIONS_ERROR:
    case constants.SET_ORGANIZATION_DECISION_ERROR:
    case constants.GET_ORGANIZATION_ERROR:
    case constants.GET_PERSONS_ERROR:
    case constants.GET_PERSON_ERROR:
    case constants.POST_PERSON_NOTE_ERROR:
    case constants.POST_ORGANIZATION_NOTE_ERROR:
    case constants.SET_PERSON_DECISION_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    }
    default:
      return state
  }
}

export default identity
