import { Component } from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { injectIntl } from 'react-intl'

@connect(({ notifications }) => ({ notifications }))
class Notifications extends Component {
  componentWillReceiveProps(nextProps) {
    const { intl } = this.props
    notification[nextProps.notifications.type]({
      message: intl.formatMessage({ id: nextProps.notifications.messageId }),
      description: intl.formatMessage({ id: nextProps.notifications.descriptionId }),
    })
  }

  render() {
    return null
  }
}

export default injectIntl(Notifications)
