import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { SubTitle } from 'features/Settings/settings.styled'
import { TransactionHistoryContainer } from '../billing.styled'
import { StyledTable } from '../../Identity/identity.styled'
import { SETTINGS_BILLING_INVOICE_ROUTE } from '../../App/app.router.constants'
import { getInvoices } from '../billing.actions'
import { MOMENT_DATE_FORMAT } from '../../App/app.constants'

const columns = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'End Date',
    dataIndex: 'endAt',
    key: 'endAt',
    render: value => {
      return <span>{moment(value).format(MOMENT_DATE_FORMAT)}</span>
    },
  },
  {
    title: 'Due Date',
    dataIndex: 'dueAt',
    key: 'dueAt',
    render: value => {
      return <span>{moment(value).format(MOMENT_DATE_FORMAT)}</span>
    },
  },
  {
    title: 'Total Charges',
    dataIndex: 'totalCharges',
    key: 'totalCharges',
  },
  {
    title: 'Total Payments',
    dataIndex: 'totalPayments',
    key: 'totalPayments',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
]

const InvoiceHistory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { invoices, invoicesLoading } = useSelector(state => state.billing)

  useEffect(() => {
    dispatch(getInvoices())
  }, [])

  const handleRowClick = record => {
    history.push(`${SETTINGS_BILLING_INVOICE_ROUTE.replace(':id', `${record.id}`)}`)
  }

  return (
    <TransactionHistoryContainer>
      <SubTitle>
        <FormattedMessage
          id="settings.billing.transaction.history"
          defaultMessage="Invoice History"
        />
      </SubTitle>
      <StyledTable
        rowKey="id"
        className="utils__scrollTable hoverable mt-0"
        size="default"
        pagination={false}
        dataSource={invoices.list.data}
        columns={columns}
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
        loading={invoicesLoading}
      />
    </TransactionHistoryContainer>
  )
}

export default InvoiceHistory
