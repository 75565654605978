import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Form } from 'antd'
import { Button, FormItem, Input } from 'components'
import WarningIcon from 'assets/images/icons/ic-warning.svg'
import { LoginContainer, Title, StyledRetryErrorMessage } from '../auth.styled'

const ForgotPasswordForm = ({ onFinish, loading, retry }) => {
  const [form] = Form.useForm()

  return (
    <Fragment>
      <Title>
        <FormattedMessage id="forgot-password.subheading" defaultMessage="Forgot Password?" />
      </Title>
      <Title>
        <FormattedMessage
          id="forgot-password.subtext"
          defaultMessage="Please enter your email address. A link to reset your password will be sent to you."
        />
      </Title>

      {retry ? (
        <StyledRetryErrorMessage>
          <img src={WarningIcon} alt="warning icon" />
          <FormattedMessage
            id="forgot-password.retry"
            defaultMessage="It looks like you clicked on an invalid password reset link. Please try again."
          />
        </StyledRetryErrorMessage>
      ) : null}

      <LoginContainer>
        <Helmet title="Forgot Password" />
        <Form
          form={form}
          onFinish={onFinish}
          name="login"
          layout="vertical"
          scrollToFirstError
          hideRequiredMark
        >
          <FormItem
            name="email"
            hasFeedback
            rules={[
              {
                required: true,
                message: (
                  <span data-testid="auth.forgotPassword.input.email.error.required">
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.email.error.required"
                      defaultMessage="Please enter your Email"
                    />
                  </span>
                ),
              },
              {
                type: 'email',
                message: (
                  <span data-testid="auth.forgotPassword.input.email.error.notValid">
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.email.error.email"
                      defaultMessage="Please enter valid Email address"
                    />
                  </span>
                ),
              },
            ]}
          >
            <Input
              maxLength={319}
              data-testid="auth.forgotPassword.input.email"
              placeholder="your@email.com"
            />
          </FormItem>
          <Button
            htmlType="submit"
            data-testid="auth.forgotPassword.button.submit"
            loading={loading}
            className="w-100"
            type="primary"
          >
            <FormattedMessage id="forgot-password.sendEmail" defaultMessage="Send Email" />
          </Button>
        </Form>
      </LoginContainer>
    </Fragment>
  )
}

export default ForgotPasswordForm
