import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, HorizontalSeparator } from 'components'
import { IDENTITY_STATUSES, IDENTITY_ENUM } from '../identity.constants'
import { ERROR_404_ROUTE } from '../../App/app.router.constants'
import {
  StyledApplicationHistoryContainer,
  StyledCustomerDetailsLeftColumn,
  StyledDetailsBlock,
  StyledDetailsContainer,
  StyledDetailsRow,
} from '../identity.styled'
import {
  ApplicationHistory,
  AdditionalData,
  CustomerInformation,
  DataProvidersResults,
  DetailsFooter,
  DetailsHeader,
  GovernmentIssuedID,
} from '../components'
import {
  getPerson,
  setPersonDecision,
  postPersonNote,
  uploadKycDocument,
  getKycDocuments,
  getKycDocument,
  getPersonNotes,
  getPersonGovernmentIDs,
} from '../identity.actions'
import { getKYCButtonsState } from '../identity.utils'

const IdentityCustomerDetails = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id } = match.params
  const {
    loading,
    selected,
    error,
    acceptLoading,
    denyLoading,
    noteLoading,
    idsLoading,
    countries,
    states,
    documentForDownload,
    documentForDownloadLoading,
    documentUploadLoading,
    governmentIDsLoading,
  } = useSelector(state => state.identity)
  const { data, result, notes, documents, governmentIDs } = selected
  const isResultPresent = Object.keys(result).length !== 0
  const { isAcceptAllowed, isDeclineAllowed } = getKYCButtonsState(result.status)

  useEffect(() => {
    dispatch(getPerson(id))
  }, [id])

  useEffect(() => {
    dispatch(getKycDocuments(id))
  }, [id])

  useEffect(() => {
    dispatch(getPersonGovernmentIDs(id))
  }, [id])

  useEffect(() => {
    if (result.status) {
      dispatch(getPersonNotes(id))
    }
  }, [id, result.status])

  useEffect(() => {
    if (error) {
      history.push(ERROR_404_ROUTE)
    }
  }, [error])

  const handleFileUpload = file => {
    dispatch(uploadKycDocument({ file, id }))
  }

  const handleFileDownload = name => {
    dispatch(getKycDocument({ id, name }))
  }

  const handleAccept = note =>
    dispatch(setPersonDecision({ id, decision: IDENTITY_STATUSES.ACCEPT, note }))
  const handleDeny = note =>
    dispatch(setPersonDecision({ id, decision: IDENTITY_STATUSES.DENY, note }))

  return (
    <StyledDetailsContainer>
      <Container>
        <DetailsHeader
          loading={loading}
          history={history}
          name={
            data.firstName
              ? `${data.firstName} ${data.lastName} / ${data.email} / ${data.id}`
              : null
          }
          status={isResultPresent ? result.status : null}
        />
        <HorizontalSeparator />
        <StyledDetailsRow>
          <StyledCustomerDetailsLeftColumn fullWidth={!isResultPresent}>
            <StyledDetailsBlock>
              <CustomerInformation data={data} loading={loading} />
            </StyledDetailsBlock>
            <StyledDetailsBlock>
              <GovernmentIssuedID
                loading={idsLoading}
                listLoading={governmentIDsLoading}
                person={selected}
                id={id}
                data={governmentIDs}
                countries={countries}
                states={states}
              />
            </StyledDetailsBlock>
          </StyledCustomerDetailsLeftColumn>

          {isResultPresent ? (
            <StyledApplicationHistoryContainer>
              <ApplicationHistory
                statusHistory={result.actionHistory}
                notes={notes}
                documents={documents}
                loading={loading}
                noteLoading={noteLoading}
                postNote={note => dispatch(postPersonNote(id, note))}
                handleFileUpload={handleFileUpload}
                handleFileDownload={handleFileDownload}
                documentForDownload={documentForDownload}
                documentForDownloadLoading={documentForDownloadLoading}
                documentUploadLoading={documentUploadLoading}
              />
            </StyledApplicationHistoryContainer>
          ) : null}
        </StyledDetailsRow>
        {isResultPresent ? (
          <StyledDetailsBlock>
            <DataProvidersResults data={result.checks} loading={loading} />
          </StyledDetailsBlock>
        ) : null}
        {data.meta && Object.keys(data.meta).length !== 0 ? (
          <AdditionalData data={data.meta} />
        ) : null}
      </Container>

      {!loading && isResultPresent ? (
        <DetailsFooter
          isAcceptAllowed={isAcceptAllowed}
          isDeclineAllowed={isDeclineAllowed}
          handleAccept={handleAccept}
          handleDeny={handleDeny}
          acceptLoading={acceptLoading}
          denyLoading={denyLoading}
          type={IDENTITY_ENUM.KYC}
        />
      ) : null}
    </StyledDetailsContainer>
  )
}

IdentityCustomerDetails.propTypes = {
  match: PropTypes.object.isRequired,
}

export default IdentityCustomerDetails
