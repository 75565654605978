import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { SETTINGS_MENU_DEFAULT_SELECTED_KEY, SETTINGS_MENU_ENUM } from './settings.constants'
import {
  StyledMenu as Menu,
  StyledMenuItem as Item,
  StyledSettingsContentContainer,
  StyledSettingsContainer,
} from './settings.styled'
import { getOrganization } from '../Organization/organization.actions'

const Settings = ({ children, location, history }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { organization, updateRoleLoading, removeMemberLoading } = useSelector(
    state => state.organization,
  )

  useEffect(() => {
    if (user.accountId && !organization.id) {
      dispatch(getOrganization(user.accountId))
    }
  }, [user.accountId, organization])

  useEffect(() => {
    if (user.accountId) {
      dispatch(getOrganization(user.accountId))
    }
  }, [updateRoleLoading, removeMemberLoading])

  const getSelectedMenuKey = () => {
    const selectedItem = SETTINGS_MENU_ENUM.find(
      item =>
        item.route === location.pathname ||
        (item.subRoutes && item.subRoutes.indexOf(location.pathname) !== -1),
    )
    return selectedItem ? selectedItem.key : SETTINGS_MENU_DEFAULT_SELECTED_KEY
  }

  const handleMenuItemClick = ({ key }) => {
    const clickedItem = SETTINGS_MENU_ENUM.find(item => item.key === key)
    history.push(clickedItem.route)
  }

  return (
    <StyledSettingsContainer>
      <Helmet title="Settings View" />
      <StyledSettingsContentContainer>
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={[getSelectedMenuKey()]}
          onClick={handleMenuItemClick}
        >
          {SETTINGS_MENU_ENUM.map(item => (
            <Item key={item.key}>
              <FormattedMessage
                id={`account-settings.menuMap.${item.key}`}
                defaultMessage={item.defaultMessage}
              />
            </Item>
          ))}
        </Menu>
        {children}
      </StyledSettingsContentContainer>
    </StyledSettingsContainer>
  )
}

export default Settings
