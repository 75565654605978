import { applyMiddleware, createStore } from 'redux'

import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { logger } from 'redux-logger'

import rootReducer from './reducers'
import sagas from './sagas'

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(history) {
  const routeMiddleware = routerMiddleware(history)
  const middlewares = [thunkMiddleware, sagaMiddleware, routeMiddleware]

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
  }

  const middlewareEnhancer = applyMiddleware(...middlewares)
  const store = createStore(rootReducer(history), {}, middlewareEnhancer)
  sagaMiddleware.run(sagas)

  return store
}
