import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Checkbox, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormItem, Input, Link, PasswordInput, Text } from 'components'
import { LoginContainer, Title } from '../auth.styled'
import * as routerConstants from '../../App/app.router.constants'
import { login } from '../auth.actions'

const Login = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.auth)
  const [form] = Form.useForm()

  const onFinish = values => {
    dispatch(login(values))
  }

  return (
    <Fragment>
      <Helmet title="Charge Identity - Login" />
      <Title>
        <FormattedMessage
          id="login.subheading"
          defaultMessage="A customizable KYC widget for customers, merchants, and business leads."
        />
      </Title>
      <LoginContainer>
        <Form
          form={form}
          onFinish={onFinish}
          name="login"
          layout="vertical"
          scrollToFirstError
          hideRequiredMark
          initialValues={{
            remember: true,
          }}
        >
          <FormItem
            name="email"
            hasFeedback
            rules={[
              {
                required: true,
                message: (
                  <span data-testid="auth.login.input.email.error.required">
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.email.error.required"
                      defaultMessage="Please enter your Email"
                    />
                  </span>
                ),
              },
              {
                type: 'email',
                message: (
                  <span data-testid="auth.login.input.email.error.notValid">
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.email.error.email"
                      data-testid="auth.login.input.email.error.notValid"
                      defaultMessage="Please enter valid Email address"
                    />
                  </span>
                ),
              },
            ]}
          >
            <Input
              maxLength={319}
              data-testid="auth.login.input.email"
              placeholder="your@email.com"
            />
          </FormItem>
          <FormItem
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: (
                  <span data-testid="auth.login.input.password.error.required">
                    <FormattedMessage
                      id="preSaleCheckout.account.details.field.password.error.required"
                      defaultMessage="Please enter your Password"
                    />
                  </span>
                ),
              },
            ]}
          >
            <PasswordInput
              maxLength={250}
              data-testid="auth.login.input.password"
              placeholder="Password"
            />
          </FormItem>
          <Button
            htmlType="submit"
            data-testid="auth.login.button.submit"
            loading={loading}
            className="w-100"
            type="primary"
          >
            <FormattedMessage id="form.login" defaultMessage="Login" />
          </Button>
          <div className="row mt-3">
            <div className="col-6">
              <FormItem name="remember" valuePropName="checked" className="mb-0">
                <Checkbox data-testid="auth.login.checkbox.remember">
                  <FormattedMessage id="form.login.remember" defaultMessage="Remember me" />
                </Checkbox>
              </FormItem>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <Link to={routerConstants.FORGOT_PASSWORD_ROUTE}>
                <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot Password?" />
              </Link>
            </div>
          </div>
        </Form>
      </LoginContainer>
      <Text className="text-center text-white">
        <FormattedMessage
          id="login.register.question"
          defaultMessage="Don't have a Charge account?"
        />
        <a className="ml-1 text-primary" href="mailto:support@charge.io">
          support@charge.io
        </a>
      </Text>
    </Fragment>
  )
}

export default Login
