import * as constants from './organization.constants'

export function getOrganization(id) {
  return { type: constants.GET_ORGANIZATION, id }
}

export function createOrganization(payload) {
  return { type: constants.CREATE_ORGANIZATION, payload }
}

export function updateOrganization(payload) {
  return { type: constants.UPDATE_ORGANIZATION, payload }
}

export function updatePaymentPlan(payload) {
  return { type: constants.UPDATE_PAYMENT_PLAN, payload }
}

export function postInvitation(payload) {
  return { type: constants.POST_INVITATION, payload }
}

export function getInvitations(payload) {
  return { type: constants.GET_INVITATIONS, payload }
}

export function updateMemberRole(payload) {
  return { type: constants.UPDATE_MEMBER_ROLE, payload }
}

export function removeMember(payload) {
  return { type: constants.REMOVE_MEMBER, payload }
}

export function freezeAccount(payload) {
  return { type: constants.FREEZE_ACCOUNT, payload }
}

export function deactivateAccount(payload) {
  return { type: constants.DEACTIVATE_ACCOUNT, payload }
}

export function activateAccount(payload) {
  return { type: constants.ACTIVATE_ACCOUNT, payload }
}
