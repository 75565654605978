import axios from 'axios'

export async function getPaymentPlans() {
  return axios.get(`/plans`).then(res => res.data)
}

export async function getPaymentMethods() {
  return axios.get(`/paymentMethods`).then(res => res.data)
}

export async function updatePaymentMethod(payload) {
  return axios.put(`/paymentMethods/${payload.id}`, payload).then(res => res.data)
}

export async function getInvoices() {
  return axios.get(`/invoices`).then(res => res.data)
}

export async function getInvoice(id) {
  return axios.get(`/invoices/${id}`).then(res => res.data)
}
