import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { BaseTableFilters, Container, HorizontalSeparator, PageTitle } from 'components'
import { IdentityList, IdentityListSearch } from './components'
import { getPersons, searchPersons } from './identity.actions'
import {
  IDENTITY_ENUM,
  IDENTITY_LIST_DEFAULT_PAGE,
  IDENTITY_LIST_DEFAULT_PAGE_SIZE,
  KYC_TABLE_COLUMNS,
  SEARCH_PERSONS_FIELDS,
  DEFAULT_SEARCH_PERSONS_FIELD,
} from './identity.constants'
import { getColumnsWithStatusFilter, getColumnsAndResetStatusFilter } from './identity.utils'

const IdentityCustomer = () => {
  const dispatch = useDispatch()
  const { loading, list, listFiltered } = useSelector(state => state.identity)
  const [columns, setColumns] = useState(KYC_TABLE_COLUMNS)

  useEffect(() => {
    if (listFiltered) {
      setColumns(getColumnsWithStatusFilter(columns))
    } else {
      setColumns(getColumnsAndResetStatusFilter(columns))
    }
  }, [listFiltered])

  useEffect(() => getInitialList(), [])

  const handlePageChange = (currentPage, pageSize) => {
    if (!listFiltered) {
      dispatch(
        getPersons({
          currentPage,
          pageSize,
        }),
      )
    }
  }

  const getInitialList = () => {
    dispatch(
      getPersons({
        currentPage: IDENTITY_LIST_DEFAULT_PAGE,
        pageSize: IDENTITY_LIST_DEFAULT_PAGE_SIZE,
      }),
    )
  }

  const handleSearch = query => {
    dispatch(searchPersons(query))
  }

  return (
    <Container>
      <Helmet title="KYC List" />
      <PageTitle>
        <FormattedMessage id="identity.customer.title" defaultMessage="KYC List" />
      </PageTitle>
      <HorizontalSeparator />
      <BaseTableFilters>
        <IdentityListSearch
          listFiltered={listFiltered}
          handleSearch={handleSearch}
          handleSearchReset={getInitialList}
          loading={loading}
          fields={SEARCH_PERSONS_FIELDS}
          defaultField={DEFAULT_SEARCH_PERSONS_FIELD}
        />
      </BaseTableFilters>
      <IdentityList
        handlePageChange={handlePageChange}
        type={IDENTITY_ENUM.KYC}
        loading={loading}
        data={list}
        columns={columns}
      />
    </Container>
  )
}

export default IdentityCustomer
