import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledFormItem, StyledInput } from '../../antd.styled'
import { getRules } from '../formFields.utils'
import PhoneInput from '../PhoneInput'
import { PasswordInput } from '../../index'

const FormItemWrapper = ({
  name,
  intlPrefix,
  required,
  type,
  form,
  disabled,
  dependencies,
  hideLabel,
  dataTestId,
  defaultValue,
  maxLength,
}) => {
  const prefix = intlPrefix || name

  const rules = getRules(required, type || name, prefix)

  const handleChange = (fieldName, value) => {
    const values = {}
    values[fieldName] = value
    form.setFieldsValue(values)
    form.validateFields([`${fieldName}`])
  }

  return (
    <StyledFormItem
      label={
        hideLabel ? null : <FormattedMessage id={`form.item.${prefix}`} defaultMessage={prefix} />
      }
      name={name}
      hasFeedback
      dependencies={dependencies}
      rules={rules}
    >
      <FormattedMessage id={`form.item.${prefix}`} defaultMessage={prefix}>
        {placeholder => {
          switch (type || name) {
            case 'phone':
              return (
                <PhoneInput
                  value={form.getFieldValue(name)}
                  name={name}
                  onChange={handleChange}
                  placeholder={placeholder}
                  disabled={disabled}
                />
              )
            case 'password':
            case 'oldPassword':
            case 'confirmPassword':
              return (
                <PasswordInput
                  value={form.getFieldValue(name)}
                  name={name}
                  onChange={e => handleChange(name, e.target.value)}
                  placeholder={placeholder}
                  data-testid={dataTestId || null}
                  maxLength={250}
                />
              )
            default:
              return (
                <StyledInput
                  value={form.getFieldValue(name)}
                  onChange={e => handleChange(name, e.target.value)}
                  placeholder={placeholder}
                  disabled={disabled}
                  data-testid={dataTestId || null}
                  defaultValue={defaultValue}
                  maxLength={maxLength}
                />
              )
          }
        }}
      </FormattedMessage>
    </StyledFormItem>
  )
}

export default FormItemWrapper
