import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Content, ContentFooter, ContentHeader, PageTitle } from 'features/Settings/settings.styled'
import { Form } from 'antd'
import { Button } from 'components'
import moment from 'moment'
import { updatePaymentMethod } from '../billing.actions'
import StripeCheckoutForm from '../components/StripeCheckoutForm'

const UpdatePaymentMethod = ({ location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [token, setToken] = useState(null)
  const [cardError, setCardError] = useState(null)

  const onFinish = () => {
    setFormSubmitted(true)
  }

  const handleCardError = error => {
    setFormSubmitted(false)
    setCardError(error)
  }

  useEffect(() => {
    if (token) {
      const payment = {
        isDefault: true,
        type: token.type,
        label: token.card.brand,
        token: token.id,
        lastFour: token.card.last4,
        expirationDate: moment(`${token.card.exp_month}/${token.card.exp_year}`, 'M/YYYY').format(
          'YYYY-MM-DD',
        ),
      }

      dispatch(
        updatePaymentMethod({
          id: location.state.paymentMethodId,
          ...payment,
        }),
      )
    }
  }, [token])

  const handleTokenCreateSuccess = res => {
    setToken(res)
  }

  const handleSubmit = () => form.submit()

  return (
    <div>
      <Helmet title="Settings - Add Payment Method" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage
            id="settings.addPaymentMethod.title"
            defaultMessage="Add a Payment Method"
          />
        </PageTitle>
      </ContentHeader>
      <Content>
        <Form
          form={form}
          name="checkout"
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          hideRequiredMark
        >
          <StripeCheckoutForm
            formSubmitted={formSubmitted}
            handleTokenCreateSuccess={handleTokenCreateSuccess}
            setCardError={handleCardError}
          />
        </Form>
      </Content>
      <ContentFooter>
        <Button disabled={cardError && cardError.code} type="primary" onClick={handleSubmit}>
          <FormattedMessage
            id="settings.addPaymentMethod.button.save"
            defaultMessage="Save a Payment Method"
          />
        </Button>
      </ContentFooter>
    </div>
  )
}

export default UpdatePaymentMethod
