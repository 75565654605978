import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import store from 'store'
import * as authConstants from '../../Auth/auth.constants'
import * as routerConstants from '../app.router.constants'

/**
 * Wrapper for private routes
 * @param Component - route component
 * @param Layout - layout component
 * @param roles - array of roles which will be able to access this route (will be implemented and replaced Authorize component, once we will need such functionality)
 * @param options - rest route props
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({ component: Component, layout: Layout, roles, noFooter, ...options }) => {
  if (!store.get(authConstants.USER_STORE_FIELD_NAME)) {
    return <Redirect to={routerConstants.LOGIN_ROUTE} />
  }
  return (
    <Route
      {...options}
      render={props => (
        <Layout noFooter={noFooter} {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default PrivateRoute
