import { all, call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import store from 'store'

import * as routerConstants from '../App/app.router.constants'
import * as notificationsConstants from '../Notifications/notifications.constants'
import * as organizationConstants from '../Organization/organization.constants'
import * as userProfileConstants from '../UserProfile/userProfile.constants'
import * as api from './auth.api'
import * as constants from './auth.constants'

export function* login({ payload }) {
  try {
    const response = yield call(api.login, payload)
    yield put({ type: constants.USER_LOGIN_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.login.success.message',
        descriptionId: 'notification.auth.login.success.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
    yield store.set(constants.USER_STORE_FIELD_NAME, response)
    yield put(push(routerConstants.DASHBOARD_ROUTE))
  } catch (err) {
    yield put({ type: constants.USER_LOGIN_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.auth.login.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.auth.login.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* logout({ noRedirect }) {
  yield store.remove(constants.USER_STORE_FIELD_NAME)
  yield put({ type: organizationConstants.CLEAR_ORGANIZATION })
  yield put({ type: userProfileConstants.CLEAR_USER_PROFILE })
  if (!noRedirect) {
    yield put(push(routerConstants.LOGIN_ROUTE))
  }
}

export function* register({ payload }) {
  try {
    yield call(api.register, payload)
    yield put({ type: constants.USER_REGISTER_SUCCESS })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.register.success.message',
        descriptionId: 'notification.auth.register.success.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.USER_REGISTER_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.auth.register.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.auth.register.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* validateEmail({ payload }) {
  try {
    yield call(api.validateEmail, payload)
    yield put({ type: constants.USER_VALIDATE_EMAIL_SUCCESS })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.verify.message',
        descriptionId: 'notification.auth.verify.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
    yield put(push(routerConstants.LOGIN_ROUTE))
  } catch (err) {
    yield put({ type: constants.USER_VALIDATE_EMAIL_ERROR })
    yield put(push(routerConstants.LOGIN_ROUTE))
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.validate.error.message',
        descriptionId: 'notification.auth.validate.error.description',
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* forgotPassword({ payload }) {
  try {
    yield call(api.forgotPassword, payload)
    yield put({ type: constants.USER_FORGOT_PASSWORD_SUCCESS })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.forgotPassword.success.message',
        descriptionId: 'notification.auth.forgotPassword.success.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.USER_FORGOT_PASSWORD_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.forgotPassword.error.message',
        descriptionId: 'notification.auth.forgotPassword.error.description',
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* resetPassword({ payload }) {
  try {
    yield call(api.resetPassword, payload)
    yield put({ type: constants.USER_RESET_PASSWORD_SUCCESS })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.resetPassword.success.message',
        descriptionId: 'notification.auth.resetPassword.success.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
    yield store.remove(constants.USER_STORE_FIELD_NAME)
    yield put(push(routerConstants.LOGIN_ROUTE))
  } catch (err) {
    yield put({
      type: constants.USER_RESET_PASSWORD_ERROR,
      payload: err.response.data.error.message,
    })
  }
}

export function* getLoggedInUser() {
  const user = yield store.get(constants.USER_STORE_FIELD_NAME)
  yield put({ type: constants.GET_LOGGED_IN_USER_SUCCESS, payload: user })
}

export function* setUserAccountId({ accountId }) {
  try {
    const user = yield store.get(constants.USER_STORE_FIELD_NAME)
    user.accountId = accountId
    yield store.set(constants.USER_STORE_FIELD_NAME, user)
    yield put({ type: constants.SET_USER_ACCOUNT_ID_SUCCESS, payload: user })
  } catch (err) {
    yield put({ type: constants.SET_USER_ACCOUNT_ID_ERROR })
  }
}

export function* setUserProfile({ payload }) {
  try {
    const user = yield store.get(constants.USER_STORE_FIELD_NAME)
    user.profile = payload
    yield store.set(constants.USER_STORE_FIELD_NAME, user)
    yield put({ type: constants.SET_USER_ACCOUNT_ID_SUCCESS, payload: user })
  } catch (err) {
    yield put({ type: constants.SET_USER_PROFILE_ERROR })
  }
}

export function* validateInvitationToken({ payload }) {
  try {
    const response = yield call(api.validateInvitationToken, payload)
    yield put({ type: constants.VALIDATE_INVITATION_TOKEN_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.VALIDATE_INVITATION_TOKEN_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.validate.invitation.token.error.message',
        descriptionId: 'notification.auth.validate.invitation.token.error.description',
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* registerByInvitation({ payload }) {
  try {
    yield call(api.registerByInvitation, payload)
    yield put({ type: constants.USER_REGISTER_BY_INVITATION_SUCCESS })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: 'notification.auth.register.invitation.success.message',
        descriptionId: 'notification.auth.register.invitation.success.description',
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.USER_REGISTER_BY_INVITATION_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.auth.register.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.auth.register.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updatePassword({ payload }) {
  try {
    const response = yield call(api.updatePassword, payload)
    yield put({ type: constants.UPDATE_PASSWORD_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updatePassword.success.message`,
        descriptionId: `notification.settings.security.updatePassword.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.UPDATE_PASSWORD_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updatePassword.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.security.updatePassword.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* toggleUserNotification({ payload }) {
  try {
    yield call(api.toggleUserNotification, payload)
    yield put({ type: constants.TOGGLE_USER_NOTIFICATIONS_SUCCESS, payload })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.toggleNotifications.success.message`,
        descriptionId: `notification.settings.security.toggleNotifications.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.TOGGLE_USER_NOTIFICATIONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.toggleNotifications.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.security.toggleNotifications.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.USER_LOGIN, login)])
  yield all([takeEvery(constants.USER_REGISTER, register)])
  yield all([takeEvery(constants.USER_VALIDATE_EMAIL, validateEmail)])
  yield all([takeEvery(constants.USER_FORGOT_PASSWORD, forgotPassword)])
  yield all([takeEvery(constants.USER_RESET_PASSWORD, resetPassword)])
  yield all([takeEvery(constants.USER_LOGOUT, logout)])
  yield all([takeEvery(constants.GET_LOGGED_IN_USER, getLoggedInUser)])
  yield all([takeEvery(constants.SET_USER_ACCOUNT_ID, setUserAccountId)])
  yield all([takeEvery(constants.SET_USER_PROFILE, setUserProfile)])
  yield all([takeEvery(constants.VALIDATE_INVITATION_TOKEN, validateInvitationToken)])
  yield all([takeEvery(constants.USER_REGISTER_BY_INVITATION, registerByInvitation)])
  yield all([takeEvery(constants.UPDATE_PASSWORD, updatePassword)])
  yield all([takeEvery(constants.TOGGLE_USER_NOTIFICATIONS, toggleUserNotification)])
}
