import * as constants from './organization.constants'

const initialState = {
  loading: false,
  organization: {
    plan: {},
    paymentMethods: [],
  },
  invitationLoading: false,
  updateRoleLoading: false,
  invitations: [],
}

const organization = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORGANIZATION:
    case constants.CREATE_ORGANIZATION:
    case constants.UPDATE_ORGANIZATION:
    case constants.UPDATE_PAYMENT_PLAN:
      return {
        ...state,
        loading: true,
      }
    case constants.FREEZE_ACCOUNT:
    case constants.DEACTIVATE_ACCOUNT:
      return {
        ...state,
        loadingStatusUpdate: true,
      }
    case constants.FREEZE_ACCOUNT_SUCCESS:
    case constants.DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          status: action.payload.status,
        },
        loadingStatusUpdate: false,
      }
    case constants.POST_INVITATION:
      return {
        ...state,
        invitationLoading: true,
      }
    case constants.POST_INVITATION_SUCCESS:
      return {
        ...state,
        invitationLoading: false,
      }
    case constants.UPDATE_MEMBER_ROLE:
      return {
        ...state,
        updateRoleLoading: true,
      }
    case constants.REMOVE_MEMBER:
      return {
        ...state,
        removeMemberLoading: true,
      }
    case constants.REMOVE_MEMBER_SUCCESS:
      return {
        ...state,
        removeMemberLoading: false,
      }
    case constants.UPDATE_MEMBER_ROLE_SUCCESS:
      return {
        ...state,
        updateRoleLoading: false,
      }
    case constants.GET_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitations: action.payload,
      }
    case constants.CREATE_ORGANIZATION_SUCCESS:
    case constants.GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        loading: false,
      }
    }

    case constants.UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        },
        loading: false,
      }
    }

    case constants.UPDATE_PAYMENT_PLAN_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        loading: false,
      }
    }

    case constants.CLEAR_ORGANIZATION: {
      return initialState
    }

    case constants.GET_ORGANIZATION_ERROR:
    case constants.CREATE_ORGANIZATION_ERROR:
    case constants.UPDATE_ORGANIZATION_ERROR:
    case constants.UPDATE_PAYMENT_PLAN_ERROR:
    case constants.GET_INVITATIONS_ERROR:
    case constants.POST_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default organization
