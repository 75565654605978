import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, CollapsePanel, WhiteVerticalTable } from 'components'

const AdditionalDataPanel = ({ panel }) => {
  const data = Object.keys(panel.data).map(key => ({
    key,
    label: key,
    value: panel.data[key],
  }))

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: value => {
        return <span className="font-weight-bold">{value}</span>
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      nopadding
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <CollapsePanel header={<span className="font-weight-bold">{panel.title}</span>} key="1">
        <WhiteVerticalTable
          className=""
          rowKey="key"
          columns={columns}
          dataSource={data}
          bordered
          size="default"
          pagination={false}
          showHeader={false}
        />
      </CollapsePanel>
    </Collapse>
  )
}

AdditionalDataPanel.defaultProps = {
  additionalChecks: [],
}

export default AdditionalDataPanel
