// ACTIONS TYPES
export const GET_USER_PROFILE = 'settings/GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'settings/GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_ERROR = 'settings/GET_USER_PROFILE_ERROR'

export const CREATE_USER_PROFILE = 'settings/CREATE_USER_PROFILE'
export const CREATE_USER_PROFILE_SUCCESS = 'settings/CREATE_USER_PROFILE_SUCCESS'
export const CREATE_USER_PROFILE_ERROR = 'settings/CREATE_USER_PROFILE_ERROR'

export const UPDATE_USER_PROFILE = 'settings/UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_SUCCESS = 'settings/UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_ERROR = 'settings/UPDATE_USER_PROFILE_ERROR'

export const CLEAR_USER_PROFILE = 'settings/CLEAR_USER_PROFILE'
