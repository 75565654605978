import React from 'react'

/**
 * Render layout components one by one in each one, from first array element to last
 * By this fn we able to create deep multi-layout pages
 * @param layouts array
 * @returns {function(*=): null}
 */
// eslint-disable-next-line import/prefer-default-export
export const composeLayouts = layouts => {
  return props => {
    let composedLayout = null
    for (let i = 0; i < layouts.length; i += 1) {
      if (layouts[i + 1]) {
        composedLayout = React.createElement(
          layouts[i],
          props,
          React.createElement(layouts[i + 1], props),
        )
      }
    }
    return composedLayout
  }
}
