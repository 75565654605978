import * as constants from './userProfile.constants'

export function getUserProfile() {
  return { type: constants.GET_USER_PROFILE }
}

export function createUserProfile(payload) {
  return { type: constants.CREATE_USER_PROFILE, payload }
}

export function updateUserProfile(payload) {
  return { type: constants.UPDATE_USER_PROFILE, payload }
}
