import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as routerConstants from 'features/App/app.router.constants'
import { Content, ContentFooter, ContentHeader } from 'features/Settings/settings.styled'
import { showNotification } from 'features/Notifications/notifications.actions'
import * as notificationsConstants from 'features/Notifications/notifications.constants'

import {
  CopyToClipBoardBtn,
  HintText,
  HintTextRed,
  PageTitle,
  SubTitle,
} from 'features/ApiKeys/apiKeys.styled'
import CopyIcon from 'assets/images/icons/ic-copy.svg'
import { Button, Input } from 'components'

const CreateApiKeySuccess = ({ history, location }) => {
  const dispatch = useDispatch()
  const { apiKey } = location.state

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey)
    dispatch(
      showNotification({
        messageId: 'notification.settings.apiKey.copiedToClipBoard.message',
        descriptionId: 'notification.settings.apiKey.create.error.copiedToClipBoard.description',
        type: notificationsConstants.NOTIFICATION_TYPES.INFO,
      }),
    )
  }

  return (
    <div>
      <Helmet title="Create API Key Success" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.apiKey.title" defaultMessage="API keys" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <SubTitle>
          <FormattedMessage
            id="settings.apiKey.cancel.success.title"
            defaultMessage="API Key Created"
          />
        </SubTitle>
        <HintText>
          <FormattedMessage
            id="settings.apiKey.cancel.success.hint"
            defaultMessage="Please copy this key and save it somewhere safe"
          />
        </HintText>
        <HintTextRed>
          <FormattedMessage
            id="settings.apiKey.cancel.success.hint.warning"
            defaultMessage="For security reasons, we cannot show it to you again"
          />
        </HintTextRed>

        <Input
          disabled
          size="default"
          type="text"
          className="w-100 mb-4 pl-0 mt-4"
          value={apiKey}
          suffix={
            <CopyToClipBoardBtn type="button" onClick={handleCopyToClipboard}>
              <img className="" src={CopyIcon} alt="Api key permission icon" />
            </CopyToClipBoardBtn>
          }
        />
      </Content>
      <ContentFooter>
        <Button
          type="primary"
          onClick={() => history.push(routerConstants.SETTINGS_API_KEYS_ROUTE)}
        >
          <FormattedMessage id="settings.apiKey.done.button" defaultMessage="Done" />
        </Button>
      </ContentFooter>
    </div>
  )
}

CreateApiKeySuccess.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default CreateApiKeySuccess
