import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as constants from './dashboard.constants'
import * as api from './dashboard.api'
import * as notificationsConstants from '../Notifications/notifications.constants'

export function* getApplicationsHistory({ payload }) {
  try {
    const response = yield call(api.getApplicationsHistory, { ...payload })
    yield put({ type: constants.GET_APPLICATION_HISTORY_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_APPLICATION_HISTORY_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.dashboard.applicationsHistory.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.dashboard.applicationsHistory.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getStatistics({ payload }) {
  try {
    const response = yield call(api.getStatistics, payload)
    yield put({ type: constants.GET_STATISTICS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_STATISTICS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.dashboard.statistics.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.dashboard.statistics.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.GET_APPLICATION_HISTORY, getApplicationsHistory)])
  yield all([takeEvery(constants.GET_STATISTICS, getStatistics)])
}
