import React from 'react'
import { Skeleton } from 'antd'
import {
  StyledLinkedApplication,
  StyledLinkedApplicationBody,
  StyledLinkedApplicationHeader,
} from '../identity.styled'

const LinkedApplicationsSkeleton = () => (
  <div className="row w-100">
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
      <StyledLinkedApplication>
        <StyledLinkedApplicationHeader>
          <div />
          <Skeleton.Input active size="small" />
        </StyledLinkedApplicationHeader>
        <StyledLinkedApplicationBody>
          <Skeleton active />
        </StyledLinkedApplicationBody>
      </StyledLinkedApplication>
    </div>
  </div>
)

export default LinkedApplicationsSkeleton
