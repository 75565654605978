// ACTIONS TYPES
export const GET_ORGANIZATION = 'settings/GET_ORGANIZATION'
export const GET_ORGANIZATION_SUCCESS = 'settings/GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_ERROR = 'settings/GET_ORGANIZATION_ERROR'

export const CLEAR_ORGANIZATION = 'settings/CLEAR_ORGANIZATION'

export const CREATE_ORGANIZATION = 'settings/CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_SUCCESS = 'settings/CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_ERROR = 'settings/CREATE_ORGANIZATION_ERROR'

export const UPDATE_ORGANIZATION = 'settings/UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'settings/UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_ERROR = 'settings/UPDATE_ORGANIZATION_ERROR'

export const UPDATE_PAYMENT_PLAN = 'settings/UPDATE_PAYMENT_PLAN'
export const UPDATE_PAYMENT_PLAN_SUCCESS = 'settings/UPDATE_PAYMENT_PLAN_SUCCESS'
export const UPDATE_PAYMENT_PLAN_ERROR = 'settings/UPDATE_PAYMENT_PLAN_ERROR'

export const POST_INVITATION = 'settings/POST_INVITATION'
export const POST_INVITATION_SUCCESS = 'settings/POST_INVITATION_SUCCESS'
export const POST_INVITATION_ERROR = 'settings/POST_INVITATION_ERROR'

export const GET_INVITATIONS = 'settings/GET_INVITATIONS'
export const GET_INVITATIONS_SUCCESS = 'settings/GET_INVITATIONS_SUCCESS'
export const GET_INVITATIONS_ERROR = 'settings/GET_INVITATIONS_ERROR'

export const UPDATE_MEMBER_ROLE = 'settings/UPDATE_MEMBER_ROLE'
export const UPDATE_MEMBER_ROLE_SUCCESS = 'settings/UPDATE_MEMBER_ROLE_SUCCESS'
export const UPDATE_MEMBER_ROLE_ERROR = 'settings/UPDATE_MEMBER_ROLE_ERROR'

export const REMOVE_MEMBER = 'settings/REMOVE_MEMBER'
export const REMOVE_MEMBER_SUCCESS = 'settings/REMOVE_MEMBER_SUCCESS'
export const REMOVE_MEMBER_ERROR = 'settings/REMOVE_MEMBER_ERROR'

export const FREEZE_ACCOUNT = 'settings/FREEZE_ACCOUNT'
export const FREEZE_ACCOUNT_SUCCESS = 'settings/FREEZE_ACCOUNT_SUCCESS'
export const FREEZE_ACCOUNT_ERROR = 'settings/FREEZE_ACCOUNT_ERROR'

export const DEACTIVATE_ACCOUNT = 'security/DEACTIVATE_ACCOUNT'
export const DEACTIVATE_ACCOUNT_SUCCESS = 'security/DEACTIVATE_ACCOUNT_SUCCESS'
export const DEACTIVATE_ACCOUNT_ERROR = 'security/DEACTIVATE_ACCOUNT_ERROR'

export const ACTIVATE_ACCOUNT = 'security/ACTIVATE_ACCOUNT'
export const ACTIVATE_ACCOUNT_SUCCESS = 'security/ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_ERROR = 'security/ACTIVATE_ACCOUNT_ERROR'

export const ORGANIZATIONS_STATUSES_ENUM = {
  ACTIVE: 'active',
  ON_HOLD: 'on-hold',

  // TODO: this mess should be refactored from server side. Need to send 'deactivate' to set status 'canceled' :\
  DEACTIVATED: 'deactivate',
  CANCELED: 'canceled',
}
