import React from 'react'
import { useHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'
import * as routerConstants from 'features/App/app.router.constants'
import moment from 'moment'
import { Button } from 'components'
import {
  Card,
  CardHintText,
  CardText,
  MonthlyBillPrice,
  MonthlyBillPriceContainer,
} from '../billing.styled'
import CurrentMonthlyBillSkeleton from '../skeletons/CurrentMonthlyBillSkeleton'

const CurrentMonthlyBill = ({ paymentPlan, loading }) => {
  const history = useHistory()
  const { id, price, label, updatedAt, duration } = paymentPlan

  if (!id || loading) return <CurrentMonthlyBillSkeleton />

  return (
    <Card className="p-0 mb-2">
      <CardText>
        <FormattedMessage
          id="settings.billing.current.monthly.billing.title"
          defaultMessage="Current Monthly Bill"
        />
      </CardText>
      <MonthlyBillPriceContainer>
        <MonthlyBillPrice>${price / 100}</MonthlyBillPrice>
        <FormattedMessage id={`settings.billing.plan.${label}`} defaultMessage={label} />
      </MonthlyBillPriceContainer>
      <div>
        <CardHintText>
          <FormattedMessage
            id="settings.billing.current.monthly.billing.next.label"
            defaultMessage="Next Recurring Payment On"
          />
        </CardHintText>
        <CardHintText className="font-weight-bold">
          <span>
            {moment(updatedAt)
              .add(duration, 'months')
              .format('MMMM DD, YYYY')}
          </span>
        </CardHintText>

        <Button
          type="primary"
          onClick={() => history.push(routerConstants.SETTINGS_BILLING_SELECT_PLAN_ROUTE)}
          className="w-100 mt-2"
        >
          <FormattedMessage
            id="settings.billing.current.monthly.billing.upgrade.btn"
            defaultMessage="Upgrade My Plan"
          />
        </Button>
      </div>
    </Card>
  )
}

CurrentMonthlyBill.defaultProps = {
  paymentPlan: {},
}

export default CurrentMonthlyBill
