import styled from 'styled-components'
import { Input, Select } from 'antd'
import { BaseTable, Text } from 'components'

const { Search } = Input

export const StyledChartContainer = styled.div`
  height: 270px;
  border: solid 1px #e4e9f0;
  padding-left: 35px;
  padding-bottom: 15px;
  margin-top: 20px;

  @media (max-width: 640px) {
    padding-left: 10px;
  }
`

export const StyledSelect = styled(Select)`
  width: 120px;
`

export const StyledChartTooltip = styled(Text)`
  background: #ffffff;
  padding: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  font-size: 12px;
`

export const StyledChartTooltipLabel = styled(Text)`
  font-weight: 600;
  margin-bottom: 5px;
`

export const StyledChartTooltipPointRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

export const StyledChartTooltipPointCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 7px;
  background-color: ${props => props.serieColor};
`

export const StyledTable = styled(BaseTable)`
  margin-top: 20px;
  cursor: pointer;

  @media (max-width: 520px) {
    margin-top: 10px;

    .ant-table-thead > tr > th:nth-child(2),
    .ant-table-thead > tr > th:nth-child(4),
    .ant-table-tbody > tr > td:nth-child(2),
    .ant-table-tbody > tr > td:nth-child(4) {
      display: none;
    }
  }
`

export const StyledSearchInput = styled(Search)`
  width: 144px !important;
`

export const ThisMonthStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 109px;
  border: 1px solid #e4e9f0;
  margin-top: 20px;

  @media (max-width: 640px) {
    flex-direction: column;
    height: auto;

    > div {
      height: 70px;
      border-bottom: 1px solid #e4e9f0;
      border-left: none;
    }
  }
`

export const ThisMonthStatsCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-left: 24px;
  
  
  > span {
    color: #5d5777;
    font-size: 14px;
    
    > span {
    &:first-word {
      font-weight: 600;
    }
  }
  > div {
    margin-top: 5px;
    font-size: 22px;
    font-weight: bold;
    color: #5d5777;
  }
`

export const StyledChecksBlock = styled(Text)`
  margin-top: 20px;
  border-radius: 4px;
  border: solid 1px #e4e9f0;
  padding: 20px;
  display: flex;
  flex-direction: row;
`

export const StyledChecksCount = styled(Text)`
  font-size: 22px;
  font-weight: 800;
  margin-top: 8px;
`

export const StyledTableFilters = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .ant-input-search {
    margin-left: 15px;
  }

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-bottom: 10px;
    }
    .ant-radio-group {
      margin-bottom: 10px;
    }
    .ant-input-search {
      margin-left: 0;
      width: 100% !important;
    }
  }
`
