import styled from 'styled-components'
import { Text } from 'components'

export const PageTitle = styled(Text)`
  font-weight: bold;
  font-size: 22px;
  color: #524d6a;
`

export const SubTitle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: #524d6a;
  margin-bottom: 12px;
`

export const HintText = styled(Text)`
  font-size: 12px;
  line-height: 1.5;
`

export const HintTextRed = styled(Text)`
  font-size: 12px;
  line-height: 1.5;
  color: #ff2323;
`

export const ApiKeyPermissionRow = styled.div`
  padding: 16px;
  border: solid 1px #e4e9f0;
  border-radius: 2px;
  margin-bottom: 12px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12);
  }
  &.selected {
    border: solid 1px #1890ff;
  }
`

export const ApiKeyPermissionDescription = styled(Text)`
  font-size: 12px;
`

export const CopyToClipBoardBtn = styled.button`
  background: transparent;
  border: none;

  &:active,
  &:focus {
    outline: none;
    opacity: 0.7;
  }
`
