import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chart from './Chart'
import ThisMonthStats from './ThisMonthStats'
import { getStatistics } from '../dashboard.actions'
import { CHART_DEFAULT_TIME_FRAME } from '../dashboard.constants'

const Statistics = () => {
  const dispatch = useDispatch()
  const { statisticsLoading, statistics } = useSelector(state => state.dashboard)

  useEffect(() => {
    dispatch(getStatistics({ timeFrame: CHART_DEFAULT_TIME_FRAME }))
  }, [dispatch])

  const handleTimeFrameChange = value => {
    dispatch(getStatistics({ timeFrame: value }))
  }

  return (
    <Fragment>
      <Chart
        loading={statisticsLoading}
        handleTimeFrameChange={handleTimeFrameChange}
        data={statistics}
      />
      <ThisMonthStats values={statistics} />
    </Fragment>
  )
}

export default Statistics
