import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as constants from './organization.constants'
import * as api from './organization.api'
import * as notificationsConstants from '../Notifications/notifications.constants'

export function* createOrganization({ payload }) {
  try {
    const response = yield call(api.createOrganization, payload)
    yield put({ type: constants.CREATE_ORGANIZATION_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.create.success.message`,
        descriptionId: `notification.settings.organization.create.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.CREATE_ORGANIZATION_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.create.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.create.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getOrganization({ id }) {
  try {
    const response = yield call(api.getOrganization, id)
    yield put({ type: constants.GET_ORGANIZATION_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_ORGANIZATION_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updateOrganization({ payload }) {
  try {
    const response = yield call(api.updateOrganization, payload)
    yield put({ type: constants.UPDATE_ORGANIZATION_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.update.success.message`,
        descriptionId: `notification.settings.organization.update.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.UPDATE_ORGANIZATION_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.update.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.update.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updatePaymentPlan({ payload }) {
  try {
    const response = yield call(api.updatePaymentPlan, payload)
    yield put({ type: constants.UPDATE_PAYMENT_PLAN_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.update.plan.success.message`,
        descriptionId: `notification.settings.billing.update.plan.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.UPDATE_PAYMENT_PLAN_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.billing.paymentPlan.update.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.billing.paymentPlan.update.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* postInvitation({ payload }) {
  try {
    const response = yield call(api.postInvitation, payload)
    yield put({ type: constants.POST_INVITATION_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.post.invitation.success.message`,
        descriptionId: `notification.settings.organization.post.invitation.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.POST_INVITATION_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.post.invitation.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.post.invitation.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getInvitations({ payload }) {
  try {
    const response = yield call(api.getInvitations, payload)
    yield put({ type: constants.GET_INVITATIONS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_INVITATIONS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.get.invitation.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.get.invitation.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updateMemberRole({ payload }) {
  try {
    const response = yield call(api.updateMemberRole, payload)
    yield put({ type: constants.UPDATE_MEMBER_ROLE_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.updateRole.success.message`,
        descriptionId: `notification.settings.organization.updateRole.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.UPDATE_MEMBER_ROLE_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.updateRole.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.updateRole.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* removeMember({ payload }) {
  try {
    const response = yield call(api.removeMember, payload)
    yield put({ type: constants.REMOVE_MEMBER_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.removeMember.success.message`,
        descriptionId: `notification.settings.organization.removeMember.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.REMOVE_MEMBER_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.organization.removeMember.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.organization.removeMember.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* freezeAccount({ payload }) {
  try {
    const response = yield call(api.freezeAccount, payload)
    yield put({ type: constants.FREEZE_ACCOUNT_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.success.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.FREEZE_ACCOUNT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* deactivateAccount({ payload }) {
  try {
    yield call(api.deactivateAccount, payload)
    yield put({
      type: constants.DEACTIVATE_ACCOUNT_SUCCESS,
      payload: { status: constants.ORGANIZATIONS_STATUSES_ENUM.CANCELED },
    })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.success.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.DEACTIVATE_ACCOUNT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* activateAccount({ payload }) {
  try {
    const response = yield call(api.activateAccount, payload)
    yield put({ type: constants.ACTIVATE_ACCOUNT_SUCCESS, payload: response })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.success.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.SUCCESS,
      },
    })
  } catch (err) {
    yield put({ type: constants.ACTIVATE_ACCOUNT_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.security.updateAccountStatus.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.CREATE_ORGANIZATION, createOrganization)])
  yield all([takeEvery(constants.GET_ORGANIZATION, getOrganization)])
  yield all([takeEvery(constants.UPDATE_ORGANIZATION, updateOrganization)])
  yield all([takeEvery(constants.UPDATE_PAYMENT_PLAN, updatePaymentPlan)])
  yield all([takeEvery(constants.POST_INVITATION, postInvitation)])
  yield all([takeEvery(constants.GET_INVITATIONS, getInvitations)])
  yield all([takeEvery(constants.UPDATE_MEMBER_ROLE, updateMemberRole)])
  yield all([takeEvery(constants.REMOVE_MEMBER, removeMember)])
  yield all([takeEvery(constants.FREEZE_ACCOUNT, freezeAccount)])
  yield all([takeEvery(constants.DEACTIVATE_ACCOUNT, deactivateAccount)])
  yield all([takeEvery(constants.ACTIVATE_ACCOUNT, activateAccount)])
}
