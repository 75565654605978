import { all, call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import * as constants from './apiKeys.constants'
import * as api from './apiKeys.api'
import * as notificationsConstants from '../Notifications/notifications.constants'
import * as routerConstants from '../App/app.router.constants'

export function* createApiKey({ payload }) {
  try {
    const response = yield call(api.createApiKey, payload)
    yield put({ type: constants.API_KEY_CREATE_SUCCESS, payload: response })
    yield put(
      push(routerConstants.SETTINGS_API_KEYS_CREATE_SUCCESS_ROUTE, { apiKey: response.apiKey }),
    )
  } catch (err) {
    yield put({ type: constants.API_KEY_CREATE_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.create.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.apiKey.create.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* getApiKeys() {
  try {
    const response = yield call(api.getApiKeys)
    yield put({ type: constants.GET_API_KEYS_SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: constants.GET_API_KEYS_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.get.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.apiKey.get.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* deleteApiKey({ payload }) {
  try {
    yield call(api.deleteApiKey, payload)
    yield put({ type: constants.API_KEY_DELETE_SUCCESS, payload })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.delete.success.message`,
        descriptionId: `notification.settings.apiKey.delete.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.INFO,
      },
    })
  } catch (err) {
    yield put({ type: constants.API_KEY_DELETE_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.delete.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.apiKey.delete.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export function* updateApiKey({ payload }) {
  try {
    yield call(api.updateApiKey, payload)
    yield put({ type: constants.API_KEY_UPDATE_SUCCESS, payload })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.update.success.message`,
        descriptionId: `notification.settings.apiKey.update.success.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.INFO,
      },
    })
  } catch (err) {
    yield put({ type: constants.API_KEY_UPDATE_ERROR })
    yield put({
      type: notificationsConstants.NOTIFICATION_SHOW,
      payload: {
        messageId: `notification.settings.apiKey.update.error.${err.response.data.error.type}.message`,
        descriptionId: `notification.settings.apiKey.update.error.${err.response.data.error.type}.description`,
        type: notificationsConstants.NOTIFICATION_TYPES.ERROR,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(constants.API_KEY_CREATE, createApiKey)])
  yield all([takeEvery(constants.GET_API_KEYS, getApiKeys)])
  yield all([takeEvery(constants.API_KEY_DELETE, deleteApiKey)])
  yield all([takeEvery(constants.API_KEY_UPDATE, updateApiKey)])
}
