import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Skeleton } from 'antd'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../../ApiKeys/apiKeys.styled'
import { Content, ContentFooter, ContentHeader } from '../../Settings/settings.styled'
import { StyledUserProfileFormContainer } from '../userProfile.styled'

const UserProfileSkeleton = () => {
  return (
    <div>
      <Helmet title="Settings - User Profile" />
      <ContentHeader>
        <PageTitle>
          <FormattedMessage id="settings.userProfile.title" defaultMessage="User Profile" />
        </PageTitle>
      </ContentHeader>
      <Content>
        <StyledUserProfileFormContainer>
          <div className="row mb-5 mt-3">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Skeleton.Input className="with-label" active size="large" />
            </div>
          </div>
        </StyledUserProfileFormContainer>
      </Content>

      <ContentFooter>
        <Skeleton.Button active />
      </ContentFooter>
    </div>
  )
}

export default UserProfileSkeleton
