import React, { Fragment, useEffect, useState } from 'react'
import { Radio } from 'antd'
import { FormattedMessage } from 'react-intl'
import { PageSubTitle } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { HISTORY_ITEM_TYPES_FILTER_ENUM } from '../dashboard.constants'
import { StyledTableFilters } from '../dashboard.styled'
import {
  IDENTITY_LIST_DEFAULT_PAGE,
  IDENTITY_LIST_DEFAULT_PAGE_SIZE,
  KYB_TABLE_COLUMNS,
  KYC_TABLE_COLUMNS,
} from '../../Identity/identity.constants'
import { IdentityList } from '../../Identity/components'
import { getPersons, getOrganizations } from '../../Identity/identity.actions'

const History = () => {
  const dispatch = useDispatch()
  const [type, setType] = useState('kyc')
  const { loading, list } = useSelector(state => state.identity)
  const action = type === HISTORY_ITEM_TYPES_FILTER_ENUM.KYC ? getPersons : getOrganizations
  const columns =
    type === HISTORY_ITEM_TYPES_FILTER_ENUM.KYC ? KYC_TABLE_COLUMNS : KYB_TABLE_COLUMNS

  useEffect(() => {
    dispatch(
      action({
        currentPage: IDENTITY_LIST_DEFAULT_PAGE,
        pageSize: IDENTITY_LIST_DEFAULT_PAGE_SIZE,
      }),
    )
  }, [type])

  const handlePageChange = (currentPage, pageSize) => {
    dispatch(
      action({
        currentPage,
        pageSize,
      }),
    )
  }

  const handleTypeChange = e => setType(e.target.value)

  return (
    <Fragment>
      <StyledTableFilters>
        <PageSubTitle>
          <FormattedMessage id="dashboard.subTitle.history" defaultMessage="History" />
        </PageSubTitle>
        <div>
          <Radio.Group
            onChange={handleTypeChange}
            defaultValue={HISTORY_ITEM_TYPES_FILTER_ENUM.KYC}
          >
            <Radio.Button value={HISTORY_ITEM_TYPES_FILTER_ENUM.KYC}>
              <FormattedMessage
                id={`dashboard.history.filter.types.${HISTORY_ITEM_TYPES_FILTER_ENUM.KYC}`}
                defaultMessage="KYC"
              />
            </Radio.Button>
            <Radio.Button value={HISTORY_ITEM_TYPES_FILTER_ENUM.KYB}>
              <FormattedMessage
                id={`dashboard.history.filter.types.${HISTORY_ITEM_TYPES_FILTER_ENUM.KYB}`}
                defaultMessage="KYB"
              />
            </Radio.Button>
          </Radio.Group>
        </div>
      </StyledTableFilters>

      <IdentityList
        handlePageChange={handlePageChange}
        type={type}
        loading={loading}
        data={list}
        columns={columns}
      />
    </Fragment>
  )
}

export default History
