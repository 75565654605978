import styled from 'styled-components'
import { Text } from 'components'
import { Radio } from 'antd'

export const SecurityRow = styled(Text)`
  display: flex;
  flex-direction: row;
  padding: 21px 18px;
  border-radius: 4px;
  border: solid 1px #e4e9f0;
  justify-content: space-between;
  margin-bottom: ${props => (props.noMargin ? '0' : '15px')}
  align-items: center;
  line-height: 1.5;
 
  background: ${props => (props.selected ? 'rgba(228, 233, 240, .2);' : 'none')}
  
  img {
      height: 42px;
      width: 32px;
  }
`

export const StyledRadioGroud = styled(Radio.Group)`
  width: 100%;
`

export const StyledRadio = styled(Radio)`
  min-height: 86px;
  margin-bottom: 15px !important;
  margin-right: 0 !important;

  display: flex !important;
  flex-direction: row;
  align-items: center;

  > span:not(.ant-radio) {
    width: 100%;
    padding-right: 0;
    white-space: initial;
  }
`

export const SecurityRowToggleContainer = styled(Text)`
  min-width: 128px;
  border-left: 1px solid #e4e9f0;
  height: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`
