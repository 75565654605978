import * as constants from './dashboard.constants'

const initialState = {
  applicationsLoading: true,
  statisticsLoading: true,
  applications: {
    data: [],
    meta: {},
  },
  statistics: {},
  error: null,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_STATISTICS: {
      return {
        ...state,
        statisticsLoading: true,
      }
    }
    case constants.GET_APPLICATION_HISTORY: {
      return {
        ...state,
        applicationsLoading: true,
      }
    }
    case constants.GET_APPLICATION_HISTORY_SUCCESS: {
      return {
        ...state,
        applications: action.payload,
        applicationsLoading: false,
      }
    }
    case constants.GET_STATISTICS_SUCCESS: {
      return {
        ...state,
        statistics: action.payload,
        statisticsLoading: false,
      }
    }
    case constants.GET_APPLICATION_HISTORY_ERROR:
    case constants.GET_STATISTICS_ERROR: {
      return {
        ...state,
        error: action.error,
        statisticsLoading: false,
        applicationsLoading: false,
      }
    }
    default:
      return state
  }
}

export default dashboard
