import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { LoginContainerTransparent } from '../../Auth/auth.styled'
import { ErrorTitle, ErrorSubTitle } from '../app.styled'
import * as routerConstants from '../app.router.constants'

const Error500 = () => {
  const history = useHistory()

  return (
    <LoginContainerTransparent>
      <Helmet title="Charge 500 - Oops!" />

      <ErrorTitle>
        <FormattedMessage id="error.500.title" defaultMessage="Oops!" />
      </ErrorTitle>

      <ErrorSubTitle>
        <FormattedMessage
          id="error.500.subtitle"
          defaultMessage="Something went wrong. Our developers are on it!"
        />
      </ErrorSubTitle>

      <Button
        type="primary"
        htmlType="button"
        className="w-100 mt-4"
        onClick={() => history.push(routerConstants.DASHBOARD_ROUTE)}
      >
        <FormattedMessage id="error.500.btn" defaultMessage="Take me to my Dashboard" />
      </Button>

      <Button
        type="link"
        htmlType="button"
        className="w-100 mt-4"
        onClick={() => history.push(routerConstants.DASHBOARD_ROUTE)}
      >
        <FormattedMessage id="error.500.btn.reload" defaultMessage="Reload" />
      </Button>
    </LoginContainerTransparent>
  )
}

export default Error500
