import * as constants from './notifications.constants'

const notifications = (state = {}, action) => {
  switch (action.type) {
    case constants.NOTIFICATION_SHOW: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default notifications
