import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import OrganizationCreate from './views/OrganizationCreate'
import OrganizationUpdate from './views/OrganizationUpdate'

import { setUserAccountId } from '../Auth/auth.actions'

const Organization = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { organization, loading } = useSelector(state => state.organization)

  useEffect(() => {
    if (!user.accountId && organization.id) {
      dispatch(setUserAccountId(organization.id))
    }
  }, [user.accountId, organization.id])

  return user.accountId ? (
    <OrganizationUpdate organization={organization} loading={loading} />
  ) : (
    <OrganizationCreate loading={loading} />
  )
}

export default Organization
