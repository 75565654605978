import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { Skeleton } from 'antd'
import { Collapse, CollapsePanel } from 'components'
import { StyledSubCollapse } from '../identity.styled'
import DataFieldList from './DataFieldList'
import {
  IDENTITY_BUSINESS_NAME_FIELDS_SET,
  IDENTITY_BUSINESS_LOCATION_FIELDS_SET,
  IDENTITY_BUSINESS_CONTACT_FIELDS_SET,
  IDENTITY_BUSINESS_DEVICE_FIELDS_SET,
} from '../identity.constants'
import { mapDataFields } from '../identity.utils'

const IdentityBusinessInformation = ({ data, loading }) => {
  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      nopadding
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <CollapsePanel
        header={
          <span className="font-weight-bold">
            <FormattedMessage
              id="identity.business.details.info"
              defaultMessage="Business Information"
            />
          </span>
        }
        key="1"
      >
        <StyledSubCollapse
          expandIconPosition="right"
          bordered={false}
          defaultActiveKey={['1', '2', '3', '4']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <CollapsePanel
            header={
              <span className="font-weight-bold">
                <FormattedMessage
                  id="identity.business.details.info.name.panel"
                  defaultMessage="Business Name"
                />
              </span>
            }
            key="1"
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <DataFieldList
                data={mapDataFields(IDENTITY_BUSINESS_NAME_FIELDS_SET, data.organization)}
              />
            )}
          </CollapsePanel>

          <CollapsePanel
            header={
              <span className="font-weight-bold">
                <FormattedMessage
                  id="identity.business.details.info.location.panel"
                  defaultMessage="Business Location"
                />
              </span>
            }
            key="2"
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <DataFieldList
                data={mapDataFields(IDENTITY_BUSINESS_LOCATION_FIELDS_SET, data.organization)}
              />
            )}
          </CollapsePanel>
          <CollapsePanel
            header={
              <span className="font-weight-bold">
                <FormattedMessage
                  id="identity.business.details.info.contact.panel"
                  defaultMessage="Business Contact"
                />
              </span>
            }
            key="3"
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <DataFieldList
                data={mapDataFields(IDENTITY_BUSINESS_CONTACT_FIELDS_SET, data.organization)}
              />
            )}
          </CollapsePanel>
          <CollapsePanel
            header={
              <span className="font-weight-bold">
                <FormattedMessage
                  id="identity.business.details.info.device.panel"
                  defaultMessage="Device Data"
                />
              </span>
            }
            key="4"
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <DataFieldList
                data={mapDataFields(IDENTITY_BUSINESS_DEVICE_FIELDS_SET, data.organization)}
              />
            )}
          </CollapsePanel>
        </StyledSubCollapse>
      </CollapsePanel>
    </Collapse>
  )
}

export default IdentityBusinessInformation
