import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form, Modal, Checkbox } from 'antd'
import { Button, FormItemWrapper, FormItem, Select } from 'components'
import { WidgetForm } from '../widgets.styled'

const { Option } = Select

const WidgetFormModal = ({ visible, setVisible, widget }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({
    enablePassword: false,
    warningLimitEnabled: false,
    stopLimitEnabled: false,
  })

  const onFieldsChange = (data, fields) => {
    setFormData(
      fields.reduce((acc, cur) => {
        acc[cur.name[0]] = cur.value
        return acc
      }),
    )
  }

  const handleSubmit = () => {
    form.submit()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onFinish = () => {
    setVisible(false)
  }

  return (
    <Modal
      centered
      visible={visible}
      title={
        widget ? (
          <FormattedMessage id="widgets.settings.modal.title" defaultMessage="Settings" />
        ) : (
          <FormattedMessage id="widgets.create.modal.title" defaultMessage="New Widget" />
        )
      }
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          <FormattedMessage id="widgets.create.modal.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={false} onClick={handleSubmit}>
          {widget ? (
            <FormattedMessage id="widgets.create.modal.update" defaultMessage="Update" />
          ) : (
            <FormattedMessage id="widgets.create.modal.create" defaultMessage="Create" />
          )}
        </Button>,
      ]}
    >
      <WidgetForm
        form={form}
        name="checkout"
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        hideRequiredMark
      >
        <FormItemWrapper
          name="widgetName"
          intlPrefix="widgetName"
          required
          type="string"
          form={form}
        />

        <FormItem
          label={<FormattedMessage id="widget.field.widgetType" defaultMessage="Widget Type" />}
          name="widgetType"
          hasFeedback
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="widget.field.widgetType.error.required"
                  defaultMessage="Please choose your Widget Type"
                />
              ),
            },
          ]}
        >
          <Select placeholder="Choose your Widget Type" allowClear>
            <Option value="kyc">
              <FormattedMessage id="widget.field.widgetType.kyc" defaultMessage="KYC" />
            </Option>
            <Option value="kyb">
              <FormattedMessage id="widget.field.widgetType.KYB" defaultMessage="KYB" />
            </Option>
          </Select>
        </FormItem>

        <FormItem className="mb-0" valuePropName="checked" name="enablePassword">
          <Checkbox>
            <span className="font-weight-bold">
              <FormattedMessage id="widget.field.enablePassword" defaultMessage="Enable password" />
            </span>
          </Checkbox>
        </FormItem>

        <div className="checkbox-description">
          <FormattedMessage
            id="widget.field.enablePassword.description"
            defaultMessage="If selected, this feature will require users to enter a password before filling out your KYC/KYB form. This can help prevent abuse of your KYC/KYB form and save you money."
          />
          {formData.enablePassword ? (
            <FormItemWrapper
              name="password"
              intlPrefix="password"
              required
              type="password"
              form={form}
            />
          ) : null}
        </div>

        <FormItem className="mb-0" valuePropName="checked" name="warningLimitEnabled">
          <Checkbox>
            <span className="font-weight-bold">
              <FormattedMessage id="widget.field.warningLimit" defaultMessage="Warning limit" />
            </span>
          </Checkbox>
        </FormItem>

        <div className="checkbox-description">
          <FormattedMessage
            id="widget.field.warningLimit.description"
            defaultMessage="If selected, this feature will notify you via email when a specified limit of KYC/KYB checks has occurred in a 30 day period. This can help you monitor your spending."
          />
          {formData.warningLimitEnabled ? (
            <FormItemWrapper name="warningLimit" required type="string" form={form} />
          ) : null}
        </div>

        <FormItem className="mb-0" name="stopLimitEnabled" valuePropName="checked">
          <Checkbox>
            <span className="font-weight-bold">
              <FormattedMessage id="widget.field.stopLimit" defaultMessage="Stop limit" />
            </span>
          </Checkbox>
        </FormItem>

        <div className="checkbox-description">
          <FormattedMessage
            id="widget.field.stopLimit.description"
            defaultMessage="If selected, this feature will automatically stop all KYC/KYB checks when a specified limit of checks has occurred in a 30 day period. This can help limit abuse of your KYC/KYB form."
          />
          {formData.stopLimitEnabled ? (
            <FormItemWrapper name="stopLimit" required type="string" form={form} />
          ) : null}
        </div>
      </WidgetForm>
    </Modal>
  )
}

export default WidgetFormModal
