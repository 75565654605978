import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { StyledAuthFooter } from '../auth.styled'
import {
  DATA_PROTECTION_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from '../../App/app.router.constants'

const AuthFooter = () => {
  return (
    <StyledAuthFooter>
      <ul>
        <li>
          <Link target="_blank" to={TERMS_AND_CONDITIONS_ROUTE}>
            <FormattedMessage id="auth.footer.terms" defaultMessage="Terms & Conditions" />
          </Link>
        </li>
        <li>&nbsp;|&nbsp;</li>
        <li>
          <Link target="_blank" to={PRIVACY_POLICY_ROUTE}>
            <FormattedMessage id="auth.footer.privacy" defaultMessage="Privacy Policy" />
          </Link>
        </li>
        <li>&nbsp;|&nbsp;</li>
        <li>
          <Link target="_blank" to={DATA_PROTECTION_ROUTE}>
            <FormattedMessage
              id="auth.footer.data.protection"
              defaultMessage="Data Protection Notice"
            />
          </Link>
        </li>
        <li>&nbsp;|&nbsp;</li>
        <li>
          <a href="mailto:support@charge.io">support@charge.io</a>
        </li>
      </ul>
      <p className="text-center">1100 Highland Avenue, Manhattan Beach, CA 90277, 213-260-0142</p>
      <p className="text-center">&copy; 2020 Charge Technology Group, Inc. All Rights Reserved.</p>
    </StyledAuthFooter>
  )
}

export default AuthFooter
