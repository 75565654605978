import styled from 'styled-components'
import { BaseTable, PageSubTitle, PageTitle, Text, Button } from 'components'

export const Layout = styled.div`
  min-height: 100%;
  width: 100%;
  background: #001433;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  nav ul {
    margin-top: 30px;
    display: inline-flex;
    list-style: none;
    color: rgba(255, 255, 255, 0.6);

    a {
      color: rgba(255, 255, 255, 0.6);

      &:hover {
        color: #ffffff;
      }
    }
  }
`

export const Logo = styled.img`
  display: block;
  width: 252px;
  height: 80px;
  margin: 30px auto 0;
`

export const Inner = styled.div`
  background: white;
  padding: 35px;
  border-radius: 10px;
  width: 350px;
  margin: 25px auto;

  .ant-form-item-children {
    display: block;
  }

  .loader > div {
    position: static;
    display: block;
    width: 50px;
    height: 50px;
    background-color: transparent;
    margin: 0 auto;
  }
`

export const Title = styled(Text)`
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
`

export const Container = styled(Text)`
  max-width: 1120px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px #e4e9f0;
  padding: 36px;
  margin-top: 30px;

  a {
    color: #1890ff;
  }
`

export const LoginContainer = styled(Text)`
  max-width: 350px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px #e4e9f0;
  padding: 36px;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-form label {
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    padding: 25px;
  }
`

export const LoginContainerTransparent = styled(LoginContainer)`
  background: transparent;
  max-width: 450px;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  button {
    max-width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 320px) {
    padding: 15px;
  }

  ${Button} {
    &.ant-btn-link {
      border-radius: 4px;
      border: solid 1px #ffffff;
      color: white;

      span {
        text-decoration: none !important;
      }
    }
  }
`

export const TermsContainer = styled(Container)`
  ${PageTitle} {
    margin-bottom: 30px;
  }
  ${PageSubTitle} {
    margin-bottom: 16px;
  }
  ${Text} {
    margin-bottom: 30px;
    line-height: 1.57;
    text-align: justify;

    ul {
      color: #524d6a;
      display: inline-block;
      list-style: disc;

      li {
        margin-bottom: 10px;
      }
    }
  }
  ${BaseTable} {
    margin-bottom: 16px;
  }
`

export const StyledAuthFooter = styled.nav`
  margin-bottom: 30px;
  font-size: 12px;
  text-align: center;

  ul {
    margin-top: 30px;
    display: inline-flex;
    list-style: none;
    padding: 0;

    a {
      &:hover {
        color: #ffffff;
      }
    }
  }

  @media (max-width: 420px) {
    margin-bottom: 10px;

    > ul {
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
`

export const StyledRetryErrorMessage = styled.div`
  background-color: #e0383e;
  width: 100%;
  border-radius: 6px;
  padding: 27px;
  color: white;
  max-width: 350px;
  margin-top: 30px;
  font-weight: bold;
  font-family: 'Assistant', sans-serif;
  flex-direction: row;
  display: flex;
  line-height: 1.2;

  img {
    display: block;
    margin-right: 24px;
  }
`
