import React from 'react'
import { Skeleton } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Collapse, CollapsePanel, WhiteHorizontalTable } from 'components'
import { MOMENT_DATE_TIME_FORMAT } from '../../App/app.constants'

const DataProvidersResults = ({ data, loading }) => {
  const columns = [
    {
      title: 'Result',
      dataIndex: 'status',
      key: 'status',
      render: value => (
        <FormattedMessage
          id={`dashboard.subTitle.history.row.status.${value}`}
          defaultMessage={value}
        />
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => {
        return <span>{moment(value).format(MOMENT_DATE_TIME_FORMAT)}</span>
      },
      responsive: ['md'],
    },
    {
      title: 'Rule ID',
      dataIndex: 'resultId',
      key: 'resultId',
      responsive: ['md'],
    },
    {
      title: 'Rule Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Details',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md'],
    },
  ]

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      nopadding
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <CollapsePanel
        header={
          <span className="font-weight-bold">
            <FormattedMessage
              id="identity.business.details.data.providers"
              defaultMessage="Data Providers Results"
            />
          </span>
        }
        key="1"
      >
        {loading ? (
          <div className="p-3">
            <Skeleton active />
          </div>
        ) : (
          <WhiteHorizontalTable
            className="utils__scrollTable"
            rowKey="createdAt"
            columns={columns}
            dataSource={data}
            bordered
            size="default"
            pagination={false}
          />
        )}
      </CollapsePanel>
    </Collapse>
  )
}

DataProvidersResults.defaultProps = {
  data: [],
}

export default DataProvidersResults
