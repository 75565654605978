import styled from 'styled-components'
import { Badge, Button, Collapse, Form, Input, Select, Table, Tabs, Radio } from 'antd'

const { Panel } = Collapse
const { TabPane } = Tabs
const { Item } = Form
const { Password } = Input

export const StyledSelect = styled(Select)`
  .ant-select-selection-search {
    height: 41px;
  }
  &.ant-select > .ant-select-selector {
    font-family: 'Assistant', sans-serif;
    font-size: 14px;
    height: 41px;
    padding: 5px 16px;
  }
  .ant-select-selector {
    height: 42px !important;
    align-items: center;
  }
`

export const StyledFormItem = styled(Item)`
  .ant-form-item-label label span {
    font-family: 'Assistant', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #524d6a;
  }

  &.ant-form-item-has-error .ant-input,
  &.ant-form-item-has-error .ant-input-affix-wrapper,
  &.ant-form-item-has-error .ant-input-affix-wrapper:hover,
  &.ant-form-item-has-error .ant-input:hover {
    border-color: #ff4d4f !important;
  }
`

export const StyledBadge = styled(Badge)`
  width: 50px;
  height: 20px;

  .ant-badge-count {
    font-family: 'Assistant', sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;

    color: #ffffff;
    box-shadow: none;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.23px;
  }
`

export const StyledButton = styled(Button)`
  border-radius: 2px;
  font-weight: bold !important;
  font-family: 'Assistant', sans-serif;
  min-width: 128px;
  font-size: 12px !important;

  &.ant-btn-outline {
    border: solid 1px #1890ff;
    color: #1890ff;
  }

  &[disabled] {
    background-color: #e4e9f0 !important;
    border-color: #e4e9f0 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  &.ant-btn-link {
    padding: 0;

    span {
      text-decoration: underline !important;
      font-weight: normal;
    }
  }

  &.ant-btn-secondary {
    border-radius: 2px;
    border: solid 1px #f2f4f8;
    background-color: #f2f4f8;
    color: #1890ff;
  }

  &.ant-btn-success,
  &.ant-btn-success:active,
  &.ant-btn-success:focus {
    background: #48cb2d;
    border: solid 1px #48cb2d;
    color: #ffffff;

    &:hover {
      background: #48cb2d;
      border: solid 1px #48cb2d;
      color: #ffffff;
      opacity: 0.85;
    }
  }
`

export const StyledInput = styled(Input)`
  &.ant-input,
  input.ant-input {
    font-family: 'Assistant', sans-sesrif, serif !important;
    font-size: 14px;
    height: 42px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #e4e9f0;
    padding-left: 16px !important;
  }
`

export const StyledPasswordInput = styled(Password)`
  &.ant-input-password {
    height: 42px;
  }
  &.ant-input,
  input.ant-input {
    font-family: 'Assistant', sans-sesrif, serif !important;
    font-size: 14px;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    padding-left: 16px;
  }
  &.ant-input-affix-wrapper {
    border: solid 1px #e4e9f0;

    input.ant-input {
      border: none;
      padding: 0;
      outline: none;
    }
  }
`

export const StyledBaseTable = styled(Table)`
  border-bottom: none;

  .ant-table {
    border: solid 1px #e4e9f0;
  }

  .ant-table-body {
    border: solid 1px #e4e9f0;
  }

  .ant-table-body tr td {
    font-size: 14px !important;
  }

  .ant-table-thead tr th {
    background-color: #f3f5f8 !important;
    font-size: 14px !important;
    color: #524d6a !important;
    font-weight: 600 !important;

    span {
      font-size: 14px !important;
      color: #524d6a !important;
      font-weight: 600 !important;

      .anticon {
        font-size: 9px !important;
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px;
    border-color: #e4e9f0 !important;
  }

  .ant-table-column-sorters {
    padding: 0;
  }

  @media (max-width: 520px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 10px;
    }
    .ant-table-column-sorters {
      padding: 6px 5px;
    }
  }
  @media (max-width: 320px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 7px;
    }
  }
`

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    background-color: #f3f5f8;
    font-size: 14px;
    height: 52px;
    padding: 15px 40px 15px 16px !important;
    border: #e4e9f0;
  }
  .ant-collapse-item {
    border: solid 1px #e4e9f0;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: solid 1px #e4e9f0;
    }
  }
`

export const StyledCollapsePanel = styled(Panel)`
  background: #ffffff;
`

export const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-tabs-nav {
    display: flex;
    width: 100%;
    margin-bottom: 0 !important;

    > div {
      display: flex;
      width: 100%;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    display: flex;
    flex: 1;
    height: 52px;
    align-items: center;
    justify-content: center;
    background: rgba(243, 245, 248, 0.4);
    margin-right: 0;
    border-right: 1px solid #e4e9f0;
    border-bottom: 1px solid #e4e9f0;
    font-weight: bold;
    color: #524d6a;
    outline: none;

    &:hover {
      color: #524d6a;
      opacity: 0.85;
    }

    &.ant-tabs-tab-active {
      background: #ffffff;
    }
  }
  .ant-tabs-ink-bar {
    background: #e4e9f0;
    height: 1px;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
`

export const StyledTabPane = styled(TabPane)``

export const StyledWhiteHorizontalTable = styled(Table)`
  font-size: 12px;

  table {
    border: none !important;
  }

  .ant-table-thead > tr > th {
    color: #524d6a;
    font-weight: bold;
  }

  tr:last-child td {
    border-bottom: none !important;
  }

  @media (min-width: 520px) {
    tr th,
    tr td {
      padding: 18px 16px !important;

      &:last-child {
        border-right: none !important;
      }
    }
  }

  @media (max-width: 767px) {
    tr th,
    tr td {
      padding: 10px !important;
    }
  }

  .ant-table-column-title {
    color: #524d6a;
    font-weight: bold;
  }
`

export const StyledWhiteVerticalTable = styled(Table)`
  table {
    border: none !important;
  }

  tr th,
  tr td {
    padding: 16px !important;

    &:last-child {
      border-right: none !important;
    }
  }
`

export const StyledRadio = styled(Radio)`
  display: block;
  line-height: 1.5715;
  white-space: normal !important;

  .ant-radio-label-content {
    padding-left: 25px;
    font-size: 12px;

    .ant-radio-label {
      display: block;
      padding-bottom: 15px;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-select-selector {
    height: 42px;
    align-items: center;
  }
`
