import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  'account-settings.api.button': 'Reveal',
  'account-settings.api.add-new-api-button': 'New API Key',
  'account-settings.api.description': 'The API Key is a unique identifier for your requests.',
  'account-settings.basic.update': 'Update Information',
  'account-settings.basic.change-avatar': 'Change Avatar',
  'account-settings.form.message.cc': 'Please input credit card number.',
  'account-settings.form.message.expiry': 'Please insert expiration date.',
  'account-settings.form.message.cvc': 'Please enter security code.',
  'account-settings.form.account.accountName': 'This will be your organization name.',
  'account-settings.form.account.contactName': 'Enter your contact email.',
  'account-settings.form.account.bizName': 'Enter the owner of this organization.',
  'account-settings.notification.messages': 'Charge.io Notifications',
  'account-settings.notification.messages-description':
    'I want to receive news, promotions, and updates from Charge.io.',
  'account-settings.security.bind': 'Bind',
  'account-settings.security.strong': 'Strong',
  'account-settings.security.medium': 'Medium',
  'account-settings.security.weak': 'Weak',
  'account-settings.security.password': 'Account Password',
  'account-settings.security.password-description': 'Current password strength：',
  'account-settings.security.email': 'Backup Email',
  'account-settings.security.email-description': 'Email：',
  'account-settings.security.mfa': 'MFA Device',
  'account-settings.security.mfa-description': 'No MFA device set.',
  'account-settings.security.modify': 'Modify',

  'account-settings.settings.open': 'Yes',
  'account-settings.settings.close': 'No',

  'billing-settings.overview.card1.title': 'Current monthly bill',
  'billing-settings.overview.card2.title': 'Next Payment Due',
  'billing-settings.overview.card1.subtitle': '$7.00',
  'billing-settings.overview.card2.subtitle': 'May 20, 2020',
  'billing-settings.overview.card1.link': 'Switch to yearly billing',
  'billing-settings.overview.card2.link': 'View Payment History',
  'billing-settings.costManagement.contentTitle': 'Monthly Spending Limit',
  'billing-settings.costManagement.contentSubtitle':
    'Set up a monthly limit for KYC/KYB. You can adjust limit at any time.',
  'billing-settings.costManagement.radio1.heading': 'Limit Spending',
  'billing-settings.costManagement.radio2.heading': 'Unlimited Spending',
  'billing-settings.costManagement.radio1.subheading':
    'Set up a spending limit on a monthly basis.',
  'billing-settings.costManagement.radio2.subheading':
    'Pay as much as needed to keep KYC/KYB running',
  'billing-settings.costManagement.radio1.button': 'Update Limit',
  'billing-settings.paymentInfo.button': 'Add Credit Card',
  'billing-settings.paymentInfo.column.payment-link': 'Edit',
  'billing-settings.paymentInfo.column.lastPayment-link': 'View all Payments',
  'billing-settings.paymentInfo.column.coupon-link': 'Redeem A Coupon',
  'billing-settings.paymentInfo.column.extraInfo-link': 'Add Information',
  'billing-settings.paymentForm.title': 'Add Payment Method',

  'dashboard.button.withdraw': 'WITHDRAW',
  'dashboard.chart.range.day': '1 DAY',
  'dashboard.chart.range.week': '1 WEEK',
  'dashboard.chart.range.month': '1 MONTH',
  'dashboard.chart.range.months': '6 MONTHS',
  'dashboard.chart.type.volume': 'VOLUME',
  'dashboard.chart.type.sales': 'SALES',
  'dashboard.chart.header': 'Total Account Balance',
  'dashboard.table.header': 'Total Account History',
  'empty-status.description': 'Oh no, please download Charge app!',
  'forgot-password.retry':
    'It looks like you clicked on an invalid password reset link. Please try again.',
  'forgot-password.subheading': 'Forgot Password?',
  'forgot-password.subtext':
    'Please enter your email address. A link to reset your password will be sent to you.',
  'forgot-password.sendEmail': 'Send Email',
  'forgot-password.largeNumbAttempts':
    'Account is locked because the maximum number of login attempts has been exceeded.',
  'forgot-password.resetPassword': 'Reset your password to access your account.',
  'form.button.submit': 'Submit',
  'form.button.cancel': 'Cancel',
  'form.login': 'Login',
  'form.login.remember': 'Remember me',
  'form.message.address': 'Please enter your address.',
  'form.message.firstName': 'Please enter your first name.',
  'form.message.lastName': 'Please enter your last name.',
  'form.message.city': 'Please enter your city.',
  'form.message.state': 'Please select your state.',
  'form.message.zipCode': 'Please input zip code.',
  'form.message.country': 'Please select country.',
  'form.message.phoneNumber': 'Please enter phone number.',
  'form.profile.button': 'Add Profile',
  'form.register': 'Sign Up',
  'input.field.email': 'Please input your e-mail address',
  'input.field.firstName': 'Please enter your first name',
  'input.field.lastName': 'Please enter your last name',
  'input.field.password': 'Please input your password',
  'inventory.product-table.button.search': 'Search',
  'inventory.product-table.button.reset': 'Reset',
  'inventory.product-table.button.view': 'View',
  'inventory.product-table.button.remove': 'Remove',
  'inventory.product-table.dropdown.allInventory': 'INVENTORY',
  'inventory.product-table.header': 'My Items',
  'inventory.product-table.header.AddProduct': 'ADD PRODUCT',
  'inventory.product-table.menu.sold': 'Sold',
  'inventory.transaction-history.header': 'Transaction History',
  'inventory.transaction-history.table.header': 'Total Account Balance',
  'login.forgotPassword': 'Forgot Password?',
  'login.registerHere': 'Register Here',
  'login.register.question': `Don't have a Charge account?`,
  'login.subheading': 'A customizable KYC widget for customers, merchants, and business leads.',
  'modal.addNewProduct.message.attribute': 'Attribute set is required.',
  'modal.addNewProduct.message.name': 'Name is required. Between 3 and 15 alphanumeric characters.',
  'modal.addNewProduct.message.price': 'Set Price is required.',
  'modal.addNewProduct.message.quantity': 'Quantity is required.',
  'modal.addNewProduct.message.status': 'Status is required.',
  'modal.addNewProduct.message.sku': 'Product SKU is required.',
  'modal.addNewProduct.message.type': 'Type is required.',
  'organization.index.button': 'Add Organization',
  'organization.drawer.title': 'Add New Organization',
  'organization.profile.update': 'Update Profile',
  'organization.teamMember.button': 'Add Team Members',
  'organization.teamMember.title': 'Add Team Members To Your Organizations',
  'organization.teamMember.form.name': `Enter team member's name.`,
  'organization.teamMember.form.email': `Enter team member's email.`,
  'organization.teamMember.form.role': `Please choose team member's role.`,
  'organization.teamMember.form.role-1': 'Admin',
  'organization.teamMember.form.role-2': 'Developer',
  'organization.teamMember.form.role-3': 'Auditor',
  'register.heading': 'charge',
  'register.subheading': 'Register Your Account',
  'register.login.subtext': 'Already have a Charge account?',
  'register.loginLink': 'Login',
  'register.confirmationEmail.subheading': 'Thanks for registering your account!',
  'register.confirmationEmail.subtext':
    'A confirmation email has been sent to you to activate your Charge account.',
  'register.confirmationEmail.confirm': 'CONFIRM',
  'register.validatingEmail': 'Verifying your email',
  'register.validatingEmailSubTitle': 'This will only take a few seconds...',
  'table.columns-title.amount': 'Amount (USD)',
  'table.columns-title.date': 'Date',
  'table.columns-title.description': 'Description',
  'table.columns-title.customer': 'Customer',
  'table.columns-title.status': 'Status',
  'topBar.issuesHistory': 'Issues History',
  'topBar.network': 'Network',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.buyNow': 'Buy Now $24',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profile-menu.hello': 'Hello',
  'topBar.profile-menu.billingPlan': 'Billing Plan',
  'topBar.profile-menu.role': 'Role',
  'topBar.profile-menu.email': 'Email',
  'topBar.profile-menu.phone': 'Phone',
  'topBar.profile-menu.editProfile': 'Account Settings',
  'topBar.profile-menu.logout': 'Logout',
  'topBar.network-management.custom': 'Custom',
  'topBar.network-management.ethMainnet': 'ETH Mainnet',
  'topBar.network-management.ethRinkeby': 'ETH Rinkeby',
  'vault.header': 'My Items',
  'vault.items.button': 'SELL',
  'vault.sell-items.marketplace.message':
    'Please select where you would like to sell your product.',
  'vault.sell-items.price.message': 'There must be a set priced.',
  'wallet-notification.delete.description':
    'Please remove marketplace attached to wallet before removing the wallet!',
  'wallet-notification.delete.message': 'Remove Marketplace(s)',

  'verify.title': 'Your registration is almost done.',
  'verify.subTitle': 'Please, check your email for a verification message.',

  'verify.loginLink': 'Login',
  'verify.backLink': 'Back',

  // APP MENU
  'app.menu.dashboard': 'Dashboard',
  'app.menu.identity': 'Identity',
  'app.menu.identity_customer': 'Customer',
  'app.menu.identity_business': 'Business',
  'app.menu.widgets': 'Widgets',
  'app.menu.settings': 'Settings',
  'app.menu.help': 'Help',
  'app.menu.faqs': 'FAQs',
  'app.menu.doc': 'Doc',
  'app.menu.logout': 'Logout',

  // Auth
  'notification.auth.login.success.message': 'Logged In',
  'notification.auth.login.success.description':
    'You have successfully logged in to your Charge Dashboard!',
  'notification.auth.login.error.NOT_FOUND.message': 'Error signing into your account',
  'notification.auth.login.error.NOT_FOUND.description': 'User not found',
  'notification.auth.login.error.UNAUTHORIZED.message': 'Error signing into your account',
  'notification.auth.login.error.UNAUTHORIZED.description': 'Please verify your email to continue',
  'notification.auth.login.error.BAD_REQUEST.message': 'Error signing into your account',
  'notification.auth.login.error.BAD_REQUEST.description': 'Incorrect password or email',

  'notification.auth.validate.error.message': 'Error validating your email',
  'notification.auth.validate.error.description': 'Try again later',

  'notification.auth.validate.success.message': 'Email successfully validated',
  'notification.auth.validate.success.description':
    'Your email has been validated, you can login now',
  'notification.auth.verify.message': 'Your registration is almost done',
  'notification.auth.verify.description': 'Your registration is almost done',

  'notification.auth.register.success.message': 'You successfully registered',
  'notification.auth.register.success.description': 'Please verify your email to continue',

  'notification.auth.register.invitation.success.message': 'Success',
  'notification.auth.register.invitation.success.description': 'You successfully registered',

  'notification.auth.register.error.CONFLICT.message': 'Error',
  'notification.auth.register.error.CONFLICT.description': 'Email already exists',

  'notification.auth.resetPassword.success.message': 'Password reset success',
  'notification.auth.resetPassword.success.description':
    'Your password has been successfully reset',

  'notification.auth.forgotPassword.success.message': 'Forgot Password Success',
  'notification.auth.forgotPassword.success.description':
    'Please check your email to continue the password reset process',

  'auth.forgotPassword.success.title': 'We have sent a reset link to your email address',
  'auth.forgotPassword.success.description':
    'Please check your email to continue the password reset process',

  'auth.resetPassword.btn': 'Save',
  'auth.resetPassword.title': 'Reset password',

  // Settings menu
  'account-settings.menuMap.profile': 'Profile',
  'account-settings.menuMap.usage': 'Usage',
  'account-settings.menuMap.notifications': 'Notifications',
  'account-settings.menuMap.apiKeys': 'API Key',
  'account-settings.menuMap.security': 'Security Settings',
  'account-settings.menuMap.billing': 'Billing',
  'account-settings.menuMap.basic': 'User Profile',
  'account-settings.menuMap.account': 'Organization',

  // Settings ApiKeys
  'settings.apiKey.title': 'API Keys',
  'settings.apiKey.create.button': 'Create an API Key',
  'settings.apiKey.create.button.maxLimit': 'You can not create more than 5 API keys',
  'settings.apiKey.create.subTitle': 'Create an API Key',
  'settings.apiKey.create.subTitle.permissions': 'API Key Permissions',
  'settings.apiKey.createAndView.button': 'Create & View',
  'settings.apiKey.cancel.button': 'Cancel',
  'settings.apiKey.done.button': 'Done',
  'settings.apiKey.edit.button': 'Edit',
  'settings.apiKey.delete.button': 'Delete',
  'settings.apiKey.save.button': 'Save',
  'settings.apiKey.cancel.success.title': 'API Key Created',
  'settings.apiKey.cancel.success.hint': 'Please copy this key and save it somewhere safe',
  'settings.apiKey.cancel.success.hint.warning':
    'For security reasons, we cannot show it to you again',
  'settings.apiKey.permission.type.admin.title': 'Admin',
  'settings.apiKey.permission.type.dev.title': 'Developer',
  'settings.apiKey.permission.type.compliance.title': 'Compliance',
  'settings.apiKey.permission.type.admin.description': 'Admin Description',
  'settings.apiKey.permission.type.dev.description': 'Developer Description',
  'settings.apiKey.permission.type.compliance.description': 'Compliance Description',
  'settings.apiKey.noRecords': 'You don’t have any API Keys',
  'notification.settings.apiKey.copiedToClipBoard.message': 'Copied',
  'notification.settings.apiKey.create.error.copiedToClipBoard.description':
    'API key has been successfully copied to Clipboard',
  'notification.settings.apiKey.delete.success.message': 'Deleted',
  'notification.settings.apiKey.delete.success.description':
    'API key has been successfully deleted',
  'notification.settings.apiKey.update.success.message': 'Updated',
  'notification.settings.apiKey.update.success.description':
    'API key has been successfully updated',

  // Settings Billing
  'settings.billing.title': 'Billing',
  'settings.billing.contactUs.modal.title': 'Contact Us',
  'settings.billing.contactUs.modal.cancel': 'Cancel',
  'settings.billing.contactUs.modal.send': 'Send Request',
  'settings.billing.contactUs.modal.description.label': 'Request Description',
  'settings.billing.contactUs.modal.contact.type.label': 'How would you like us to contact you?',
  'settings.billing.contactUs.modal.contact.type.phone.label': 'Phone',
  'settings.billing.contactUs.modal.contact.type.email.label': 'Email',
  'settings.billing.current.monthly.billing.title': 'Current Monthly Bill',
  'settings.billing.current.monthly.billing.type.medium': 'Medium',
  'settings.billing.current.monthly.billing.next.label': 'Next Recurring Payment On',
  'settings.billing.current.monthly.billing.upgrade.btn': 'Upgrade My Plan',
  'settings.billing.payment.method.title': 'Payment Method',
  'settings.billing.payment.method.update.link': 'Update Payment Information',
  'settings.billing.plan.period.lifetime': 'Lifetime',
  'settings.billing.plan.period.monthly': 'Monthly',
  'settings.billing.plan.type.contactUs': 'Contact Us',

  'settings.billing.plan.selected.btn': 'Selected',
  'settings.billing.plan.select.btn': 'Select',
  'settings.billing.plan.current.btn': 'Your Plan',
  'settings.billing.transaction.history': 'Invoice History',
  'settings.billing.selectPlan.modal.title': 'Are you sure you want to upgrade your plan?',
  'settings.billing.selectPlan.modal.cancel': 'Cancel',
  'settings.billing.selectPlan.modal.upgrade': 'Yes, upgrade my plan',
  'settings.billing.selectPlan.modal.selected.label': 'Plan Selected',
  'settings.billing.selectPlan.modal.subtotal.label': 'Subtotal',
  'settings.billing.selectPlan.modal.frequency.label': 'Plan selected',
  'settings.billing.selectPlan.modal.total.label': 'Total',
  'settings.billing.selectPlan.title': 'Select A Plan',

  'settings.billing.plan.self-service': 'Self Service',
  'settings.billing.plan.small-business': 'Small Business',
  'settings.billing.plan.custom': 'Custom',
  'settings.billing.plan.large': 'Large',
  'settings.billing.plan.enterprise': 'Enterprise',

  'settings.billing.invoice.details.title': 'Invoice',
  'settings.billing.invoice.details.status': 'Status',
  'settings.billing.invoice.details.startDate': 'Start Date',
  'settings.billing.invoice.details.endDate': 'End Date',
  'settings.billing.invoice.details.dueDate': 'Due Date',
  'settings.billing.invoice.details.billedTo': 'Billed To',
  'settings.billing.invoice.details.totalBalance': 'Total Balance',
  'settings.billing.invoice.details.subtotal': 'Subtotal',
  'settings.billing.invoice.details.radio.summary': 'Summary',
  'settings.billing.invoice.details.radio.detail': 'Detail',
  'settings.billing.invoice.details.payments': 'Payments',
  'settings.billing.invoice.details.charges': 'Charges',

  // Settings Usage
  'settings.usage.title': 'Usage',
  'settings.usage.upgradePlan.button': 'Upgrade My Plan',
  'settings.usage.selectPlan.button': 'Select A Plan',
  'settings.usage.noRecords.title': 'You don’t have a plan yet',
  'settings.usage.noRecords.hint': 'Head over to billing and select a plan to begin',
  'settings.usage.row.label.type': 'Your Plan',
  'settings.usage.row.label.lookups': 'Monthly Lookups',
  'settings.usage.row.label.used': 'Used',
  'settings.usage.row.label.remaining': 'Remaining',
  'settings.usage.row.label.extraLookups': 'Extra lookups',

  // Settings Security
  'settings.security.title': 'Security Settings',
  'settings.security.subTitle': 'Account management',
  'settings.security.changePassword.title': 'Change Password',
  'settings.security.changePassword.subTitle': 'Here you can change your password',
  'settings.security.changePassword.btn': 'Change Password',
  'settings.security.changePassword.modal.title': 'Change Password',
  'settings.security.changePassword.modal.cancel': 'Cancel',
  'settings.security.changePassword.modal.ok': 'Update',
  'settings.security.changePassword.modal.oldPassword.label': 'Old Password',
  'settings.security.changePassword.modal.newPassword.label': 'New Password',
  'settings.security.changePassword.modal.confirmNewPassword.label': 'Confirm New Password',
  'settings.security.deactivate.title': 'Deactivate Account',
  'settings.security.deactivate.subTitle':
    'Your account will be deactivated, your payments will stop, and your API keys will be destroyed.\n' +
    'You will not be able to recover your API keys in the future.',
  'settings.security.deactivate.modal.title': 'Are you sure you want to deactivate your account?',
  'settings.security.deactivate.modal.cancel': 'Cancel',
  'settings.security.deactivate.modal.ok': 'Okay',
  'settings.security.deactivate.modal.description':
    'Your account will be deactivated, your payments will stop, and your API\n' +
    'keys will be destroyed. You will not be able to recover your API keys\n' +
    'in the future.',
  'settings.security.freeze.title': 'Freeze Account',
  'settings.security.freeze.subTitle':
    'Your payments and API keys will be frozen and you won’t be able to make API calls or perform KYC/KYB checks',
  'settings.security.freeze.modal.title': 'Are you sure you want to freeze your account?',
  'settings.security.freeze.modal.cancel': 'Cancel',
  'settings.security.freeze.modal.ok': 'Okay',
  'settings.security.freeze.modal.description':
    'Your payments and API keys will be frozen and you won’t be able to make API calls or perform KYC/KYB checks',
  'settings.security.logout.title': 'Log Out Account',
  'settings.security.logout.subTitle': 'Log out of your account on this device',
  'settings.security.logout.btn': 'Log Out',

  'settings.security.accountStatus': 'Account status',
  'settings.security.active.title': 'Active Account',
  'settings.security.active.subTitle':
    'Your payments and API keys active and you able to make API calls or perform KYC/KYB checks',

  'settings.security.notifications.title': 'Charge Notifications',
  'settings.security.notifications.subTitle':
    'Enable or Disable  news, promotions, and update emails from Charge.',
  'settings.security.notifications.modal.title': 'Are you sure you want to turn off notifications?',
  'settings.security.notifications.modal.description':
    'You will no longer receive promotional emails from Charge Identity. We will always email regarding important information about your account and security.',
  'settings.security.notifications.modal.cancel': 'Cancel',
  'settings.security.notifications.modal.ok': 'Okay',

  'notification.settings.security.updateAccountStatus.error.BAD_REQUEST.message': 'Error',
  'notification.settings.security.updateAccountStatus.error.BAD_REQUEST.description':
    'Account status was not updated',

  'notification.settings.security.updateAccountStatus.success.message': 'Success',
  'notification.settings.security.updateAccountStatus.success.description':
    'Organization was successfully updated',

  'notification.app.notifications.get.error.BAD_REQUEST.message': 'Error',
  'notification.app.notifications.get.error.BAD_REQUEST.description':
    'Could not get user notification',

  'notification.settings.security.toggleNotifications.success.message': 'Success',
  'notification.settings.security.toggleNotifications.success.description':
    'Notification settings was successfully updated',

  // Dashboard
  'dashboard.title': 'Dashboard',
  'dashboard.subTitle.chart': 'Number of KYC / KYB Checks',
  'dashboard.chart.period.week': 'Last Week',
  'dashboard.chart.period.two_week': 'Last 2 Weeks',
  'dashboard.chart.period.month': 'Last Month',
  'dashboard.chart.label.checks': 'Checks',
  'dashboard.subTitle.history': 'History',
  'dashboard.history.filter.types.all_type': 'All types',
  'dashboard.history.filter.types.kyc': 'KYC',
  'dashboard.history.filter.types.kyb': 'KYB',
  'dashboard.performed.checks.label': 'Checks Performed this Month',

  'dashboard.stats.totalApplications': 'Total Applications This Month',
  'dashboard.stats.totalKYCApplications': 'KYC Performed',
  'dashboard.stats.totalKYCApprovedApplications': 'KYC Approved',
  'dashboard.stats.totalKYBApplications': 'KYB Performed',
  'dashboard.stats.totalKYBApprovedApplications': 'KYB Approved',

  'dashboard.subTitle.history.row.status.ACCEPT': 'Approved',
  'dashboard.subTitle.history.row.status.DENY': 'Declined',
  'dashboard.subTitle.history.row.status.PROCESSING': 'Processing',
  'dashboard.subTitle.history.row.status.PENDING': 'Pending',
  'dashboard.subTitle.history.row.status.CREATED': 'Created',
  'dashboard.subTitle.history.row.status.MANUAL_REVIEW': 'Manual review',
  'dashboard.subTitle.history.row.status.CLOSE': 'Closed',

  // Identity
  'identity.business.title': 'KYB List',
  'identity.customer.title': 'KYC List',
  'identity.api.data.checks.name': 'Name',
  'identity.api.data.checks.type': 'Type',
  'identity.api.data.checks.account_number': 'Account number',
  'identity.api.data.checks.routing_number': 'Routing number',

  'identity.api.data.additionalChecks': 'Additional Checks',
  'identity.api.data.checks': 'Checks',
  'identity.api.data.resultStatus': 'Result Status',
  'identity.history': 'Application History',
  'identity.history.tab.history': 'History',
  'identity.history.tab.notes': 'Notes',
  'identity.history.tab.documents': 'Documents',
  'identity.history.tab.documents.upload.btn': 'Upload',
  'identity.history.tab.documents.upload.modal.title': 'Upload a document',
  'identity.history.tab.documents.upload.modal.select.button': 'Select a document',
  'identity.history.tab.documents.upload.modal.description':
    'Click or drag file to this area to upload',
  'identity.history.tab.documents.upload.modal.document.already.exist':
    'Document with this name already exists',
  'identity.history.tab.notes.row.reviewedBy': 'by',
  'identity.business.details.info': 'Business Information',
  'identity.business.details.info.name.panel': 'Business Name',
  'identity.business.details.info.location.panel': 'Business Location',
  'identity.business.details.info.contact.panel': 'Business Contact',
  'identity.business.details.info.device.panel': 'Device Data',
  'identity.business.details.data.providers': 'Data Providers Results',
  'identity.details.footer.approve.btn': 'Approve',
  'identity.details.footer.decline.btn': 'Decline',
  'identity.details.footer.kyb.disabled.note':
    'Please approve or decline all associated KYCs before continuing',

  'identity.details.decline.modal.description':
    'Please give detailed reasons why application was declined',
  'identity.details.decision.modal.cancel': 'Cancel',
  'identity.details.decision.modal.submit': 'Submit',

  'identity.details.decision.ACCEPT.modal.title': 'Approve',
  'identity.details.decision.ACCEPT.modal.description':
    'Please give detailed reasons why application was Approved',

  'identity.details.decision.DENY.modal.title': 'Decline',
  'identity.details.decision.DENY.modal.description':
    'Please give detailed reasons why application was Declined',

  'identity.business.details.display.data': 'Display Data',
  'identity.filter.btn': 'Filter',
  'identity.business.details.linked.applications': 'KYC Linked Applications',
  'identity.business.details.linked.applications.view.btn': 'View',
  'identity.business.details.linked.organization.view.btn': 'View Organization',

  'identity.data.field.businessType': 'Type Of Business',
  'identity.data.field.typeOfBusinessOwnership': 'Type Of Business Ownership',
  'identity.data.field.stateOfFormation': 'State Of Formation',
  'identity.data.field.businessStartDate': 'Business Start Date',
  'identity.data.field.typeOfLocation': 'Type Of Location',
  'identity.data.field.squareFootageOffice': 'Square Footage Office',
  'identity.data.field.numberOfLocations': 'Number Of Locations',
  'identity.data.field.priorBankruptcy': 'Prior Bankruptcy',
  'identity.data.field.contactLastName': 'Last Name',

  'identity.data.field.employees': 'Employees',
  'identity.data.field.typeOfCustomer': 'Type of Customer',
  'identity.data.field.typeOfCustomerOwnership': 'Type of Customer Ownership',

  'identity.data.field.name': 'Person name',
  'identity.data.field.businessName': 'Name',
  'identity.data.field.dba': 'DBA',
  'identity.data.field.ein': 'EIN',

  'identity.data.field.location': 'Location',
  'identity.data.field.contact': 'Contact',
  'identity.data.field.device_data': 'Device data',

  'identity.data.field.address': 'Address',
  'identity.data.field.businessStreetAddress': 'Address',
  'identity.data.field.city': 'City',
  'identity.data.field.businessCity': 'City',
  'identity.data.field.state': 'State',
  'identity.data.field.businessState': 'State',
  'identity.data.field.ZIPCode': 'ZIP Code',
  'identity.data.field.businessZip': 'ZIP Code',
  'identity.data.field.country': 'Country',
  'identity.data.field.businessCountry': 'Country',

  'identity.data.field.identifier': 'Identifier',
  'identity.data.field.firstName': 'First name',
  'identity.data.field.lastName': 'Last name',
  'identity.data.field.contactFirstName': 'First name',
  'identity.data.field.title': 'Title',
  'identity.data.field.contactTitle': 'Title',
  'identity.data.field.phone': 'Phone',
  'identity.data.field.contactPhone': 'Phone number',
  'identity.data.field.phoneNumber': 'Phone number',
  'identity.data.field.email': 'Email',
  'identity.data.field.contactEmail': 'Email',
  'identity.data.field.website': 'Website',

  'identity.data.field.IPAddress': 'IP Address',
  'identity.data.field.ip': 'IP',
  'identity.data.field.deviceFingerprint': 'Device',

  'identity.data.field.date': 'Date',
  'identity.data.field.id': 'ID',
  'identity.data.field.dob': 'Date of birth',
  'identity.data.field.tin': 'TIN',
  'identity.data.field.ssn': 'Social security number',

  'identity.history.tab.history.row.status.setTo': 'the application’s state is set to',
  'identity.history.tab.history.row.by': 'by',
  'identity.history.tab.history.row.at': 'at',

  'identity.business.details.additional.data': 'Additional Data',
  'identity.business.details.api.data.additional.checks': 'Additional Checks',
  'identity.business.details.api.data.result.status': 'Result Status',
  'identity.business.details.api.data.checks': 'Checks',

  'identity.customer.details.info': 'Customer Information',
  'identity.government.issued.id': 'Government Issued ID',
  'identity.government.issued.id.passport.panel': 'Passport',
  'identity.government.issued.id.upload.selfie.label': 'Selfie',
  'identity.government.issued.id.passport.driver.license': 'Driver’s License',
  'identity.government.issued.id.upload.back.label': 'Back',
  'identity.government.issued.id.upload.front.label': 'Front',

  'identity.notes.tab.addNote': 'Add note',
  'identity.notes.tab.modal.title': 'Add new note',
  'identity.notes.tab.modal.cancel': 'Cancel',
  'identity.notes.tab.modal.submit': 'Submit',
  'identity.notes.tab.modal.input.label': 'Please enter note',

  'identity.search.field.email': 'Email',
  'identity.search.field.identifier': 'Identifier',
  'identity.search.field.lastName': 'Last Name',
  'identity.search.field.businessName': 'Business Name',
  'identity.search.field.dba': 'DBA',

  'identity.row.noStatus.message': 'This KYC is linked to Organization',

  'notification.identity.error.imageUpload.type.message': 'Error',
  'notification.identity.error.imageUpload.type.description': 'You can only upload JPG/PNG file',

  'notification.identity.error.imageUpload.sizeLess.message': 'Error',
  'notification.identity.error.imageUpload.sizeLess.description': 'Image must be bigger than 400kb',

  'notification.identity.error.imageUpload.sizeMore.message': 'Error',
  'notification.identity.error.imageUpload.sizeMore.description': 'Image must be smaller than 4MB!',

  'notification.identity.permission.error.message': 'Info',
  'notification.identity.permission.error.description':
    'The Developer role does not have permission to watch customers details. Ask your administrator to change the role.',

  'image.upload.btn': 'Upload',
  'image.selectImage.btn': 'Select an Image',
  'image.upload.description':
    'Drag and drop or select an image file (JPEG or PNG). Minimal size is 400 kilobytes and maximal size is 4 megabytes.',
  'image.upload.modal.cancel': 'Cancel',
  'image.upload.modal.submit': 'Upload',
  'image.selectFile.modal.title': 'Select an Image',
  'image.preview.modal.changeImage': 'Upload new Image',
  'image.preview.modal.title': 'Preview',

  'auth.footer.terms': 'Terms & Conditions',
  'auth.footer.privacy': 'Privacy Policy',
  'auth.footer.data.protection': 'Data Protection Notice',

  // Pre-sale checkout
  'preSaleCheckout.title': 'Thank you for your interest in Charge',
  'preSaleCheckout.description':
    'During the Charge pre-sale customers are able to get the best pricing of our product and lock their rates in for a year. Plans will activate when you process your first KYC or KYB check.',
  'preSaleCheckout.agree.text': 'I agree to',
  'preSaleCheckout.agree.link.terms': 'Terms & Condition',
  'preSaleCheckout.agree.text.and': 'and',
  'preSaleCheckout.agree.link.privacy': 'Privacy Policy',

  'preSaleCheckout.plansTable.column.feature': 'Feature',
  'preSaleCheckout.plansTable.column.selfService': 'Self Service',
  'preSaleCheckout.plansTable.column.smallBusiness': 'Small Business',
  'preSaleCheckout.plansTable.column.enterprise': 'Enterprise',

  'preSaleCheckout.plansTable.value.dashboard': 'Dashboard',
  'preSaleCheckout.plansTable.value.api': 'API',
  'preSaleCheckout.plansTable.value.kycKybWidget': 'KYC/KYB Widget',
  'preSaleCheckout.plansTable.value.reporting': 'Reporting & Archiving',
  'preSaleCheckout.plansTable.value.teamMembers': 'Teams Members',
  'preSaleCheckout.plansTable.value.recurringSanctions': 'Recerring Sanctions & PEP List Screening',
  'preSaleCheckout.plansTable.value.machineLearning': 'Machine Learning Enables Widget',
  'preSaleCheckout.plansTable.value.customizableWidget': 'Customizable Widget',
  'preSaleCheckout.plansTable.value.customizableReporting': 'Customizable Reporting',
  'preSaleCheckout.plansTable.value.kycKybChecks': 'KYC/KYB Checks',
  'preSaleCheckout.plansTable.value.platformFee': 'Platform Fee',

  'preSaleCheckout.plansTable.value.unlimited': 'Unlimited',
  'preSaleCheckout.plansTable.value.manual': 'Manual',
  'preSaleCheckout.plansTable.value.quarterly': 'Quarterly',
  'preSaleCheckout.plansTable.value.monthly': 'Monthly',
  'preSaleCheckout.plansTable.value.limited': 'Limited',
  'preSaleCheckout.plansTable.value.full': 'Full',

  'preSaleCheckout.plansTable.value.kycKybChecks.selfService': '$4.00 - Flat fee',
  'preSaleCheckout.plansTable.value.kycKybChecks.smallBusiness': '$4.00 - $3.50 - Volume based',
  'preSaleCheckout.plansTable.value.kycKybChecks.enterprise': 'Contact Us',

  'preSaleCheckout.plansTable.value.platformFee.selfService': '$99 monthly',
  'preSaleCheckout.plansTable.value.platformFee.smallBusiness': '$399 monthly',
  'preSaleCheckout.plansTable.value.platformFee.enterprise': 'Contact Us',

  'preSaleCheckout.plansTable.hint.1':
    'Our platform fee is a monthly fee that gives you access to your dashboard and historical archive of data. No checks are included.',

  'preSaleCheckout.account.details': 'Contact Us',
  'preSaleCheckout.account.details.field.firstName': 'First Name',
  'preSaleCheckout.account.details.field.firstName.error.required': 'Please enter your First Name',
  'preSaleCheckout.account.details.field.lastName': 'Last Name',
  'preSaleCheckout.account.details.field.lastName.error.required': 'Please enter your Last Name',
  'preSaleCheckout.account.details.field.companyName': 'Company Name',
  'preSaleCheckout.account.details.field.companyName.error.required':
    'Please enter your Company Name',
  'preSaleCheckout.account.details.field.email': 'Email',
  'preSaleCheckout.account.details.field.email.error.required': 'Please enter your Email',
  'preSaleCheckout.account.details.field.email.error.email': 'Please enter valid Email address',
  'preSaleCheckout.account.details.field.phone': 'Phone',
  'preSaleCheckout.account.details.field.phone.error.required': 'Please enter your Phone',
  'preSaleCheckout.account.details.field.plan': 'Plan',
  'preSaleCheckout.account.details.field.plan.error.required': 'Please choose your Plan',
  'preSaleCheckout.account.details.field.plan.selfService': 'Self Service',
  'preSaleCheckout.account.details.field.plan.smallBusiness': 'Small Business',
  'preSaleCheckout.account.details.field.plan.enterprise': 'Enterprise',
  'preSaleCheckout.account.details.field.password': 'Password',
  'preSaleCheckout.account.details.field.password.error.required': 'Please enter your Password',
  'preSaleCheckout.account.details.field.confirmPassword': 'Confirm Password',
  'preSaleCheckout.account.details.field.confirmPassword.error.required':
    'Please enter your Confirm Password',

  'preSaleCheckout.payment.info': 'Payment Information',
  'preSaleCheckout.payment.info.field.cardNumber': 'Credit Card Number',
  'preSaleCheckout.payment.info.field.cardNumber.error.required':
    'Please enter your Credit Card Number',
  'preSaleCheckout.payment.info.field.billingName': 'Billing Name',
  'preSaleCheckout.payment.info.field.billingName.error.required': 'Please enter your Billing Name',
  'preSaleCheckout.payment.info.field.expirationDate': 'Expiration Date',
  'preSaleCheckout.account.details.field.expirationDate.error.required':
    'Please enter your Expiration Date',
  'preSaleCheckout.payment.info.field.cvc': 'CVC',
  'preSaleCheckout.account.details.field.cvv.error.required': 'Please enter your CVC',
  'preSaleCheckout.payment.info.field.billingZipCode': 'Billing Zip Code',
  'preSaleCheckout.account.details.field.billingZipCode.error.required':
    'Please enter your Billing Zip Code',
  'preSaleCheckout.account.details.field.billingZipCode.error.valid':
    'Please enter valid Billing Zip Code',
  'preSaleCheckout.success.title': 'Thank you!',
  'preSaleCheckout.success.description':
    'Your account has been created. We will email you when the dashboard is launched.',
  'preSaleCheckout.success.footer':
    'In the meantime, if you have any questions about your account you can reach out to our support team at',
  'preSaleCheckout.summary.table.column.details': 'Order Details',
  'preSaleCheckout.summary.table.column.unit': 'Unit',
  'preSaleCheckout.summary.table.column.price': 'Price',
  'preSaleCheckout.summary.table.column.amount': 'Amount',
  'preSaleCheckout.success.orderSummary': 'Order Summary',
  'preSaleCheckout.success.orderSummary.date': 'Order placed on',
  'preSaleCheckout.success.orderSummary.total': 'Total',
  'preSaleCheckout.success.orderSummary.oneYearDiscount': '1 year discount 10%',
  'preSaleCheckout.success.orderSummary.cardTransactions':
    'Credit Card Transactions (ending in {lastFour})',
  'preSaleCheckout.success.orderSummary.balance': 'Balance',

  'kycKybChecks.smallBusiness.oneYearDiscount': 'Total',

  'error.404.title': '404',
  'error.404.subtitle': 'Looks like you are lost! The page you are looking for does not exist',
  'error.404.btn': 'Take me to my Dashboard',

  'error.500.title': '500',
  'error.500.subtitle': 'Something went wrong. Our developers are on it!',
  'error.500.btn': 'Take me to my Dashboard',
  'error.500.btn.reload': 'Reload',

  'settings.userProfile.title': 'User Profile',
  'user.form.upload.selfie.label': 'Photo',
  'settings.userProfile.update.button': 'Update',
  'settings.organization.title': 'Organization Settings',
  'settings.organization.delete.member.modal.title': 'Remove team member',
  'settings.organization.delete.member.modal.description':
    'Are you sure you want to remove team member?',
  'settings.organization.paymentInfo': 'Payment Information',
  'settings.organization.selectPlan': 'Select Plan',

  'settings.organization.teamMembers': 'Team Members',
  'settings.organization.pendingInvitations': 'Pending Invitations',
  'settings.organization.member.remove.button': 'Remove from organization',
  'settings.organization.member.changeRole.button': 'Change Role',

  'settings.organization.member.role.admin': 'Administrator',
  'settings.organization.member.role.dev': 'Developer',
  'settings.organization.member.role.compliance': 'Compliance',

  'settings.organization.member.invitation.status.pending': 'Pending',
  'settings.organization.member.invitation.status.completed': 'Completed',
  'settings.organization.member.invitation.status.canceled': 'Canceled',

  'settings.organization.changeRole.member.modal.title': 'Change role',
  'settings.organization.changeRole.member.modal.description': 'Please select new user role',

  'notification.settings.organization.update.success.message': 'Success',
  'notification.settings.organization.update.success.description':
    'Organization was successfully updated!',

  'notification.settings.organization.updateRole.success.message': 'Success',
  'notification.settings.organization.updateRole.success.description':
    'Member role was successfully updated!',

  'notification.settings.organization.removeMember.success.message': 'Success',
  'notification.settings.organization.removeMember.success.description':
    'Member was successfully removed!',

  'notification.settings.organization.post.invitation.success.message': 'Success',
  'notification.settings.organization.post.invitation.success.description':
    'Organization invitation was successfully sent!',

  'notification.settings.billing.update.plan.success.message': 'Success',
  'notification.settings.billing.update.plan.success.description':
    'Your plan has been successfully updated!',

  'notification.settings.billing.paymentMethod.plan.success.message': 'Success',
  'notification.settings.billing.paymentMethod.plan.success.description':
    'Your Payment Method has been successfully updated!',

  'notification.settings.organization.create.success.message': 'Success',
  'notification.settings.organization.create.success.description':
    'Organization was successfully created!',

  'notification.settings.userProfile.create.success.message': 'Success',
  'notification.settings.userProfile.create.success.description':
    'Profile was successfully created!',

  'notification.settings.userProfile.update.success.message': 'Success',
  'notification.settings.userProfile.update.success.description':
    'Your profile has been successfully updated!',

  'notification.settings.security.updatePassword.success.message': 'Success',
  'notification.settings.security.updatePassword.success.description':
    'Your password has been successfully updated!',

  'settings.settings.organization.noAccount.title':
    'Hey, to access this page you need to set up your organization first',
  'settings.settings.organization.noAccount.btn': 'Set up my Organization now',

  'settings.settings.organization.noPermission.title':
    'You don’t have permission to view this page',
  'settings.settings.organization.noPermission.description':
    'Contact your organization’s admin to request increased permission',

  'settings.addPaymentMethod.title': 'Add payment method',
  'settings.addPaymentMethod.button.save': 'Save a Payment Method',

  // widgets
  'widgets.list.title': 'Widgets',
  'widgets.list.button.create': 'Create widget',
  'widgets.create.modal.title': 'New Widget',
  'widgets.settings.modal.title': 'Settings',
  'widgets.create.modal.cancel': 'Cancel',
  'widgets.create.modal.create': 'Create',
  'form.item.widgetName': 'Widget Name',
  'widget.field.widgetType': 'Widget Type',
  'widget.field.widgetType.error.required': 'Please choose your Widget Type',
  'widget.field.widgetType.kyc': 'KYC',
  'widget.field.widgetType.KYC': 'KYC',
  'widget.field.widgetType.kyb': 'KYB',
  'widget.field.widgetType.KYB': 'KYB',
  'widget.field.enablePassword': 'Enable password',
  'widget.field.enablePassword.description':
    'If selected, this feature will require users to enter a password before filling out your KYC/KYB form. This can help prevent abuse of your KYC/KYB form and save you money.',
  'widget.field.warningLimit': 'Warning limit',
  'widget.field.warningLimit.description':
    'If selected, this feature will notify you via email when a specified limit of KYC/KYB checks has occurred in a 30 day period. This can help you monitor your spending.',
  'form.item.warningLimit': 'Warning limit',
  'widget.field.stopLimit': 'Stop limit',
  'widget.field.stopLimit.description':
    'If selected, this feature will automatically stop all KYC/KYB checks when a specified limit of checks has occurred in a 30 day period. This can help limit abuse of your KYC/KYB form.',
  'form.item.stopLimit': 'Stop limit',
  'widget.list.item.settings.preview': 'Preview',
  'widget.list.item.settings.button': 'Settings',
  'widget.list.item.delete.button': 'Delete',
  'widgets.delete.modal.title': 'Delete Widget',
  'widgets.delete.modal.cancel': 'Cancel',
  'widgets.delete.modal.submit': 'Delete Widget',
  'widgets.delete.modal.description': 'Are you sure you want to delete this widget?',
  'widgets.create.modal.update': 'Update',

  // form fields
  'form.item.businessName': 'Organization Name',
  'form.item.businessName.error.required': 'Please enter your Organization Name',
  'form.item.legalBusinessName': 'Name of Business or Institution',
  'form.item.legalBusinessName.error.required': 'Please enter your Name of Business or Institution',
  'form.item.billingStreetName': 'Business Address',
  'form.item.billingStreetName.error.required': 'Please enter your Business Address',
  'form.item.city': 'City',
  'form.item.city.error.required': 'Please enter your City',
  'form.item.zipCode': 'Zip Code',
  'form.item.zipCode.error.required': 'Please enter your Zip Code',
  'form.item.state': 'State',
  'form.item.state.error.required': 'Please enter your State',
  'form.item.country': 'Country',
  'form.item.country.error.required': 'Please enter your Country',
  'form.item.email': 'Email',
  'form.item.email.error.required': 'Please enter your Email',
  'form.item.email.error.notValid': 'Please enter valid Email',
  'form.item.phone': 'Phone number',
  'form.item.phone.error.required': 'Please enter your Phone number',
  'form.item.firstName': 'First Name',
  'form.item.firstName.error.required': 'Please enter your First Name',
  'form.item.lastName': 'Last Name',
  'form.item.lastName.error.required': 'Please enter your Last Name',
  'form.item.address': 'Address',
  'form.item.address.error.required': 'Please enter your Address',
  'form.item.teamMembers': 'Team Members',
  'form.item.inviteMember': 'Invite with email',
  'form.item.companyName': 'Company Name',
  'form.item.companyName.error.required': 'Please enter your Company Name',
  'form.item.password': 'Password',
  'form.item.oldPassword': 'Old Password',
  'form.item.password.error.required': 'Please enter your Password',
  'form.item.oldPassword.error.required': 'Please enter your Old Password',
  'form.item.password.error.notValid':
    'Passwords must be at least 8 characters, have one capital letter, one number, and one special character.',
  'form.item.oldPassword.error.notValid':
    'Passwords must be at least 8 characters, have one capital letter, one number, and one special character.',
  'form.item.confirmPassword': 'Confirm Password',
  'form.item.confirmPassword.error.required': 'Please confirm your Password',
  'form.item.phone.error.notValid': 'Please enter valid Phone Number',
  'form.item.button.save': 'Save',
  'form.item.button.invite': 'Invite',
  'form.item.button.find': 'Find',
  'form.item.button.reset': 'Reset',
}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
