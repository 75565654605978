import React from 'react'
import { PageSubTitle, PageTitle, Text } from 'components'
import { TermsContainer } from '../auth.styled'

const TermsAndConditions = () => {
  return (
    <TermsContainer>
      <PageTitle>Charge Identity Terms and Conditions</PageTitle>

      <Text>
        Charge Identity Terms and Conditions These terms and conditions (the “Agreement”) govern
        access to and use of the Charge Identity Platform (the “Platform”) made available by Charge
        Technology Group, Inc. (“Charge”). By registering for or accessing the Platform, or using
        the services provided through the Platform, the business identified when registering to use
        the Platform (the “Customer”) indicate you understand this Agreement and agree to be bound
        by this Agreement. This Agreement incorporates by reference all amendments and addenda made
        to the Agreement.
      </Text>

      <PageSubTitle>1. Access</PageSubTitle>

      <Text>
        1.1 Access. Subject to Customer’s compliance with the terms and conditions of this
        Agreement, Charge hereby grants Customer a worldwide, non-exclusive, non-transferable,
        non-sublicensable right to use and access the Platform during the Term and solely for
        Customer’s internal business purposes and in accordance with the intended use of the
        Platform as communicated by Charge from time to time.
      </Text>

      <PageSubTitle>2. Responsibilities and Restrictions</PageSubTitle>

      <Text>
        2.1 Charge Identity Terms and Conditions These terms and conditions (the “Agreement”) govern
        access to and use of the Charge Identity Platform (the “Platform”) made available by Charge
        Technology Group, Inc. (“Charge”). By registering for or accessing the Platform, or using
        the services provided through the Platform, the business identified when registering to use
        the Platform (the “Customer”) indicate you understand this Agreement and agree to be bound
        by this Agreement. This Agreement incorporates by reference all amendments and addenda made
        to the Agreement. 1.Access1.1Access. Subject to Customer’s compliance with the terms and
        conditions of this Agreement, Charge hereby grants Customer a worldwide, non-exclusive,
        non-transferable, non-sublicensable right to use and access the Platform during the Term and
        solely for Customer’s internal business purposes and in accordance with the intended use of
        the Platform as communicated by Charge from time to time. 2.Responsibilities and
        Restrictions2.1Restrictions. Except as expressly permitted by this Agreement, Customer will
        not, and will not permit any third party (including Authorized Users) to, use the Platform
        in any manner beyond the scope of the rights expressly granted in this Agreement. Customer
        will not at any time, directly or indirectly, and will not permit any third party to: (a)
        modify or create derivative works of the Platform, in whole or in part; (b) reverse
        engineer, disassemble, decompile, decode or otherwise attempt to derive or gain improper
        access to any software component of the Platform, in whole or in part; (c) sell, resell,
        rent or lease use of the Platform to any other third party, or otherwise allow the Platform
        to be used for any purpose other than for the benefit of Customer in accordance with this
        Agreement; (d) use the Platform to store, transmit, upload or post any infringing, libelous
        or otherwise unlawful or tortious material or any data for which it does not have the
        necessary consents or rights to store, transmit, upload or post (as applicable) in
        connection with the Platform; (e) interfere with, or disrupt the integrity or performance
        of, the Platform, or any data or content contained therein or transmitted thereby; (f)
        access or search the Platform (or download any data or content contained therein or
        transmitted thereby) through the use of any engine, software, tool, agent, device or
        mechanism (including spiders, robots, crawlers or any other similar data mining tools) other
        than software or Platform features provided by Charge for use expressly for such purposes;
        (g) use the Platform, any documentation provided by Charge or any other Charge’s
        Confidential Information to develop, commercialize, license or sell any product, service or
        technology that could, directly or indirectly, compete with the Platform; (h) use the
        Platform in any way that exceeds any usage limitations as specified in any communication by
        Charge from time to time; or (i) delete or in any manner alter the copyright, trademark, and
        other proprietary rights notices appearing on or included in the Platform or the Platform
        Output as delivered.
      </Text>

      <Text>
        2.2 Authorized Users. Customer will not permit any other third party to access, use or
        operate the Platform, except that Customer may permit Authorized Users to access and use the
        Platform consistent with the terms of this Agreement; provided that Customer will ensure
        that each such Authorized User complies with all applicable laws, rules, and regulations, as
        well as all terms and conditions of the Agreement (including applicable privacy policies,
        acceptable use policies and terms and conditions which may be posted or made available
        through the Platform), and Customer is fully and directly responsible to Charge for any act
        or omission by each such Authorized User in connection with their use of the Platform.
        Customer will, and will require all Authorized Users to, use all reasonable means to secure
        user names and passwords, hardware and software used to access the Platform, and will
        promptly notify Charge if Customer or any Authorized User knows or reasonably suspects that
        any user name and password has been compromised. Each account for access to and use of the
        Platform may only be accessed and used by the specific Authorized User for whom such account
        is created. Customer will further ensure that no Authorized User misrepresents their
        identity or otherwise provides any deceptive or misleading profile information or images
        when creating an account in connection with the Platform. In this Agreement, “Authorized
        User” means any employee of Customer who is authorized by Customer to use the Platform.
      </Text>

      <Text>
        2.3 Third Party Software. The Platform may include, or may provide Customer with access to,
        software or other technology licensed to Charge from third parties, and which may be owned
        by such third parties (collectively, “Third Party Software”). Customer acknowledges and
        agrees that Third Party Software is provided solely on an “AS IS” basis, and that Charge
        does not make any warranties or guarantees regarding Third Party Software and is not
        responsible for the operation or failure of, or any errors or bugs in, any Third Party
        Software.
      </Text>

      <Text>
        2.4 Third Party Services. Certain features and functionalities within the Platform as Charge
        determines in its sole discretion may allow Customer and its Authorized Users to interface
        or interact with, access and/or use compatible third-party services, products, technology
        and content (collectively, “Third Party Services”) through the Platform. Customer hereby
        acknowledges and agrees that: (a) Charge is not the provider of the Third Party Services and
        is not responsible for any compatibility issues, errors or bugs in the Platform or Third
        Party Services caused in whole or in part by the Third Party Services or any update or
        upgrade thereto; and (b) Customer is solely responsible for maintaining the Third Party
        Services and obtaining any associated licenses and consents necessary to use the Third Party
        Services in connection with the Platform.
      </Text>

      <Text>
        2.5 Acceptable Use. Customer may not use the Platform: (a) in violation of this Agreement;
        (b) in a way that infringes, violates, dilutes or misappropriates the intellectual property
        rights of any third party or any rights of publicity or privacy; (c) in violation of any
        law, statute, ordinance or regulation, including related to unfair competition,
        anti-discrimination, unfair or deceptive acts or practices, and/or false advertising; or (d)
        to engage in any illegal, fraudulent, offensive, indecent, inappropriate or objectionable
        conduct or content. Customer may access the Platform only through the interfaces and
        protocols provided or authorized by Charge. Customer may not access the Platform through
        unauthorized means, such as unlicensed software clients.
      </Text>

      <Text>
        2.6 Participant Information. “Participant Information” means information supplied by or on
        behalf of a subject of an identity verification in connection with such verification,
        including any non-public information, images and metadata Customer will: (a) provide Charge
        with Participant Information in the form and format requested by Charge, or as otherwise
        required to access and use the Platform; (b) be responsible for all Participant Information;
        (c) accurately supply all data requested to access and use the Platform, (d) ensure
        compliance with all laws, rules, and regulations applicable to its use of the Platform; (e)
        implement and maintain appropriate safeguards to identify data and processing errors, (f)
        implement and maintain appropriate information security controls with regard to the
        sensitivity of Participant Information, and (g) obtain all waivers, consents and other
        rights necessary for Charge to use Participant Information to provide the Platform and the
        services provided thereby to Customer and in accordance with this Agreement.
      </Text>

      <Text>
        2.7 Suspension, Limitation or Termination. Charge is entitled, without liability to
        Customer, to immediately suspend, terminate or limit Customer’s access to any or all part of
        the Platform at any time in the event: (a) that Charge reasonably suspects that the Platform
        is being used in violation of any applicable law or regulation or in a manner inconsistent
        with this Agreement or the Platform’s intended use as communicated by Charge from time to
        time; (b) that Charge determines that the Platform is being used in an unauthorized or
        fraudulent manner; (c) that Charge determines that the use of the Platform adversely affects
        Charge’s equipment or service to other subscribers and customers; (d) Charge is prohibited
        by an order of a court or other governmental agency from providing the Platform; or (e) any
        other event which Charge determines, in its sole discretion, may create a risk to the
        Platform or to any other users of the Platform. Without limitation, Charge will have no
        liability for any damages, liabilities or losses as a result of any suspension, limitation
        or termination of Customer’s right to use the Platform in accordance with this Agreement.
      </Text>

      <PageSubTitle>3. Term, Fees and Payment</PageSubTitle>

      <Text>
        3.1 Term. The term of this Agreement begins on the date of Customer’s registration for the
        Platform (the “Effective Date”) and, unless terminated earlier pursuant to this Agreement,
        will continue in effect for three (3) years thereafter, and will then renew for unlimited,
        successive, one-year terms unless a party gives ninety (90) days prior written notice of its
        intent not to renew. (the “Term”).
      </Text>

      <Text>
        3.2 Platform and Use Fees. In consideration for the Charge’s provision of the Platform, for
        each month during the Term, Customer will pay the subscription platform fee as selected by
        the Customer in the Order Form, per-use fee, and all applicable taxes (collectively,
        “Fees”). Charge may ask Customer to supply additional information relevant to payment of
        Fees, including Customer’s credit card number, the expiration date of that credit card and
        contact information for bill processing and notification (such information, “Payment
        Information”). By purchasing a subscription, Charge (or its third-party payment processor)
        will automatically collect the Fees using the Payment Information provided.By agreeing to
        this Agreement and electing to subscribe for access to the Platform, Customer acknowledges
        that its subscription has recurring payment features and accepts responsibility for all
        recurring payment obligations prior to cancellation of the Agreement by Customer or Charge.
      </Text>

      <Text>
        3.3 Invoices and Payment. Charge will invoice Customer depending on the type of subscription
        program purchased as reflected in the Order Form. Customer can either purchase: (i) a
        standard subscription where Charge will invoice Customer for all applicable Fees on a
        monthly basis following the Effective Date (“Standard Subscription”); or (ii) an advance
        payment subscription where Customer will be charged for all expected Fees in advance of the
        service month (“Advance Fee Subscription”). Customer will be responsible for any costs or
        Fees in excess of the Advance Fee Subscription amount. Charge may suspend Customer’s ability
        to access all or part of the Platform if Customer’s use of the Platform exceeds the value of
        the Advance Fee Subscription. All payments made under this Agreement to Charge will be made
        in U.S. dollars of immediately available funds to an account designated by Charge using the
        Payment Information and will be non-refundable. Any amounts due to Charge hereunder and not
        paid when due will accrue late charges at the lesser of a rate of 1.5% per month or the
        highest rate permitted by applicable law. Customer will reimburse Charge for all costs and
        expenses incurred (including attorneys’ fees) in collecting overdue amounts hereunder.
      </Text>

      <Text>
        3.4 No Set-Off. Customer will not have any right to set off, discount, or otherwise reduce
        or refuse to pay any amounts due under this Agreement for any reason.
      </Text>

      <Text>
        3.5 Taxes. The Fees described in Section 3.2 are exclusive of all taxes, including national,
        state, provincial, territorial and local use, sales, value-added, property and similar
        taxes, if any. Customer agrees to pay such taxes (excluding U.S. taxes based on Charge’s net
        income) unless Customer has provided Charge with a valid exemption certificate. In the case
        of any withholding requirements, Customer will pay any required withholding itself and will
        not reduce the amount paid to Charge on account thereof.
      </Text>

      <Text>
        3.6 Termination. Without limiting any right or remedy available to either party, either
        party may terminate this Agreement, effective on written notice to the other party, if the
        other party breaches this Agreement, and such breach remains uncured for thirty (30) days or
        longer after the non-breaching party provides the breaching party with written notice of
        such breach.
      </Text>

      <Text>
        3.7 Effect of Termination. Upon termination or expiration of this Agreement: (a) the license
        granted under Section 1.1 will terminate; (b) Customer will, and will cause its Authorized
        Users to, immediately cease using the Platform, and will destroy all copies of all content
        provided by Charge hereunder; (c) Charge will have no further obligation to provide access
        to the Platform or Platform Output (as defined below); (d) Customer will pay any unpaid
        Fees; and (e) each party will return or destroy all copies of Confidential Information of
        the other party (in accordance with the other party’s direction).
      </Text>

      <Text>
        3.8 Survival. Sections 2.1 (Restrictions), 2.2 (Authorized Users), 2.3 (Third Party
        Software), 2.4 (Third Party Services), 3.2 (Fees and Payment), 3.3 (No Set-Off), 3.4
        (Taxes), 3.6 (Effect of Termination), 3.7 (Survival), 5 (Intellectual Property Rights), 6
        (Confidentiality), 7 (Representations and Warranties), 8 (Indemnification), 9 (Limitation of
        Liability), 10 (Dispute Resolution; Arbitration Agreement; No Class Action) and 11 (General
        Provisions) are expressly intended to survive any expiration or termination of this
        Agreement.
      </Text>

      <PageSubTitle>4. Privacy</PageSubTitle>

      <Text>
        4.1 Charge Data Processing Terms. Where Charge processes Participant Information that is
        “personal data” or “personal information” under applicable data protection laws on behalf of
        Customer (“Personal Information”), Charge shall:
        <div>
          <ul>
            <li>(a) only process Personal Information in accordance with this Agreement;</li>
            <li>
              (b) ensure that Charge’s personnel authorized to process Personal Information have
              committed themselves to confidentiality;
            </li>
            <li>
              (c) implement commercially reasonable technical and organizational measures designed
              to protect Personal Information. If Charge becomes aware of any unauthorized access,
              use, or disclosure of Personal Information, it will notify Customer without undue
              delay. Where possible, such notice will include all available details required under
              applicable data protection laws for Customer to comply with its own notification
              obligations to regulatory authorities and affected individuals;
            </li>
            <li>
              (d) only engage subprocessors to process Personal Information where Charge has entered
              into a written agreement with such subprocessors imposing data protection obligations
              that are consistent with this Section 4.4. Where the subprocessor fails to fulfil such
              obligations, Charge shall remain fully liable to Customer for the performance of that
              subprocessor’s obligations in accordance with applicable data protection laws. Charge
              shall notify Customer of any new subprocessors in advance and allow Customer ten (10)
              days to object. If Customer has legitimate objections to the appointment of any new
              subprocessor, the parties will work together in good faith to resolve the grounds for
              the objection;
            </li>
            <li>
              (e) provide reasonable assistance and comply with reasonable instructions from
              Customer related to any requests from individuals exercising their rights in Personal
              Information under applicable data protection laws;
            </li>
            <li>
              (f) assist Customer in ensuring compliance with Customer’s obligations pursuant to
              Articles 35 and 36 of Regulation (EU) 2016/679;
            </li>
            <li>
              (g) delete or return all Personal Information following the expiry or termination of
              the Agreement upon request (excluding any back-up or archival copies which shall be
              deleted in accordance with Charge’s data retention schedule), except where Charge is
              required to retain copies under applicable laws, in which case Charge will isolate and
              protect that Personal Information from any further processing except to the extent
              required by applicable laws;
            </li>
            <li>
              (h) make available to Customer all information necessary to demonstrate compliance
              with the obligations set forth in this Section 4.1 and allow for and contribute to
              audits, conducted by Customer or another auditor mandated by Customer by completing a
              data protection questionnaire of reasonable length; and
            </li>
            <li>
              (i) use the European Commission Decision C(2010)593 Standard Contractual Clauses for
              Controllers to Processors (“Model Clauses”) to support the transfer of Personal
              Information originating in the European Economic Area, Switzerland, and/or United
              Kingdom, the terms of which are herein incorporated by reference. Customer and Charge
              agree that: (i) the audits described in Clause 5(f) and Clause 12(2) of the Model
              Clauses shall be carried out in accordance with Section 4.4(h) of this Agreement; (ii)
              pursuant to Clause 5(h) of the Model Clauses, Charge may engage new subprocessors in
              accordance with Section 4.1(d) of this Agreement; (iii) the subprocessor agreements
              referenced in Clause 5(j) and certification of deletion referenced in Clause 12(1) of
              the Model Clauses shall be provided by Charge only upon written request; and (iv) the
              optional clauses are expressly not included. Each party’s acceptance of this Agreement
              shall be considered a signature to the Model Clauses to the extent the Model Clauses
              apply hereunder. If required by the laws or regulatory procedures of any jurisdiction,
              the parties shall execute or re-execute the Model Clauses as separate documents.
            </li>
          </ul>
        </div>
      </Text>

      <PageSubTitle>5. Intellectual Property Rights</PageSubTitle>

      <Text>
        5.1 Charge Intellectual Property and Updates. Subject to the right and license granted to
        Customer in Section 1.1, Charge (and its licensors, where applicable) has and retains
        ownership of, and all intellectual property rights relating to, the Platform and the
        Platform Output, and any modifications, improvements or derivatives of the foregoing.
        Customer acknowledges and agrees that the Platform may be upgraded or updated at any time,
        with or without notice, during the Term. “Platform Output” means all data, information and
        materials generated by the Platform based on Participant Information or information or data
        supplied to Charge by Customer, but excluding any Participant Information incorporated
        therein.
      </Text>

      <Text>
        5.2 Participant Information. Customer hereby grants Charge a non-exclusive, worldwide,
        royalty-free right and license to use, reproduce, modify, create derivative works from,
        transmit, anonymize and display Participant Information (a) during the Term only, in
        connection with the operation and provision of the Platform and (b) on a perpetual basis
        whether incorporated into the Platform Output or not, provided that in all cases such
        Participant Information is in aggregated, anonymized and/or deidentified form, in connection
        with any business purpose (including for the purpose of improving and developing Charge’s
        current and future products, services, methods and processes).
      </Text>

      <Text>
        5.3 Feedback. Customer grants to Charge a perpetual, non-exclusive, sublicensable, and
        royalty-free license to use, modify, distribute, and otherwise freely exploit any
        suggestions, ideas, enhancement requests, feedback, recommendations, or other information or
        ideas provided by Customer or any third party on behalf of Customer relating to the Platform
        or any of Charge’s current and future products and services.
      </Text>

      <Text>
        5.4 Trademarks and References. Customer hereby grants Charge a limited, non-exclusive,
        royalty-free license to use and display Customer’s name, designated trademarks and
        associated logos (the “Customer Marks”) in connection with: (i) the operation and
        maintenance of the Platform during the Term; and (ii) Charge’s marketing and promotional
        efforts for the its products and services, including by publicly naming Customer as a client
        of Charge during or after the Term. Customer agrees to participate in press announcements,
        case studies, trade shows, or other forms of publicity reasonably requested by Charge. All
        goodwill and improved reputation generated by Charge’s use of the Customer Marks inures to
        the exclusive benefit of Customer. Charge will use the Customer Marks in the form stipulated
        by Customer and will conform to and observe such standards as Customer prescribes from time
        to time in connection with the license granted hereunder.
      </Text>

      <PageSubTitle>6. Confidentiality</PageSubTitle>

      <Text>
        6.1 Trademarks and References. Customer hereby grants Charge a limited, non-exclusive,
        royalty-free license to use and display Customer’s name, designated trademarks and
        associated logos (the “Customer Marks”) in connection with: (i) the operation and
        maintenance of the Platform during the Term; and (ii) Charge’s marketing and promotional
        efforts for the its products and services, including by publicly naming Customer as a client
        of Charge during or after the Term. Customer agrees to participate in press announcements,
        case studies, trade shows, or other forms of publicity reasonably requested by Charge. All
        goodwill and improved reputation generated by Charge’s use of the Customer Marks inures to
        the exclusive benefit of Customer. Charge will use the Customer Marks in the form stipulated
        by Customer and will conform to and observe such standards as Customer prescribes from time
        to time in connection with the license granted hereunder.
      </Text>

      <Text>
        6.2 Exclusions/Remedies. The obligations in this Section do not restrict any disclosure by
        either party pursuant to any applicable law, or by order of any court or government agency
        (provided that the disclosing party will give prompt notice to the non-disclosing party of
        such order so that the non-disclosing party may seek a protective order or other appropriate
        remedy). In the event of a breach of this Section or other compromise of Confidential
        Information of which a party is or should be aware (whether or not resulting from a breach),
        such party will immediately notify the other party in writing detailing all information
        known to such party about the compromise, the Confidential Information affected, and the
        steps taken by such party to prevent the recurrence of such breach and to mitigate the risk
        to the other party. The parties agree that in the event of a breach or anticipated breach of
        this Section 6, the affected party will be entitled to seek injunctive or other equitable
        relief as a remedy for any such breach or anticipated breach without the necessity of
        posting a bond. Any such relief will be in addition to and not in lieu of any appropriate
        relief in the way of monetary damages.
      </Text>

      <PageSubTitle>7. Representations and Warranties</PageSubTitle>

      <Text>
        7.1 Customer Representations and Warranties. Customer represents and warrants to Charge
        that: (a) Customer has the necessary authority to enter into this Agreement and carry out
        its obligations hereunder; (b) it has provided appropriate notice to individuals prior to or
        at the point of collecting Participant Information; (c) it will comply with all applicable
        laws in its use of the Platform and Platform Output, including all applicable data
        protection and privacy laws such as the EU General Data Protection Regulation (GDPR) and
        California Consumer Privacy Act (CCPA); (d) it has obtained and will obtain all necessary
        authorizations, lawful bases, consents, permissions and licenses with respect to any and all
        Participant Information to the extent necessary: (i) for Customer to grant the rights and
        licenses hereunder to Charge; (ii) for Customer and Charge to comply with all applicable
        laws, rules and regulations; and (iii) for Customer to grant the licenses contemplated by
        Section 5.2 without violating any third party intellectual property, privacy rights or any
        other proprietary rights; and (e) Charge’s use of Participant Information as contemplated by
        this Agreement will not violate any applicable laws.
      </Text>

      <Text>
        7.2 Disclaimers. EXCEPT AS EXPRESSLY PROVIDED HEREIN, CHARGE MAKES NO WARRANTY OF ANY KIND,
        WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND CHARGE SPECIFICALLY DISCLAIMS ALL
        IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
        EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, THE PLATFORM (INCLUDING THE PLATFORM OUTPUT)
        IS PROVIDED “AS IS,” WITHOUT ANY WARRANTY WHATSOEVER. CHARGE DOES NOT WARRANT THAT THE
        PLATFORM WILL BE CONTINUOUSLY AVAILABLE, ERROR-FREE OR SECURE, OR THAT ALL DEFECTS IN THE
        PLATFORM WILL BE CORRECTED. CHARGE WILL NOT BE LIABLE IN ANY WAY RELATED TO NON-CHARGE
        APPLICATIONS. CHARGE DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR
        DAMAGES CAUSED BY ANY THIRD-PARTY PROVIDERS. CHARGE’S ONLY OBLIGATIONS WITH RESPECT TO THE
        PLATFORM ARE EXPRESSLY STATED IN THIS AGREEMENT. CUSTOMER ASSUMES SOLE RESPONSIBILITY AND
        LIABILITY FOR THE RESULTS OBTAINED FROM ITS THE USE OF THE PLATFORM AND FOR CONCLUSIONS
        DRAWN FROM SUCH USE. CUSTOMER ACKNOWLEDGES THAT IT IS RESPONSIBLE FOR MAINTAINING ADEQUATE
        SECURITY AND CONTROL OF ANY AND ALL IDS, PASSWORDS, HINTS, PERSONAL IDENTIFICATION NUMBERS
        (PINS), OR ANY OTHER CODES THAT CUSTOMER USES TO ACCESS OR IN RELATION TO THE PLATFORM.
      </Text>

      <Text>
        7.3 No Liability for Participant Information. WITHOUT LIMITING THE GENERALITY OF SECTION
        7.3, CUSTOMER ACKNOWLEDGES THAT CHARGE IS NOT REQUIRED TO PRE-SCREEN OR MONITOR THE
        PARTICIPANT INFORMATION. UNDER NO CIRCUMSTANCES WILL CHARGE BE LIABLE IN ANY WAY FOR ANY
        PARTICIPANT INFORMATION OR PLATFORM OUTPUT, INCLUDING LIABILITY FOR ANY ERRORS,
        INACCURACIES, OR OMISSIONS THEREIN, INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR BREACH
        OF THIS AGREEMENT OR APPLICABLE LAWS.
      </Text>

      <Text>
        7.4 Reports. PORTIONS OF THE DATA PROVIDED HEREUNDER ARE DERIVED FROM PUBLIC SOURCES.
        CERTAIN CONTENT MAY BE SUMMARIZED AND ALL INFORMATION SHOULD BE CONSIDERED WITHIN THE FULL
        CONTEXT AVAILABLE IN THE THIRD-PARTY SOURCES TO WHICH SOURCE LINKS ARE PROVIDED. CHARGE IS
        NOT RESPONSIBLE FOR THE CONTENT OF THIRD-PARTY SOURCES. CUSTOMER SHALL BE RESPONSIBLE FOR
        ITS OWN CONCLUSIONS BASED ON RELATIONSHIPS AND CATEGORIES IN THE INFORMATION PROVIDED BY
        THIRD PARTY SOURCES. DUE TO THE NATURE OF PUBLIC RECORD INFORMATION, THE PUBLIC RECORDS AND
        COMMERCIALLY AVAILABLE DATA SOURCES USED BY CHARGE MAY BE INCOMPLETE AND CONTAIN INACCURATE
        INFORMATION OR ERRORS. CUSTOMER SHALL INDEPENDENTLY VERIFY ALL DATA PURSUANT TO ITS OWN
        POLICIES AND PROCEDURES. CHARGE IS NOT A CONSUMER REPORTING AGENCY AND NONE OF ITS PRODUCTS,
        SERVICES OR THE DATA CONTAINED THEREIN CONSTITUTE A ‘CONSUMER REPORT’ AS SUCH TERM IS
        DEFINED IN THE FEDERAL FAIR CREDIT REPORTING ACT (“FCRA”), 15 U.S.C. SEC. 1681 ET SEQ. THE
        DATA PROVIDED TO CUSTOMER MAY NOT BE USED AS A FACTOR IN CONSUMER DEBT COLLECTION
        DECISIONING, ESTABLISHING A CONSUMER’S ELIGIBILITY FOR CREDIT, INSURANCE, EMPLOYMENT,
        GOVERNMENT BENEFITS, OR HOUSING, OR FOR ANY OTHER PURPOSE AUTHORIZED UNDER THE FCRA. BY
        ACCESSING THE CHARGE SERVICES, CUSTOMER AGREES NOT TO USE THE CHARGE SERVICES FOR ANY
        PURPOSE AUTHORIZED UNDER THE FCRA OR IN RELATION TO TAKING AN ADVERSE ACTION RELATING TO A
        CONSUMER APPLICATION
      </Text>

      <PageSubTitle>8. Indemnification</PageSubTitle>

      <Text>
        8.1 Indemnification by Charge. Charge will defend Customer against any claim, demand, suit
        or proceeding made or brought against Customer by a third party alleging that the use of the
        Platform in accordance with this Agreement violates, infringes or misappropriates a third
        party’s intellectual property rights (a “Claim Against Customer”), and will indemnify
        Customer from any damages, attorneys’ fees and costs finally awarded against Customer as a
        result of, or for amounts paid or payable by Customer under a Charge and court-approved
        settlement of, a Claim Against Customer, provided Customer: (a) promptly gives Charge
        written notice of the Claim Against Customer, (b) gives Charge sole control of the defense
        and settlement of the Claim Against Customer (except that Charge may not settle any Claim
        Against Customer unless it unconditionally releases Customer of all liability for past
        infringements), and (c) gives Charge all reasonable assistance. If Charge receives
        information about an infringement or misappropriation claim related to the Platform, Charge
        may in its discretion and at no cost to Customer: (i) modify the Platform so that it no
        longer infringes or misappropriates, (ii) obtain a license for Customer’s continued use of
        the Platform in accordance with this Agreement, or (iii) terminate Customer’s subscription
        for the Platform upon thirty (30) days’ written notice and refund Customer any prepaid fees
        covering the remainder of the term of the terminated Platform, depreciated over three years
        on a straight-line basis. Charge will not have any obligations under this Section or any
        liability to the extent (A) a Claim Against Customer arises from information or data
        supplied to Charge by Customer, including Participant Information, a non-Charge application
        or Customer’s breach of this Agreement, or (B) the Claim Against Customer would not have
        arisen but for (I) Customer’s combination or use of the Platform with non-Charge software,
        services or data, (II) modification of the Platform by anyone other than Charge, (III)
        Customer`s continued allegedly infringing activity after being notified thereof or after
        being provided modifications that would have avoided the alleged infringement, (IV) Charge’s
        customization of the Platform to meet Customer’s particular specifications or instructions,
        or (V) Customer’s use of the Platform in a manner not strictly in accordance with this
        Agreement.
      </Text>

      <Text>
        8.2 Indemnification by Customer. Customer will defend Charge against any claim, demand, suit
        or proceeding made or brought against Charge by a third party alleging (a) that the
        Participant Information (or Charge’s use thereof), or Customer’s use of any Platform in
        breach of this Agreement, violates, infringes or misappropriates a third party’s
        intellectual property, privacy or other rights, or violates applicable law, (b) facts that,
        if true, constitute a breach of this Agreement by Customer or acts or omissions by Customer
        described in Section 8.1(I)-(V), or (c) any demand for compensation or benefits, or any
        other claim, related to Customer’s use of or decision based on the Platform, except to the
        extent constituting grounds for a Claim Against Customer (collectively, a “Claim Against
        Charge”), and will indemnify Charge from any damages, attorney fees and costs finally
        awarded against Charge as a result of, or for any amounts paid or payable by Charge under a
        court-approved settlement of, a Claim Against Charge, provided Charge (i) promptly gives
        Customer written notice of the Claim Against Charge, (ii) gives Customer sole control of the
        defense and settlement of the Claim Against Charge (except that Customer may not settle any
        Claim Against Charge unless it unconditionally releases Charge of all liability), and (iii)
        gives Customer all reasonable assistance.
      </Text>

      <Text>
        8.3 Exclusive Remedy. This Section 8 states the indemnifying party’s sole liability to, and
        the indemnified party’s exclusive remedy against, the other party for any type of
        third-party claim described in Section 8.1.
      </Text>

      <PageSubTitle>9. Limitation of Liability</PageSubTitle>

      <Text>
        9.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL CHARGE BE LIABLE FOR
        ANY LOST DATA, LOSS OF REVENUE, ANTICIPATED PROFITS, LOST BUSINESS OR LOST SALES, OR FOR ANY
        INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING FROM OR RELATED TO
        THIS AGREEMENT, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
        OTHERWISE, AND EVEN IF CHARGE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE TOTAL
        LIABILITY OF CHARGE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
        LIABILITY, OR OTHERWISE, WILL NOT EXCEED, IN THE AGGREGATE, AMOUNTS PAID BY CUSTOMER TO
        CHARGE IN THE 12-MONTH PERIOD PRECEDING THE INITIAL CLAIM GIVING RISE TO LIABILITY
        HEREUNDER.
      </Text>

      <PageSubTitle>10. Dispute Resolution; Arbitration Agreement; No Class Action</PageSubTitle>

      <Text>
        10.1 Dispute Resolution. The parties must submit all claims and issues arising from,
        relating to, or connected with this Agreement to binding arbitration (e.g., a contract
        breach claim, indemnification duty issue, and questions regarding an arbitrator’s authority)
        in accordance with this Section. A single arbitrator will conduct the arbitration in Los
        Angeles County, CA, and in accordance with the current Rules of Practice and Procedure of
        the Judicial Arbitration and Mediation Service (JAMS). The arbitrator is bound by strict
        rules of law and this Agreement’s terms (i.e., the arbitrator may not waive, change or
        equitably excuse any Agreement term, including ancillary documents (e.g., an amendment)).
        The arbitrator does not have the power to commit errors of law or legal reasoning, and a
        court may vacate or correct an arbitration award because of such errors. The Federal
        Arbitration Act (9 U.S.C. § 1 et seq.) governs all issues arising from the arbitrability or
        the enforcement of the agreement to arbitrate, except for the requirements to apply
        California state law (but disregarding any principle of law that would cause the application
        of the law of any other jurisdiction or permit a court, as opposed to the arbitrator, to
        determine the applicability or validity of this agreement to arbitrate). The parties will
        equally share the arbitrators’ fees and other arbitration costs, regardless of outcome. The
        parties must submit or file any claim that would constitute a compulsory counterclaim (as
        defined by Rule 13 of the Federal Rules of Civil Procedure) within the same arbitration
        proceedings as the claim to which it relates; any such claim which is not submitted or filed
        will be barred. The arbitrator may only award damages and may only grant relief that is
        permitted by this Agreement. The arbitrator’s decision, award and relief will be conclusive
        and binding on the parties. Either party may enter the arbitrator’s decision, award and
        relief in any court having appropriate jurisdiction.
      </Text>

      <Text>
        10.2 No Class Action. Arbitration may only be conducted on an individual, not a class wide,
        basis. No arbitration proceeding between the parties may be consolidated with any other
        arbitration proceeding involving Charge and any other person or entity. Each party shall
        file and prosecute arbitration proceedings separately and individually in the name of
        Customer and Charge, and not in any representative capacity. Each party hereby irrevocably
        waives and agrees not to assert any claim inconsistent with this Section.
      </Text>

      <Text>
        10.3 Governing Law. This Agreement, and any dispute arising out of or related to this
        Agreement, will be governed by the Federal Arbitration Act, applicable federal law, and the
        laws of the state of California, excluding its conflicts of law rules, regardless of
        Customer’s country of origin or where Customer accesses the Platform. The United Nations
        Convention on Contracts for the International Sale of Goods does not apply to this
        Agreement. All claims and issues arising from, relating to, or connected with this Agreement
        that an arbitrator determines are excluded from this Agreement’s arbitration requirements
        may only be filed and resolved by state or Federal courts located in Los Angeles County,
        California, and each party consents to the exclusive jurisdiction of those courts. Further,
        Charge may obtain injunctive or other equitable relief in any court of competent
        jurisdiction in the event of any infringement or threatened infringement of its intellectual
        property rights. Neither party will claim that an aforementioned court lacks personal
        jurisdiction, is an inconvenient forum or is an improper venue.
      </Text>

      <PageSubTitle>11. General Provisions</PageSubTitle>

      <Text>
        11.1 Force Majeure. Neither party will be liable in damages or have the right to terminate
        this Agreement for any delay or default in performing hereunder (except for failure to
        timely pay) if such delay or default is caused by conditions beyond its reasonable control
        including acts of God, government restrictions (including the denial or cancellation of any
        export or other necessary license), pandemic, acts of terrorism, wars or insurrections.
      </Text>

      <Text>
        11.2 Notices. Except as may be otherwise set forth herein, all notices, requests, demands
        and other communications hereunder will be in writing (including by email provided that
        delivery to the recipient is confirmed). Any hard copies of such communications will be
        deemed to have been duly given on the next day if delivered personally or sent by express
        courier to such party. All notices will be sent to the following address: If to Customer, to
        the name and address or to the email address provided upon registration for the Platform. If
        to Charge, to <a href="mailto:support@charge.io">support@charge.io</a>.
      </Text>

      <Text>
        11.3 Export Control. Customer will not export or re-export, either directly or indirectly,
        any technical data, software, process, product, service, or system obtained from Charge,
        without first complying with the United States and all other applicable government laws and
        regulations governing the export, re-export, and import of those items.
      </Text>

      <Text>
        11.4 Miscellaneous. Neither party may assign, delegate, or otherwise transfer its rights or
        obligations under this Agreement in whole or in part, except that either party may assign
        this Agreement in connection with a merger, acquisition, sale of a majority of its equity,
        sale of substantially all of its assets to which this Agreement relates, or a similar
        transaction. This Agreement will be binding upon and will inure to the benefit of the
        parties and their permitted successors and assigns. This Agreement constitutes the entire
        agreement, and supersedes any and all prior or contemporaneous representations,
        understandings and agreements, between the parties with respect to the subject matter of
        this Agreement, all of which are hereby merged into this Agreement. Without limitation, the
        terms of any other document, course of dealing, or course of trade will not modify this
        Agreement, except as expressly provided in this Agreement or as the parties may agree in
        writing. Charge may amend the Agreement from time to time and such amendments will be
        effective upon Charge’s communication of such amendments to Customer in writing. Customer’s
        continued use of the Platform confirms Customer’s consent to be bound by the Agreement, as
        amended. Failure to promptly enforce a provision of this Agreement will not be construed as
        a waiver of such provision. Nothing contained in this Agreement will be deemed to create, or
        be construed as creating, a joint venture or partnership between the parties. Neither party
        is, by virtue of this Agreement or otherwise, authorized as an agent or legal representative
        of the other party. Except as otherwise set forth in this Agreement, neither party is
        restricted from doing business with any other person, entity or organization by virtue of
        this Agreement. Neither party to this Agreement is granted any right or authority to assume
        or to create any obligation or responsibility, express or implied, on behalf or in the name
        of the other party, or to bind such other party in any manner. Nothing contained in this
        Agreement will be deemed to create any third-party beneficiary right upon any third party
        whatsoever. Each of the parties acknowledges that it has had the opportunity to have this
        Agreement reviewed or not by independent legal counsel of its choice. If any one or more of
        the provisions of this Agreement should be ruled wholly or partly invalid or unenforceable,
        then the provisions held invalid or unenforceable will be deemed amended, and the court or
        other government body is authorized to reform the provision(s) to the minimum extent
        necessary to render them valid and enforceable in conformity with the parties’ intent as
        manifested herein. The headings to Sections of this Agreement are for convenience or
        reference only and do not form a part of this Agreement and will not in any way affect its
        interpretation. Neither party will be afforded or denied preference in the construction of
        this Agreement, whether by virtue of being the drafter or otherwise. For purposes of this
        Agreement, the words and phrases “include,” “includes”, “including” and “such as” are deemed
        to be followed by the words “without limitation”. This Agreement may be executed in
        counterparts, each of which will constitute the same instrument. The parties agree to
        contract electronically. Electronic signatures will be given the same effect and weight as
        originals.
      </Text>
    </TermsContainer>
  )
}

export default TermsAndConditions
