import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { StyledSideMenu as AntMenu } from '../app.styled'
import {
  APP_MENU_DEFAULT_SELECTED_KEY,
  APP_MENU_ENUM,
  APP_MENU_ITEMS_ENUM,
} from '../app.menu.constants'
import { logout } from '../../Auth/auth.actions'

const Menu = ({ location, history, hideMenu }) => {
  const dispatch = useDispatch()

  const getSelectedMenuKey = () => {
    const selectedItem = APP_MENU_ENUM.find(
      item =>
        item.route === location.pathname ||
        (item.subRoutes && item.subRoutes.indexOf(location.pathname) !== -1),
    )
    return selectedItem ? selectedItem.key : APP_MENU_DEFAULT_SELECTED_KEY
  }

  const handleMenuItemClick = ({ key, keyPath }) => {
    if (key === APP_MENU_ITEMS_ENUM.LOGOUT) {
      dispatch(logout())
      return
    }

    const isSubRoute = keyPath.length > 1
    const topKey = !isSubRoute ? key : keyPath[keyPath.length - 1]
    const topItem = APP_MENU_ENUM.find(item => item.key === topKey)
    const route = isSubRoute
      ? topItem.subRoutes.find(subItem => subItem.key === key).route
      : topItem.route
    history.push(route)

    if (hideMenu) {
      hideMenu()
    }
  }

  return (
    <AntMenu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[getSelectedMenuKey()]}
      onClick={handleMenuItemClick}
    >
      {APP_MENU_ENUM.map(item => {
        if (!item.subRoutes) {
          return (
            <AntMenu.Item key={item.key}>
              <span className="anticon">
                <img src={item.icon} alt={`${item.key} icon`} />
              </span>
              <span>
                <FormattedMessage
                  id={`app.menu.${item.key}`}
                  defaultMessage={item.defaultMessage}
                />
              </span>
            </AntMenu.Item>
          )
        }
        return (
          <AntMenu.SubMenu
            key={item.key}
            icon={null}
            title={
              <span>
                <span className="anticon">
                  <img src={item.icon} alt={`${item.key} icon`} />
                </span>
                <span>
                  <FormattedMessage
                    id={`app.menu.${item.key}`}
                    defaultMessage={item.defaultMessage}
                  />
                </span>
              </span>
            }
          >
            {item.subRoutes.map(subItem => (
              <AntMenu.Item key={subItem.key}>
                <FormattedMessage
                  id={`app.menu.${subItem.key}`}
                  defaultMessage={subItem.defaultMessage}
                />
              </AntMenu.Item>
            ))}
          </AntMenu.SubMenu>
        )
      })}
    </AntMenu>
  )
}

export default Menu
