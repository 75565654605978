import React from 'react'
import { ConfigProvider } from 'antd'
import { addLocaleData, IntlProvider } from 'react-intl'
import english from 'locales/en-US'
import french from 'locales/fr-FR'
import russian from 'locales/ru-RU'
import chinese from 'locales/zh-CN'

addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(russian.localeData)
addLocaleData(chinese.localeData)

const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
}

const AppLocalization = ({ children }) => {
  // Hardcoded en-US for now. We don't have ability to change locale from UI now
  // Need to be updated after user will be able to change locale from UI
  const currentLocale = locales['en-US']
  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default AppLocalization
