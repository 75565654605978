import { API_KEY_PERMISSIONS_TYPES } from 'features/ApiKeys/apiKeys.constants'
import { USER_ROLES_ENUM } from 'features/Auth/auth.constants'

// eslint-disable-next-line import/prefer-default-export
export function getApiKeyPermissionsTypesByRole(role) {
  let whiteList = []
  switch (role) {
    case USER_ROLES_ENUM.ADMIN:
      whiteList = [USER_ROLES_ENUM.ADMIN, USER_ROLES_ENUM.DEVELOPER, USER_ROLES_ENUM.COMPLIANCE]
      break
    case USER_ROLES_ENUM.DEVELOPER:
      whiteList = [USER_ROLES_ENUM.DEVELOPER, USER_ROLES_ENUM.COMPLIANCE]
      break
    case USER_ROLES_ENUM.COMPLIANCE:
      whiteList = [USER_ROLES_ENUM.COMPLIANCE]
      break
    default:
      whiteList = []
  }
  return API_KEY_PERMISSIONS_TYPES.filter(item => whiteList.indexOf(item.key) !== -1)
}
