import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { SearchOutlined } from '@ant-design/icons'
import { StyledSearchInput, StyledSearchInputsGroup } from '../identity.styled'

const IdentityListSearch = ({ handleSearch, handleSearchReset, listFiltered, loading }) => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const handleFindClick = () => {
    const value = searchValue.trim()
    if (value.length) {
      handleSearch({
        field: null,
        value: searchValue,
      })
    } else {
      setSearchValue('')
    }
  }

  const handleSearchResetClick = () => {
    setSearchValue('')
    handleSearchReset()
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (searchValue.length) {
        handleFindClick()
      } else {
        handleSearchReset()
      }
    }
  }

  const after = (
    <Fragment>
      <Button
        type="primary"
        loading={loading}
        onClick={handleFindClick}
        disabled={!searchValue.length}
      >
        <FormattedMessage id="form.item.button.find" defaultMessage="Find" />
      </Button>
      {listFiltered ? (
        <Button type="outline" loading={loading} onClick={handleSearchResetClick} className="ml-1">
          <FormattedMessage id="form.item.button.reset" defaultMessage="Reset" />
        </Button>
      ) : null}
    </Fragment>
  )

  return (
    <StyledSearchInputsGroup>
      <StyledSearchInput
        prefix={
          <SearchOutlined style={{ color: listFiltered ? '#1890ff' : undefined, width: 30 }} />
        }
        suffix={after}
        placeholder="Please enter the value"
        onChange={handleSearchChange}
        value={searchValue}
        maxLength={319}
        onKeyDown={handleKeyDown}
      />
    </StyledSearchInputsGroup>
  )
}

export default IdentityListSearch
