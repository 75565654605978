import React, { Fragment, useState, useEffect } from 'react'
import { Empty, Modal } from 'antd'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Button, Input, Text } from 'components'
import {
  StyledNotesRow,
  StyledNotesInputContainer,
  StyledNotesScrollContainer,
} from '../../identity.styled'
import { MOMENT_DATE_TIME_FORMAT } from '../../../App/app.constants'

const { TextArea } = Input

const Notes = ({ data, postNote, noteLoading }) => {
  const [visible, setVisible] = useState(false)
  const [note, setNote] = useState('')

  useEffect(() => {
    setVisible(false)
    setNote('')
  }, [data.length])

  const handleNoteChange = e => {
    setNote(e.target.value)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleNoteAdd = () => {
    setVisible(true)
  }

  const handleSubmit = () => {
    postNote(note)
  }

  return (
    <div className="position-relative h-100">
      <StyledNotesInputContainer>
        <Button onClick={handleNoteAdd} type="primary" className="w-100">
          <FormattedMessage id="identity.notes.tab.addNote" defaultMessage="Add note" />
        </Button>
      </StyledNotesInputContainer>
      {data.length ? (
        <StyledNotesScrollContainer>
          {data.map(({ id, body, createdAt, user }) => (
            <StyledNotesRow key={id}>
              <div>{body}</div>
              {user.email || createdAt ? (
                <span>
                  {user.email ? (
                    <Fragment>
                      <FormattedMessage
                        id="identity.history.tab.history.row.by"
                        defaultMessage="by"
                      />
                      &nbsp;
                      <span className="note-email font-weight-bold">{user.email}</span>
                    </Fragment>
                  ) : null}
                  {createdAt ? (
                    <Fragment>
                      &nbsp;
                      <FormattedMessage
                        id="identity.history.tab.history.row.at"
                        defaultMessage="at"
                      />
                      &nbsp;
                      <span>{moment(createdAt).format(MOMENT_DATE_TIME_FORMAT)}</span>
                    </Fragment>
                  ) : null}
                </span>
              ) : null}
            </StyledNotesRow>
          ))}
        </StyledNotesScrollContainer>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <Empty />
        </div>
      )}

      <Modal
        centered
        visible={visible}
        title={
          <FormattedMessage id="identity.notes.tab.modal.title" defaultMessage="Add new note" />
        }
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            <FormattedMessage id="identity.notes.tab.modal.cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="submit" type="primary" loading={noteLoading} onClick={handleSubmit}>
            <FormattedMessage id="identity.notes.tab.modal.submit" defaultMessage="Submit" />
          </Button>,
        ]}
      >
        <Text className="font-weight-bold mb-3">
          <FormattedMessage
            id="identity.notes.tab.modal.input.label"
            defaultMessage="Please enter note"
          />
        </Text>
        <TextArea
          value={note}
          onChange={handleNoteChange}
          className="mb-4"
          rows={7}
          placeholder="Please enter note..."
        />
      </Modal>
    </div>
  )
}

Notes.defaultProps = {
  data: [],
}

export default Notes
