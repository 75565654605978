import React, { Fragment } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { Collapse, CollapsePanel } from 'components'
import {
  StyledAdditionalDataPanelContainer,
  StyledAdditionalDataPanel,
} from '../../identity.styled'
import AdditionalDataPanel from './AdditionalDataPanel'
import AdditionalDataKeyValue from './AdditionalDataKeyValue'

const DISPLAY_TYPE_PANEL = 'display-panel'
const MAX_PANELS_IN_ROW = 3

const AdditionalData = ({ data }) => {
  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      nopadding
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <CollapsePanel
        header={
          <span className="font-weight-bold">
            <FormattedMessage
              id="identity.business.details.additional.data"
              defaultMessage="Additional Data"
            />
          </span>
        }
        key="1"
      >
        {data.type === DISPLAY_TYPE_PANEL ? (
          <Fragment>
            {_.chunk(data.panels, MAX_PANELS_IN_ROW).map(item => (
              <StyledAdditionalDataPanelContainer>
                {item.map(panel => (
                  <StyledAdditionalDataPanel>
                    <AdditionalDataPanel panel={panel} />
                  </StyledAdditionalDataPanel>
                ))}
              </StyledAdditionalDataPanelContainer>
            ))}
          </Fragment>
        ) : (
          <AdditionalDataKeyValue data={data} />
        )}
      </CollapsePanel>
    </Collapse>
  )
}

export default AdditionalData
