import React from 'react'
import { FormItemWrapper } from 'components'
import { StyledOrganizationFormContainer } from '../organization.styled'

const OrganizationForm = ({ form, isAdmin }) => {
  return (
    <StyledOrganizationFormContainer>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <FormItemWrapper
              name="name"
              intlPrefix="businessName"
              required
              type="string"
              form={form}
              disabled={!isAdmin}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              disabled={!isAdmin}
              name="legalBusinessName"
              required
              type="string"
              form={form}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="billingCountry"
              intlPrefix="country"
              required
              type="string"
              form={form}
              disabled={!isAdmin}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="billingState"
              intlPrefix="state"
              required
              type="string"
              form={form}
              disabled={!isAdmin}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="billingCity"
              intlPrefix="city"
              required
              type="string"
              form={form}
              disabled={!isAdmin}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="billingZip"
              intlPrefix="zipCode"
              required
              type="string"
              form={form}
              disabled={!isAdmin}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              disabled={!isAdmin}
              name="billingStreetName"
              required
              type="string"
              form={form}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="businessEmail"
              intlPrefix="email"
              required
              type="email"
              form={form}
              disabled={!isAdmin}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6">
            <FormItemWrapper
              name="billingPhoneNumber"
              intlPrefix="phone"
              required
              type="phone"
              form={form}
              disabled={!isAdmin}
            />
          </div>
        </div>
      </div>
    </StyledOrganizationFormContainer>
  )
}

export default OrganizationForm
