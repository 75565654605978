import React from 'react'
import { Skeleton } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { Card, CardText, PaymentMethodRow } from '../billing.styled'

const PaymentMethodSkeleton = () => {
  return (
    <Card>
      <CardText className="font-weight-bold">
        <FormattedMessage
          id="settings.billing.payment.method.title"
          defaultMessage="Payment Method"
        />
      </CardText>
      <PaymentMethodRow>
        <Skeleton.Input active size="large" />
      </PaymentMethodRow>
      <Button type="link">
        <FormattedMessage
          id="settings.billing.payment.method.update.link"
          defaultMessage="Update Payment Information"
        />
      </Button>
    </Card>
  )
}

export default PaymentMethodSkeleton
