import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Popconfirm } from 'antd'
import { FormattedMessage } from 'react-intl'

const ApiKeysTableRowActions = ({
  item,
  editingItemId,
  setEditingItemId,
  handleDelete,
  handleSave,
  handleCancel,
  editingName,
}) => {
  return (
    <div>
      {editingItemId !== item.id ? (
        <Fragment>
          <Button type="link" onClick={() => setEditingItemId(item.id)}>
            <FormattedMessage id="settings.apiKey.edit.button" defaultMessage="Edit" />
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this API Key?"
            okText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleDelete(item.id)}
          >
            <Button type="link">
              <FormattedMessage id="settings.apiKey.delete.button" defaultMessage="Delete" />
            </Button>
          </Popconfirm>
        </Fragment>
      ) : (
        <Fragment>
          <Button disabled={!editingName} type="link" onClick={() => handleSave()}>
            <FormattedMessage id="settings.apiKey.save.button" defaultMessage="Save" />
          </Button>
          <Popconfirm
            title="Are you sure want to cancel?"
            okText="Okay"
            cancelText="Cancel"
            onConfirm={() => handleCancel()}
          >
            <Button type="link">
              <FormattedMessage id="settings.apiKey.cancel.button" defaultMessage="Cancel" />
            </Button>
          </Popconfirm>
        </Fragment>
      )}
    </div>
  )
}

ApiKeysTableRowActions.propTypes = {
  item: PropTypes.object.isRequired,
  editingItemId: PropTypes.string.isRequired,
  setEditingItemId: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  editingName: PropTypes.string.isRequired,
}

export default ApiKeysTableRowActions
