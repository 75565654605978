import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { Collapse, CollapsePanel, ImageUpload } from 'components'
import { Skeleton } from 'antd'
import { StyledGovernmentIssuedID, StyledSubCollapse } from '../identity.styled'
import {
  postPersonPassport,
  postPersonDriverLicense,
  getSupportedCountries,
  getSupportedStates,
} from '../identity.actions'
import {
  IDS_TYPES_ENUM,
  IDS_IMAGE_TYPES_ENUM,
  DEFAULT_PASSPORT_COUNTRY,
  DEFAULT_DRIVER_LICENSE_STATE,
} from '../identity.constants'

const GovernmentIssuedID = ({ id, data, countries, states, loading, listLoading }) => {
  const dispatch = useDispatch()
  const passport = data.find(item => item.type === IDS_TYPES_ENUM.PASSPORT)
  const [country, setCountry] = useState(DEFAULT_PASSPORT_COUNTRY)
  const driverLicense = data.find(item => item.type === IDS_TYPES_ENUM.DRIVER_LICENSE)
  const [state, setState] = useState(DEFAULT_DRIVER_LICENSE_STATE)
  const [activeKey, setActiveKey] = useState(['1'])

  useEffect(() => {
    if (passport || (passport && driverLicense)) {
      setActiveKey(['1'])
    }
    if (!passport && driverLicense) {
      setActiveKey(['2'])
    }
  }, [passport, driverLicense])

  useEffect(() => {
    if (driverLicense && driverLicense.state) {
      setState(driverLicense.state)
    }
  }, [driverLicense])

  useEffect(() => {
    dispatch(getSupportedCountries())
  }, [id])

  useEffect(() => {
    dispatch(getSupportedStates())
  }, [id])

  const handleCountryChange = value => {
    setCountry(value)
  }

  const handleStateChange = value => {
    setState(value)
  }

  const uploadPersonPassport = (idType, imageType, base64) => {
    dispatch(
      postPersonPassport(id, {
        country,
        imageType,
        image: base64.split(',')[1],
      }),
    )
  }

  const uploadPersonDriverLicense = (idType, imageType, base64) => {
    dispatch(
      postPersonDriverLicense(id, {
        state,
        imageType,
        image: base64.split(',')[1],
      }),
    )
  }

  const handlePanelActiveKeyChange = key => setActiveKey(key)

  return (
    <StyledGovernmentIssuedID>
      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={['1']}
        nopadding
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <CollapsePanel
          header={
            <span className="font-weight-bold">
              <FormattedMessage
                id="identity.government.issued.id"
                defaultMessage="Government Issued ID"
              />
            </span>
          }
          key="1"
        >
          {listLoading ? (
            <div className="row p-3">
              <div className="col-6">
                <Skeleton.Image className="w-100" active />
              </div>
              <div className="col-6">
                <Skeleton.Image className="w-100" active />
              </div>
            </div>
          ) : (
            <StyledSubCollapse
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={['1']}
              activeKey={activeKey}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              onChange={handlePanelActiveKeyChange}
              accordion
            >
              {!driverLicense && passport ? (
                <CollapsePanel
                  header={
                    <span className="font-weight-bold">
                      <FormattedMessage
                        id="identity.government.issued.id.passport.panel"
                        defaultMessage="Passport"
                      />
                    </span>
                  }
                  key="1"
                >
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <ImageUpload
                        upload={base64 =>
                          uploadPersonPassport(
                            IDS_TYPES_ENUM.PASSPORT,
                            IDS_IMAGE_TYPES_ENUM.FRONT,
                            base64,
                          )
                        }
                        handleSelectChange={handleCountryChange}
                        loading={loading}
                        type={IDS_TYPES_ENUM.PASSPORT}
                        image={passport ? passport.front : null}
                        label={
                          <FormattedMessage
                            id="identity.government.issued.id.upload.front.label"
                            defaultMessage="Selfie"
                          />
                        }
                        countries={countries}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <ImageUpload
                        upload={base64 =>
                          uploadPersonPassport(
                            IDS_TYPES_ENUM.PASSPORT,
                            IDS_IMAGE_TYPES_ENUM.SELFIE,
                            base64,
                          )
                        }
                        handleSelectChange={handleCountryChange}
                        loading={loading}
                        type={IDS_TYPES_ENUM.PASSPORT}
                        image={passport ? passport.selfie : null}
                        label={
                          <FormattedMessage
                            id="identity.government.issued.id.upload.selfie.label"
                            defaultMessage="Selfie"
                          />
                        }
                        countries={countries}
                      />
                    </div>
                  </div>
                </CollapsePanel>
              ) : null}

              {driverLicense && !passport ? (
                <CollapsePanel
                  header={
                    <span className="font-weight-bold">
                      <FormattedMessage
                        id="identity.government.issued.id.passport.driver.license"
                        defaultMessage="Driver’s License"
                      />
                    </span>
                  }
                  key="2"
                >
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <ImageUpload
                        upload={base64 =>
                          uploadPersonDriverLicense(
                            IDS_TYPES_ENUM.DRIVER_LICENSE,
                            IDS_IMAGE_TYPES_ENUM.FRONT,
                            base64,
                          )
                        }
                        handleSelectChange={handleStateChange}
                        loading={loading}
                        image={driverLicense ? driverLicense.front : null}
                        label={
                          <FormattedMessage
                            id="identity.government.issued.id.upload.front.label"
                            defaultMessage="Front"
                          />
                        }
                        states={states}
                        state={state}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <ImageUpload
                        upload={base64 =>
                          uploadPersonDriverLicense(
                            IDS_TYPES_ENUM.DRIVER_LICENSE,
                            IDS_IMAGE_TYPES_ENUM.BACK,
                            base64,
                          )
                        }
                        handleSelectChange={handleStateChange}
                        loading={loading}
                        image={driverLicense ? driverLicense.back : null}
                        label={
                          <FormattedMessage
                            id="identity.government.issued.id.upload.back.label"
                            defaultMessage="Back"
                          />
                        }
                        states={states}
                        state={state}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <ImageUpload
                        upload={base64 =>
                          uploadPersonDriverLicense(
                            IDS_TYPES_ENUM.DRIVER_LICENSE,
                            IDS_IMAGE_TYPES_ENUM.SELFIE,
                            base64,
                          )
                        }
                        handleSelectChange={handleStateChange}
                        loading={loading}
                        image={driverLicense ? driverLicense.selfie : null}
                        label={
                          <FormattedMessage
                            id="identity.government.issued.id.upload.selfie.label"
                            defaultMessage="Selfie"
                          />
                        }
                        states={states}
                        state={state}
                      />
                    </div>
                  </div>
                </CollapsePanel>
              ) : null}
            </StyledSubCollapse>
          )}
        </CollapsePanel>
      </Collapse>
    </StyledGovernmentIssuedID>
  )
}

GovernmentIssuedID.defaultProps = {
  data: [],
}

export default GovernmentIssuedID
