import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'
import { Button, Collapse, CollapsePanel } from 'components'
import DataFieldList from './DataFieldList'
import { StyledDataFieldListContainer } from '../identity.styled'
import { IDENTITY_BUSINESS_ROUTE } from '../../App/app.router.constants'

const IdentityCustomerInformation = ({ data, loading }) => {
  const history = useHistory()

  const dataFields = [
    {
      field: 'name',
      value: `${data.firstName} ${data.lastName}`,
    },
    {
      field: 'location',
      value: `${data.address} ${data.city} ${data.state} ${data.country} ${data.zip}`,
    },
    {
      field: 'phoneNumber',
      value: `${data.phoneNumber}`,
    },
    {
      field: 'dob',
      value: `${data.dob}`,
    },
    {
      field: 'ip',
      value: `${data.ip}`,
    },
    {
      field: 'deviceFingerprint',
      value: `${data.deviceFingerprint}`,
    },
    {
      field: 'ssn',
      value: `${data.ssn}`,
    },
  ]

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={['1']}
      nopadding
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <CollapsePanel
        header={
          <span className="font-weight-bold">
            <FormattedMessage
              id="identity.customer.details.info"
              defaultMessage="Customer Information"
            />
          </span>
        }
        key="1"
      >
        <StyledDataFieldListContainer>
          {loading ? <Skeleton active /> : <DataFieldList data={dataFields} />}
          {data.organizationId ? (
            <Button
              onClick={() => history.push(`${IDENTITY_BUSINESS_ROUTE}/${data.organizationId}`)}
              type="link"
            >
              <FormattedMessage
                id="identity.business.details.linked.organization.view.btn"
                defaultMessage="View organization"
              />
            </Button>
          ) : null}
        </StyledDataFieldListContainer>
      </CollapsePanel>
    </Collapse>
  )
}

export default IdentityCustomerInformation
