import * as routerConstants from '../App/app.router.constants'

export const SETTINGS_MENU_DEFAULT_SELECTED_KEY = 'profile'

export const SETTINGS_MENU_ENUM = [
  {
    route: routerConstants.SETTINGS_PROFILE_ROUTE,
    key: 'profile',
    defaultMessage: 'User Profile',
  },
  {
    route: routerConstants.SETTINGS_ORGANIZATION_ROUTE,
    key: 'account',
    defaultMessage: 'Account',
  },
  {
    route: routerConstants.SETTINGS_API_KEYS_ROUTE,
    subRoutes: [
      routerConstants.SETTINGS_API_KEYS_CREATE_ROUTE,
      routerConstants.SETTINGS_API_KEYS_CREATE_SUCCESS_ROUTE,
    ],
    key: 'apiKeys',
    defaultMessage: 'API Keys',
  },
  // {
  //   route: routerConstants.SETTINGS_BILLING_ROUTE,
  //   key: 'billing',
  //   defaultMessage: 'Billing',
  //   subRoutes: [
  //     routerConstants.SETTINGS_BILLING_SELECT_PLAN_ROUTE,
  //     routerConstants.SETTINGS_ADD_PAYMENT_METHOD_ROUTE,
  //     routerConstants.SETTINGS_BILLING_INVOICE_ROUTE,
  //   ],
  // },
  {
    route: routerConstants.SETTINGS_SECURITY_ROUTE,
    key: 'security',
    defaultMessage: 'Security Settings',
  },
]
