import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ThisMonthStatsRow, ThisMonthStatsCol } from '../dashboard.styled'

const ThisMonthStats = ({ values }) => {
  return (
    <ThisMonthStatsRow>
      <ThisMonthStatsCol>
        <span>
          <FormattedMessage
            id="dashboard.stats.totalApplications"
            defaultMessage="Total Performed Applications This Month"
          />
          :
        </span>
        <div className="font-weight-bold">{values.totalApplications || 0}</div>
      </ThisMonthStatsCol>
      <ThisMonthStatsCol>
        <span>
          <FormattedMessage
            id="dashboard.stats.totalKYCApplications"
            defaultMessage="KYC Performed"
          />
          :
        </span>
        <div className="font-weight-bold">{values.kycTotal || 0}</div>
      </ThisMonthStatsCol>
      <ThisMonthStatsCol>
        <span>
          <FormattedMessage
            id="dashboard.stats.totalKYCApprovedApplications"
            defaultMessage="KYB Approved Performed"
          />
          :
        </span>
        <div className="font-weight-bold">{values.kycApproved || 0}</div>
      </ThisMonthStatsCol>
      <ThisMonthStatsCol>
        <span>
          <FormattedMessage
            id="dashboard.stats.totalKYBApplications"
            defaultMessage="KYB Performed"
          />
          :
        </span>
        <div className="font-weight-bold">{values.kybTotal || 0}</div>
      </ThisMonthStatsCol>
      <ThisMonthStatsCol>
        <span>
          <FormattedMessage
            id="dashboard.stats.totalKYBApprovedApplications"
            defaultMessage="KYB Approved Performed"
          />
          :
        </span>
        <div className="font-weight-bold">{values.kybApproved || 0}</div>
      </ThisMonthStatsCol>
    </ThisMonthStatsRow>
  )
}

export default ThisMonthStats
