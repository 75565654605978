import React from 'react'
import PropTypes from 'prop-types'
import { Form, Modal } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Button, FormItemWrapper } from 'components'

const ChangePasswordModal = ({ visible, handleSubmit, handleCancel, form, onFinish, loading }) => {
  return (
    <Modal
      centered
      visible={visible}
      title={
        <FormattedMessage
          id="settings.security.changePassword.modal.title"
          defaultMessage="Change Password"
        />
      }
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          <FormattedMessage
            id="settings.security.changePassword.modal.cancel"
            defaultMessage="Cancel"
          />
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          <FormattedMessage id="settings.security.changePassword.modal.ok" defaultMessage="Okay" />
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        name="login"
        layout="vertical"
        scrollToFirstError
        hideRequiredMark
      >
        <FormItemWrapper name="oldPassword" type="oldPassword" required form={form} />

        <FormItemWrapper name="password" type="password" required form={form} />

        <FormItemWrapper
          name="confirmPassword"
          type="confirmPassword"
          required
          dependencies={['password']}
          form={form}
        />
      </Form>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default ChangePasswordModal
