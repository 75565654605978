import * as constants from './identity.constants'

export function getPersons(payload) {
  return { type: constants.GET_PERSONS, payload }
}

export function getPerson(id) {
  return { type: constants.GET_PERSON, id }
}

export function getPersonGovernmentIDs(id) {
  return { type: constants.GET_PERSON_GOVERNMENT_IDS, id }
}

export function getPersonByOrganizationId(id) {
  return { type: constants.GET_PERSONS_BY_ORGANIZATION_ID, id }
}

export function setPersonDecision(payload) {
  return { type: constants.SET_PERSON_DECISION, payload }
}

export function getOrganizations(payload) {
  return { type: constants.GET_ORGANIZATIONS, payload }
}

export function getOrganization(id) {
  return { type: constants.GET_ORGANIZATION, id }
}

export function setOrganizationDecision(payload) {
  return { type: constants.SET_ORGANIZATION_DECISION, payload }
}

export function getOrganizationNotes(id) {
  return { type: constants.GET_ORGANIZATION_NOTES, payload: { id } }
}

export function postOrganizationNote(id, note) {
  return { type: constants.POST_ORGANIZATION_NOTE, payload: { id, note } }
}

export function getPersonNotes(id) {
  return { type: constants.GET_PERSON_NOTES, payload: { id } }
}

export function postPersonNote(id, note) {
  return { type: constants.POST_PERSON_NOTE, payload: { id, note } }
}

export function postPersonPassport(id, data) {
  return { type: constants.POST_PERSON_PASSPORT, payload: { id, data } }
}

export function postPersonDriverLicense(id, data) {
  return { type: constants.POST_PERSON_DRIVER_LICENSE, payload: { id, data } }
}

export function searchPersons(query) {
  return { type: constants.SEARCH_PERSONS, payload: { query } }
}

export function searchOrganizations(query) {
  return { type: constants.SEARCH_ORGANIZATIONS, payload: { query } }
}

export function getSupportedCountries(payload) {
  return { type: constants.GET_SUPPORTED_COUNTRIES, payload }
}

export function getSupportedStates(payload) {
  return { type: constants.GET_SUPPORTED_STATES, payload }
}

export function getKycDocuments(id) {
  return { type: constants.GET_KYC_DOCUMENTS, payload: { id } }
}

export function getKybDocuments(id) {
  return { type: constants.GET_KYB_DOCUMENTS, payload: { id } }
}

export function getKybDocument(payload) {
  return { type: constants.GET_KYB_DOCUMENT, payload }
}

export function getKycDocument(payload) {
  return { type: constants.GET_KYC_DOCUMENT, payload }
}

export function uploadKycDocument(payload) {
  return { type: constants.UPLOAD_KYC_DOCUMENT, payload }
}

export function uploadKybDocument(payload) {
  return { type: constants.UPLOAD_KYB_DOCUMENT, payload }
}
