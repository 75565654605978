export const BILLING_MODE_TYPES = {
  PREPAID: 'prepaid',
  INVOICE: 'invoice',
}

export const DEFAULT_SELECTED_PAYMENT_PLAN_INDEX = 1

export const LINE_ITEMS_TYPES_ENUM = {
  KYC: 'KYC_US',
  KYB: 'KYB_US',
  PAYMENT: 'payment',
}

// ACTIONS TYPES
export const GET_PAYMENT_PLANS = 'settings/GET_PAYMENT_PLANS'
export const GET_PAYMENT_PLANS_SUCCESS = 'settings/GET_PAYMENT_PLANS_SUCCESS'
export const GET_PAYMENT_PLANS_ERROR = 'settings/GET_PAYMENT_PLANS_ERROR'

export const GET_PAYMENT_METHODS = 'settings/GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_SUCCESS = 'settings/GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_ERROR = 'settings/GET_PAYMENT_METHODS_ERROR'

export const UPDATE_PAYMENT_METHOD = 'settings/UPDATE_PAYMENT_METHOD'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'settings/UPDATE_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PAYMENT_METHOD_ERROR = 'settings/UPDATE_PAYMENT_METHOD_ERROR'

export const GET_INVOICES = 'settings/GET_INVOICES'
export const GET_INVOICES_SUCCESS = 'settings/GET_INVOICES_SUCCESS'
export const GET_INVOICES_ERROR = 'settings/GET_INVOICES_ERROR'

export const GET_INVOICE = 'settings/GET_INVOICE'
export const GET_INVOICE_SUCCESS = 'settings/GET_INVOICE_SUCCESS'
export const GET_INVOICE_ERROR = 'settings/GET_INVOICE_ERROR'
