import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Content, PageTitle } from 'features/Settings/settings.styled'
import { CostText } from 'components'
import moment from 'moment'
import {
  InvoiceDetailsHeader,
  InvoiceDetailsFooter,
  InvoiceDetailsHeaderInfo,
} from '../billing.styled'
import InvoiceDetailsChargesTable from '../components/InvoiceDetailsChargesTable'
import InvoiceDetailsPaymentsTable from '../components/InvoiceDetailsPaymentsTable'
import { getInvoice } from '../billing.actions'
import { getSortedInlineItems } from '../billing.selectors'
import { MOMENT_DATE_FORMAT } from '../../App/app.constants'

const InvoiceDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { invoices, invoicesLoading } = useSelector(state => state.billing)
  const { organization } = useSelector(state => state.organization)
  const { kycList, kybList, paymentsList } = useSelector(getSortedInlineItems)
  const invoice = invoices.details

  const total = [...kycList, ...kybList, ...paymentsList].reduce((acc, val) => {
    return acc + val.price
  }, 0)

  useEffect(() => {
    dispatch(getInvoice(id))
  }, [id])

  return (
    <div>
      <Helmet title="Settings - Invoice details" />
      <InvoiceDetailsHeader>
        <div>
          <PageTitle>
            <FormattedMessage
              id="settings.billing.invoice.details.title"
              defaultMessage="Invoice"
            />{' '}
            <span>#{invoice.number}</span>
          </PageTitle>
          <span>
            <FormattedMessage
              id="settings.billing.invoice.details.status"
              defaultMessage="Status"
            />
            : <span className="font-weight-bold">{invoices.details.state}</span>
          </span>
        </div>
        <InvoiceDetailsHeaderInfo>
          <div>
            <div>
              <FormattedMessage
                id="settings.billing.invoice.details.startDate"
                defaultMessage="Start Date"
              />
              :{' '}
              <span className="font-weight-bold">
                {moment(invoice.startAt).format(MOMENT_DATE_FORMAT)}
              </span>
            </div>
            <div>
              <FormattedMessage
                id="settings.billing.invoice.details.endDate"
                defaultMessage="End Date"
              />
              :{' '}
              <span className="font-weight-bold">
                {moment(invoice.endAt).format(MOMENT_DATE_FORMAT)}
              </span>
            </div>
            <div>
              <FormattedMessage
                id="settings.billing.invoice.details.dueDate"
                defaultMessage="Due Date"
              />
              :{' '}
              <span className="font-weight-bold">
                {moment(invoice.dueAt).format(MOMENT_DATE_FORMAT)}
              </span>
            </div>
          </div>
          <div>
            <div className="font-weight-bold">
              <FormattedMessage
                id="settings.billing.invoice.details.billedTo"
                defaultMessage="Billed To"
              />
              :
            </div>
            <div className="invoice-details-name">{organization.legalBusinessName}</div>
            <div>
              {organization.billingStreetName ? (
                <span>{organization.billingStreetName}, </span>
              ) : null}
              {organization.billingCity ? <span>{organization.billingCity}, </span> : null}
              {organization.billingState ? <span>{organization.billingState}, </span> : null}
              {organization.billingZip ? <span>{organization.billingZip}, </span> : null}
            </div>
          </div>
        </InvoiceDetailsHeaderInfo>
      </InvoiceDetailsHeader>
      <Content>
        <InvoiceDetailsChargesTable kycList={kycList} kybList={kybList} loading={invoicesLoading} />
        <InvoiceDetailsPaymentsTable paymentsList={paymentsList} loading={invoicesLoading} />
      </Content>
      <InvoiceDetailsFooter>
        <PageTitle>
          <FormattedMessage
            id="settings.billing.invoice.details.totalBalance"
            defaultMessage="Total balance"
          />
        </PageTitle>
        <PageTitle>
          <CostText value={total} />
        </PageTitle>
      </InvoiceDetailsFooter>
    </div>
  )
}

export default InvoiceDetails
