import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components'
import { LoginContainerTransparent } from '../../Auth/auth.styled'
import { ErrorTitle, ErrorSubTitle } from '../app.styled'
import * as routerConstants from '../app.router.constants'

const Error404 = () => {
  const history = useHistory()

  return (
    <LoginContainerTransparent>
      <Helmet title="Charge 404 - Page not found" />

      <ErrorTitle>
        <FormattedMessage id="error.404.title" defaultMessage="404" />
      </ErrorTitle>

      <ErrorSubTitle>
        <FormattedMessage
          id="error.404.subtitle"
          defaultMessage="Looks like you are lost! The page you are looking for does not exist"
        />
      </ErrorSubTitle>

      <Button
        type="primary"
        htmlType="button"
        className="w-100 mt-4"
        onClick={() => history.push(routerConstants.DASHBOARD_ROUTE)}
      >
        <FormattedMessage id="error.404.btn" defaultMessage="Take me to my Dashboard" />
      </Button>
    </LoginContainerTransparent>
  )
}

export default Error404
