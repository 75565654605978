import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const getChartDataByPeriod = (kyb, kyc) => {
  const result = []

  if (kyc && kyc.count) {
    const kycData = []
    for (let i = 0; i < kyc.count.length; i += 1) {
      kycData.push({
        x: moment(kyc.count[i].date).format('MM/DD/YYYY'),
        y: kyc.count[i].number,
      })
    }
    if (kycData.length) {
      result.push({
        id: 'KYC',
        color: '#acb7bf',
        data: kycData,
      })
    }
  }

  if (kyb && kyb.count) {
    const kybData = []
    for (let i = 0; i < kyb.count.length; i += 1) {
      kybData.push({
        x: moment(kyb.count[i].date).format('MM/DD/YYYY'),
        y: kyb.count[i].number,
      })
    }
    if (kybData.length) {
      result.push({
        id: 'KYB',
        color: '#6889fd',
        data: kybData,
      })
    }
  }

  return result
}
