import styled from 'styled-components'
import { Text } from 'components'

export const StyledOrganizationFormContainer = styled.div`
  @media (min-width: 767px) {
    .row {
      > div:last-child {
        padding-right: 0;
      }
      > div:first-child {
        padding-left: 0;
      }
    }
  }
`

export const StyledMemberInviteContainer = styled.div`
  input.ant-input {
    border-right: none;
  }
  .ant-input-group-addon {
    background: white;
    padding-right: 4px;
  }
  .ant-row {
    margin-bottom: 0;
  }
`

export const StyledMemberList = styled.div`
  height: 219px;
  overflow-y: scroll;
  border: 1px solid #e4e9f0;
  border-top: ${props => (props.noTopBorder ? 'none' : '1px solid #e4e9f0')};

  > div:nth-child(even) {
    background-color: #f6f7fa;
  }
`

export const StyledMemberListItem = styled(Text)`
  display: flex;
  height: 48px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  font-size: 16px;
  position: relative;
  justify-content: space-between;

  .ant-btn-link span {
    text-decoration: none !important;
    height: auto !important;
  }
`

export const StyledMemberListItemRole = styled(Text)`
  background-color: #e4e9f0;
  border-radius: 20px;
  padding: 5px 16px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  height: 24px;
  margin-left: 12px;
`

export const StyledNoOrganizationScreen = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 85px;

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
  }
  span {
    font-size: 16px;
    width: 270px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 15px;
  }
`

export const StyledOrganizationInputAddonAfter = styled.div`
  display: flex;
  align-items: center;

  .ant-select.ant-select-single {
    width: 150px !important;
    border: solid 1px #e4e9f0;
    height: 32px;
    margin: 0;
    margin-right: 5px;
  }
`

export const StyledNoPermissionWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 35px;

  img {
    width: 420px;
  }

  > div {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
`
