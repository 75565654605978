import React, { useLayoutEffect, useState } from 'react'
import { Menu as AntMenu } from 'antd'
import Logo from 'assets/images/logo-white-mobile.svg'
import MenuIcon from 'assets/images/icons/ic-menu.svg'
import { StyledHeader } from '../app.styled'
import Menu from './Menu'
import UserInfo from './UserInfo'

const { SubMenu } = AntMenu

const Header = ({ location, history }) => {
  const [openKeys, setOpenKeys] = useState([])

  const triggerSelect = ({ key }) => {
    setOpenKeys(openKeys.length ? [] : [key])
  }

  /**
   * Hide header menu on resize (or change device orientation)
   */
  useLayoutEffect(() => {
    const updateSize = () => {
      setOpenKeys([])
    }
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <StyledHeader>
      <img src={Logo} alt="Logo" />
      <div className="d-flex">
        <AntMenu theme="dark" forceSubMenuRender mode="horizontal" openKeys={openKeys}>
          <SubMenu
            key="menu"
            onTitleClick={triggerSelect}
            icon={<img src={MenuIcon} alt="Menu icon" />}
          >
            <UserInfo />
            <Menu location={location} history={history} hideMenu={() => setOpenKeys([])} />
          </SubMenu>
        </AntMenu>
      </div>
    </StyledHeader>
  )
}

export default Header
