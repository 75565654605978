import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import NotificationsIcon from 'assets/images/icons/ic-notifications.svg'
import { useSelector } from 'react-redux'
import { Button } from 'components'
import { StyledNoOrganizationScreen } from '../organization.styled'
import * as routerConstants from '../../App/app.router.constants'

const NoOrganizationWrapper = ({ children }) => {
  const history = useHistory()
  const { user } = useSelector(state => state.auth)

  return user.accountId ? (
    children
  ) : (
    <StyledNoOrganizationScreen>
      <img src={NotificationsIcon} alt="Info icon" />
      <FormattedMessage
        id="settings.settings.organization.noAccount.title"
        defaultMessage="Hey, to access this page you need to set up your organization first"
      />
      <Button
        type="primary"
        onClick={() => history.push(routerConstants.SETTINGS_ORGANIZATION_ROUTE)}
        className="mt-2"
      >
        <FormattedMessage
          id="settings.settings.organization.noAccount.btn"
          defaultMessage="Set up my Organization now"
        />
      </Button>
    </StyledNoOrganizationScreen>
  )
}

export default NoOrganizationWrapper
