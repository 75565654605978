import React from 'react'
import { BaseTable, PageSubTitle, PageTitle, Text } from 'components'
import { TermsContainer } from '../auth.styled'
import { TERMS_COOKIES_COLUMNS, TERMS_COOKIES_DATA } from '../auth.constants'

const DataProtection = () => (
  <TermsContainer>
    <PageTitle>Data Protection Notice for Customers and Website Visitors</PageTitle>

    <Text>
      This notice provides you with information on how Charge Technology Group, Inc. 1100 Highland
      Ave, Manhattan Beach, CA 90266 (“Charge”) will process your personal data, each acting
      independently as a controller in its own right. Furthermore, Charge would like to inform you,
      as (i) a contact person at one of Charge’s (potential) customers or (ii) a visitor of the
      website www.charge.io (the “Site”), about your rights regarding your personal data and how you
      can get in touch with Charge in order to enforce them.
    </Text>

    <Text>
      A separate policy, available here, describes our privacy practices in connection with the
      payment services, payment processing services, and other related applications that Charge
      makes available on the Site and that are embedded in third-party applications and services.
    </Text>

    <PageTitle>
      1. Processing of your personal data as a contact person at one of Charge’s customers
    </PageTitle>

    <Text>
      If you are a contact person at one of Charge’s (potential) customers, Charge will process your
      personal data for the following purposes:
      <div>
        <ul>
          <li>to inform you about Charge’s products and services;</li>
          <li>to be able to communicate with Charge’s (potential) customers;</li>
          <li>to provide a self-service portal to Charge’s customers (customer portal);</li>
          <li>
            to be able to provide Charge’s customers with Charge’s products, support and services;
          </li>
          <li>for customer relationship management purposes;</li>
          <li>to conduct satisfaction and marketing surveys among (potential) customers;</li>
          <li>to comply with legal obligations, in particular under U.S. law; and</li>
          <li>
            to comply with the requirements of the Payment Card Industry Data Security Standard (PCI
            DSS).
          </li>
        </ul>
      </div>
      For these purposes, Charge will process the following of your personal data:
      <div>
        <ul>
          <li>name</li>
          <li>job title</li>
          <li>related company name and address</li>
          <li>phone numbers</li>
          <li>email address</li>
          <li>content of agreements</li>
          <li>data from Charge’s communication with you, and</li>
          <li>other data provided by you in your communications with Charge; and</li>
          <li>
            as regards Charge, data that you provide via the customer portal as well as information
            about your use of the customer portal, in particular all information necessary to
            maintain a complete audit trail of any access to personal data in the customer portal.
          </li>
        </ul>
      </div>
      Charge collects this data either from you directly, when you provide Charge with your personal
      data by e.g., sending Charge an email, providing Charge with your personal data on the phone
      or through Charge’s customer portal. Charge may also collect some of your personal data in the
      course of Charge’s business relationship with your employer.
    </Text>

    <Text>
      Where Charge asks you to provide your personal data, this provision of personal data is
      voluntary. However, if you do not provide your personal data you will not be able to
      communicate with Charge and your employer may not be able to make use of the full range of
      Charge’s products and services.
    </Text>

    <PageTitle>2. Processing of your personal data as a visitor of the Site</PageTitle>

    <PageSubTitle>2.1 Personal and pseudonymized information</PageSubTitle>

    <Text>
      Charge collects both “personal information” and “pseudonymized information” about Site users.
      For purposes of this section, “pseudonymized information” is information that Charge cannot
      directly associate with a specific person without the aid of additional information. By
      contrast, “personal information,” is information such as a name or email address that Charge
      can directly associate with a specific person or entity without additional information.
    </Text>

    <Text>
      <span className="font-weight-bold">Personal information.</span>
      You can visit the Site without submitting any personal information. However, if you use
      certain features on the Site, such as the “Request Information” feature, you will be required
      to provide personal information. Such information could include, for example, your name, email
      address, telephone number, postal address, company, job title, and/or industry. Our online
      contact form also contains a “comments” field through which you may submit additional personal
      information.
    </Text>

    <Text>
      There is no obligation to provide the information that we ask you for on the Site. However, if
      you do not provide your personal information, you will not be able to make use of all
      functionality of the Site.
    </Text>

    <Text>
      We may combine personal information collected through the Site with other information that we
      or third parties collect about you in other contexts—such as our communications with you via
      email or phone, or your customer service records. We will treat such combined information as
      personal information and protect it in accordance with this Policy.
    </Text>

    <PageSubTitle>2.2 Web technologies</PageSubTitle>

    <Text>
      <span className="font-weight-bold">Web log data.</span>
      When you use the Site, we automatically receive and record the following information from your
      computer (or other device) and your browser: your IP address and domain name, the pages you
      visit on the Site, the date and time of your visit, the files that you download, the URLs from
      the websites you visit before and after navigating to the Site, your software and hardware
      attributes (including device IDs), your general geographic location (e.g., your city, state,
      or metropolitan region), and certain cookie information (see below).
    </Text>

    <Text>
      <span className="font-weight-bold">Cookies.</span>
      We also use cookies on the Site. For further information please refer to the section entitled
      “Cookies” below
    </Text>

    <Text>
      <span className="font-weight-bold">Web beacons.</span>
      The Site or the emails that you receive from Charge may use an application known as a “web
      beacon” (also known as a “clear gif” or “web bug”). A web beacon is an electronic file that
      usually consists of a single-pixel image. It can be embedded in a web page or in an email to
      transmit information, which could include personal information. For example, it allows an
      email sender to determine whether a user has opened a particular email.
    </Text>

    <Text>
      <span className="font-weight-bold">
        Third-party online tracking and behavioral advertising.
      </span>
      We also may partner with certain third parties to collect, analyze, and use some of the
      personal and pseudonymized information described in this section. For example, we may allow
      third parties to set cookies or use web beacons on the Site or in email communications from
      Charge. This information may be used for a variety of purposes, including online behavioral
      advertising, as discussed below (see the section entitled “How we share personal and
      pseudonymized information with third parties”).
    </Text>

    <PageTitle>2.3 Cookies</PageTitle>

    <Text>
      <span className="font-weight-bold">What are cookies?</span>
      Cookies are small files that are stored on your computer by your web browser. A cookie allows
      the Site to recognize whether you have visited before and may store user preferences and other
      information.
    </Text>

    <Text>
      <span className="font-weight-bold">How are cookies used?</span>
      For example, cookies can be used to collect information about your use of the Site during your
      current session and over time (including the pages you view and the files you download), your
      computer’s operating system and browser type, your Internet service provider, your domain name
      and IP address, your general geographic location, the website that you visited before the
      Site, and the link you used to leave the Site.
    </Text>

    <Text>
      <span className="font-weight-bold">What kind of cookies are used on the Website?</span>
      Our website primarily uses the following types of cookies:
    </Text>

    <BaseTable
      className="utils__scrollTable"
      columns={TERMS_COOKIES_COLUMNS}
      dataSource={TERMS_COOKIES_DATA}
      bordered
      size="default"
      pagination={false}
    />

    <Text>
      “Session cookies” are temporary bits of information which are deleted when you exit your web
      browser. Session cookies are typically used to improve navigation and to collect web
      statistics.
    </Text>

    <Text>
      “Persistent cookies” are more permanent bits of information that are stored and remain on your
      computer until they are deleted by you. This type of cookie stores information on your
      computer for a number of purposes; such as saving your passwords. Persistent cookies delete
      themselves after a certain period of time but are renewed each time you visit the website.
    </Text>

    <Text>
      <span className="font-weight-bold">How do you avoid cookies?</span>
      If you are concerned about having cookies on your computer or device, you can set your browser
      to refuse all cookies or to indicate when a cookie is being set, allowing you to decide
      whether to accept it. You can also delete cookies from your computer. However, if you choose
      to block or delete cookies, certain features of the Services may not operate correctly.
    </Text>

    <PageTitle>
      2.4 How we use the personal and pseudonymized information that we collect on the Site
    </PageTitle>

    <Text>
      Charge uses the information that we collect on the Site for a variety of purposes. If we have
      personal information about you, we may use it to: respond to your questions or requests
      concerning the Site or other services offered by Charge or our partners; to fulfill the terms
      of any agreement you have with us; to fulfill your requests for our services or otherwise
      complete a transaction that you initiate; to send you information about our services and other
      topics that are likely to be of interest to you, including newsletters, updates, or other
      communications; to deliver confirmations, account information, notifications, and similar
      operational communications; to improve your user experience and the quality of our products
      and services; and to comply with legal and/or regulatory requirements.
    </Text>

    <Text>
      We use the pseudonymized information that we collect for: counting and recognizing visitors to
      the Site; analyzing how visitors use the Site and various Site features; improving the Site
      and enhancing users’ experiences with the Site; creating new products and services or
      improving our existing products and services; and enabling additional website analytics and
      research concerning the Site. Charge may link pseudonymized information gathered using cookies
      and web beacons with personal information.
    </Text>

    <PageTitle>
      2.5 How we share personal and pseudonymized information collected on the Site with third
      parties
    </PageTitle>

    <Text>
      We share your personal information and pseudonymized information with other parties for a
      variety of purposes, as described below.
    </Text>

    <Text>
      <span className="font-weight-bold">Analytics and online behavioral advertising.</span>
      We partner with certain third parties to collect the pseudonymized information discussed above
      and to engage in analysis, auditing, research, and reporting. These third parties may use web
      logs or web beacons, and they may set and access cookies on your computer or other device. In
      particular, the Site uses Google Analytics to help collect and analyze certain information for
      the purposes discussed above. You may opt out of the use of cookies by Google Analytics here.
    </Text>

    <Text>
      The Site also enables third-party tracking mechanisms to collect your pseudonymized
      information for use in online behavioral advertising. For example, third parties may use the
      fact that you visited the Site to target online ads for Charge services to you on non- Charge
      websites. In addition, our third-party advertising networks might use information about your
      use of the Site to help target non-Charge advertisements based on your online behavior in
      general. For information about behavioral advertising practices, including privacy and
      confidentiality, visit the Network Advertising Initiative website or the Digital Advertising
      Alliance website.
    </Text>

    <Text>
      The use of online tracking mechanisms by third parties is subject to those third parties’ own
      privacy policies, and not this Policy. If you prefer to prevent third parties from setting and
      accessing cookies on your computer, you may set your browser to block cookies. Additionally,
      you may remove yourself from the targeted advertising of companies within the Network
      Advertising Initiative by opting out here, or of companies participating in the Digital
      Advertising Alliance program by opting out here. Although our website currently does not
      respond to “do not track” browser headers, you can limit tracking through these third-party
      programs and by taking the other steps discussed above.
    </Text>

    <Text>
      <span className="font-weight-bold">Third-party service providers and business partners.</span>
      Charge also uses other third-party service providers to help us manage and improve the Site
      and other Charge services. These service providers may collect and/or use your personal or
      pseudonymized information to assist us in achieving the purposes discussed above in the
      section entitled “How we use the personal and pseudonymized information that we collect.” For
      example, we use third parties to help us target and implement our email communications and
      direct mailings. We also use third-party platforms to help us manage our relationships with
      the people and companies that use our services.
    </Text>

    <Text>
      We also may share your personal or pseudonymized information with other third parties when
      necessary to fulfill your requests for services; to complete a transaction that you initiate;
      or to meet the terms of any agreement that you have with us or our partners.
    </Text>

    <Text>
      <span className="font-weight-bold">
        Your direct sharing of personal information through third-party websites and services.
      </span>
      The Site may enable you to share directly personal information with websites or online
      services operated by third parties. For example, the Site contains links to third-party
      websites that incorporate comment and social media features. The Site may also containa a
      Facebook “like” button and a Twitter “follow” button. If you choose to use these features, you
      may disclose your personal information not just to those third-party websites and services,
      but also to their users and the public more generally. Because these third-party websites and
      services are not operated by Charge, Charge is not responsible for the content or practices of
      those websites or services. The collection, use, and disclosure of your personal and
      pseudonymized information will be subject to the privacy policies of the third-party websites
      or services, and not this Policy
    </Text>

    <Text>
      <span className="font-weight-bold">Third-party plugins.</span>
      The Site may integrate certain third-party plug-ins (such as a Facebook “like” button). Even
      if you do not click on these plug-ins, they may collect information about you, such as your IP
      address and the pages that you view. They also may set and/or access a cookie. These plugins
      are governed by the privacy policy of the company providing them.
    </Text>

    <Text>
      <span className="font-weight-bold">Legal purposes.</span>
      We also may use or share your personal or pseudonymized information with third parties when we
      have reason to believe that doing so is necessary:
      <div>
        <ul>
          <li>to comply with applicable law or a court order, subpoena, or other legal process;</li>
          <li>
            to investigate, prevent, or take action regarding illegal activities, suspected fraud,
            violations of our terms and conditions, or situations involving threats to our property
            or the property or physical safety of any person or third-party;
          </li>
          <li>
            to establish, protect, or exercise our legal rights or defend against legal claims; or
          </li>
          <li>
            to facilitate the financing, securitization, insuring, sale, assignment, bankruptcy, or
            other disposal of all or part of our business or assets. Other than to the extent
            ordered by a bankruptcy or other court, the use and disclosure of all transferred
            personal information will be subject to this Policy, or to a new privacy policy if (i)
            you are given notice of that new privacy policy, and (ii) to the extent that policy is
            materially different from this Policy, you affirmatively opt-in to accept it. Personal
            information submitted or collected after a transfer, however, will be subject to any new
            privacy policy adopted by the successor.
          </li>
        </ul>
      </div>
    </Text>

    <Text>
      <span className="font-weight-bold">Aggregated information.</span>
      From time to time, Charge may also share anonymized and aggregated information about Site
      users, such as by publishing a report on trends in the usage of the Site.
    </Text>

    <PageSubTitle>2.6 Children’s online privacy</PageSubTitle>

    <Text>
      The Site is not directed to children under the age of 13, and Charge will never knowingly
      collect personal or other information from anyone it knows is under the age of 13. We
      recommend that persons over 13 but under 18 years of age ask their parents for permission
      before using the Site or sending any information about themselves to anyone over the Internet.
    </Text>

    <PageTitle>3. Legal basis of the processing</PageTitle>

    <Text>
      Charge processes your personal data on the basis of Charge’s prevailing legitimate interest
      according to Article 6(1)(f) General Data Protection Regulation (“GDPR”) to achieve the
      purposes set out above under Sections 1 and 2. Charge may ask you for your consent to process
      your personal data with Charge in a separate process. In case you provide your consent, Charge
      will be processing your data on the basis of this consent (Article 6(1)(a) GDPR). You may
      withdraw such consent previously granted at any time. However, withdrawing your consent does
      not affect the lawfulness of any processing based on your consent before your withdrawal.
    </Text>

    <Text>
      If you opt in to receive marketing emails from us, we also may use the personal data that we
      collect to send you promotional communications, including emails or postal mail about the
      products and services offered by Charge or our partners. Although we hope you will find our
      promotional emails of interest, you may opt out of receiving them by sending an email to{' '}
      <a href="mailto:privacy@charge.io">privacy@charge.io</a> with “optout” in the subject line.
      Promotional emails will also have instructions for opting out of that type of communication in
      the future.
    </Text>

    <PageTitle>4. Transfer of your personal data</PageTitle>

    <Text>
      As far as this is necessary to achieve the purposes set out above and in addition to the
      transfers described in Section 2.5, Charge will transfer your personal data to the following
      recipients:
      <div>
        <ul>
          <li>IT service providers that Charge may use (e.g., Salesforce.com, Inc.);</li>
          <li>companies that are part of Charge’s corporate group;</li>
          <li>public authorities;</li>
          <li>auditors for the performance of audits;</li>
          <li>
            contract partners or business partners who are participating in the performance of
            Charge’s service delivery.
          </li>
        </ul>
      </div>
      Some of the recipients referred to above or in Section 2.5 are located in or process personal
      data outside of your country. The level of data protection in another country may not be
      equivalent to that in your country. However, Charge only transfers your personal data to
      countries where the EU Commission has decided that they have an adequate level of data
      protection or Charge takes measures to ensure that all recipients provide an adequate level of
      data protection. Charge does this for example by entering into appropriate data transfer
      agreements based on Standard Contractual Clauses (2010/87/EC and/or 2004/915/EC). Such
      agreements are accessible upon request from privacy@charge.io.
    </Text>

    <PageTitle>5. Retention periods</PageTitle>

    <Text>
      In general, Charge will retain your personal data (i) until the end of Charge’s business
      relationship with you if you are a contact person at a (potential) customer of Charge or (ii)
      for three months if you are a visitor of the Site. Thereafter your data will be retained as
      long as there are statutory retention obligations or the data is necessary to exercise or
      defend against potential legal claims that are not yet time-barred.
    </Text>

    <Text>
      If you have subscribed to our newsletter, we will store your email address as long as you are
      subscribed to the newsletter. Moreover we will store information related to any request you
      send us and any following communication for as long as necessary to respond to and fulfill
      your request.
    </Text>

    <PageTitle>6. Security</PageTitle>

    <Text>
      Charge uses commercially reasonable physical, electronic, and procedural safeguards to protect
      your personal and pseudonymized information against loss or unauthorized access, use,
      modification, or deletion. However, no security program is foolproof, and thus we cannot
      guarantee the absolute security of your personal or other information.
    </Text>

    <PageTitle>7. Your rights in connection with your personal data</PageTitle>

    <Text>
      Under applicable law, you have, among others, the rights (under the conditions set out in
      applicable law): (i) to check whether and what kind of personal data Charge holds about you
      and to request copies of such data, (ii) to request correction, supplementation or deletion of
      your personal data that is inaccurate or processed in non-compliance with applicable
      requirements, and (iii) to request Charge to restrict the processing of your personal data,
      (iv) in certain circumstances, to object for legitimate reasons to the processing of your
      personal data, (v) to request data portability, (vi) to know the identities of third parties
      to which your personal data are transferred, and (vii) to lodge a complaint with the competent
      authority.
    </Text>

    <PageTitle>8. Changes to this Notice</PageTitle>

    <Text>
      Technology and the Internet are rapidly changing. Charge therefore needs to revise this Policy
      to reflect those changes. Charge will post all such changes on the Site, so you should review
      this page periodically. If we make a material change to the Policy, you will be provided with
      appropriate notice. If we maintain your email address, we also may email you a copy of the
      revised Policy at your most recently provided email address. It is therefore important that
      you update your email address if it changes.
    </Text>

    <PageTitle>9. Charge’s contact details</PageTitle>

    <Text>
      Please address your requests or questions concerning the processing of your personal data to
      the relevant Charge entity:
    </Text>

    <Text>
      Charge Technology Group, Inc. <br />
      1100 Highland Ave <br />
      Manhattan Beach, CA 90266
    </Text>
  </TermsContainer>
)

export default DataProtection
