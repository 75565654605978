import { BILLING_MODE_TYPES, LINE_ITEMS_TYPES_ENUM } from './billing.constants'

export const getInvoicePaymentPlans = state => {
  return {
    paymentPlans: state.billing.paymentPlans.filter(
      plan => plan.billingMode === BILLING_MODE_TYPES.INVOICE,
    ),
    paymentPlansLoading: state.billing.loading,
  }
}

export const getPrepaidPaymentPlans = state => {
  return {
    paymentPlans: state.billing.paymentPlans.filter(
      plan => plan.billingMode === BILLING_MODE_TYPES.PREPAID,
    ),
    paymentPlansLoading: state.billing.loading,
  }
}

export const getSortedInlineItems = state => {
  if (state.billing.invoices.details && state.billing.invoices.details.lineItems) {
    const kycList = state.billing.invoices.details.lineItems.filter(
      item => item.label === LINE_ITEMS_TYPES_ENUM.KYC,
    )
    const kybList = state.billing.invoices.details.lineItems.filter(
      item => item.label === LINE_ITEMS_TYPES_ENUM.KYB,
    )
    const paymentsList = state.billing.invoices.details.lineItems.filter(
      item => item.label === LINE_ITEMS_TYPES_ENUM.PAYMENT,
    )

    return {
      kycList,
      kybList,
      paymentsList,
    }
  }
  return {
    kycList: [],
    kybList: [],
    paymentsList: [],
  }
}
