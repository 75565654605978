import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import ApproveIcon from 'assets/images/icons/ic-approve.svg'
import DeclineIcon from 'assets/images/icons/ic-decline.svg'
import CreatedIcon from 'assets/images/icons/ic-created.svg'
import ManualReviewIcon from 'assets/images/icons/ic-manual-review.svg'
import { StyledHistoryRow, StyledHistoryRowStatus } from '../../identity.styled'
import { MOMENT_DATE_TIME_FORMAT } from '../../../App/app.constants'

const TYPE_TO_ICON_MAP = {
  ACCEPT: ApproveIcon,
  DENY: DeclineIcon,
  MANUAL_REVIEW: ManualReviewIcon,
  CREATED: CreatedIcon,
  PROCESSING: CreatedIcon,
  PENDING: CreatedIcon,
}

const ACTIONS_TYPES_ENUM = {
  NOTE_ADDED: 'NOTE_ADDED',
  STATUS_CHANGED: 'STATUS_CHANGED',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
}

const History = ({ data }) => {
  return (
    <Fragment>
      {data.map(({ meta, user, createdAt, action }) => (
        <StyledHistoryRow key={createdAt}>
          <img src={TYPE_TO_ICON_MAP[meta.status] || CreatedIcon} alt="approve icon" />
          <div>
            {action === ACTIONS_TYPES_ENUM.DOCUMENT_UPLOADED ? (
              <div>
                <FormattedMessage
                  id="identity.history.tab.history.row.documentUploaded"
                  defaultMessage="document uploaded: "
                />
                &nbsp;
                <span>{meta.name}</span>
              </div>
            ) : null}
            {action === ACTIONS_TYPES_ENUM.NOTE_ADDED ? (
              <div>
                <FormattedMessage
                  id="identity.history.tab.history.row.noteAdded"
                  defaultMessage="added note:"
                />
                &nbsp;
                <span>{meta.body}</span>
              </div>
            ) : null}
            {action === ACTIONS_TYPES_ENUM.STATUS_CHANGED ? (
              <div>
                <FormattedMessage
                  id="identity.history.tab.history.row.status.setTo"
                  defaultMessage="the application’s state is set to"
                />
                &nbsp;
                <StyledHistoryRowStatus status={meta.status}>
                  <FormattedMessage
                    id={`dashboard.subTitle.history.row.status.${meta.status}`}
                    defaultMessage={meta.status}
                  />
                </StyledHistoryRowStatus>
              </div>
            ) : null}

            {user.identifier || createdAt ? (
              <div>
                {user.identifier ? (
                  <Fragment>
                    <FormattedMessage
                      id="identity.history.tab.history.row.by"
                      defaultMessage="by"
                    />
                    &nbsp;
                    <span className="font-weight-bold">{user.identifier}</span>
                  </Fragment>
                ) : null}
                {createdAt ? (
                  <Fragment>
                    &nbsp;
                    <FormattedMessage
                      id="identity.history.tab.history.row.at"
                      defaultMessage="at"
                    />
                    &nbsp;
                    <span>{moment(createdAt).format(MOMENT_DATE_TIME_FORMAT)}</span>
                  </Fragment>
                ) : null}
              </div>
            ) : null}
          </div>
        </StyledHistoryRow>
      ))}
    </Fragment>
  )
}

History.defaultProps = {
  data: [],
}

export default History
