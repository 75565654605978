import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import SideBar from './components/SideBar'
import Header from './components/Header'
import Footer from '../Auth/components/AuthFooter'
import { StyledContent, StyledLayout } from './app.styled'
import { getLoggedInUser } from '../Auth/auth.actions'

const App = ({ children, location, history, noFooter }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    if (!user.jwt) {
      dispatch(getLoggedInUser())
    }
  }, [user])

  return (
    <Layout>
      <SideBar />
      <StyledLayout>
        <Header history={history} location={location} />
        <StyledContent>{children}</StyledContent>
        {!noFooter ? <Footer /> : null}
      </StyledLayout>
    </Layout>
  )
}

export default App
