import React from 'react'
import { Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import BaseRoute from 'features/App/components/BaseRoute'
import PublicRoute from 'features/App/components/PublicRoute'
import PrivateRoute from 'features/App/components/PrivateRoute'

import AuthLayout from 'features/Auth'
import Login from 'features/Auth/views/Login'
import Register from 'features/Auth/views/Register'
import ForgotPassword from 'features/Auth/views/ForgotPassword'
import VerifyEmail from 'features/Auth/views/VerifyEmail'
import ResetPassword from 'features/Auth/views/ResetPassword'
import TermsAndConditions from 'features/Auth/views/TermsAndConditions'
import PrivacyPolicy from 'features/Auth/views/PrivacyPolicy'
import DataProtection from 'features/Auth/views/DataProtection'
import PreSaleCheckout from 'features/PreSaleCheckout'
import PreSaleCheckoutPaymentSuccess from 'features/PreSaleCheckout/views/PaymentSuccess'

import AppLayout from 'features/App'
import Dashboard from 'features/Dashboard'

import IdentityBusiness from 'features/Identity/index.business'
import IdentityCustomer from 'features/Identity/index.customer'
import IdentityBusinessDetails from 'features/Identity/views/BusinessDetails'
import IdentityCustomerDetails from 'features/Identity/views/CustomerDetails'

import SettingsLayout from 'features/Settings'
import ApiKeys from 'features/ApiKeys'
import CreateApiKey from 'features/ApiKeys/views/CreateApiKey'
import CreateApiKeySuccess from 'features/ApiKeys/views/CreateApiKeySuccess'
import Billing from 'features/Billing'
import BillingSelectPlan from 'features/Billing/views/SelectPaymentPlan'
import BillingInvoiceDetails from 'features/Billing/views/InvoiceDetails'
import UpdatePaymentMethod from 'features/Billing/views/UpdatePaymentMethod'
import SecuritySettings from 'features/Security'
import UserProfile from 'features/UserProfile'
import Organization from 'features/Organization'
import Widgets from 'features/Widgets'

import Error404 from 'features/App/views/Error404'
import Error500 from 'features/App/views/Error500'

import * as routerConstants from 'features/App/app.router.constants'

import Notifications from 'features/Notifications'

import { composeLayouts } from 'features/App/app.utils'

import initInterceptors from './config/axios.config'

const Router = ({ history }) => {
  const dispatch = useDispatch()
  const AppSettingsCompositedLayout = composeLayouts([AppLayout, SettingsLayout])

  initInterceptors(history, dispatch)

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <BaseRoute exact path="/" />

        <PublicRoute
          exact
          path={routerConstants.LOGIN_ROUTE}
          layout={AuthLayout}
          component={Login}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.REGISTER_ROUTE}
          layout={AuthLayout}
          component={Register}
        />
        <PublicRoute
          exact
          path={routerConstants.FORGOT_PASSWORD_ROUTE}
          layout={AuthLayout}
          component={ForgotPassword}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.VERIFY_EMAIL_ROUTE}
          layout={AuthLayout}
          component={VerifyEmail}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.RESET_PASSWORD_ROUTE}
          layout={AuthLayout}
          component={ResetPassword}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.TERMS_AND_CONDITIONS_ROUTE}
          layout={AuthLayout}
          component={TermsAndConditions}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.PRIVACY_POLICY_ROUTE}
          layout={AuthLayout}
          component={PrivacyPolicy}
        />
        <PublicRoute
          exact
          restrict={false}
          path={routerConstants.DATA_PROTECTION_ROUTE}
          layout={AuthLayout}
          component={DataProtection}
        />
        <PublicRoute
          exact
          path={routerConstants.PRE_SALE_CHECKOUT_ROUTE}
          layout={AuthLayout}
          component={PreSaleCheckout}
        />
        <PublicRoute
          exact
          path={routerConstants.PRE_SALE_CHECKOUT_SUCCESS_ROUTE}
          layout={AuthLayout}
          component={PreSaleCheckoutPaymentSuccess}
        />
        <PrivateRoute
          exact
          path={routerConstants.DASHBOARD_ROUTE}
          layout={AppLayout}
          component={Dashboard}
        />
        <PrivateRoute
          exact
          path={routerConstants.IDENTITY_BUSINESS_ROUTE}
          layout={AppLayout}
          component={IdentityBusiness}
        />
        <PrivateRoute
          exact
          path={routerConstants.IDENTITY_CUSTOMER_ROUTE}
          layout={AppLayout}
          component={IdentityCustomer}
        />
        <PrivateRoute
          exact
          path={routerConstants.IDENTITY_BUSINESS_DETAILS_ROUTE}
          layout={AppLayout}
          component={IdentityBusinessDetails}
          noFooter
        />
        <PrivateRoute
          exact
          path={routerConstants.IDENTITY_CUSTOMER_DETAILS_ROUTE}
          layout={AppLayout}
          component={IdentityCustomerDetails}
          noFooter
        />
        <PrivateRoute
          exact
          path={routerConstants.WIDGETS_ROUTE}
          layout={AppLayout}
          component={Widgets}
        />

        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_API_KEYS_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={ApiKeys}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_API_KEYS_CREATE_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={CreateApiKey}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_API_KEYS_CREATE_SUCCESS_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={CreateApiKeySuccess}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_BILLING_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={Billing}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_BILLING_SELECT_PLAN_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={BillingSelectPlan}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_BILLING_INVOICE_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={BillingInvoiceDetails}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_ADD_PAYMENT_METHOD_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={UpdatePaymentMethod}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_SECURITY_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={SecuritySettings}
        />

        {/* Old pages left */}
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_PROFILE_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={UserProfile}
        />
        <PrivateRoute
          exact
          path={routerConstants.SETTINGS_ORGANIZATION_ROUTE}
          layout={AppSettingsCompositedLayout}
          component={Organization}
        />

        <PublicRoute
          restrict={false}
          path={routerConstants.ERROR_500_ROUTE}
          layout={AuthLayout}
          component={Error500}
        />
        <PublicRoute restrict={false} layout={AuthLayout} component={Error404} />
      </Switch>
      <Notifications />
    </ConnectedRouter>
  )
}

export default Router
