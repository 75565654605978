import axios from 'axios'

export async function getOrganization(id) {
  return axios.get(`/accounts/${id}`).then(res => res.data)
}

export async function createOrganization(payload) {
  return axios.post('/accounts', payload).then(res => res.data)
}

export async function updateOrganization(payload) {
  return axios.patch(`/accounts/${payload.id}`, payload).then(res => res.data)
}

export async function updatePaymentPlan({ organizationId, paymentPlanId }) {
  return axios.put(`/accounts/${organizationId}/plans/${paymentPlanId}`).then(res => res.data)
}

export async function postInvitation(payload) {
  return axios.post('/invitations', payload).then(res => res.data)
}

export async function getInvitations() {
  return axios.get(`/invitations`).then(res => res.data)
}

export async function updateMemberRole(payload) {
  return axios.post(`/roles`, payload).then(res => res.data)
}

export async function removeMember({ userId }) {
  return axios.delete(`/accounts/remove/${userId}`).then(res => res.data)
}

export async function freezeAccount(payload) {
  const res = await axios.post(`settings/freeze`, payload)
  return res.data
}

export async function deactivateAccount(payload) {
  const res = await axios.post(`settings/deactivate`, payload)
  return res.data
}

export async function activateAccount(payload) {
  const res = await axios.post(`settings/deactivate`, payload)
  return res.data
}
