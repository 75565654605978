import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'antd'
import { Button } from 'components'

const WidgetDeleteConfirmModal = ({ visible, setVisible, handleDelete }) => {
  return (
    <Modal
      centered
      visible={visible}
      title={<FormattedMessage id="widgets.delete.modal.title" defaultMessage="Delete Widget" />}
      onOk={handleDelete}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={handleDelete}>
          <FormattedMessage id="widgets.delete.modal.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={false} onClick={handleDelete}>
          <FormattedMessage id="widgets.delete.modal.submit" defaultMessage="Delete Widget" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="widgets.delete.modal.description"
        defaultMessage="Are you sure you want to delete this widget?"
      />
    </Modal>
  )
}

export default WidgetDeleteConfirmModal
