import * as constants from './auth.constants'

export function login(payload) {
  return { type: constants.USER_LOGIN, payload }
}

export function logout(noRedirect) {
  return { type: constants.USER_LOGOUT, noRedirect }
}

export function register(payload) {
  return { type: constants.USER_REGISTER, payload }
}

export function validateEmail(payload) {
  return { type: constants.USER_VALIDATE_EMAIL, payload }
}

export function forgotPassword(payload) {
  return { type: constants.USER_FORGOT_PASSWORD, payload }
}

export function resetPassword(payload) {
  return { type: constants.USER_RESET_PASSWORD, payload }
}

export function getLoggedInUser() {
  return { type: constants.GET_LOGGED_IN_USER }
}

export function setUserAccountId(accountId) {
  return { type: constants.SET_USER_ACCOUNT_ID, accountId }
}

export function setUserProfile(payload) {
  return { type: constants.SET_USER_PROFILE, payload }
}

export function registerByInvitation(payload) {
  return { type: constants.USER_REGISTER_BY_INVITATION, payload }
}

export function validateInvitationToken(payload) {
  return { type: constants.VALIDATE_INVITATION_TOKEN, payload }
}

export function updatePassword(payload) {
  return { type: constants.UPDATE_PASSWORD, payload }
}

export function toggleUserNotification(payload) {
  return { type: constants.TOGGLE_USER_NOTIFICATIONS, payload }
}
