import React from 'react'
import PlanCard from './PlanCard'
import { StyledSelectPaymentPlanForm } from '../billing.styled'
import PlanCardSkeleton from '../skeletons/PlanCardSkeleton'
import 'react-credit-cards/es/styles-compiled.css'

const SelectPaymentPlanForm = ({
  handlePlanSelect,
  selectedPlanId,
  paymentPlans,
  loading,
  currentPlanId,
}) => {
  const handleSelect = item => {
    handlePlanSelect(item)
  }

  if (loading) return <SelectPaymentPlanFormSkeleton />

  return (
    <StyledSelectPaymentPlanForm>
      {paymentPlans.map(item => (
        <PlanCard
          key={item.id}
          selectedPlanId={selectedPlanId}
          handleSelect={() => handleSelect(item)}
          currentPlanId={currentPlanId}
          {...item}
        />
      ))}
    </StyledSelectPaymentPlanForm>
  )
}

const SelectPaymentPlanFormSkeleton = () => {
  return (
    <StyledSelectPaymentPlanForm>
      <PlanCardSkeleton />
      <PlanCardSkeleton />
      <PlanCardSkeleton />
      <PlanCardSkeleton />
      <PlanCardSkeleton />
    </StyledSelectPaymentPlanForm>
  )
}

export default SelectPaymentPlanForm
