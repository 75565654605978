import * as constants from './billing.constants'

const initialState = {
  loading: false,
  paymentPlans: [],
  paymentMethod: {},
  invoicesLoading: false,
  invoices: {
    list: {
      data: [],
      meta: {},
    },
    details: {},
  },
}

const billing = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PAYMENT_PLANS:
    case constants.UPDATE_PAYMENT_METHOD:
    case constants.GET_PAYMENT_METHODS:
      return {
        ...state,
        loading: true,
      }
    case constants.GET_INVOICES:
    case constants.GET_INVOICE:
      return {
        ...state,
        invoicesLoading: true,
      }
    case constants.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: {
          ...state.invoices,
          list: action.payload,
        },
      }
    case constants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: {
          ...state.invoices,
          details: action.payload,
        },
      }
    case constants.GET_PAYMENT_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentPlans: action.payload,
      }
    case constants.UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethod: action.payload,
      }
    case constants.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        // we support only one payment method for now
        paymentMethod: action.payload.data.find(method => method.isDefault),
      }
    case constants.GET_PAYMENT_PLANS_ERROR:
    case constants.UPDATE_PAYMENT_METHOD_ERROR:
    case constants.GET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default billing
