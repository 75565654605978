import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Empty } from 'antd'
import { Button } from 'components'
import { useSelector } from 'react-redux'
import {
  StyledMemberInviteContainer,
  StyledMemberList,
  StyledMemberListItem,
} from '../organization.styled'

const PendingInvitations = () => {
  const { invitations } = useSelector(state => state.organization)

  return (
    <StyledMemberInviteContainer>
      <StyledMemberList>
        {invitations && invitations.length ? (
          invitations.map(item => (
            <StyledMemberListItem key={item.id}>
              <div className="d-flex flex-row">
                <div>{item.email}</div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <Button danger type="link" onClick={() => false}>
                  <FormattedMessage
                    id={`settings.organization.member.invitation.status.${item.status}`}
                    defaultMessage={item.status}
                  />
                </Button>
              </div>
            </StyledMemberListItem>
          ))
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <Empty />
          </div>
        )}
      </StyledMemberList>
    </StyledMemberInviteContainer>
  )
}

export default PendingInvitations
