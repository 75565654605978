import React, { Fragment } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { PageSubTitle } from 'components'
import { TIME_RANGES_ENUM } from '../dashboard.constants'
import {
  StyledChartContainer,
  StyledChartTooltip,
  StyledChartTooltipLabel,
  StyledChartTooltipPointCircle,
  StyledChartTooltipPointRow,
  StyledSelect,
} from '../dashboard.styled'
import { getChartDataByPeriod } from '../dashboard.utils'

const Chart = ({ data, loading, handleTimeFrameChange }) => {
  const handleDurationChange = value => {
    handleTimeFrameChange(value)
  }

  const chartData = getChartDataByPeriod(data.kyb, data.kyc)

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <PageSubTitle>
          <FormattedMessage
            id="dashboard.subTitle.chart"
            defaultMessage="Number of KYC / KYB Checks"
          />
        </PageSubTitle>
        <StyledSelect
          loading={loading}
          defaultValue={TIME_RANGES_ENUM.MONTH.key}
          onChange={handleDurationChange}
        >
          <StyledSelect.Option value={TIME_RANGES_ENUM.WEEK.key}>
            <FormattedMessage id="dashboard.chart.period.week" defaultMessage="Last Week" />
          </StyledSelect.Option>
          <StyledSelect.Option value={TIME_RANGES_ENUM.MONTH.key}>
            <FormattedMessage id="dashboard.chart.period.month" defaultMessage="Last Month" />
          </StyledSelect.Option>
        </StyledSelect>
      </div>
      <StyledChartContainer>
        <ResponsiveLine
          data={chartData}
          margin={{ top: 25, right: 35, bottom: 35, left: 35 }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            orient: 'left',
            format: e => Math.floor(e) === e && e,
          }}
          curve="monotoneX"
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <StyledChartTooltip>
                <StyledChartTooltipLabel>
                  {moment(slice.points[0].data.x).format('MM/DD/YYYY')}
                </StyledChartTooltipLabel>
                {slice.points.map(point => (
                  <StyledChartTooltipPointRow key={point.id}>
                    <StyledChartTooltipPointCircle serieColor={point.serieColor} />
                    <span>
                      {point.serieId}: {point.data.y}{' '}
                      <FormattedMessage id="dashboard.chart.label.checks" defaultMessage="Checks" />
                    </span>
                  </StyledChartTooltipPointRow>
                ))}
              </StyledChartTooltip>
            )
          }}
          enableGridX={false}
          colors={{ scheme: 'category10' }}
          pointSize={10}
          pointColor="white"
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="testY"
          useMesh
        />
      </StyledChartContainer>
    </Fragment>
  )
}

export default Chart
