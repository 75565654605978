import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import KeyIcon from 'assets/images/icons/ic-key.svg'
import ApiKeysTableRowActions from './ApiKeysTableRowActions'
import ApiKeysTableEditableColumn from './ApiKeysTableEditableColumn'

const ApiKeysTable = ({ data, handleDeleteItem, handleSaveItem }) => {
  const [editingItemId, setEditingItemId] = useState('')
  const [editingName, setEditingName] = useState('')

  const handleNameChange = e => {
    setEditingName(e.target.value)
  }

  const handleSave = () => {
    handleSaveItem({
      name: editingName,
      id: editingItemId,
    })
    handleCancel()
  }

  const handleCancel = () => {
    setEditingItemId('')
    setEditingName('')
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, item) => {
        return (
          <ApiKeysTableEditableColumn
            value={value}
            item={item}
            handleNameChange={handleNameChange}
            editingItemId={editingItemId}
          />
        )
      },
    },
    {
      title: 'API key ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      render: () => {
        return (
          <Fragment>
            <img className="mr-4" src={KeyIcon} alt="Key icon" />
            <span className="hidden-api-key">
              &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
            </span>
          </Fragment>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      className: 'column-actions',
      render: (_, item) => (
        <ApiKeysTableRowActions
          item={item}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleDelete={handleDeleteItem}
          setEditingItemId={setEditingItemId}
          editingItemId={editingItemId}
          editingName={editingName}
        />
      ),
    },
  ]

  return (
    <Table
      className="utils__scrollTable"
      rowKey="id"
      columns={columns}
      dataSource={data}
      bordered
      size="default"
      pagination={false}
    />
  )
}

ApiKeysTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  handleSaveItem: PropTypes.func.isRequired,
}

export default ApiKeysTable
