import React from 'react'
import { FormattedMessage } from 'react-intl'
import SaveAnimate from 'assets/images/icons/safe-animate.svg'
import { useSelector } from 'react-redux'
import { StyledNoPermissionWrapper } from '../organization.styled'

const NoPermissionWrapper = ({ children, roles }) => {
  const { user } = useSelector(state => state.auth)

  return roles.indexOf(user.role) !== -1 ? (
    children
  ) : (
    <StyledNoPermissionWrapper>
      <img src={SaveAnimate} alt="Info icon" />
      <div>
        <FormattedMessage
          id="settings.settings.organization.noPermission.title"
          defaultMessage="You don’t have permission to view this page"
        />
      </div>
      <FormattedMessage
        id="settings.settings.organization.noPermission.description"
        defaultMessage="Contact your organization’s admin to request increased permission"
      />
    </StyledNoPermissionWrapper>
  )
}

export default NoPermissionWrapper
