import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import DocumentIcon from 'assets/images/icons/ic-doc.svg'
import { Button } from 'components'
import { Upload, Spin, Modal, Empty } from 'antd'
import DeleteFileIcon from 'assets/images/icons/ic-file-download.svg'
import { StyledHistoryDocumentRow, StyledUploadDocumentButton } from '../../identity.styled'

const { Dragger } = Upload

const Documents = ({
  documentUploadLoading,
  handleFileUpload,
  handleFileDownload,
  data,
  documentForDownload,
  documentForDownloadLoading,
}) => {
  const [selectedDocumentKey, setSelectedDocumentKey] = useState(null)
  const [uploadedFileModalVisible, setUploadedFileModalVisible] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [enableToUpload, setEnableToUpload] = useState(true)

  useEffect(() => {
    if (documentForDownload && selectedDocumentKey) {
      window.open(documentForDownload.URL, documentForDownload.URL)
      setSelectedDocumentKey(null)
    }
  }, [documentForDownload])

  useEffect(() => {
    if (!documentUploadLoading) {
      setUploadedFileModalVisible(false)
      setFileToUpload(null)
    }
  }, [documentUploadLoading])

  const props = {
    name: 'file',
    showUploadList: false,
    customRequest: () => false,
    beforeUpload: () => {},
    onChange(info) {
      setFileToUpload(info.file)
      setEnableToUpload(true)

      const result = data.find(f => f.Key.indexOf(info.file.name) !== -1)
      if (result) {
        setEnableToUpload(false)
      }
    },
  }

  const handleUploadedFileSubmit = () => {
    handleFileUpload(fileToUpload)
  }

  const handleUploadedFileCancel = () => {
    setUploadedFileModalVisible(false)
    setFileToUpload(null)
  }

  const getFileName = key => {
    const arrayKey = key.split('/')
    return arrayKey[arrayKey.length - 1]
  }

  const handleDownloadClick = ({ fileName, key }) => {
    handleFileDownload(fileName)
    setSelectedDocumentKey(key)
  }

  return (
    <Fragment>
      <StyledUploadDocumentButton type="primary">
        <Button type="primary" onClick={() => setUploadedFileModalVisible(true)}>
          <FormattedMessage
            id="identity.history.tab.documents.upload.modal.title"
            defaultMessage="Upload a document"
          />
        </Button>
      </StyledUploadDocumentButton>

      {data && data.length ? (
        data.map(item => (
          <StyledHistoryDocumentRow key={item.Key}>
            <div>
              <img src={DocumentIcon} alt="Document icon" />
              <Button
                onClick={() =>
                  handleDownloadClick({ fileName: getFileName(item.Key), key: item.Key })
                }
                type="link"
              >
                {getFileName(item.Key)}
              </Button>
            </div>
            <div>
              <button
                onClick={() =>
                  handleDownloadClick({ fileName: getFileName(item.Key), key: item.Key })
                }
                type="button"
              >
                {documentForDownloadLoading && selectedDocumentKey === item.Key ? (
                  <Spin size="small" />
                ) : (
                  <img src={DeleteFileIcon} alt="Delete member btn" />
                )}
              </button>
            </div>
          </StyledHistoryDocumentRow>
        ))
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
          <Empty />
        </div>
      )}

      <Modal
        centered
        visible={uploadedFileModalVisible}
        title={
          <FormattedMessage
            id="identity.history.tab.documents.upload.modal.title"
            defaultMessage="Upload a document"
          />
        }
        onOk={handleUploadedFileSubmit}
        onCancel={handleUploadedFileCancel}
        footer={[
          <Button key="back" onClick={handleUploadedFileCancel}>
            <FormattedMessage id="image.upload.modal.cancel" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={documentUploadLoading}
            onClick={handleUploadedFileSubmit}
            disabled={!fileToUpload || !enableToUpload}
          >
            <FormattedMessage id="image.upload.modal.submit" defaultMessage="Submit" />
          </Button>,
        ]}
      >
        {!fileToUpload ? (
          <Dragger {...props}>
            <p className="ant-upload-text">
              <FormattedMessage
                id="identity.history.tab.documents.upload.modal.description"
                defaultMessage="Select a document"
              />
            </p>
            <Button type="primary" className="mt-2">
              <FormattedMessage
                id="identity.history.tab.documents.upload.modal.select.button"
                defaultMessage="Select a document"
              />
            </Button>
          </Dragger>
        ) : (
          <>
            <span>{fileToUpload.name}</span>
            {!enableToUpload ? (
              <div className="mt-3 ant-form-item-has-error">
                <span className="ant-form-item-explain">
                  <FormattedMessage
                    id="identity.history.tab.documents.upload.modal.document.already.exist"
                    defaultMessage="Document with this name already exists"
                  />
                </span>
              </div>
            ) : null}
          </>
        )}
      </Modal>
    </Fragment>
  )
}

export default Documents
